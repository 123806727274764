import {ArchiveItem} from "../../models/ArchiveItem";
import IconFolderSvg from "../Icons/IconFolderSvg";
import IconRecordSvg from "../Icons/IconRecordSvg";
import {formatDateTime} from "../../helpers/FormatHelper";
import {useLocale} from "../../hooks/LocaleHook";
import React from "react";

interface Props {
    archiveItem: ArchiveItem;
    isHighlighted : boolean;
    itemHighlightListener?: (fileName: string, ctrl : boolean, shift: boolean) => void;
    itemSelectionListener?: (fileName: string) => void;
    itemContextClickListener? : (fileName: string, x: number, y: number) => void;
}

export function ArchiveListItem(props: Props) {
    const locale = useLocale();
    const clickHandler = (e : React.MouseEvent) => {
        e.stopPropagation();
        if (props.archiveItem.name !== "") {
            if (props.itemHighlightListener) {
                props.itemHighlightListener(props.archiveItem.id, e.ctrlKey, e.shiftKey);
            }
        }
    };
    const doubleClickHandler = () => {
        if (props.itemSelectionListener) {
            props.itemSelectionListener(props.archiveItem.id);
        }
    };
    const contextMenuHandler = (event : any) => {
        event.preventDefault();
        if (props.itemContextClickListener) {
            props.itemContextClickListener(props.archiveItem.id, event.clientX, event.clientY);
        }
    };
    return (
        <tr className={`no-select ${props.isHighlighted ? "archive-item-active" : ""}`} onClick={clickHandler} onDoubleClick={doubleClickHandler} onContextMenu={contextMenuHandler}>
            <td className={"py-2 archive-item-data"}>
                <div className="archive-item-data">
                    <span className="item-type-icon">{props.archiveItem.isFolder ? <IconFolderSvg/> : <IconRecordSvg/>}</span>
                    <span>{props.archiveItem.name}</span>
                </div>
            </td>
            <td className={"py-2 archive-item-data"}>
                <div className="archive-item-data">
                    {formatDateTime(locale, props.archiveItem.lastChanged)}
                </div>
            </td>
        </tr>
    );
}