import {TFunction} from "i18next";
import {DATA_PLACEHOLDER} from "../AppSettings";
import {formatNumber} from "./FormatHelper";
import i18next from "i18next";

export function formatAwpTudKrcValue(value : number){
    return formatNumber(value, 1, 1);
}

export function formatAwpTudKrcProbeType(t: TFunction<"translation">, type?: number){
    switch (type){
        case 1:
            return t('probe_rebound');
        case 2:
            return t('probe_uci');
        case 3:
            return t('probe_krc');
        case 4:
            return t('probe_krc_2');
        case 5:
            return t('probe_d1');
        case 130:
            return t('probe_u2');
        default:
            return DATA_PLACEHOLDER;
    }
}

export function formatEnAwpTudKrcScaleDescription(probeType? : number){
    switch (probeType){
        case 1:
        case 2:
        case 5:
        case 130:
            return "Hardness";
        case 3:
        case 4:
            return "Coercive force";
        default:
            return DATA_PLACEHOLDER;
    }
}

export function formatAwpTudKrcScaleName(t: TFunction<"translation">, scale: number, probeType? : number){
    switch (probeType){
        case 1:
        case 2:
        case 5:
        case 130:
            return formatHardnessScaleName(t, scale);
        case 3:
        case 4:
            return formatKrcScaleName(t, scale);
        default:
            return DATA_PLACEHOLDER;
    }
}

export function formatEnAwpTudKrcScaleName(scale: number, probeType? : number){
    const t = i18next.getFixedT("en");
    return formatAwpTudKrcScaleName(t, scale, probeType);
}

export function formatAwpTudKrcMaterialName(t: TFunction<"translation">, material: number, probeType? : number){
    switch (probeType){
        case 1:
        case 2:
        case 5:
        case 130:
            return formatHardnessMaterialName(t, material);
        case 3:
        case 4:
            return formatKrcMaterialName(t, material);
        default:
            return DATA_PLACEHOLDER;
    }
}

export function formatEnAwpTudKrcMaterialName(material: number, probeType? : number){
    const t = i18next.getFixedT("en");
    return formatAwpTudKrcMaterialName(t, material, probeType);
}

function formatHardnessScaleName(t: TFunction<"translation">, scale: number){
    switch (scale) {
        case 0:
            return t('scale_hrc');
        case 1:
            return t('scale_hb');
        case 2:
            return t('scale_hv');
        case 3:
            return t('scale_hrb');
        case 4:
            return t('scale_hs');
        case 5:
            return t('scale_user_1');
        case 6:
            return t('scale_user_2');
        case 7:
            return t('scale_user_3');
        case 8:
            return t('scale_mpa');
        case 9:
            return t('scale_leeb');
        default:
            return DATA_PLACEHOLDER;
    }
}

function formatKrcScaleName(t: TFunction<"translation">, scale: number){
    switch (scale) {
        case 0:
            return t('scale_a_cm');
        case 1:
            return t('scale_hc');
        case 2:
            return t('scale_bs');
        case 3:
            return t('scale_user_1');
        case 4:
            return t('scale_user_2');
        case 5:
            return t('scale_user_3');
        case 6:
            return t('scale_user_4');
        case 7:
            return t('scale_user_5');
        case 8:
            return t('scale_hc_bs');
        case 9:
            return t('scale_cod');
        default:
            return DATA_PLACEHOLDER;
    }
}

function formatHardnessMaterialName(t: TFunction<"translation">, material: number){
    switch (material) {
        case 0:
            return t('material_steel');
        case 1:
            return t('material_alloy_steel');
        case 2:
            return t('material_stainless_steel');
        case 3:
            return t('material_gray_iron');
        case 4:
            return t('material_nodular_iron');
        case 5:
            return t('material_aluminium');
        case 6:
            return t('material_brass');
        case 7:
            return t('material_bronze');
        case 8:
            return t('material_copper');
        case 9:
            return t('material_user_1');
        case 10:
            return t('material_user_2');
        default:
            return DATA_PLACEHOLDER;
    }
}

function formatKrcMaterialName(t: TFunction<"translation">, material: number){
    switch (material) {
        case 0:
            return t('material_steel');
        case 1:
            return t('material_alloy_steel');
        case 2:
            return t('material_stainless_steel');
        case 3:
            return t('material_gray_iron');
        case 4:
            return t('material_nodular_iron');
        case 5:
            return t('material_user_1');
        case 6:
            return t('material_user_2');
        case 7:
            return t('material_user_3');
        case 8:
            return t('material_user_4');
        case 9:
            return t('material_user_5');
        case 10:
            return t('material_user_6');
        default:
            return DATA_PLACEHOLDER;
    }
}

export function getAwpTudKrcMeasurementError(scale: number, probeType? : number){
    switch (probeType){
        case 1:
        case 2:
        case 5:
        case 130:
            return getAwpHardnessMeasurementError(scale);
        case 3:
        case 4:
            return getAwpKrcMeasurementError(scale);
        default:
            return 10;
    }
}

export function getAwpHardnessMeasurementError(scale: number){
    switch (scale){
        case 0:
            return 4;
        case 1:
            return 20;
        case 2:
            return 20;
        case 3:
            return 4;
        case 4:
            return 10;
        case 5:
            return 10;
        case 6:
            return 10;
        case 7:
            return 10;
        case 8:
            return 10;
        case 9:
            return 10;
        default:
            return 10;
    }
}

export function getAwpKrcMeasurementError(scale: number){
    switch (scale){
        default:
            return 10;
    }
}