import * as React from "react"
import {SVGProps} from "react"

const MultipleRecordsIconSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 484.178 484.179"
        xmlSpace="preserve"
        {...props}
    >
        <path d="M408.792 0h-267.44c-19.038 0-34.531 15.498-34.531 34.531v22.053H75.386c-19.038 0-34.53 15.498-34.53 34.531v358.533c0 19.033 15.492 34.531 34.53 34.531h267.449c19.039 0 34.531-15.498 34.531-34.531v-22.053h31.426c19.041 0 34.531-15.498 34.531-34.53V34.531C443.323 15.498 427.833 0 408.792 0zm-59.049 449.648c0 3.804-3.105 6.907-6.908 6.907H75.386c-3.802 0-6.907-3.104-6.907-6.907V91.115c0-3.807 3.105-6.907 6.907-6.907h31.435v308.856c0 19.032 15.493 34.53 34.531 34.53h208.391v22.054zm65.957-56.583c0 3.803-3.098 6.907-6.908 6.907h-267.44c-3.811 0-6.906-3.104-6.906-6.907V34.531c0-3.806 3.096-6.907 6.906-6.907h267.439c3.811 0 6.908 3.102 6.908 6.907v358.534z" fill="currentColor"/>
        <path d="M376.36 85.786H173.785c-7.628 0-13.813 6.19-13.813 13.811 0 7.636 6.186 13.813 13.813 13.813h202.573c7.629 0 13.812-6.178 13.812-13.813.002-7.62-6.183-13.811-13.81-13.811zM376.36 151.529H173.785c-7.628 0-13.813 6.188-13.813 13.811 0 7.634 6.186 13.813 13.813 13.813h202.573c7.629 0 13.812-6.18 13.812-13.813.002-7.622-6.183-13.811-13.81-13.811zM376.36 217.27H173.785c-7.628 0-13.813 6.191-13.813 13.814 0 7.633 6.186 13.811 13.813 13.811h202.573c7.629 0 13.812-6.178 13.812-13.811.002-7.623-6.183-13.814-13.81-13.814zM376.36 283.014H173.785c-7.628 0-13.813 6.191-13.813 13.811 0 7.638 6.186 13.813 13.813 13.813h202.573c7.629 0 13.812-6.177 13.812-13.813.002-7.62-6.183-13.811-13.81-13.811z" fill="currentColor"/>
    </svg>
)

export default MultipleRecordsIconSvg
