import {formatNumber} from "../../helpers/FormatHelper";
import {useTranslation} from "react-i18next";
import {TpSurfaceTemperatureMeasurement} from "../../models/TpSurfaceTemperatureMeasurement";

interface Props {
    measurement: TpSurfaceTemperatureMeasurement;
}

export function TpSurfaceTemperatureMeasurementWithoutPhoto(props: Props) {
    const {t} = useTranslation();
    return (
        <div className="d-flex flex-row flex-grow-1 align-items-center">
            <table className="measurement-info-table w-100">
                <tbody>
                <tr>
                    <th>{t('temperature')}</th>
                    <td>{`${formatNumber(props.measurement.temperature, 1, 1)} ⁰C`}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}