import './AwpRecordList.css';
import React, {useMemo} from "react";
import {TFunction} from "i18next";
import {AwpRecordListItem} from "./AwpRecordListItem";
import {AwpRecordInfo} from "../../models/AwpRecordInfo";
import {DATA_PLACEHOLDER} from "../../AppSettings";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA
} from "../../helpers/AwpHelper";
import {formatAwpTpProbeType} from "../../helpers/AwpTpFormatHelper";
import {formatAwpTudKrcProbeType} from "../../helpers/AwpTudKrcFormatHelper";
import {AwpRecordListHeader} from "./AwpRecordListHeader";
import {formatAwpUd2303ProbeType} from "../../helpers/AwpUd2303FormatHelper";
import {EmptyArchivePlaceholder} from "./EmptyArchivePlaceholder";
import {usePersistentState} from "../../hooks/PersistentStateHook";
import {AWP_LIST_SORT_ORDER} from "../../persistence";
import {useTranslation} from "react-i18next";

interface Props {
    isRoot: boolean;
    deviceType: number;
    records: Array<AwpRecordInfo>;
    highlightedItems: Array<string>;
    itemHighlightListener?: (fileName: string, ctrl: boolean, shift: boolean) => void;
    itemContextClickListener?: (fileName: string, x: number, y: number) => void;
    itemDoubleClickListener?: (fileName: string) => void;
    sortedIdsRef?: React.MutableRefObject<Array<string> | null>;
}

interface ItemInfo {
    isFolder: boolean;
    data: (string | Date | undefined)[];
}

export function AwpRecordList(props: Props) {
    const {t} = useTranslation();
    const [sortType, setSortType] = usePersistentState(AWP_LIST_SORT_ORDER, 0);
    const headers = useMemo(() => {
        return prepareHeaders(t, props.deviceType, props.records);
    }, [t, props.deviceType, props.records]);
    const items = useMemo(() => {
        return prepareItems(t, props.deviceType, props.records);
    }, [t, props.deviceType, props.records]);
    const sortedItems = useMemo(() => {
        const column = Math.floor(sortType / 2);
        const order = sortType % 2 === 0 ? 1 : -1;
        const sortedItems = new Array<ItemInfo>(...items);
        sortedItems.sort((a, b) => {
            if (a.data[column] instanceof Date && b.data[column] instanceof Date) {
                return order * ((a.data[column] as Date).getTime() - (b.data[column] as Date).getTime());
            } else {
                if ((a.data[column] ?? "") > (b.data[column] ?? "")) {
                    return order;
                }
                if ((a.data[column] ?? "") < (b.data[column] ?? "")) {
                    return -order;
                }
                return 0;
            }
        }).sort((a, b) => (a.isFolder < b.isFolder) ? 1 : (a.isFolder === b.isFolder ? 0 : -1));
        if (props.sortedIdsRef) {
            props.sortedIdsRef.current = sortedItems.map(item => item.data[item.data.length - 1] as string);
        }
        if (!props.isRoot) {
            const rootItem = prepareRootItem(props.deviceType);
            if (rootItem) {
                sortedItems.unshift(rootItem);
            }
        }
        return sortedItems;
    }, [items, sortType]);
    return (
        <table className="record-list no-select">
            <thead>
            <AwpRecordListHeader titles={headers} sortType={sortType} sortTypeChangeHandler={setSortType}/>
            </thead>
            <tbody>
            {sortedItems.map((item, idx) => (
                <AwpRecordListItem key={`rli-${idx}`} item={item.data}
                                   isFolder={item.isFolder}
                                   isHighlighted={props.highlightedItems.includes(item.data[item.data.length - 1] as string)}
                                   itemHighlightListener={props.itemHighlightListener}
                                   itemSelectionListener={props.itemDoubleClickListener}
                                   itemContextClickListener={props.itemContextClickListener}/>
            ))}
            {props.records.length === 0 &&
                <tr style={{height: "100%"}}>
                    <td colSpan={headers.length} className='py-4'>
                        <EmptyArchivePlaceholder/>
                    </td>
                </tr>}
            {props.records.length > 0 &&
            <tr style={{height: "100%"}}/>
            }
            </tbody>
            <tfoot>
            <tr className="fixed-footer">
                <td colSpan={headers.length}>
                    <div className="my-2"/>
                    <div className="small-instruction-text no-select">{t('record_list_instruction_1')}</div>
                </td>
            </tr>
            </tfoot>
        </table>
    );
}

function prepareHeaders(t: TFunction<"translation">, deviceType: number, records: Array<AwpRecordInfo>) {
    switch (deviceType) {
        case AWP_DEVICE_TUD:
        case AWP_DEVICE_KRC:
            if (records.some(r => r.date)) {
                return [t("record"), t("date_time"), t("probe"), t("probe_id"), t("device_id")];
            } else {
                return [t("record"), t("probe"), t("probe_id"), t("device_id")];
            }
        case AWP_DEVICE_TP:
            return [t("record"), t("probe"), t("probe_id"), t("device_id")];
        case AWP_DEVICE_UT:
            return [t("record"), t("probe"), t("device_id")];
        case AWP_DEVICE_MF:
            return [t("record"), t("probe"), t("probe_id"), t("device_id")];
        case AWP_DEVICE_UD2301:
            return [t("record"), t("date_time"), t("device_id")];
        case AWP_DEVICE_UD2303:
            return [t("record"), t("date_time"), t("probe"), t("device_id")];
        case AWP_DEVICE_UD3701:
            return [t("record"), t("date_time"), t("device_id")];
        case AWP_DEVICE_UT3EMA:
            return [t("record"), t("date_time"), t("device_id")];
        case AWP_DEVICE_UT2A:
            return [t("record"), t("date_time"), t("device_id")];
        case AWP_DEVICE_IPSM:
            return [t("record"), t("date_time"), t("device_id")];
    }
    return [];
}

function prepareItems(t: TFunction<"translation">, deviceType: number, records: Array<AwpRecordInfo>): Array<ItemInfo> {
    switch (deviceType) {
        case AWP_DEVICE_TUD:
        case AWP_DEVICE_KRC:
            if (records.some(r => r.date)) {
                return records.map(r => {
                    return {
                        isFolder: r.isFolder,
                        data: [r.name, r.date, formatAwpTudKrcProbeType(t, r.probeType), r.probeId ?? DATA_PLACEHOLDER, r.deviceId ?? DATA_PLACEHOLDER, r.fileName]
                    };
                });
            } else {
                return records.map(r => {
                    return {
                        isFolder: r.isFolder,
                        data: [r.name, formatAwpTudKrcProbeType(t, r.probeType), r.probeId ?? DATA_PLACEHOLDER, r.deviceId ?? DATA_PLACEHOLDER, r.fileName]
                    };
                });
            }
        case AWP_DEVICE_TP:
            return records.map(r => {
                return {
                    isFolder: r.isFolder,
                    data: [r.name, formatAwpTpProbeType(t, r.probeId, r.probeName), r.probeId ?? DATA_PLACEHOLDER, r.deviceId ?? DATA_PLACEHOLDER, r.fileName]
                };
            });
        case AWP_DEVICE_UT:
            return records.map(r => {
                return {
                    isFolder: r.isFolder,
                    data: [r.name, r.probeName ?? DATA_PLACEHOLDER, r.deviceId ?? DATA_PLACEHOLDER, r.fileName]
                };
            });
        case AWP_DEVICE_MF:
            return records.map(r => {
                return {
                    isFolder: r.isFolder,
                    data: [r.name, r.probeName ?? DATA_PLACEHOLDER, r.probeId ?? DATA_PLACEHOLDER, r.deviceId ?? DATA_PLACEHOLDER, r.fileName]
                };
            });
        case AWP_DEVICE_UD2301:
            return records.map(r => {
                return {
                    isFolder: r.isFolder,
                    data: [r.name, r.date, r.deviceId ?? DATA_PLACEHOLDER, r.fileName]
                };
            });
        case AWP_DEVICE_UD2303:
            return records.map(r => {
                return {
                    isFolder: r.isFolder,
                    data: [r.name, r.date, formatAwpUd2303ProbeType(t, r.probeType), r.deviceId ?? DATA_PLACEHOLDER, r.fileName]
                };
            });
        case AWP_DEVICE_UD3701:
            return records.map(r => {
                return {
                    isFolder: r.isFolder,
                    data: [r.name, r.date, r.deviceId ?? DATA_PLACEHOLDER, r.fileName]
                };
            });
        case AWP_DEVICE_UT3EMA:
            return records.map(r => {
                return {
                    isFolder: r.isFolder,
                    data: [r.name, r.date, r.deviceId ?? DATA_PLACEHOLDER, r.fileName]
                };
            });
        case AWP_DEVICE_UT2A:
            return records.map(r => {
                return {
                    isFolder: r.isFolder,
                    data: [r.name, r.date, r.deviceId ?? DATA_PLACEHOLDER, r.fileName]
                };
            });
        case AWP_DEVICE_IPSM:
            return records.map(r => {
                return {
                    isFolder: r.isFolder,
                    data: [r.name, r.date, r.deviceId ?? DATA_PLACEHOLDER, r.fileName]
                };
            });
    }
    return [];
}

function prepareRootItem(deviceType: number): ItemInfo | undefined {
    switch (deviceType) {
        case AWP_DEVICE_TUD:
        case AWP_DEVICE_KRC:
            return {
                isFolder: true,
                data: ["..", "", "", "", "", ""]
            };
        case AWP_DEVICE_TP:
            return {
                isFolder: true,
                data: ["..", "", "", "", ""]
            };
        case AWP_DEVICE_UT:
            return {
                isFolder: true,
                data: ["..", "", "", ""]
            };
        case AWP_DEVICE_MF:
            return {
                isFolder: true,
                data: ["..", "", "", "", ""]
            };
        case AWP_DEVICE_UD2301:
            return {
                isFolder: true,
                data: ["..", "", "", ""]
            };
        case AWP_DEVICE_UD2303:
            return {
                isFolder: true,
                data: ["..", "", "", "", ""]
            };
        case AWP_DEVICE_UD3701:
            return {
                isFolder: true,
                data: ["..", "", "", ""]
            };
        case AWP_DEVICE_UT3EMA:
            return {
                isFolder: true,
                data: ["..", "", "", ""]
            };
        case AWP_DEVICE_UT2A:
            return {
                isFolder: true,
                data: ["..", "", "", ""]
            };
        case AWP_DEVICE_IPSM:
            return {
                isFolder: true,
                data: ["..", "", "", ""]
            };
    }
    return undefined;
}