export class ResourceNotFoundError extends Error{
    constructor() {
        super("Resource not found");
    }
}

export class ArchiveFolderCreationError extends Error{
    constructor() {
        super("Archive folder creation error");
    }
}

export class GoogleDriveRootFolderNotFoundError extends Error{
    constructor() {
        super("Google Drive root folder not found");
    }
}

export class GoogleDriveError extends Error{
    constructor() {
        super("Unknown Google Drive error");
    }
}