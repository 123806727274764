import './AwpSeriesInfo.css';
import {AWP_DEVICE_UT, AwpSeries, formatAwpValue} from "../../helpers/AwpHelper";
import {formatNumber} from "../../helpers/FormatHelper";
import {useTranslation} from "react-i18next";
import {AwpUtSeries} from "../../models/AwpUtSeries";
import {convertAwpUtValue, formatAwpUtScaleName} from "../../helpers/AwpUtFormatHelper";

interface Props{
    data: AwpSeries;
}

export function AwpUtSeriesInfo(props : Props) {
    const {t} = useTranslation();
    const data = props.data as AwpUtSeries;
    return (
        <table className="table-measurement-info">
            <tbody>
            <tr>
                <th>{t('speed_material')}</th>
                <td>{formatNumber(data.measure.speedMaterial)}</td>
            </tr>
            <tr>
                <th>{t('scale')}</th>
                <td>{formatAwpUtScaleName(t, data.measure.scale)}</td>
            </tr>
            <tr>
                <th>{t('thickness')}</th>
                <td>{formatAwpValue(AWP_DEVICE_UT, convertAwpUtValue(data.measure.scale, data.measure.saveThinc), props.data)}</td>
            </tr>
            </tbody>
        </table>
    );
}