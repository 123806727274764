import {Chart, registerables} from "chart.js";

Chart.register(...registerables);

export const MM_TO_PX_FACTOR = 5;

export const backgroundPlugin = {
    id: 'custom_canvas_background_color',
    beforeDraw: (chart : Chart) => {
        const ctx = chart.canvas.getContext('2d');
        if (ctx) {
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    }
};