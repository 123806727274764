import {MeasurementLocation} from "./MeasurementLocation";
import {UtMeasurementContact} from "./UtMeasurementContact";
import {UtRawMeasurement} from "./UtRawMeasurement";

export interface UtMeasurement{
    measurementContacts : UtMeasurementContact[];
    utScale : string;

    location? : MeasurementLocation;
    dateTime : number;

    isNewContact : boolean;
    isBlockedNewContact : boolean;
    isCalibrationContact : boolean;

    discreteness : number;
}

export function getAllMeasurements(utMeasurement : UtMeasurement) : UtRawMeasurement[]{
    const result = [];
    for(let contact of utMeasurement.measurementContacts){
        for (const measurement of contact.rawMeasurements){
            result.push(measurement);
        }
    }
    return result;
}