import {IS_DEMO_APP} from "../../AppSettings";
import {AwpLocalRecordView} from "./AwpLocalRecordView";
import {AwpDemoRecordView} from "./AwpDemoRecordView";


interface Props {
    deviceType: number;
}

export function AwpRecordView(props: Props) {
    if (IS_DEMO_APP){
        return AwpDemoRecordView(props);
    } else {
        return AwpLocalRecordView(props);
    }
}