import IconSortAscSvg from "../Icons/IconSortAscSvg";
import IconSortDescSvg from "../Icons/IconSortDescSvg";
import {Fragment} from "react";
import {HorizontalDivider} from "../Divider/HorizontalDivider";

interface Props {
    titles: Array<string>;
    sortType: number;
    sortTypeChangeHandler: (sortType: number) => void;
}

export function AwpRecordListHeader(props: Props) {
    return (
        <Fragment>
            <tr>
                {props.titles.map((title, index) => {
                    const ascSort = index * 2;
                    const descSort = ascSort + 1;
                    return (
                        <th key={`rlh-${index}`} className="fixed-header"
                            onClick={() => props.sortTypeChangeHandler(props.sortType === ascSort ? descSort : ascSort)}>
                            <div className="sort-type py-1">
                                {title}
                                {props.sortType === ascSort && <div className="sort-icon"><IconSortAscSvg/></div>}
                                {props.sortType === descSort && <div className="sort-icon"><IconSortDescSvg/></div>}
                                {props.sortType !== ascSort && props.sortType !== descSort &&
                                    <div className="sort-icon-placeholder"/>}
                            </div>
                        </th>
                    );
                })}
            </tr>
            <tr>
                <td colSpan={5} className="fixed-header" style={{top: 34}}><HorizontalDivider/></td>
            </tr>
        </Fragment>
    );
}