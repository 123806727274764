import {useTranslation} from "react-i18next";
import {AwpSeries} from "../../helpers/AwpHelper";
import {formatNumber} from "../../helpers/FormatHelper";
import {AwpUt3EmaSeries} from "../../models/AwpUt3EmaSeries";
import {DATA_PLACEHOLDER} from "../../AppSettings";
import {formatUt3EmaProbeInfo, getUt3EmaDeviceInfo} from "../../helpers/AwpUt3EmaFormatHelper";

interface Props {
    data: AwpSeries;
}

export function AwpUt3EmaDeviceInfo(props: Props) {
    const {t} = useTranslation();
    const data = props.data as AwpUt3EmaSeries;
   const  deviceInfo = getUt3EmaDeviceInfo(t, data);
    return (
        <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-stretch">
            <table className="table-measurement-info">
                <tbody>
                <tr>
                    <th>{t('working_mode')}</th>
                    <td>{deviceInfo.workMode}</td>
                </tr>
                <tr>
                    <th>{t('measurement_mode')}</th>
                    <td>{deviceInfo.measMode}</td>
                </tr>
                <tr>
                    <th>{t('amplification_db')}</th>
                    <td>{deviceInfo.gain}</td>
                </tr>
                <tr>
                    <th>{t('averaging_points')}</th>
                    <td>{deviceInfo.middle}</td>
                </tr>
                <tr>
                    <th>{deviceInfo.durationTitle}</th>
                    <td>{deviceInfo.duration}</td>
                </tr>
                <tr>
                    <th>{deviceInfo.delayTitle}</th>
                    <td>{deviceInfo.delay}</td>
                </tr>
                <tr>
                    <th>{deviceInfo.velocityTitle}</th>
                    <td>{deviceInfo.velocity}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}