import {CartesianGrid, Legend, Line, LineChart, XAxis, YAxis} from "recharts";
import {useEffect, useMemo, useRef, useState} from "react";
import {DpmMeasurement} from "../../models/DpmMeasurement";
import {useTranslation} from "react-i18next";

const DATA_COLOR="#27769d";
const DATA_COLOR_2="#F43249";
const DATA_COLOR_3="#629c44";
const DATA_COLOR_4="#262626";

export interface DpmChartData {
    x: number;
    humidity: number;
    tempSurface: number;
    tempAir: number;
    dewPoint: number;
}

interface ChartProperties {
    data: DpmChartData[];
    tempMinY: number;
    tempMaxY: number;
    humidityMinY : number,
    humidityMaxY: number,
    ticksY: number;
    maxX : number;
    ticksX: number;
}

interface Props {
    data: DpmMeasurement;
    classNames? : string;
}

export function MeasurementsDpmChart(props: Props) {
    const {t} = useTranslation();
    const container = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const checkSize = () => {
        const w = container.current?.clientWidth ?? 0;
        const h = container.current?.clientHeight ?? 0;
        if (w !== width) {
            setWidth(w);
        }
        if (h !== height) {
            setHeight(h);
        }
    };
    useEffect(() => {
        checkSize();
        window.addEventListener("resize", checkSize);
        return () => {
            window.removeEventListener("resize", checkSize);
        }
    });
    const chartProps = useMemo(() => {
        const tempPoints = new Array<number>();
        tempPoints.push(...props.data.measurements.map(m => m.tempSurface));
        tempPoints.push(...props.data.measurements.map(m => m.tempAir));
        tempPoints.push(...props.data.measurements.map(m => m.devPoint));
        const minTemp = Math.min(...tempPoints);
        const maxTemp = Math.max(...tempPoints);
        const tempMeasurementError = 1;
        const tempDelta = Math.max(1.5 * (maxTemp - minTemp), tempMeasurementError * 10);
        const tempMiddle = minTemp + (maxTemp - minTemp) / 2;
        const texmAxisMin = Math.floor(tempMiddle - tempDelta / 2);
        const tempAxisMax = texmAxisMin + Math.ceil(tempDelta / 10) * 10;
        const divisions = 11;
        const data = props.data.measurements.map((m, i) => {
            return {
                x: i,
                humidity: m.humidity,
                tempSurface: m.tempSurface,
                tempAir: m.tempAir,
                dewPoint: m.devPoint
            } as DpmChartData
        });
        return {
            data: data,
            tempMinY: texmAxisMin,
            tempMaxY: tempAxisMax,
            humidityMinY: 0,
            humidityMaxY: 100,
            ticksY: divisions,
            maxX: 180,
            ticksX: 11
        } as ChartProperties;
    }, [props]);
    return (
        <div className={`d-flex flex-column ${props.classNames}`} ref={container}>
            <LineChart width={width} height={height} data={chartProps.data}>
                <CartesianGrid vertical={false}/>
                <XAxis dataKey={"x"} domain={[0, chartProps.maxX]} type={"number"} tickCount={chartProps.ticksX}
                       interval={0} padding={{left: 0, right: 16}}/>
                <YAxis yAxisId="temp" domain={[chartProps.tempMinY, chartProps.tempMaxY]} tickCount={chartProps.ticksY} tickFormatter={(v : number) => v.toFixed(0)}/>
                <YAxis yAxisId="humidity" orientation="right" domain={[chartProps.humidityMinY, chartProps.humidityMaxY]} tickCount={chartProps.ticksY} tickFormatter={(v : number) => v.toFixed(0)}/>
                <Legend verticalAlign={"top"} height={36}/>
                <Line dataKey={"humidity"} name={`${t("air_humidity_short")} %`} stroke={DATA_COLOR} dot={false} yAxisId="humidity"/>
                <Line dataKey={"tempSurface"} name={`${t("surface_temperature_short")} ⁰C`} stroke={DATA_COLOR_2}  dot={false}  yAxisId="temp"/>
                <Line dataKey={"tempAir"} name={`${t("air_temperature_short")} ⁰C`} stroke={DATA_COLOR_3}  dot={false}  yAxisId="temp"/>
                <Line dataKey={"dewPoint"} name={`${t("dew_point_temperature_short")} ⁰C`} stroke={DATA_COLOR_4}  dot={false}  yAxisId="temp"/>
            </LineChart>
        </div>
    );
}