import {OverlayTrigger, Popover} from "react-bootstrap";
import IconHelpSvg from "../Icons/IconHelpSvg";
import './HelpButton.css';

interface Props{
    text: string;
}

export function HelpButton(props: Props){
    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <span style={{display:"block", textAlign: "center"}}>{props.text}</span>
            </Popover.Body>
        </Popover>
    );
    return (
        <OverlayTrigger trigger={["hover","focus"]} placement="bottom" overlay={popover}>
            <div
                className='d-flex flex-shrink-0 justify-content-center align-items-center help-button-container disabled'>
                <IconHelpSvg/>
            </div>
        </OverlayTrigger>
    );
}