import {TFunction} from "i18next";
import {AwpDeviceInfo} from "../../models/AwpDeviceInfo";
import {AwpFwInfo} from "../../models/AwpFwInfo";
import {getAwpFwDeviceCloudType} from "../../helpers/AwpFwFormatHelper";

export const CONNECTION_STATE_NOT_CONNECTED = 1;
export const CONNECTION_STATE_CONNECTING = 2;
export const CONNECTION_STATE_CONNECTED = 3;
export const CONNECTION_STATE_AUTO_DETECTION = 4;

export function formatConnectionState(t: TFunction<"translation">, state: number) {
    switch (state) {
        case CONNECTION_STATE_NOT_CONNECTED:
            return t("loader_not_connected");
        case CONNECTION_STATE_CONNECTING:
            return t("awp_fw_connecting");
        case CONNECTION_STATE_CONNECTED:
            return t("loader_connected");
        case CONNECTION_STATE_AUTO_DETECTION:
            return t("loader_autodetect_in_progress");
    }
    return "";
}

export interface FwLoaderStatusMessage {
    text: string;
    isError?: boolean;
}

export interface FwLoaderState {
    autodetect: boolean;
    slowDevice: boolean;
    deviceInfo: AwpDeviceInfo | null | undefined;
    portState: number;
    progress: number | undefined;
    fwData: FwLoaderClientFirmwareData | undefined;
    terminalText: string;
    chatText: string;
}

export interface DeviceReadyConfirmationData {
    instructions: string;
    type: number;
    content: ArrayBuffer;
}

export interface FwLoaderFirmwareSubFileData {
    name: string;
    type: number;
    content: ArrayBuffer;
}

export interface FwLoaderFirmwareData {
    info: AwpFwInfo | undefined;
    name: string;
    type: number;
    content: ArrayBuffer;
    subFiles: Array<FwLoaderFirmwareSubFileData> | undefined
}

export interface FwLoaderClientFirmwareSubFileData {
    name: string;
    type: number;
}

export interface FwLoaderClientFirmwareData {
    info: AwpFwInfo | undefined;
    name: string;
    type: number;
    subFiles: Array<FwLoaderClientFirmwareSubFileData> | undefined
}

export interface FwLoaderRemoteFirmwareInfo {
    info: AwpFwInfo | undefined;
    name: string;
    type: number;
    chunks: number;
}

export interface FwWriteRequest {
    subFileName?: string;
}

export function checkFwCompatibility(deviceInfo: AwpDeviceInfo | null | undefined, fwData: FwLoaderFirmwareData | FwLoaderClientFirmwareData | undefined): boolean | undefined {
    if (deviceInfo && fwData?.info) {
        if (getAwpFwDeviceCloudType(deviceInfo.deviceTypeId) === fwData.info.deviceType && fwData.info.hwMin <= deviceInfo.hwVersion && fwData.info.hwMax >= deviceInfo.hwVersion) {
            if (!fwData.info.bootloader) {
                return true;
            } else if (deviceInfo.bootloader === fwData.info.bootloader) {
                return true;
            } else if (deviceInfo.bootloader === "1.0" && (fwData.info.bootloader === "old" || fwData.info.bootloader === "new")) {
                return undefined;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    return undefined;
}