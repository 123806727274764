import {useTranslation} from "react-i18next";
import {Button, Form, Modal} from "react-bootstrap";
import IconCopySvg from "../Icons/IconCopySvg";
import {generateAssistCode} from "../../helpers/RandomHelper";

interface Props {
    show: boolean;
    closeHandler: (code?: string) => void;
}

export function RequestAssistDialog(props: Props) {
    const {t} = useTranslation();
    const code = generateAssistCode();
    const copyButtonClickHandler = () => {
        navigator.clipboard.writeText(code).then(() => {
            props.closeHandler(code);
        });
    };
    return (
        <Modal show={props.show} onHide={() => props.closeHandler()}>
            <Modal.Header closeButton>
                <Modal.Title>{t("loader_remote_assistance")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>{t("loader_remote_assistance_prompt")}</div>
                <div className="d-flex align-self-stretch flex-row justify-content-between align-items-center my-4">
                    <Form.Control type="text" disabled className="flex-grow fw-bold text-center"
                                  defaultValue={code}/>
                    <Button className="ms-4" variant="outline-primary"
                            onClick={copyButtonClickHandler}><IconCopySvg/></Button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="mx-2"
                        onClick={() => props.closeHandler()}>{t("cancel")}</Button>
                <Button variant="primary" className="ms-2"
                        onClick={() => props.closeHandler(code)}>{t("ok")}</Button>
            </Modal.Footer>
        </Modal>
    );
}