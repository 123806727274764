import {MediaRecord} from "../../models/MediaRecord";
import {useEffect, useRef, useState} from "react";
import {getMediaFile, getMediaRecordUrl} from "../../helpers/GoogleStorageApiHelper";
import {Error} from "../Error/Error";
import {useAbortStatus} from "../../hooks/AbortHook";
import {LoaderSmall} from "../Loader/LoaderSmall";
import {ResourceNotFoundError} from "../../errors/GoogleDriveErrors";
import {NotFoundSmall} from "../NotFound/NotFoundSmall";
import {ErrorSmall} from "../Error/ErrorSmall";
import {useScreenSize} from "../../hooks/ScreenSizeHooks";
import {isIOS, isMacOs} from "react-device-detect";
import {PlaybackError, UnknownPlayerError} from "../../errors/MediaPlayerErrors";
import {VideoNotSupported} from "../VideoNotSupported/VideoNotSupported";

interface Props {
    drive: any;
    recordId: string;
    mediaRecord: MediaRecord;
}

export function VideoPlayer(props: Props) {
    const isAborted = useAbortStatus();
    const container = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState("100%");
    const [height, setHeight] = useState("250px");
    const [videoWidth, setVideoWidth] = useState(null as number | null);
    const [videoHeight, setVideoHeight] = useState(null as number | null);
    const [screenWidth, screenHeight] = useScreenSize();
    const [mediaUrl, setMediaUrl] = useState(null as string | null);
    const [error, setError] = useState(null as Error | null);
    const [retry, setRetry] = useState(0);
    const isApple = isMacOs || isIOS;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        (isApple ? getMediaFile(props.drive, props.recordId, props.mediaRecord.fileName) : getMediaRecordUrl(props.drive, props.recordId, props.mediaRecord.fileName)).then(url => {
            if (!isAborted) {
                setMediaUrl(url);
                setError(null);
            }
        }).catch((e) => {
            if (!isAborted) {
                setError(e);
            }
        });
    }, [props.drive, props.recordId, props.mediaRecord, retry]);
    const videoMetadataLoadHandler = (e: any) => {
        if (!isAborted) {
            const video = e.target as HTMLVideoElement;
            setVideoWidth(video.videoWidth);
            setVideoHeight(video.videoHeight);
        }
    };
    useEffect(() => {
        if (videoWidth && videoHeight) {
            let h = Math.min(screenHeight * 0.8, videoHeight);
            let w = videoWidth * h / videoHeight;
            w = Math.min(container.current?.clientWidth ?? 0, w);
            h = videoHeight * w / videoWidth;
            setWidth(`${w}px`);
            setHeight(`${h}px`);
        }
    }, [videoWidth, videoHeight, screenWidth, screenHeight]);
    const renderError = (error: Error) => {
        if (error instanceof ResourceNotFoundError) {
            return <NotFoundSmall/>;
        }
        if (error instanceof PlaybackError) {
            return <VideoNotSupported downloadClickHandler={() => {
                if (mediaUrl) {
                    const a = document.createElement("a");
                    a.href = mediaUrl;
                    a.download = props.mediaRecord.fileName;
                    a.click();
                    a.remove();
                }
            }
            }/>
        }
        return <ErrorSmall error={error} retryClickHandler={() => setRetry(retry + 1)}/>;
    };
    return (
        <div className="d-flex flex-column my-2">
            {(!error && !mediaUrl) && <LoaderSmall/>}
            {(error || mediaUrl) &&
            <span className="view-item-name align-self-center">{props.mediaRecord.fileName}</span>}
            {error && renderError(error)}
            {(!error && mediaUrl) &&
            <div ref={container} className={"d-flex flex-column align-self-stretch mt-2"}>
                <video preload="metadata" onError={e => {
                    const code = (e.target as HTMLVideoElement).error?.code ?? 0;
                    if (code === 3 || code === 4) {
                        setError(new PlaybackError());
                    } else {
                        setError(new UnknownPlayerError());
                    }
                }} onLoadedMetadata={videoMetadataLoadHandler} width={width} height={height}
                       className="align-self-center" controls src={`${mediaUrl}#t=0.01`}/>
            </div>
            }
        </div>
    );
}