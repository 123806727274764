export const MQTT_MESSAGE_PING = 999;
export const MQTT_MESSAGE_CHAT = 1;
export const MQTT_MESSAGE_TERMINAL = 2;
export const MQTT_MESSAGE_PORT_STATE = 11;
export const MQTT_MESSAGE_AUTODETECT_STATE = 12;
export const MQTT_MESSAGE_LOW_BAUD_RATE_STATE = 13;
export const MQTT_MESSAGE_DEVICE_INFO = 21;
export const MQTT_CLIENT_FW_DATA = 22;
export const MQTT_MESSAGE_FW_WRITE_PROGRESS = 23;
export const MQTT_MESSAGE_FW_DATA_INFO = 24;
export const MQTT_MESSAGE_FW_DATA_BINARY = 25;
export const MQTT_MESSAGE_FW_DATA_DONE = 26;
export const MQTT_MESSAGE_FW_DATA_CANCEL = 27;
export const MQTT_MESSAGE_FW_DATA_REPEAT = 28;
export const MQTT_MESSAGE_FW_DATA_RECEIVED = 29;
export const MQTT_MESSAGE_ENUMERATE_COM_PORTS = 31;
export const MQTT_MESSAGE_COM_PORT_ENUMERATION = 32;
export const MQTT_MESSAGE_CONNECT_COM_PORT = 33;
export const MQTT_MESSAGE_DISCONNECT_COM_PORT = 34;
export const MQTT_MESSAGE_START_WRITE_FW = 51;
export const MQTT_MESSAGE_STOP_WRITE_FW = 52;
export const MQTT_MESSAGE_SUPPORT_CONNECTED = 101;
export const MQTT_MESSAGE_CLIENT_STATE = 102;
export const MQTT_MESSAGE_CLIENT_TERMINATED_SESSION = 103
export const MQTT_MESSAGE_SUPPORT_DISCONNECTED = 104



export const MQTT_MESSAGE_PING_HEADER = "[PING]"
export const MQTT_MESSAGE_CHAT_HEADER = "[CHAT]"
export const MQTT_MESSAGE_TERMINAL_HEADER = "[TERM]"
export const MQTT_MESSAGE_PORT_STATE_HEADER = "[PST]"
export const MQTT_MESSAGE_AUTODETECT_STATE_HEADER = "[ADST]";
export const MQTT_MESSAGE_LOW_BAUD_RATE_STATE_HEADER = "[LBST]";
export const MQTT_MESSAGE_DEVICE_INFO_HEADER = "[DEV]";
export const MQTT_MESSAGE_CLIENT_FW_DATA_HEADER = "[FWN]";
export const MQTT_MESSAGE_FW_WRITE_PROGRESS_HEADER = "[FWPR]";
export const MQTT_MESSAGE_FW_DATA_INFO_HEADER = "[FWI]";
export const MQTT_MESSAGE_FW_DATA_BINARY_HEADER = "[FWB]";
export const MQTT_MESSAGE_FW_DATA_DONE_HEADER = "[FWD]";
export const MQTT_MESSAGE_FW_DATA_CANCEL_HEADER = "[FWC]";
export const MQTT_MESSAGE_FW_DATA_REPEAT_HEADER = "[FWR]";
export const MQTT_MESSAGE_FW_DATA_RECEIVED_HEADER = "[FWA]";
export const MQTT_MESSAGE_ENUMERATE_COM_PORTS_HEADER = "[PENUM]";
export const MQTT_MESSAGE_COM_PORT_ENUMERATION_HEADER = "[PDATA]";
export const MQTT_MESSAGE_CONNECT_COM_PORT_HEADER = "[CON]";
export const MQTT_MESSAGE_DISCONNECT_COM_PORT_HEADER = "[DCON]";
export const MQTT_MESSAGE_START_WRITE_FW_HEADER = "[FWSTRT]";
export const MQTT_MESSAGE_STOP_WRITE_FW_HEADER = "[FWSTP]";
export const MQTT_MESSAGE_SUPPORT_CONNECTED_HEADER = "[SCON]";
export const MQTT_MESSAGE_CLIENT_STATE_HEADER = "[CST]";
export const MQTT_MESSAGE_CLIENT_TERMINATED_SESSION_HEADER = "[CEND]"
export const MQTT_MESSAGE_SUPPORT_DISCONNECTED_HEADER = "[SEND]"



export const MQTT_UNDEFINED_VALUE = "UND";
export const MQTT_NULL_VALUE = "NULL";
export const MQTT_MAX_BINARY_PAYLOAD = 128 * 1024;