import {Rect} from "./Rect";
import {IMAGE_QUALITY, IMAGE_TYPE, ImageData} from "./ImageData";
import {backgroundPlugin, MM_TO_PX_FACTOR} from "./PdfChartPlugins";
import {Chart} from "chart.js";

interface BarChartData {
    x: number;
    y: number;
}

function getRounder(min: number, max: number) {
    let delta = Math.abs(min - max) < 0.00001 ? min : max - min;
    let rounder = 0.00001;
    while (delta > rounder * 10) {
        rounder *= 10;
    }
    return rounder;
}

function roundMin(min: number, rounder: number) {
    return Math.floor(min / rounder) * rounder;
}

function roundMax(max: number, rounder: number) {
    return (Math.floor(max / rounder) + 1) * rounder;
}

function getDividerY(count : number){
    let divider = 1;
    while (count / divider > 10){
        divider++;
    }
    return divider;
}

function calculateAxisY(count : number) : [max : number, ticks : number] {
    if (count <= 10){
        return [count, count + 1];
    }
    const divider = getDividerY(count);
    const max = count % divider === 0 ? count : (Math.floor(count / divider) + 1) * divider;
    return [max, max / divider + 1];
}

export function buildBarChart(bounds: Rect, label: string, chartData: Array<number>): ImageData {
    const canvas = document.getElementById("pdf-canvas") as HTMLCanvasElement;
    if (canvas) {
        canvas.setAttribute("width", `${bounds.width * MM_TO_PX_FACTOR / 1.25}px`);
        canvas.setAttribute("height", `${bounds.height * MM_TO_PX_FACTOR / 1.25}px`);
        const context = canvas.getContext('2d');
        if (context) {
            const minMeasure = Math.min(...chartData);
            const maxMeasure = Math.max(...chartData);
            let rounder = getRounder(minMeasure, maxMeasure);
            let minX = roundMin(minMeasure, rounder);
            let maxX = roundMax(maxMeasure, rounder);
            let divisions = Math.floor((maxX - minX) / rounder) + 1;
            if (Math.abs(minMeasure - maxMeasure) > 0.00001) {
                while (divisions < 5) {
                    rounder /= 2;
                    minX = roundMin(minMeasure, rounder);
                    maxX = roundMax(maxMeasure, rounder);
                    divisions = Math.floor((maxX - minX) / rounder) + 1;
                }
            }
            let binsCount = 1 + Math.floor(Math.log10(chartData.length) / Math.log10(2));
            let binWidth = (maxX - minX) / binsCount;

            let bins = new Array<number>();
            for (let i = 0; i < binsCount; i++) {
                bins.push(0);
            }
            for (let i = 0; i < chartData.length; i++) {
                let value = chartData[i];
                let binIndex = Math.max(0, Math.min(Math.floor((value - minX) / binWidth), binsCount - 1));
                bins[binIndex] = bins[binIndex] + 1;
            }
            const barChartData = new Array<BarChartData>();
            for (let i = 0; i < bins.length; i++) {
                barChartData.push({
                    x: minX + binWidth * (i + 0.5),
                    y: bins[i]
                })
            }
            const [maxY, ticksY] = calculateAxisY(Math.max(...bins));
            const log = Math.log10(rounder) - 1;
            const fractionDigits = log >= 0 ? 0 : Math.floor(Math.abs(log));
            const data = {
                labels: barChartData.map(_ => ""),
                datasets: [{
                    label: label,
                    backgroundColor: '#9E1B1B',
                    borderColor: '#9E1B1B',
                    barPercentage: 0.98,
                    categoryPercentage: 0.98,
                    data: barChartData.map(cb => cb.y),
                    order: 0,
                }]
            };
            const chart = new Chart(context, {
                type: "bar",
                data: data,
                plugins: [backgroundPlugin],
                options: {
                    animation: false,
                    scales: {
                        x: {
                            type: "category",
                            position: "top",
                            display: false,
                            grid: {
                                display: false,
                                borderColor: "#666666"
                            }
                        },
                        x2: {
                            axis: "x",
                            type: "linear",
                            offset: false,
                            min: minX,
                            max: maxX,
                            ticks: {
                                count: divisions,
                                callback: v => Number(v).toFixed(fractionDigits)
                            },
                            position: "bottom",
                            grid: {
                                display: false,
                                borderColor: "#666666"
                            }

                        },
                        y: {
                            min: 0,
                            max: maxY,
                            ticks: {
                                count: ticksY
                            },
                            grid: {
                                display: false,
                                borderColor: "#666666"
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                font: {
                                    size: 9
                                },
                                boxWidth: 6,
                                boxHeight: 6
                            }
                        }
                    }
                }
            });
            const imageData = {
                width: bounds.width,
                height: bounds.height,
                data: chart.toBase64Image(IMAGE_TYPE, IMAGE_QUALITY)
            };
            chart.destroy();
            return imageData;
        }
    }
    return {
        width: 0,
        height: 0,
        data: ""
    };
}