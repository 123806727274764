import "./MultipleRecordsInfo.css";
import {useTranslation} from "react-i18next";
import MultipleRecordsIconSvg from "./MulitpleRecordsIcon";

interface Props{
    text: string;
}

export function MultipleRecordsInfo(props: Props){
    const {t} = useTranslation();
    return (
        <div className="container-grow d-flex flex-column justify-content-center align-items-center">
            <div className="multiple-records-icon">
                <MultipleRecordsIconSvg/>
            </div>
            <span className="mt-4 multiple-records-count">{props.text}</span>
        </div>
    );
}