import {RecordWrapper} from "../../models/RecordWrapper";
import {MapInfoWindowListItem} from "./MapInfoWindowListItem";
import './MapInfoWindowList.css'

interface Props{
    items : RecordWrapper[];
    itemClickListener: (id: string) => void;
}

export function MapInfoWindowList(props : Props){
    return (
        <div className="map-info-window-list no-select">
            {props.items.map(i => <MapInfoWindowListItem key={i.id} item={i} clickListener={props.itemClickListener} />)}
        </div>
    );
}