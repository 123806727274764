import {AwpIpsmSeries, parseIpsmSeries} from "./AwpIpsmSeries";
import {AwpDirectoryHandle} from "../helpers/AwpDirectoryHandle";
import {AwpSerialDevice} from "../serial/AwpSerialDevice";
import {parseString, parseStrings} from "../helpers/BinaryParseHelper";
import {TextEncoder} from "text-encoding";
import {getIpsmScale} from "../helpers/AwpIpsmFormatHelper";
import moment from "moment";
import {AWP_RECORD_TIMESTAMP_FORMAT} from "../helpers/AwpHelper";

export interface AwpIpsmRecord {
    name: string;
    date?: Date;
    series: Array<AwpIpsmSeries>;
    deviceSerial?: string;
    probeName?: string;
    probeType?: number;
    probeSerial?: string;
    photo?: string;
    operator?: string;
    object?: string;
    conclusion?: string;
}

export function readAwpIpsmRecord(fsHandle: AwpDirectoryHandle, recordName: string): Promise<AwpIpsmRecord | null> {
    return new Promise<AwpIpsmRecord | null>(async resolve => {
        let name = "";
        let date;
        let series = new Array<AwpIpsmSeries>();
        let operator;
        let object;
        let conclusion;
        const recordHandle = await fsHandle.getDirectoryHandle(recordName).catch(() => null);
        if (recordHandle) {
            const nameHandle = await recordHandle.getFileHandle(AwpSerialDevice.nameFile).catch(() => null);
            if (nameHandle) {
                const buffer = await nameHandle.getData().catch(() => null);
                if (buffer) {
                    name = parseString(buffer);
                }
            } else {
                resolve(null);
                return;
            }
            const dateHandle = await recordHandle.getFileHandle(AwpSerialDevice.dateFile).catch(() => null);
            if (dateHandle) {
                const buffer = await dateHandle.getData().catch(() => null);
                if (buffer) {
                    date = moment(parseString(buffer), AWP_RECORD_TIMESTAMP_FORMAT).toDate();
                }
            }
            const infoFile = await recordHandle.getFileHandle(AwpSerialDevice.infoFile).catch(() => null);
            if (infoFile) {
                const info = await infoFile.getData().catch(() => null);
                if (info) {
                    const parts = parseStrings(info);
                    if (parts.length >= 1) {
                        operator = parts[0];
                    }
                    if (parts.length >= 2) {
                        object = parts[1];
                    }
                }
            }
            const conclusionFile = await recordHandle.getFileHandle(AwpSerialDevice.conclusionFile).catch(() => null);
            if (conclusionFile) {
                const info = await conclusionFile.getData().catch(() => null);
                if (info) {
                    conclusion = parseString(info);
                }
            }
            const seriesFile = await recordHandle.getFileHandle(AwpSerialDevice.getSeriesFileName(1)).catch(() => null);
            if (!seriesFile) {
                resolve(null);
                return;
            }
            const buffer = await seriesFile.getData().catch(() => null);
            if (buffer) {
                try {
                    const ipsmSeries = parseIpsmSeries(buffer);
                    if (ipsmSeries) {
                        series.push(...ipsmSeries);
                    }
                } catch (e) {
                    resolve(null);
                    return;
                }
            } else {
                resolve(null);
                return;
            }
            let deviceSerial;
            if (series.length > 0) {
                deviceSerial = series[0].deviceSerial;
                if (deviceSerial.length === 10 && deviceSerial.match(/\d*/gm)) {
                    deviceSerial = `${deviceSerial.substring(0, 3)}.${deviceSerial.substring(3, 6)}.${deviceSerial.substring(6)}`;
                }
            }
            resolve({
                name: name,
                date: date,
                deviceSerial: deviceSerial,
                probeName: undefined,
                probeType: undefined,
                probeSerial: undefined,
                series: series,
                photo: undefined,
                operator: operator,
                object: object,
                conclusion: conclusion
            });
        }
    }).catch(() => {
        return null;
    });
}

function makeRandomName(): string {
    let name = [];
    for (let i = 0; i < 8; i++) {
        name.push(65 + Math.random() * (90 - 65))
    }
    let extension = [];
    for (let i = 0; i < 3; i++) {
        extension.push(65 + Math.random() * (90 - 65))
    }
    return `${String.fromCharCode(...name)}.${String.fromCharCode(...extension)}`;
}

export function writeAwpIpsmRecord(fsHandle: AwpDirectoryHandle, record: AwpIpsmRecord): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
        const recordName = makeRandomName();
        const handle = fsHandle.getFileSystemHandle();
        if (handle) {
            const recordHandle = await handle.getDirectoryHandle(recordName, {create: true}).catch(() => null);
            if (recordHandle) {
                const nameHandle = await recordHandle.getFileHandle(AwpSerialDevice.nameFile, {create: true}).catch(() => null);
                if (nameHandle) {
                    const stream = await nameHandle.createWritable();
                    const writer = await stream.getWriter();
                    const encodedData = new TextEncoder('windows-1251', {NONSTANDARD_allowLegacyEncoding: true}).encode(record.name + "\0");
                    await writer.write(encodedData);
                    writer.releaseLock();
                    await stream.close();
                } else {
                    resolve(false);
                    return;
                }
                const dateFile = await recordHandle.getFileHandle(AwpSerialDevice.dateFile, {create: true}).catch(() => null);
                if (dateFile) {
                    const stream = await dateFile.createWritable();
                    const writer = await stream.getWriter();
                    const encodedData = new TextEncoder('windows-1251', {NONSTANDARD_allowLegacyEncoding: true}).encode(moment(record.date ?? new Date()).format(AWP_RECORD_TIMESTAMP_FORMAT));
                    await writer.write(encodedData);
                    writer.releaseLock();
                    await stream.close();
                }
                const infoFile = await recordHandle.getFileHandle(AwpSerialDevice.infoFile, {create: true}).catch(() => null);
                if (infoFile) {
                    const stream = await infoFile.createWritable();
                    const writer = await stream.getWriter();
                    const encodedData = new TextEncoder('windows-1251', {NONSTANDARD_allowLegacyEncoding: true}).encode((record.operator ?? "") + "\0" + (record.object ?? ""));
                    await writer.write(encodedData);
                    writer.releaseLock();
                    await stream.close();
                } else {
                    resolve(false)
                    return;
                }
                const conclusionFile = await recordHandle.getFileHandle(AwpSerialDevice.conclusionFile, {create: true}).catch(() => null);
                if (conclusionFile) {
                    const stream = await conclusionFile.createWritable();
                    const writer = await stream.getWriter();
                    const encodedData = new TextEncoder('windows-1251', {NONSTANDARD_allowLegacyEncoding: true}).encode((record.conclusion ?? "") + "\0");
                    await writer.write(encodedData);
                    writer.releaseLock();
                    await stream.close();
                } else {
                    resolve(false)
                    return;
                }
                const seriesFile = await recordHandle.getFileHandle(AwpSerialDevice.getSeriesFileName(1), {create: true}).catch(() => null);
                if (seriesFile) {
                    const stream = await seriesFile.createWritable();
                    const writer = await stream.getWriter();
                    const strings = [];
                    strings.push("DEVICE IPSM");
                    strings.push(`#${record.deviceSerial?.replaceAll(".", "")}`);
                    let idx = 0;
                    for (const series of record.series) {
                        for (const measurement of series.values) {
                            strings.push(`GROUP #${idx} ${measurement.value}${getIpsmScale(measurement.scale)}`)
                        }
                        idx++;
                    }
                    strings.push("END");
                    const encodedData = new TextEncoder('windows-1251', {NONSTANDARD_allowLegacyEncoding: true})
                        .encode(strings.join("\r\n"));
                    await writer.write(encodedData);
                    writer.releaseLock();
                    await stream.close();
                } else {
                    resolve(false)
                    return;
                }
                resolve(true);
                return;
            }
        }
        resolve(false);
    }).catch(() => {
        return false;
    });
}