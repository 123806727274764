const key = "25EXx8A3b8M";

export function awpDataStreamingDecode(raw: string){
    const encoder = new TextEncoder();
    const decoder = new TextDecoder("windows-1251");
    const keyBytes = Array.from(encoder.encode(key));
    const dataBytes = Array.from(encoder.encode(raw));
    const decodedData = new Array<number>();
    let i = 0;
    for (const byte of dataBytes) {
        decodedData.push(byte ^ keyBytes[i % keyBytes.length])
        i++;
    }
    return decoder.decode(Uint8Array.from(decodedData));
}