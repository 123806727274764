import {AwpIpsmSeries} from "../../models/AwpIpsmSeries";
import {useTranslation} from "react-i18next";
import {Button, ButtonGroup, Col, FormCheck, Row} from "react-bootstrap";
import {ViewContainer} from "../ViewContainer/ViewContainer";
import {formatAwpDeviceDisplayName} from "../../helpers/AwpDeviceDisplayNameHelper";
import {ViewContainerWithAction} from "../ViewContainer/ViewContainerWithAction";
import {Fragment, useState} from "react";
import {ModalRecordName} from "../ModalRecordName/ModalRecordName";
import {ModalRecordInfo} from "../ModalRecordInfo/ModalRecordInfo";
import {ModalRecordConclusion} from "../ModalRecordConclusion/ModalRecordConclusion";
import {AwpIpsmSeriesInfo} from "../AwpSeriesInfo/AwpIspmSeriesInfo";
import IconCsvSvg from "../Icons/IconCsvSvg";
import IconPdfSvg from "../Icons/IconPdfSvg";
import IconCloseSvg from "../Icons/IconCloseSvg";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import {AwpIpsmRecord} from "../../models/AwpIpsmRecord";
import IconSaveSvg from "../Icons/IconSaveSvg";
import {ModalSingleButton} from "../ModalSingleButton/ModalSingleButton";
import {AWP_DEVICE_IPSM} from "../../helpers/AwpHelper";


interface Props {
    series: AwpIpsmSeries[];
    onClose: () => void;
    onMakeCsv: (record: AwpIpsmRecord) => void;
    onMakePdf: (record: AwpIpsmRecord) => void;
    onSave: (record: AwpIpsmRecord) => void;
}

interface RecordNameEditModalData {
    title: string;
    recordName: string;
}

interface RecordInfoEditModalData {
    title: string;
    operator: string;
    object: string;
}

interface RecordConclusionEditModalData {
    title: string;
    conclusion: string;
}

export function AwpIpsmSeriesPreview(props: Props) {
    const {t} = useTranslation();
    let deviceSerial = props.series[0].deviceSerial;
    if (deviceSerial.length === 10 && deviceSerial.match(/\d*/gm)) {
        deviceSerial = `${deviceSerial.substring(0, 3)}.${deviceSerial.substring(3, 6)}.${deviceSerial.substring(6)}`;
    }
    const [recordName, setRecordName] = useState(t('new_record_name') as string | undefined);
    const [operator, setOperator] = useState(undefined as string | undefined);
    const [object, setObject] = useState(undefined as string | undefined);
    const [conclusion, setConclusion] = useState(undefined as string | undefined);
    const [showNoSelectedGroupsModal, setShowNoSelectedGroupsModal] = useState(false);
    const [showRecordNameModal, setShowRecordNameModal] = useState(null as RecordNameEditModalData | null);
    const [showRecordInfoModal, setShowRecordInfoModal] = useState(null as RecordInfoEditModalData | null);
    const [showRecordConclusionModal, setShowRecordConclusionModal] = useState(null as RecordConclusionEditModalData | null);
    const [saveGroup, setSaveGroup] = useState(props.series.map(s => true));
    const editRecordName = () => {
        setShowRecordNameModal({
            title: t('record'),
            recordName: recordName ?? ""
        });
    }
    const editRecordInfo = () => {
        setShowRecordInfoModal({
            title: recordName ?? "",
            operator: operator ?? "",
            object: object ?? ""
        });
    };
    const editRecordConclusion = () => {
        setShowRecordConclusionModal({
            title: recordName ?? "",
            conclusion: conclusion ?? ""
        });
    };
    const saveRecordName = (recordName: string) => {
        setRecordName(recordName);
    };
    const saveRecordInfo = (operator: string, object: string) => {
        setOperator(operator);
        setObject(object);
    };
    const saveRecordConclusion = (conclusion: string) => {
        setConclusion(conclusion);
    };
    const makeRecord: () => AwpIpsmRecord | null = () => {
        if (saveGroup.every(g => !g)) {
            setShowNoSelectedGroupsModal(true);
            return null;
        }
        return {
            name: recordName ?? "",
            deviceSerial: deviceSerial,
            date: new Date(),
            operator: operator,
            object: object,
            series: props.series.filter((g, i) => saveGroup[i]),
            conclusion: conclusion,
            probeName: undefined,
            probeType: undefined,
            probeSerial: undefined,
            photo: undefined
        };
    }
    const makeCsv = () => {
        const record = makeRecord();
        if (record) {
            props.onMakeCsv(record);
        }
    };
    const makePdf = () => {
        const record = makeRecord();
        if (record) {
            props.onMakePdf(record);
        }
    };
    const save = () => {
        const record = makeRecord();
        if (record) {
            props.onSave(record);
        }
    };
    const close = () => {
        props.onClose();
    }
    return (
        <div className="container-grow">
            <div id="pdf-canvas-container" style={{position: "absolute", top: "-10000px", left: "-10000px"}}>
                <canvas id="pdf-canvas"/>
            </div>
            <div className="d-flex flex-column align-items-center">
                <div className="instruction-text">{t('reading_complete')}</div>
                <div className="mt-2 mb-4 instruction-text">{t('awp_ipsm_preview_instruction')}</div>
            </div>
            <HorizontalDivider/>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <ButtonGroup className="my-4">
                    <Button variant="outline-primary"
                            onClick={makeCsv}><IconCsvSvg/></Button>
                    <Button variant="outline-primary"
                            onClick={makePdf}><IconPdfSvg/></Button>
                    <Button variant="outline-primary"
                            onClick={save}><IconSaveSvg/></Button>
                </ButtonGroup>
                <div className="button-close" onClick={close}><IconCloseSvg/></div>
            </div>
            <HorizontalDivider/>
            <div className="container-grow">
                <Row xs={1} sm={1} md={2} xl={3}>
                    <Col className="d-flex flex-column flex-grow-1">
                        <ViewContainerWithAction title={t('record')} action={t('edit')}
                                                 onActionClick={editRecordName}>
                            <span className="view-item-title mr-2">{recordName ?? t('not_set')}</span>
                        </ViewContainerWithAction>
                    </Col>
                </Row>
                <Row xs={1} sm={1} md={2} xl={3}>
                    <Col className="d-flex flex-column flex-grow-1">
                        <ViewContainer title={t('device')} reserveSpaceForDataTime={true}>
                            <div className="d-flex flex-column justify-content-start">
                                <div className="d-flex flex-column justify-content-start">
                                                <span className="view-item-title mr-2">
                                                    {formatAwpDeviceDisplayName(t, AWP_DEVICE_IPSM, deviceSerial)}
                                                </span>
                                    <span className="view-item-description">{deviceSerial}</span>
                                </div>
                            </div>
                        </ViewContainer>
                    </Col>
                    <Col className="d-flex flex-column flex-grow-1">
                        <ViewContainerWithAction title={t('additional_info')} action={t('edit')}
                                                 onActionClick={editRecordInfo}>
                            <div className="d-flex flex-column justify-content-start">
                                <div className="d-flex flex-row ">
                                    <span className="view-item-title me-2">{t('operator')}:</span>
                                    <span
                                        className="view-item-description single-line ellipsize">{operator ?? t('not_set')}</span>
                                </div>
                                <div className="d-flex flex-row">
                                    <span className="view-item-title me-2">{t('object')}:</span>
                                    <span
                                        className="view-item-description single-line ellipsize">{object ?? t('not_set')}</span>
                                </div>
                            </div>
                        </ViewContainerWithAction>
                    </Col>
                </Row>
                {
                    props.series.map((s, i) => (
                        <Fragment key={`sf-${i}`}>
                            <div
                                className="d-flex flex-row align-self-stretch align-items-center justify-content-center my-2">
                                <FormCheck key={`sft-${i}`}
                                           className={"mx-2 mb-1"}
                                           type={"checkbox"}
                                           defaultChecked={saveGroup[i]}
                                           onChange={() => setSaveGroup(saveGroup.map((g, j) => i === j ? !g : g))}>
                                </FormCheck>
                                <h5>{`${t('group_index', {index: i + 1})}`}</h5>
                            </div>
                            <Row xs={1} sm={1} md={2} xl={2} className="justify-content-center" key={`sfr-${i}`}>
                                <Col>
                                    <ViewContainer>
                                        <AwpIpsmSeriesInfo data={s}/>
                                    </ViewContainer>
                                </Col>
                            </Row>
                        </Fragment>
                    ))
                }
                <Row xs={1} sm={1} md={2} xl={3}>
                    <Col className="d-flex flex-column flex-grow-1">
                        <ViewContainerWithAction title={t('conclusion')} action={t('edit')}
                                                 onActionClick={editRecordConclusion}>
                            <div className="d-flex flex-column justify-content-start">
                                            <span
                                                className="view-item-description line-break">{((conclusion ?? "") === "") ? t('conclusion_not_set') : conclusion}</span>
                            </div>
                        </ViewContainerWithAction>
                    </Col>
                </Row>
                <ModalSingleButton show={showNoSelectedGroupsModal} title={t("read_data_from_device")} body={t("ipsm_no_selected_groups_message")}
                                   buttonText={t("ok")}
                                   closeHandler={() => setShowNoSelectedGroupsModal(false)}/>
                <ModalRecordName show={showRecordNameModal !== null} title={showRecordNameModal?.title ?? ""}
                                 recordName={showRecordNameModal?.recordName ?? ""}
                                 positiveButtonText={t('save')} negativeButtonText={t('cancel')}
                                 closeHandler={() => setShowRecordNameModal(null)}
                                 positiveButtonAction={saveRecordName}/>
                <ModalRecordInfo show={showRecordInfoModal !== null} title={showRecordInfoModal?.title ?? ""}
                                 operator={showRecordInfoModal?.operator ?? ""}
                                 object={showRecordInfoModal?.object ?? ""}
                                 positiveButtonText={t('save')} negativeButtonText={t('cancel')}
                                 closeHandler={() => setShowRecordInfoModal(null)}
                                 positiveButtonAction={saveRecordInfo}/>
                <ModalRecordConclusion show={showRecordConclusionModal !== null}
                                       title={showRecordConclusionModal?.title ?? ""}
                                       conclusion={showRecordConclusionModal?.conclusion ?? ""}
                                       positiveButtonText={t('save')} negativeButtonText={t('cancel')}
                                       closeHandler={() => setShowRecordConclusionModal(null)}
                                       positiveButtonAction={saveRecordConclusion}/>
            </div>
        </div>
    );
}