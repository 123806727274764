import {useEffect, useMemo, useRef, useState} from "react";
import {Bar, BarChart, CartesianGrid, XAxis, YAxis} from "recharts";

const DATA_COLOR = "#9e1b1b";

interface Props {
    data: number[];
    classNames?: string;
}

interface BarChartData {
    x: number;
    y: number;
}

interface ChartProperties {
    bins: BarChartData[];
    minX: number;
    maxX: number;
    ticksX: number;
    fractionDigits: number;
    maxY: number;
    ticksY: number;
}

function getRounder(min: number, max: number) {
    let delta = Math.abs(min - max) < 0.00001 ? min : max - min;
    let rounder = 0.00001;
    while (delta > rounder * 10) {
        rounder *= 10;
    }
    return rounder;
}

function roundMin(min: number, rounder: number) {
    return Math.floor(min / rounder) * rounder;
}

function roundMax(max: number, rounder: number) {
    return (Math.floor(max / rounder) + 1) * rounder;
}

function getDividerY(count : number){
    let divider = 1;
    while (count / divider > 10){
        divider++;
    }
    return divider;
}

function calculateAxisY(count : number) : [max : number, ticks : number] {
    if (count <= 10){
        return [count, count + 1];
    }
    const divider = getDividerY(count);
    const max = count % divider === 0 ? count : (Math.floor(count / divider) + 1) * divider;
    return [max, max / divider + 1];
}

export function MeasurementsBarChart(props: Props) {
    const container = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const checkSize = () => {
        const w = container.current?.clientWidth ?? 0;
        const h = container.current?.clientHeight ?? 0;
        if (w !== width) {
            setWidth(w);
        }
        if (h !== height) {
            setHeight(h);
        }
    };
    useEffect(() => {
        checkSize();
        window.addEventListener("resize", checkSize);
        return () => {
            window.removeEventListener("resize", checkSize);
        }
    });
    const chartProps = useMemo(() => {
        const minMeasure = Math.min(...props.data);
        const maxMeasure = Math.max(...props.data);
        let rounder = getRounder(minMeasure, maxMeasure);
        let min = roundMin(minMeasure, rounder);
        let max = roundMax(maxMeasure, rounder);
        let divisions = Math.floor((max - min) / rounder) + 1;
        if (Math.abs(minMeasure - maxMeasure) > 0.00001) {
            while (divisions < 5) {
                rounder /= 2;
                min = roundMin(minMeasure, rounder);
                max = roundMax(maxMeasure, rounder);
                divisions = Math.floor((max - min) / rounder) + 1;
            }
        }
        let binsCount = 1 + Math.floor(Math.log10(props.data.length) / Math.log10(2));
        let binWidth = (max - min) / binsCount;

        let bins = new Array<number>();
        for (let i = 0; i < binsCount; i++) {
            bins.push(0);
        }
        for (let i = 0; i < props.data.length; i++) {
            let value = props.data[i];
            let binIndex = Math.max(0, Math.min(Math.floor((value - min) / binWidth), binsCount - 1));
            bins[binIndex] = bins[binIndex] + 1;
        }
        const barChartData = new Array<BarChartData>();
        for (let i = 0; i < bins.length; i++) {
            barChartData.push({
                x: min + binWidth * (i + 0.5),
                y: bins[i]
            })
        }
        const [maxY, ticksY] = calculateAxisY(Math.max(...bins));
        const log = Math.log10(rounder) - 1;
        const fractionDigits = log >= 0 ? 0 : Math.floor(Math.abs(log));
        return {
            bins: barChartData,
            minX: min,
            maxX: max,
            ticksX: divisions,
            fractionDigits: fractionDigits,
            maxY: maxY,
            ticksY: ticksY
        } as ChartProperties;
    }, [props]);
    const yAxisChars = chartProps.maxY.toFixed(chartProps.fractionDigits).length;
    return (
        <div className={`d-flex flex-column ${props.classNames}`} ref={container}>
            <BarChart width={width} height={height} data={chartProps.bins} barGap={5} barCategoryGap={5}>
                <XAxis dataKey={"x"} domain={[chartProps.minX, chartProps.maxX]} tickCount={chartProps.ticksX}
                       type={"number"} tickFormatter={(v: number) => v.toFixed(chartProps.fractionDigits)}
                       padding={{left: 0, right: 8}}/>
                <YAxis domain={[0, chartProps.maxY]}
                       tickCount={chartProps.ticksY} width={yAxisChars * 12}/>
                <CartesianGrid vertical={false}/>
                <Bar dataKey={"y"} fill={DATA_COLOR} barSize={chartProps.bins.length > 1 ? undefined : 0.5 * width}/>
            </BarChart>
        </div>
    );
}