import {SerialDevice} from "./SerialDevice";
import {AwpIpsmSeries, parseIpsmSeriesFromString} from "../models/AwpIpsmSeries";

export class AwpIpsmSerialDevice {

    static readonly defaultBaudRate = 19200;
    static readonly filters: SerialPortFilter[] = [
        {
            usbVendorId: 0x10C4,
            usbProductId: 0xEA60
        }
    ];

    private readonly port: SerialDevice;
    private isAborted: boolean;

    constructor(port: SerialDevice) {
        this.port = port;
        this.isAborted = false;
    }


    readRecord(readingStateCallback: (isReading: boolean) => void): Promise<AwpIpsmSeries[] | undefined> {
        this.isAborted = false;
        return this.port.open().then((isOpen) => {
            if (!isOpen) {
                return undefined;
            }
            readingStateCallback(true);
            return new Promise<AwpIpsmSeries[] | undefined>(async (resolve, reject) => {
                let isAlive = true;
                const decoder = new TextDecoder("windows-1251");
                let localBuffer = "";
                let timerId: NodeJS.Timeout | undefined;
                const success = (data: AwpIpsmSeries[]) => {
                    resetTimer();
                    if (isAlive) {
                        resolve(data);
                    }
                    isAlive = false;
                };
                const abort = () => {
                    resetTimer();
                    if (isAlive) {
                        resolve(undefined);
                    }
                    isAlive = false;
                }
                const fail = (reason: any) => {
                    resetTimer();
                    if (isAlive) {
                        reject(reason);
                    }
                    isAlive = false;
                }
                const setTimer = (timeout: number) => {
                    resetTimer();
                    timerId = setTimeout(() => {
                        if (this.isAborted) {
                            abort();
                        } else {
                            setTimer(100);
                        }
                    }, timeout);
                }
                const resetTimer = () => {
                    if (timerId) {
                        clearTimeout(timerId);
                        timerId = undefined;
                    }
                }
                setTimer(100);
                this.port.listen(async data => {
                    if (data) {
                        try {
                            if (this.isAborted) {
                                return;
                            }
                            localBuffer += decoder.decode(data);
                            const dataRegEx = /.*(DEVICE IPSM.*END)/mgs;
                            const dataMatch = dataRegEx.exec(localBuffer);
                            if (dataMatch) {
                                let ipsmSeries = parseIpsmSeriesFromString(dataMatch[1]);
                                if (ipsmSeries) {
                                    success(ipsmSeries);
                                } else {
                                    fail("Wrong format");
                                }
                            } else {
                                const dataRegExRu = /.*(Џђ€ЃЋђ €Џ‘Њ.*ЉЋЌ…–)/mgs;
                                const dataMatchRu = dataRegExRu.exec(localBuffer);
                                if (dataMatchRu) {
                                    let ipsmSeries = parseIpsmSeriesFromString(dataMatchRu[1]);
                                    if (ipsmSeries) {
                                        success(ipsmSeries);
                                    } else {
                                        fail("Wrong format");
                                    }
                                }
                            }
                        } catch (e) {
                            fail(e);
                        }
                    } else {
                        fail("Data read error");
                    }
                });
            }).then((data) => {
                readingStateCallback(false);
                this.port.close();
                return data;
            }).catch(reason => {
                readingStateCallback(false);
                this.port.close();
                throw new Error(reason);
            });
        });
    }

    abort() {
        this.isAborted = true;
    }
}
