import {useTranslation} from "react-i18next";
import {Button, Modal} from "react-bootstrap";

interface Props {
    show: boolean;
    closeHandler: (confirmed: boolean) => void;
}

export function StopAssistDialog(props: Props) {
    const {t} = useTranslation();
    return (
        <Modal show={props.show} onHide={() => props.closeHandler(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t("loader_remote_assistance")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="fw-bold text-center">{t("awp_fw_upload_warning_1")}</div>
                <div className="my-4">{t("loader_remote_assistance_cancel")}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="mx-2"
                        onClick={() => props.closeHandler(false)}>{t("cancel")}</Button>
                <Button variant="primary" className="ms-2"
                        onClick={() => props.closeHandler(true)}>{t("ok")}</Button>
            </Modal.Footer>
        </Modal>
    );
}