import {Button, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {AwpFwSerialDevice} from "../../serial/AwpFwSerialDevice";
import {useAwpFwSerialDevice} from "../../serial/SerialPortHook";
import React, {useState} from "react";
import {Loader} from "../Loader/Loader";
import './AwpFwGspUpdate.css';
import {getConnectionWindowImage} from "./AssetsHelper";
import {logger} from "../../helpers/LogHelper";
import {SerialPortOpenErrorDialog} from "../SerialPortOpenErrorDialog/SerialPortOpenErrorDialog";

interface Props {
    onConnectButtonClick: (serialDevice: AwpFwSerialDevice | null) => void;
}

export function AwpFwConnect(props: Props) {
    const {t} = useTranslation();
    const device = useAwpFwSerialDevice(AwpFwSerialDevice.speed921600);
    const [portBusy, setPortBusy] = useState(false);
    const [showSerialPortOpenErrorDialog, setShowSerialPortOpenErrorDialog] = useState(false);
    const onConnectClick = async () => {
        if (device) {
            const result = await device.open().catch((e) => {
                logger.log(`Serial port open error: ${e}`);
                return false;
            });
            if (result) {
                setPortBusy(true);
                props.onConnectButtonClick(device);
            } else {
                props.onConnectButtonClick(null);
                setShowSerialPortOpenErrorDialog(true);
            }
        }
    };
    return (
        <div className="container-grow">
            {portBusy && <Loader message={t('awp_fw_connecting')}/>}
            {!portBusy &&
                <div className="container-grow">
                    <Row className="flex-grow-1">
                        <Col lg={6}
                             className="d-flex flex-column justify-content-center my-2 fw-simple-instruction-text">
                            <span>{t("awp_fw_connection_instruction_1")}</span>
                            <div className="my-2"/>
                            <span>{t("awp_fw_connection_instruction_2")}</span>
                            <span>{t("awp_fw_connection_instruction_3")}</span>
                            <span dangerouslySetInnerHTML={{__html: t("awp_fw_connection_instruction_4")}}/>
                        </Col>
                        <Col lg={6} className="d-flex flex-column justify-content-center align-items-center my-2">
                            <img style={{maxWidth: "375px", maxHeight: "375px"}} src={getConnectionWindowImage(t)}/>
                        </Col>
                    </Row>
                </div>
            }
            <div className="d-flex flex-row align-items-center justify-content-around my-2 awp-fw-buttons-150">
                {!portBusy && <Button onClick={onConnectClick}>{t("awp_select_com_port")}</Button>}
            </div>
            <SerialPortOpenErrorDialog show={showSerialPortOpenErrorDialog}
                                       closeHandler={() => setShowSerialPortOpenErrorDialog(false)}/>
        </div>
    );
}
