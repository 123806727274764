import {TudMeasurement} from "../../models/TudMeasurement";
import {MeasurementInfo} from "./MeasurementInfo";
import {formatTudScaleName, formatTudScaleValue} from "../../helpers/FormatHelper";
import {useTranslation} from "react-i18next";

interface Props{
    measurement : TudMeasurement;
    detailsClickListener: (measurement : TudMeasurement) => void;
}

export function TudMeasurementWithoutPhoto(props : Props){
    const {t} = useTranslation();
    const avg = `${formatTudScaleValue(props.measurement.tudScale, props.measurement.middle)} ${formatTudScaleName(t, props.measurement.tudScale)}`;
    const min = formatTudScaleValue(props.measurement.tudScale, props.measurement.min);
    const max = formatTudScaleValue(props.measurement.tudScale, props.measurement.max);
    const count = props.measurement.n.toFixed(0);
    const detailsClickHandler = () => {
        props.detailsClickListener(props.measurement);
    };
    return (
        <MeasurementInfo avg={avg} min={min} max={max} count={count} detailsClickListener={detailsClickHandler}/>
    );
}