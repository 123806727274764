import {IClientPublishOptions, MqttClient} from "mqtt";
import {Buffer} from "buffer";
import {logError} from "../helpers/LogHelper";
import {
    MQTT_CLIENT_FW_DATA,
    MQTT_MAX_BINARY_PAYLOAD,
    MQTT_MESSAGE_AUTODETECT_STATE,
    MQTT_MESSAGE_AUTODETECT_STATE_HEADER,
    MQTT_MESSAGE_CHAT,
    MQTT_MESSAGE_CHAT_HEADER,
    MQTT_MESSAGE_CLIENT_FW_DATA_HEADER,
    MQTT_MESSAGE_CLIENT_STATE,
    MQTT_MESSAGE_CLIENT_STATE_HEADER,
    MQTT_MESSAGE_CLIENT_TERMINATED_SESSION,
    MQTT_MESSAGE_CLIENT_TERMINATED_SESSION_HEADER,
    MQTT_MESSAGE_COM_PORT_ENUMERATION,
    MQTT_MESSAGE_COM_PORT_ENUMERATION_HEADER,
    MQTT_MESSAGE_CONNECT_COM_PORT,
    MQTT_MESSAGE_CONNECT_COM_PORT_HEADER,
    MQTT_MESSAGE_DEVICE_INFO,
    MQTT_MESSAGE_DEVICE_INFO_HEADER,
    MQTT_MESSAGE_DISCONNECT_COM_PORT,
    MQTT_MESSAGE_DISCONNECT_COM_PORT_HEADER,
    MQTT_MESSAGE_ENUMERATE_COM_PORTS,
    MQTT_MESSAGE_ENUMERATE_COM_PORTS_HEADER,
    MQTT_MESSAGE_FW_DATA_BINARY,
    MQTT_MESSAGE_FW_DATA_BINARY_HEADER,
    MQTT_MESSAGE_FW_DATA_CANCEL,
    MQTT_MESSAGE_FW_DATA_CANCEL_HEADER,
    MQTT_MESSAGE_FW_DATA_DONE,
    MQTT_MESSAGE_FW_DATA_DONE_HEADER,
    MQTT_MESSAGE_FW_DATA_INFO,
    MQTT_MESSAGE_FW_DATA_INFO_HEADER,
    MQTT_MESSAGE_FW_DATA_RECEIVED,
    MQTT_MESSAGE_FW_DATA_RECEIVED_HEADER,
    MQTT_MESSAGE_FW_DATA_REPEAT,
    MQTT_MESSAGE_FW_DATA_REPEAT_HEADER,
    MQTT_MESSAGE_FW_WRITE_PROGRESS,
    MQTT_MESSAGE_FW_WRITE_PROGRESS_HEADER,
    MQTT_MESSAGE_LOW_BAUD_RATE_STATE,
    MQTT_MESSAGE_LOW_BAUD_RATE_STATE_HEADER,
    MQTT_MESSAGE_PING,
    MQTT_MESSAGE_PING_HEADER,
    MQTT_MESSAGE_PORT_STATE,
    MQTT_MESSAGE_PORT_STATE_HEADER,
    MQTT_MESSAGE_START_WRITE_FW,
    MQTT_MESSAGE_START_WRITE_FW_HEADER,
    MQTT_MESSAGE_STOP_WRITE_FW,
    MQTT_MESSAGE_STOP_WRITE_FW_HEADER,
    MQTT_MESSAGE_SUPPORT_CONNECTED,
    MQTT_MESSAGE_SUPPORT_CONNECTED_HEADER,
    MQTT_MESSAGE_SUPPORT_DISCONNECTED,
    MQTT_MESSAGE_SUPPORT_DISCONNECTED_HEADER,
    MQTT_MESSAGE_TERMINAL,
    MQTT_MESSAGE_TERMINAL_HEADER,
    MQTT_NULL_VALUE,
    MQTT_UNDEFINED_VALUE
} from "./MqttMessage";
import {AwpDeviceInfo} from "../models/AwpDeviceInfo";
import {
    FwLoaderClientFirmwareData,
    FwLoaderRemoteFirmwareInfo,
    FwLoaderState
} from "../components/AwpFwLoader/AwpFwLoaderCommon";
import {delay} from "../helpers/Utils";
import {AwpFwInfo} from "../models/AwpFwInfo";

export interface MqttTextMessage {
    type: number;
    text: string;
    value: any;
}

export class MqttCancellationToken {

    private isCanceled: boolean;
    private cancellationAction?: () => void;

    constructor() {
        this.isCanceled = false;
    }

    public isCancelled(): boolean {
        return this.isCanceled;
    }

    public cancel() {
        this.isCanceled = true;
        if (this.cancellationAction) {
            this.cancellationAction();
        }
    }

    public setCancellationAction(action: () => void) {
        this.cancellationAction = action;
    }
}

export function parseMqttMessage(buffer: Buffer): MqttTextMessage | undefined {
    let type;
    let text = "";
    let value;
    const string = new TextDecoder().decode(buffer);
    console.log(string);
    if (string.startsWith(MQTT_MESSAGE_PING_HEADER)) {
        type = MQTT_MESSAGE_PING;
    }
    if (string.startsWith(MQTT_MESSAGE_CLIENT_TERMINATED_SESSION_HEADER)) {
        type = MQTT_MESSAGE_CLIENT_TERMINATED_SESSION;
    }
    if (string.startsWith(MQTT_MESSAGE_SUPPORT_CONNECTED_HEADER)) {
        type = MQTT_MESSAGE_SUPPORT_CONNECTED;
    }
    if (string.startsWith(MQTT_MESSAGE_SUPPORT_DISCONNECTED_HEADER)) {
        type = MQTT_MESSAGE_SUPPORT_DISCONNECTED;
    }
    if (string.startsWith(MQTT_MESSAGE_CLIENT_STATE_HEADER)) {
        let index = string.indexOf(":");
        if (index > 0) {
            type = MQTT_MESSAGE_CLIENT_STATE;
            text = string.slice(index + 1);
            value = JSON.parse(text) as FwLoaderState;
        }
    }
    if (string.startsWith(MQTT_MESSAGE_CHAT_HEADER)) {
        let index = string.indexOf(":");
        if (index > 0) {
            type = MQTT_MESSAGE_CHAT;
            text = string.slice(index + 1);
        }
    }
    if (string.startsWith(MQTT_MESSAGE_TERMINAL_HEADER)) {
        let index = string.indexOf(":");
        if (index > 0) {
            type = MQTT_MESSAGE_TERMINAL;
            text = string.slice(index + 1);
        }
    }
    if (string.startsWith(MQTT_MESSAGE_PORT_STATE_HEADER)) {
        let index = string.indexOf(":");
        if (index > 0) {
            type = MQTT_MESSAGE_PORT_STATE;
            text = string.slice(index + 1);
            value = Number.parseInt(text);
        }
    }
    if (string.startsWith(MQTT_MESSAGE_AUTODETECT_STATE_HEADER)) {
        let index = string.indexOf(":");
        if (index > 0) {
            type = MQTT_MESSAGE_AUTODETECT_STATE;
            text = string.slice(index + 1);
            value = Number.parseInt(text) !== 0;
        }
    }
    if (string.startsWith(MQTT_MESSAGE_LOW_BAUD_RATE_STATE_HEADER)) {
        let index = string.indexOf(":");
        if (index > 0) {
            type = MQTT_MESSAGE_LOW_BAUD_RATE_STATE;
            text = string.slice(index + 1);
            value = Number.parseInt(text) !== 0;
        }
    }
    if (string.startsWith(MQTT_MESSAGE_DEVICE_INFO_HEADER)) {
        let index = string.indexOf(":");
        if (index > 0) {
            type = MQTT_MESSAGE_DEVICE_INFO;
            text = string.slice(index + 1);
            if (text === MQTT_UNDEFINED_VALUE) {
                value = null;
            } else if (text === MQTT_NULL_VALUE) {
                value = undefined;
            } else {
                value = JSON.parse(text) as AwpDeviceInfo;
            }
        }
    }
    if (string.startsWith(MQTT_MESSAGE_CLIENT_FW_DATA_HEADER)) {
        let index = string.indexOf(":");
        if (index > 0) {
            type = MQTT_CLIENT_FW_DATA;
            text = string.slice(index + 1);
            value = JSON.parse(text) as FwLoaderClientFirmwareData;
        }
    }
    if (string.startsWith(MQTT_MESSAGE_FW_WRITE_PROGRESS_HEADER)) {
        let index = string.indexOf(":");
        if (index > 0) {
            type = MQTT_MESSAGE_FW_WRITE_PROGRESS;
            text = string.slice(index + 1);
            if (text !== MQTT_UNDEFINED_VALUE) {
                value = Number.parseFloat(text);
            }
        }
    }
    if (string.startsWith(MQTT_MESSAGE_FW_DATA_INFO_HEADER)) {
        let index = string.indexOf(":");
        if (index > 0) {
            type = MQTT_MESSAGE_FW_DATA_INFO;
            text = string.slice(index + 1);
            value = JSON.parse(text) as FwLoaderRemoteFirmwareInfo;
        }
    }
    if (string.startsWith(MQTT_MESSAGE_FW_DATA_BINARY_HEADER)) {
        let firstIndex = string.indexOf(":");
        let secondIndex = string.indexOf(":", firstIndex + 1);
        if (firstIndex > 0) {
            type = MQTT_MESSAGE_FW_DATA_BINARY;
            text = string.slice(firstIndex + 1, secondIndex);
            value = buffer.subarray(secondIndex + 1);
        }
    }
    if (string.startsWith(MQTT_MESSAGE_FW_DATA_DONE_HEADER)) {
        type = MQTT_MESSAGE_FW_DATA_DONE;
    }
    if (string.startsWith(MQTT_MESSAGE_FW_DATA_CANCEL_HEADER)) {
        type = MQTT_MESSAGE_FW_DATA_CANCEL;
    }
    if (string.startsWith(MQTT_MESSAGE_FW_DATA_REPEAT_HEADER)) {
        let index = string.indexOf(":");
        if (index > 0) {
            type = MQTT_MESSAGE_FW_DATA_REPEAT;
            text = string.slice(index + 1);
            value = Number.parseInt(text) !== 0;
        }
    }
    if (string.startsWith(MQTT_MESSAGE_FW_DATA_RECEIVED_HEADER)) {
        type = MQTT_MESSAGE_FW_DATA_RECEIVED;
    }
    if (string.startsWith(MQTT_MESSAGE_ENUMERATE_COM_PORTS_HEADER)) {
        type = MQTT_MESSAGE_ENUMERATE_COM_PORTS;
    }
    if (string.startsWith(MQTT_MESSAGE_COM_PORT_ENUMERATION_HEADER)) {
        let index = string.indexOf(":");
        if (index > 0) {
            type = MQTT_MESSAGE_COM_PORT_ENUMERATION;
            text = string.slice(index + 1);
            value = JSON.parse(text) as Array<SerialPortInfo>;
        }
    }
    if (string.startsWith(MQTT_MESSAGE_CONNECT_COM_PORT_HEADER)) {
        let index = string.indexOf(":");
        if (index > 0) {
            type = MQTT_MESSAGE_CONNECT_COM_PORT;
            text = string.slice(index + 1);
            value = JSON.parse(text) as SerialPortInfo;
        }
    }
    if (string.startsWith(MQTT_MESSAGE_DISCONNECT_COM_PORT_HEADER)) {
        type = MQTT_MESSAGE_DISCONNECT_COM_PORT;
    }
    if (string.startsWith(MQTT_MESSAGE_START_WRITE_FW_HEADER)) {
        let index = string.indexOf(":");
        if (index > 0) {
            type = MQTT_MESSAGE_START_WRITE_FW;
            text = string.slice(index + 1);
            if (text === MQTT_NULL_VALUE) {
                value = undefined;
            } else {
                value = text;
            }
        }
    }
    if (string.startsWith(MQTT_MESSAGE_STOP_WRITE_FW_HEADER)) {
        type = MQTT_MESSAGE_STOP_WRITE_FW;
    }
    if (type) {
        return {
            type: type,
            text: text,
            value: value
        }
    } else {
        return undefined;
    }
}

export function sendMessage(client: MqttClient, requestCode: string, topic: string, data: string, callback?: () => void) {
    client.publishAsync(`${requestCode}/${topic}`, data, {qos: 2})
        .then(() => {
            if (callback) {
                callback();
            }
        })
        .catch(e => logError("Error sending MQTT data", e));
}

export function sendPingMessage(client: MqttClient, requestCode: string, topic: string) {
    sendMessage(client, requestCode, topic, MQTT_MESSAGE_PING_HEADER);
}

export function sendSupportConnectedMessage(client: MqttClient, requestCode: string, topic: string) {
    sendMessage(client, requestCode, topic, MQTT_MESSAGE_SUPPORT_CONNECTED_HEADER);
}

export function sendClientStateMessage(client: MqttClient, requestCode: string, topic: string, state: FwLoaderState) {
    const data = JSON.stringify(state);
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_CLIENT_STATE_HEADER}:${data}`);
}

export function sendSupportDisconnectedMessage(client: MqttClient, requestCode: string, topic: string, callback?: () => void) {
    sendMessage(client, requestCode, topic, MQTT_MESSAGE_SUPPORT_DISCONNECTED_HEADER, callback);
}

export function sendClientTerminatedSessionMessage(client: MqttClient, requestCode: string, topic: string, callback?: () => void) {
    sendMessage(client, requestCode, topic, MQTT_MESSAGE_CLIENT_TERMINATED_SESSION_HEADER, callback);
}

export function sendChatMessage(client: MqttClient, requestCode: string, topic: string, data: string) {
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_CHAT_HEADER}:${data}`);
}

export function sendTerminalMessage(client: MqttClient, requestCode: string, topic: string, data: string) {
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_TERMINAL_HEADER}:${data}`);
}

export function sendPortStateMessage(client: MqttClient, requestCode: string, topic: string, state: number) {
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_PORT_STATE_HEADER}:${state}`);
}

export function sendAutoDetectStateMessage(client: MqttClient, requestCode: string, topic: string, state: boolean) {
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_AUTODETECT_STATE_HEADER}:${state ? 1 : 0}`);
}

export function sendLowBaudRateStateMessage(client: MqttClient, requestCode: string, topic: string, state: boolean) {
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_LOW_BAUD_RATE_STATE_HEADER}:${state ? 1 : 0}`);
}

export function sendDeviceInfoMessage(client: MqttClient, requestCode: string, topic: string, deviceInfo: AwpDeviceInfo | null | undefined) {
    let data;
    if (deviceInfo) {
        data = JSON.stringify(deviceInfo);
    } else if (deviceInfo === undefined) {
        data = MQTT_UNDEFINED_VALUE;
    } else {
        data = MQTT_NULL_VALUE;
    }
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_DEVICE_INFO_HEADER}:${data}`);
}

export function sendClientFwData(client: MqttClient, requestCode: string, topic: string, data: FwLoaderClientFirmwareData) {
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_CLIENT_FW_DATA_HEADER}:${JSON.stringify(data)}`);
}

export function sendFwWriteProgressMessage(client: MqttClient, requestCode: string, topic: string, progress: number | undefined) {
    let value;
    if (progress === undefined) {
        value = MQTT_UNDEFINED_VALUE;
    } else {
        value = `${progress}`;
    }
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_FW_WRITE_PROGRESS_HEADER}:${value}`);
}

export function sendEnumeratePortsCommand(client: MqttClient, requestCode: string, topic: string) {
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_ENUMERATE_COM_PORTS_HEADER}`);
}

export function sendPortEnumerationData(client: MqttClient, requestCode: string, topic: string, ports: SerialPortInfo[]) {
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_COM_PORT_ENUMERATION_HEADER}:${JSON.stringify(ports)}`);
}

export function sendPortConnectCommand(client: MqttClient, requestCode: string, topic: string, port: SerialPortInfo) {
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_CONNECT_COM_PORT_HEADER}:${JSON.stringify(port)}`);
}

export function sendPortDisconnectCommand(client: MqttClient, requestCode: string, topic: string) {
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_DISCONNECT_COM_PORT_HEADER}`);
}

export function sendStartFwWriteMessage(client: MqttClient, requestCode: string, topic: string, subFileName?: string) {
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_START_WRITE_FW_HEADER}:${subFileName ?? MQTT_UNDEFINED_VALUE}`);
}

export function sendStopFwWriteMessage(client: MqttClient, requestCode: string, topic: string) {
    sendMessage(client, requestCode, topic, MQTT_MESSAGE_STOP_WRITE_FW_HEADER);
}

export function sendFwDataRepeatRequestMessage(client: MqttClient, requestCode: string, topic: string, packetIndex: number) {
    sendMessage(client, requestCode, topic, `${MQTT_MESSAGE_FW_DATA_REPEAT_HEADER}:${packetIndex}`);
}

export function sendFwDataReceiveConfirmationMessage(client: MqttClient, requestCode: string, topic: string) {
    sendMessage(client, requestCode, topic, MQTT_MESSAGE_FW_DATA_RECEIVED_HEADER);
}

export function sendFwData(client: MqttClient, requestCode: string, topic: string, fwInfo: AwpFwInfo | undefined, fwFileName: string, fwFileType: number, fwData: ArrayBuffer, cancellationToken: MqttCancellationToken, progressListener: (progress: number) => void): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
        const topicString = `${requestCode}/${topic}`;
        const sendOptions = {qos: 1} as IClientPublishOptions;
        let finished = false;
        const localMqttCallback = (topic: string, message: Buffer) => {
            const mqttMessage = parseMqttMessage(message);
            if (mqttMessage) {
                switch (mqttMessage.type) {
                    case MQTT_MESSAGE_FW_DATA_REPEAT:
                        const i = mqttMessage.value as number;
                        console.log(i);
                        const packetData = fwData.slice(i * MQTT_MAX_BINARY_PAYLOAD, (i + 1) * MQTT_MAX_BINARY_PAYLOAD);
                        const header = `${MQTT_MESSAGE_FW_DATA_BINARY_HEADER}:${i}:`;
                        const data = Buffer.alloc(header.length + packetData.byteLength);
                        data.write(header);
                        data.set(new Uint8Array(packetData), header.length);
                        if (!cancellationToken.isCancelled()) {
                            client.publish(topicString, data, sendOptions);
                        }
                        break;
                    case MQTT_MESSAGE_FW_DATA_RECEIVED:
                        progressListener(100);
                        finished = true;
                        break;
                }
            }
        };
        client.on('message', localMqttCallback);
        try {
            cancellationToken.setCancellationAction(() => {
                client.publishAsync(topicString, MQTT_MESSAGE_FW_DATA_CANCEL_HEADER, sendOptions);
            })
            const numberOfPackets = Math.ceil(fwData.byteLength / MQTT_MAX_BINARY_PAYLOAD);
            if (!cancellationToken.isCancelled()) {
                const info = {
                    info: fwInfo,
                    name: fwFileName,
                    type: fwFileType,
                    chunks: numberOfPackets
                } as FwLoaderRemoteFirmwareInfo;
                await client.publishAsync(topicString, `${MQTT_MESSAGE_FW_DATA_INFO_HEADER}:${JSON.stringify(info)}`, sendOptions);
            }
            progressListener(5);
            for (let i = 0; i < numberOfPackets; i++) {
                const packetData = fwData.slice(i * MQTT_MAX_BINARY_PAYLOAD, (i + 1) * MQTT_MAX_BINARY_PAYLOAD);
                const header = `${MQTT_MESSAGE_FW_DATA_BINARY_HEADER}:${i}:`;
                const data = Buffer.alloc(header.length + packetData.byteLength);
                data.write(header);
                data.set(new Uint8Array(packetData), header.length);
                if (!cancellationToken.isCancelled()) {
                    await client.publishAsync(topicString, data, sendOptions);
                    progressListener(5 + (i + 1) * 85 / numberOfPackets);
                } else {
                    break;
                }
            }
            if (!cancellationToken.isCancelled()) {
                await client.publishAsync(topicString, MQTT_MESSAGE_FW_DATA_DONE_HEADER, sendOptions);
                progressListener(95);
            }
            while (!finished && !cancellationToken.isCancelled()) {
                await delay(300);
            }
            resolve(!cancellationToken.isCancelled());
        } catch (e) {
            logError("Error sending FW data", e);
            resolve(false);
        } finally {
            client.removeListener('message', localMqttCallback);
        }
    });
}