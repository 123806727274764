import {VerticalDivider} from "../Divider/VerticalDivider";
import {MultipleRecordsInfo} from "../MultipleRecordsInfo/MultipleRecordsInfo";
import {TFunction} from "i18next";
import {Error} from "../Error/Error";
import {FetchDataWrapper} from "../../models/FetchData";
import {TudRecord} from "../../models/TudRecord";
import {TpRecord} from "../../models/TpRecord";
import {UtRecord} from "../../models/UtRecord";
import {Loader} from "../Loader/Loader";
import IconCloseSvg from "../Icons/IconCloseSvg";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import {useMemo} from "react";
import {useLocale} from "../../hooks/LocaleHook";
import {DATA_PLACEHOLDER} from "../../AppSettings";
import {formatDateTime, formatTpProbeName, formatTudProbeName} from "../../helpers/FormatHelper";
import {DPM, LEEB, MF1M, TP1M, TUD2, TUD3, UCI, UT1M, UT1M_CT, UT1M_IP} from "../../models/DeviceType";
import {NovotestLabRecord} from "../../models/NovotestLabRecord";
import {NoPhotoPlaceholder} from "../NoPhotoPlaceholder/NoPhotoPlaceholder";
import {NovotestLabPhotoPreview} from "../PhotoViewer/NovotestLabPhotoPreview";
import {MfRecord} from "../../models/MfRecord";
import {LeebRecord} from "../../models/LeebRecord";
import {useTranslation} from "react-i18next";
import {DpmRecord} from "../../models/DpmRecord";

interface Parameter {
    name: string;
    value?: string;
}

interface Props {
    drive: any;
    recordId: string | undefined;
    showPreview: boolean;
    count: number;
    onClose: () => void;
    record: FetchDataWrapper<NovotestLabRecord>;
    onDetailsClick: () => void;
}

export function RecordPreview(props: Props) {
    const {t} = useTranslation();
    const locale = useLocale();
    const params = useMemo(() => {
        const record = props.record.data;
        if (record) {
            return prepareData(t, locale, record);
        } else {
            return new Array<Parameter>();
        }
    }, [t, locale, props.record]);
    return (
        <div
            className={`d-flex flex-row align-self-stretch overflow-hidden ${props.showPreview ? "container-record-preview-visible" : "container-record-preview-collapsed"}`}>
            <VerticalDivider className="me-2"/>
            <div className='d-flex flex-column flex-grow-1'>
                {((props.count > 1) || (props.count > 0 && !props.record.hasError && !props.record.isLoading && !props.record.data)) &&
                    <div
                        className="container-grow align-items-center justify-content-center m-2">
                        <MultipleRecordsInfo text={t("selected_elements_count_format", {count: props.count})}/>
                    </div>
                }
                {props.count === 0 && !props.record.data && !props.record.hasError && !props.record.isLoading &&
                    <div
                        className="container-grow align-items-center justify-content-center m-2">
                        <Error errorMessage={t("select_record")}/>
                    </div>
                }
                {props.count === 1 && props.record.hasError &&
                    <div
                        className="container-grow align-items-center justify-content-center m-2">
                        <Error errorMessage={t("record_loading_error")}/>
                    </div>
                }
                {props.count === 1 && props.record.isLoading &&
                    <div
                        className="container-grow align-items-center justify-content-center m-2">
                        <Loader/>
                    </div>
                }
                {props.count === 1 && props.record.data &&
                    <div className="container-grow justify-content-center m-2 overflow-y-clip">
                        <div className="d-flex flex-row align-items-center">
                            <div className="record-preview-title flex-grow-1">{props.record.data.name}</div>
                            <div className="clickable ms-2 record-preview-close-button" onClick={props.onClose}>
                                <IconCloseSvg/></div>
                        </div>
                        <HorizontalDivider className="my-2"/>
                        <div className="container-grow overflow-y-auto">
                            <div className="mb-2 align-self-stretch">
                                {!props.record.data.photo && <NoPhotoPlaceholder text={t("no_photo")}/>}
                                {props.drive && props.recordId && props.record.data.photo &&
                                    <NovotestLabPhotoPreview drive={props.drive} recordId={props.recordId}
                                                             record={props.record.data}/>
                                }
                            </div>
                            {params.map((p, i) =>
                                <div key={`preview-item-${i}`} className="record-preview-item">
                                    {`${p.name}: ${p.value ?? DATA_PLACEHOLDER}`}
                                </div>
                            )}
                            <div className="record-preview-details no-select clickable align-self-center mt-2" onClick={props.onDetailsClick}>{t('show_details')}</div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

function prepareData(t: TFunction<"translation">, locale: string, record: NovotestLabRecord) {
    switch (record.deviceType) {
        case TUD2:
        case TUD3:
            const tudRecord = record as TudRecord;
            return [
                {
                    name: t('date_save'),
                    value: tudRecord.dateTime ? formatDateTime(locale, new Date(tudRecord.dateTime)) : DATA_PLACEHOLDER
                },
                {name: t('inspector'), value: tudRecord.userName !== "" ? tudRecord.userName : DATA_PLACEHOLDER},
                {
                    name: t('company'),
                    value: tudRecord.userOrganization !== "" ? tudRecord.userOrganization : DATA_PLACEHOLDER
                },
                {name: t('device_id'), value: tudRecord.deviceId},
                {name: t('probe'), value: tudRecord.probe ? formatTudProbeName(t, tudRecord.probe) : DATA_PLACEHOLDER},
                {name: t('probe_id'), value: tudRecord.probeId ?? DATA_PLACEHOLDER}
            ];
        case LEEB:
            const leebRecord = record as LeebRecord;
            return [
                {
                    name: t('date_save'),
                    value: leebRecord.dateTime ? formatDateTime(locale, new Date(leebRecord.dateTime)) : DATA_PLACEHOLDER
                },
                {name: t('inspector'), value: leebRecord.userName !== "" ? leebRecord.userName : DATA_PLACEHOLDER},
                {
                    name: t('company'),
                    value: leebRecord.userOrganization !== "" ? leebRecord.userOrganization : DATA_PLACEHOLDER
                },
                {name: t('device_id'), value: leebRecord.deviceId}
            ];
        case UCI:
            const uciRecord = record as TudRecord;
            return [
                {
                    name: t('date_save'),
                    value: uciRecord.dateTime ? formatDateTime(locale, new Date(uciRecord.dateTime)) : DATA_PLACEHOLDER
                },
                {name: t('inspector'), value: uciRecord.userName !== "" ? uciRecord.userName : DATA_PLACEHOLDER},
                {
                    name: t('company'),
                    value: uciRecord.userOrganization !== "" ? uciRecord.userOrganization : DATA_PLACEHOLDER
                },
                {name: t('device_id'), value: uciRecord.deviceId},
            ];
        case TP1M:
            const tpRecord = record as TpRecord;
            return [
                {
                    name: t('date_save'),
                    value: tpRecord.dateTime ? formatDateTime(locale, new Date(tpRecord.dateTime)) : DATA_PLACEHOLDER
                },
                {name: t('inspector'), value: tpRecord.userName !== "" ? tpRecord.userName : DATA_PLACEHOLDER},
                {
                    name: t('company'),
                    value: tpRecord.userOrganization !== "" ? tpRecord.userOrganization : DATA_PLACEHOLDER
                },
                {name: t('device_id'), value: tpRecord.deviceId},
                {name: t('probe'), value: tpRecord.probe ? formatTpProbeName(t, tpRecord.probe) : DATA_PLACEHOLDER},
                {name: t('probe_id'), value: tpRecord.probeId ?? DATA_PLACEHOLDER}
            ];
        case UT1M:
        case UT1M_IP:
        case UT1M_CT:
            const utRecord = record as UtRecord;
            return [
                {
                    name: t('date_save'),
                    value: utRecord.dateTime ? formatDateTime(locale, new Date(utRecord.dateTime)) : DATA_PLACEHOLDER
                },
                {name: t('inspector'), value: utRecord.userName !== "" ? utRecord.userName : DATA_PLACEHOLDER},
                {
                    name: t('company'),
                    value: utRecord.userOrganization !== "" ? utRecord.userOrganization : DATA_PLACEHOLDER
                },
                {name: t('device_id'), value: utRecord.deviceId},
                {name: t('probe'), value: utRecord.probeId ?? DATA_PLACEHOLDER}
            ];
        case MF1M:
            const mfRecord = record as MfRecord;
            return [
                {
                    name: t('date_save'),
                    value: mfRecord.dateTime ? formatDateTime(locale, new Date(mfRecord.dateTime)) : DATA_PLACEHOLDER
                },
                {name: t('inspector'), value: mfRecord.userName !== "" ? mfRecord.userName : DATA_PLACEHOLDER},
                {
                    name: t('company'),
                    value: mfRecord.userOrganization !== "" ? mfRecord.userOrganization : DATA_PLACEHOLDER
                },
                {name: t('device_id'), value: mfRecord.deviceId}
            ];
        case DPM:
            const dpmRecord = record as DpmRecord;
            return [
                {
                    name: t('date_save'),
                    value: dpmRecord.dateTime ? formatDateTime(locale, new Date(dpmRecord.dateTime)) : DATA_PLACEHOLDER
                },
                {name: t('inspector'), value: dpmRecord.userName !== "" ? dpmRecord.userName : DATA_PLACEHOLDER},
                {
                    name: t('company'),
                    value: dpmRecord.userOrganization !== "" ? dpmRecord.userOrganization : DATA_PLACEHOLDER
                },
                {name: t('device_id'), value: dpmRecord.deviceId}
            ];
    }
    return [];
}
