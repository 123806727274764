import './ArchiveList.css';
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import React, {useMemo} from "react";
import IconSortAscSvg from "../Icons/IconSortAscSvg";
import IconSortDescSvg from "../Icons/IconSortDescSvg";
import {ArchiveItem} from "../../models/ArchiveItem";
import {ArchiveListItem} from "./ArchiveListItem";
import {usePersistentState} from "../../hooks/PersistentStateHook";
import {EXPLORER_SORT} from "../../persistence";
import * as SortType from "../../models/ExplorerSortType";
import {useTranslation} from "react-i18next";

interface Props {
    items: Array<ArchiveItem>;
    highlightedItems: Array<string>;
    itemHighlightListener?: (itemId: string, isMultiSelect: boolean) => void;
    itemContextClickListener?: (itemId: string, x: number, y: number) => void;
    itemDoubleClickListener?: (itemId: string) => void;
    sortedIdsRef?: React.MutableRefObject<Array<string> | null>;
}

export function ArchiveList(props: Props) {
    const {t} = useTranslation();
    const [sortType, setSortType] = usePersistentState(EXPLORER_SORT, SortType.NAME_ASC);
    const items = useMemo(() => {
        const array = [];
        let sortFunction;
        switch (sortType) {
            case SortType.NAME_ASC:
                sortFunction = (i1: ArchiveItem, i2: ArchiveItem) => i1.name === i2.name ? 0 : i1.name < i2.name ? -1 : 1;
                break;
            case SortType.NAME_DESC:
                sortFunction = (i1: ArchiveItem, i2: ArchiveItem) => i1.name === i2.name ? 0 : i1.name > i2.name ? -1 : 1;
                break;
            case SortType.DATE_ASC:
                sortFunction = (i1: ArchiveItem, i2: ArchiveItem) => i1.lastChanged.getTime() === i2.lastChanged.getTime() ? 0 : i1.lastChanged.getTime() < i2.lastChanged.getTime() ? -1 : 1;
                break;
            case SortType.DATE_DESC:
                sortFunction = (i1: ArchiveItem, i2: ArchiveItem) => i1.lastChanged.getTime() === i2.lastChanged.getTime() ? 0 : i1.lastChanged.getTime() > i2.lastChanged.getTime() ? -1 : 1;
                break;
        }
        if (sortFunction) {
            array.push(...props.items.filter(i => i.isFolder).sort(sortFunction));
            array.push(...props.items.filter(i => !i.isFolder).sort(sortFunction));
        }
        if (props.sortedIdsRef){
            props.sortedIdsRef.current = array.map(item => item.id);
        }
        return array;
    }, [props.items, sortType]);
    return (
        <table className="archive-list no-select">
            <thead>
            <tr>
                <th className="fixed-header"
                    onClick={() => setSortType(sortType === SortType.NAME_ASC ? SortType.NAME_DESC : SortType.NAME_ASC)}>
                    <div className="sort-type py-1">
                        {t("name")}
                        {sortType === SortType.NAME_ASC && <div className="sort-icon"><IconSortAscSvg/></div>}
                        {sortType === SortType.NAME_DESC && <div className="sort-icon"><IconSortDescSvg/></div>}
                    </div>
                </th>
                <th className="fixed-header"
                    onClick={() => setSortType(sortType === SortType.DATE_ASC ? SortType.DATE_DESC : SortType.DATE_ASC)}>
                    <div className="sort-type py-1">
                        {t("last_change")}
                        {sortType === SortType.DATE_ASC && <div className="sort-icon"><IconSortAscSvg/></div>}
                        {sortType === SortType.DATE_DESC && <div className="sort-icon"><IconSortDescSvg/></div>}
                    </div>
                </th>
            </tr>
            <tr>
                <td className="fixed-header" style={{top: 34}} colSpan={2}><HorizontalDivider/></td>
            </tr>
            </thead>
            <tbody>
            {items.flatMap(item => [
                <ArchiveListItem key={item.id} archiveItem={item}
                                 isHighlighted={props.highlightedItems.includes(item.id)}
                                 itemHighlightListener={props.itemHighlightListener}
                                 itemSelectionListener={props.itemDoubleClickListener}
                                 itemContextClickListener={props.itemContextClickListener}/>,
                <tr key={`${item.id}-divider`}>
                    <td colSpan={2}><HorizontalDivider/></td>
                </tr>
            ])}
            <tr style={{height: "100%"}}/>
            </tbody>
            <tfoot>
            <tr className="fixed-footer">
                <td colSpan={2}>
                    <div className="my-2"/>
                    <div className="small-instruction-text no-select">{t('record_list_instruction_1')}</div>
                </td>
            </tr>
            </tfoot>
        </table>
    );
}