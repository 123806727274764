import {Button, Form, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import IconPasteSvg from "../Icons/IconPasteSvg";
import {useEffect, useRef, useState} from "react";

interface Props {
    show: number;
    closeHandler: () => void;
    codeHandler: (code: string) => void;
}

export function ActivationCodeDialog(props: Props) {
    const {t} = useTranslation();
    const [code, setCode] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        setCode("");
    }, [props.show]);
    const pasteButtonClickHandler = () => {
        navigator.clipboard.readText().then(text => {
            const inputElement = inputRef.current;
            if (inputElement) {
                inputElement.value = text;
            }
        });
    };
    const sendButtonClickHandler = () => {
        const inputElement = inputRef.current;
        if (inputElement) {
            const value = inputElement.value;
            if (value) {
                props.codeHandler(value);
                close();
            }
        }
    };
    const close = () => {
        props.closeHandler();
    };
    return (
        <Modal show={props.show % 2 === 1} size="lg" centered onHide={close}>
            <Modal.Header closeButton>{t("loader_activation_code")}</Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column align-items-center my-2">
                    <div>{t("loader_enter_activation_code")}</div>
                    <div className="d-flex align-self-stretch flex-row justify-content-between align-items-center my-4">
                        <Form.Control type="text" className="flex-grow" placeholder={t("loader_activation_code")}
                                      ref={inputRef} value={code} onChange={e => setCode(e.target.value)}/>
                        <Button className="ms-4" variant="outline-primary"
                                onClick={pasteButtonClickHandler}><IconPasteSvg/></Button>
                    </div>
                    <div className="d-flex align-self-stretch flex-row justify-content-around align-items-center mb-4">
                        <Button variant="primary" style={{width: "120px"}} disabled={!code}
                                onClick={sendButtonClickHandler}>{t('terminal_send')}</Button>
                        <Button variant="primary" style={{width: "120px"}} onClick={close}>{t('cancel')}</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
