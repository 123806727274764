import {createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {GoogleContext} from "./GoogleContext";
import {API_KEY, CLIENT_ID, CLIENT_SCOPE, CLIENT_SECRET} from "../AppSettings";
import {GoogleUserData} from "./GoogleUserData";
import axios from "axios";
import {useNullablePersistentState} from "../hooks/PersistentStateHook";
import {USER_DATA} from "../persistence";
import {logError} from "../helpers/LogHelper";

const GoogleApiContext = createContext<GoogleContext>(null!)

const TOKEN_EXPIRATION_THRESHOLD_MS = 300_000;
const TOKEN_CHECK_INTERVAL = 30_000;

interface Props {
    children: ReactNode;
}

export function GoogleApiProvider(props: Props) {
    const [userData, setUserData] = useNullablePersistentState(USER_DATA, null as GoogleUserData | null);
    const isScriptsReady = useLoadScripts();
    const signIn = useGoogleLogin(isScriptsReady, response => googleSignIn(response, setUserData), response => setUserData(null));
    const signOut = useCallback(() => setUserData(null), [setUserData]);
    const [accessToken, setAccessToken] = useState(null as string | null | undefined);
    useEffect(() => {
            let intervalId: NodeJS.Timeout;
            if (!userData) {
                setAccessToken(null)
                return;
            }
            const refreshFunction = async () => {
                const newUserData = await refreshAccessToken(userData);
                if (newUserData) {
                    setUserData(newUserData);
                }
            }
            if (userData.accessTokenValidUntil - Date.now() > TOKEN_EXPIRATION_THRESHOLD_MS) {
                setAccessToken(userData.accessToken);
                intervalId = setInterval(refreshFunction, TOKEN_CHECK_INTERVAL);
            } else {
                refreshFunction().then();
            }
            return () => {
                if (intervalId) {
                    clearInterval(intervalId);
                }
            };
        }, [userData, setUserData]);
    const drive = useMemo(() => {
        if (isScriptsReady && accessToken !== undefined) {
            if (accessToken) {
                window.gapi.client.setToken({
                    access_token: accessToken
                });
            }
            return window.gapi.client.drive;
        } else {
            return undefined;
        }
    }, [isScriptsReady, accessToken]);
    const contextValue = useMemo(() => {
        return {
            signIn: signIn,
            signOut: signOut,
            userData: userData,
            drive: drive
        } as GoogleContext;
    }, [signIn, signOut, userData, drive]);
    return (
        <GoogleApiContext.Provider value={contextValue}>
            {props.children}
        </GoogleApiContext.Provider>
    );
}

export function useGoogleApi() {
    const context = useContext(GoogleApiContext);
    if (!context) {
        throw new Error("Context provider not found");
    }
    return context;
}

function useLoadScripts(): boolean {
    const [gapiScriptLoadedSuccessfully, setGapiScriptLoadedSuccessfully] = useState(false);
    const [gsiScriptLoadedSuccessfully, setGsiScriptLoadedSuccessfully] = useState(false);
    useEffect(() => {
        const initializeGapiClient = () => {
            gapi.client.init({
                apiKey: API_KEY,
                discoveryDocs: [
                    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
                ],
            }).then(() => setGapiScriptLoadedSuccessfully(true));
        }
        const scriptTag = document.createElement('script');
        scriptTag.src = 'https://apis.google.com/js/api.js';
        scriptTag.async = true;
        scriptTag.defer = true;
        scriptTag.onload = () => {
            gapi.load('client', initializeGapiClient);
        };
        scriptTag.onerror = () => {
            setGapiScriptLoadedSuccessfully(false);
        };
        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        };
    }, []);

    useEffect(() => {
        const scriptTag = document.createElement('script');
        scriptTag.src = 'https://accounts.google.com/gsi/client';
        scriptTag.async = true;
        scriptTag.defer = true;
        scriptTag.onload = () => {
            setGsiScriptLoadedSuccessfully(true);
        };
        scriptTag.onerror = () => {
            setGsiScriptLoadedSuccessfully(false);
        };
        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        };
    }, []);
    return gapiScriptLoadedSuccessfully && gsiScriptLoadedSuccessfully;
}

function useGoogleLogin(scriptReady: boolean, onSuccess: (response: google.accounts.oauth2.CodeResponse) => void, onError: (response: google.accounts.oauth2.CodeResponse) => void): () => void {
    const clientRef = useRef<any>();
    const onSuccessRef = useRef(onSuccess);
    onSuccessRef.current = onSuccess;
    const onErrorRef = useRef(onError);
    onErrorRef.current = onError;
    useEffect(() => {
        if (!scriptReady) {
            return;
        }
        const client = window?.google?.accounts.oauth2['initCodeClient']({
            client_id: CLIENT_ID,
            scope: CLIENT_SCOPE,
            callback: (response: google.accounts.oauth2.CodeResponse) => {
                if (response.error) {
                    return onErrorRef.current?.(response);
                }
                onSuccessRef.current?.(response);
            }
        });
        clientRef.current = client;
    }, [scriptReady]);

    return useCallback(() => clientRef.current?.requestCode(), []);
}

function googleSignIn(response: google.accounts.oauth2.CodeResponse, setUserData: (v: (GoogleUserData | null)) => void) {
    let payload = {
        grant_type: 'authorization_code',
        code: response.code,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        redirect_uri: window.location.origin,
    };
    axios.post(`https://oauth2.googleapis.com/token`, payload, {
        headers: {
            'Content-Type': 'application/json;'
        },
    }).then((res: any) => {
        return res.data;
    }).then((response: any) => {
        const accessToken = response.access_token;
        const timeLeft = response.expires_in;
        const validUntil = Date.now() + timeLeft * 1000;
        const refreshToken = response.refresh_token;
        axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json',
                },
            }
        ).then((res) => {
            setUserData({
                email: res.data.email,
                accessToken: accessToken,
                accessTokenValidUntil: validUntil,
                refreshToken: refreshToken
            });
        });
    }).catch((error) => {
        logError('Google sign in error', error);
        setUserData(null);
    });
}

export function refreshAccessToken(userData: GoogleUserData): Promise<GoogleUserData | null> {
    return new Promise((resolve, reject) => {
        if (userData.accessTokenValidUntil - Date.now() > TOKEN_EXPIRATION_THRESHOLD_MS) {
            resolve(null);
        } else {
            let payloadForAccessToken = {
                grant_type: 'refresh_token',
                refresh_token: userData.refreshToken,
                client_id: CLIENT_ID,
                client_secret: CLIENT_SECRET,
            };
            axios.post(`https://oauth2.googleapis.com/token`, payloadForAccessToken, {
                headers: {
                    'Content-Type': 'application/json;',
                },
            }).then((res: any) => {
                return res.data;
            }).then((res) => {
                const newData: GoogleUserData = {
                    email: userData.email,
                    accessToken: res.access_token,
                    accessTokenValidUntil: Date.now() + res.expires_in * 1000,
                    refreshToken: userData.refreshToken
                }
                resolve(newData);
                return;
            }).catch((err) => reject(err));
        }
    });
}