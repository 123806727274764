import {MeasurementLocation} from "../../models/MeasurementLocation";
import {Image, Layer, Stage} from "react-konva";
import useImage from "use-image";
import {useEffect, useRef, useState} from "react";
import {MarkerView} from "./MarkerView";
import './MeasurementsWithPhoto.css';
import {LoaderSmall} from "../Loader/LoaderSmall";
import {ErrorSmall} from "../Error/ErrorSmall";
import {useTranslation} from "react-i18next";

export interface MeasurementMarker {
    text: string;
    location: MeasurementLocation;
}

interface Props {
    url: string;
    markers: MeasurementMarker[];
    retryClickListener: () => void;
    imageLoadListener?: () => void;
}

const PREVIEW_WIDTH = 208;

export function MeasurementsWithPhotoPreview(props: Props) {
    const {t} = useTranslation();
    const [image, status] = useImage(props.url);
    const [isNotified, setNotified] = useState(false);
    const container = useRef<HTMLDivElement>(null);
    let h: number | null = null;
    let w: number | null = null;
    if (image) {
        w = PREVIEW_WIDTH;
        h = image.height * w / image.width;
    }
    useEffect(() => {
        if (status === "loaded" && !isNotified) {
            setNotified(true);
            if (props.imageLoadListener) {
                props.imageLoadListener();
            }
        }
    }, [status, isNotified]);
    const handleClick = (idx : number) => {
        // do nothing
    }
    return (
        <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center" ref={container}>
            {status === "failed" && <ErrorSmall retryClickHandler={props.retryClickListener}/>}
            {(status === "loading" || (status !== "failed" && !image)) && <LoaderSmall/>}
            {(w && h) &&
                <Stage width={w} height={h}>
                    <Layer>
                        <Image image={image} x={0} y={0} width={w} height={h} preventDefault={false}/>
                        {props.markers.map((m, i) => <MarkerView text={m.text} x={m.location.x} y={m.location.y}
                                                                 imageWidth={w ?? 0} imageHeight={h ?? 0} key={`m-${i}`}
                                                                 clickListener={() => handleClick(i)} disabled={true}/>)}
                    </Layer>
                </Stage>
            }
        </div>
    );
}