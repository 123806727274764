import {Group, Line, Rect} from "react-konva";
import {useState} from "react";

const MARKER_WIDTH = 28;
const MARKER_HEIGHT = 44;
const ARROW_SIZE = 14;

const FILL_COLOR = "#212121";

interface Props {
    x: number;
    y: number;
    w: number;
    h: number;
    scaleFactor: number;
    clickListener: () => void;
}

export function AwpMarkerView(props: Props) {
    const [isHovered, setHovered] = useState(false);
    const scaleFactor = props.scaleFactor;
    const sourceW = props.w / scaleFactor;
    const sourceH = props.h / scaleFactor;
    const sourceX = Math.min(props.x, sourceW - MARKER_WIDTH);
    const sourceY = Math.min(props.y, sourceH - MARKER_HEIGHT);
    const rectWidth = MARKER_WIDTH * scaleFactor;
    const rectHeight = (MARKER_HEIGHT - ARROW_SIZE) * scaleFactor;
    const rectX = sourceX * scaleFactor;
    const rectY = props.h - (sourceY + ARROW_SIZE) * scaleFactor - rectHeight;
    const arrowPoints = [
        (sourceX + 0.5 * ARROW_SIZE) * scaleFactor,
        props.h - (sourceY + ARROW_SIZE) * scaleFactor,
        (sourceX + 1.5 * ARROW_SIZE) * scaleFactor,
        props.h - (sourceY + ARROW_SIZE) * scaleFactor,
        (sourceX + ARROW_SIZE) * scaleFactor,
        props.h - sourceY * scaleFactor
    ];
    const onMouseEnter = (e: any) => {
        const container = e.target.getStage().container();
        container.style.cursor = "pointer";
        setHovered(true);
    };
    const onMouseLeave = (e: any) => {
        const container = e.target.getStage().container();
        container.style.cursor = "default";
        setHovered(false);
    };
    return (
        <Group onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onTouchStart={() => setHovered(true)}
               onTouchEnd={() => setHovered(false)} onClick={props.clickListener} onTap={props.clickListener}
               opacity={isHovered ? 0.4 : 0}>
            <Rect x={rectX} y={rectY} width={rectWidth} height={rectHeight}
                  strokeEnabled={false} fill={FILL_COLOR}/>
            <Line points={arrowPoints} strokeEnabled={false} fill={FILL_COLOR} closed={true}/>
        </Group>
    );

}