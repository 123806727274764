import {parseInt32, parseString, parseUint16, parseUint32, parseUint8} from "../helpers/BinaryParseHelper";

const VER_HW_SIZE = 20;
const VER_SW_SIZE = 50;

const BLOCKS_COUNT = 10;

const BLOCK_INFO_SIZE = 9;

export interface AwpFwBlockInfo {
    type: number;
    adr: number;
    len: number;
}

export interface AwpFwHeadGsp {
    verGsp: number;
    type: number;
    verHw: number[];
    verSw: string;
    n: number;
    blockInfo: AwpFwBlockInfo[];
}

function parseAWpFwBlockInfo(buffer: ArrayBuffer) {
    let offset = 0;
    const type = parseUint8(buffer, offset);
    offset += 1;
    const newBuffer = buffer.slice(offset);
    offset = 0;
    const adr = parseUint32(newBuffer, offset);
    offset += 4;
    const len = parseUint32(newBuffer, offset);
    return {
        type: type,
        adr: adr,
        len: len
    };
}

export function parseAwpFwHeadGsp(buffer: ArrayBuffer): AwpFwHeadGsp {
    let offset = 0;
    const verGsp = parseUint16(buffer, offset);
    offset += 2;
    const type = parseUint16(buffer, offset);
    offset += 2;
    const verHw = new Array<number>();
    for (let i = 0; i < VER_HW_SIZE; i++) {
        verHw.push(parseUint16(buffer, offset));
        offset += 2;
    }
    const verSw = parseString(buffer, offset, VER_SW_SIZE);
    offset += VER_SW_SIZE;
    const n = parseUint8(buffer, offset);
    offset += 1;
    const blockInfo = new Array<AwpFwBlockInfo>();
    for (let i = 0; i < BLOCKS_COUNT; i++) {
        const newBuffer = buffer.slice(offset);
        blockInfo.push(parseAWpFwBlockInfo(newBuffer));
        offset += BLOCK_INFO_SIZE;
    }
    return {
        verGsp: verGsp,
        type: type,
        verHw: verHw,
        verSw: verSw,
        n: n,
        blockInfo: blockInfo
    };
}