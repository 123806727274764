import {MediaRecord} from "../../models/MediaRecord";
import {useEffect, useState} from "react";
import {getMediaFile} from "../../helpers/GoogleStorageApiHelper";
import {Error} from "../Error/Error";
import './PhotoViewer.css';
import {TpMeasurement} from "../../models/TpMeasurement";
import {MeasurementMarker, MeasurementsWithPhoto} from "./MeasurementsWithPhoto";
import {formatTpScaleName, formatTpScaleValue} from "../../helpers/FormatHelper";
import {LoaderSmall} from "../Loader/LoaderSmall";
import {ResourceNotFoundError} from "../../errors/GoogleDriveErrors";
import {NotFoundSmall} from "../NotFound/NotFoundSmall";
import {ErrorSmall} from "../Error/ErrorSmall";
import {useTranslation} from "react-i18next";

interface Props {
    drive: any;
    recordId: string;
    mediaRecord: MediaRecord;
    measurement: TpMeasurement[];
    markerClickListener?: (measurement: TpMeasurement) => void;
    isFullScreen: boolean;
    imageLoadListener?: () => void;
}

export function TpPhotoViewer(props: Props) {
    const {t} = useTranslation();
    const [mediaUrl, setMediaUrl] = useState(null as string | null);
    const [error, setError] = useState(null as Error | null);
    const [retry, setRetry] = useState(0);
    useEffect(() => {
        getMediaFile(props.drive, props.recordId, props.mediaRecord.fileName).then(url => {
            setMediaUrl(url);
            setError(null);
        }).catch((e) => setError(e));
    }, [props.drive, props.recordId, props.mediaRecord, retry]);
    if (error) {
        return error instanceof ResourceNotFoundError ? <NotFoundSmall/> : <ErrorSmall error={error} retryClickHandler={() => setRetry(retry + 1)}/>
    }
    const markers = new Array<MeasurementMarker>();
    props.measurement.forEach(m => {
        if (m.location) {
            markers.push({
                text: `${formatTpScaleValue(m.tpScale, m.middle)} ${formatTpScaleName(t, m.tpScale)}`,
                location: m.location
            } as MeasurementMarker);
        }
    });
    const handleClick = props.markerClickListener ? (idx : number) => props.markerClickListener!(props.measurement[idx]) : undefined;
    return (
        <div className="d-flex flex-column flex-grow-1">
            {!mediaUrl ? <LoaderSmall/> : <MeasurementsWithPhoto key={`ph-${retry}`}  url={mediaUrl} markers={markers}
                                                                 markerClickListener={handleClick}
                                                                 retryClickListener={() => setRetry(retry + 1)} isFullScreen={props.isFullScreen}
                                                                 imageLoadListener={props.imageLoadListener}/>}
        </div>
    );
}