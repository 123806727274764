import {formatDateTime} from "../../helpers/FormatHelper";
import './ViewContainer.css';
import {useLocale} from "../../hooks/LocaleHook";
import {useTranslation} from "react-i18next";
import IconEnlargeSvg from "../Icons/IconEnlargeSvg";

interface Props {
    title?: string;
    dateTime?: number;
    className?: string;
    isLoaded : boolean;
    children: JSX.Element | JSX.Element[];
    enlargeClickHandler: () => void;
}

export function ViewContainerWithEnlarge(props: Props) {
    const {t} = useTranslation();
    const locale = useLocale();
    return (
        <div className={`d-flex flex-column my-2 ${props.className ?? ""}`}>
            <div className={`d-flex flex-row ${props.isLoaded ? "justify-content-between" : "justify-content-start"}`}>
                {props.title && <span className="align-self-start mb-2 view-title">{props.title}</span>}
                {props.isLoaded && <span className="view-enlarge" onClick={props.enlargeClickHandler}><IconEnlargeSvg
                    className="me-1"/>{t("enlarge")}</span>}
            </div>
            <div className="view-container p-2">
                {props.children}
            </div>
            {props.dateTime && <span
                className="align-self-end mt-2 view-date-time">{formatDateTime(locale, new Date(props.dateTime))}</span>}
        </div>
    );
}