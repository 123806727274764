import * as React from "react"

function IconPdfSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            width="1em"
            height="1em"
            {...props}
        >
            <path d="M47.987 21.938a.99.99 0 00-.053-.264c-.011-.032-.019-.063-.033-.094a.957.957 0 00-.193-.285l-.001-.001L42 15.586V10c0-.022-.011-.041-.013-.063a1.028 1.028 0 00-.051-.257c-.011-.032-.019-.063-.034-.094a.997.997 0 00-.196-.293l-9-9a.992.992 0 00-.294-.197c-.03-.013-.06-.022-.09-.032a.998.998 0 00-.264-.053C32.038.01 32.02 0 32 0H7a1 1 0 00-1 1v14.586L.293 21.293l-.002.002a.98.98 0 00-.192.285c-.014.031-.022.062-.033.094a.953.953 0 00-.053.264C.011 21.96 0 21.978 0 22v19a1 1 0 001 1h5v5a1 1 0 001 1h34a1 1 0 001-1v-5h5a1 1 0 001-1V22c0-.022-.011-.04-.013-.062zM44.586 21H42v-2.586L44.586 21zm-6-12H33V3.414L38.586 9zM8 2h23v8a1 1 0 001 1h8v10H8V2zM6 18.414V21H3.414L6 18.414zM40 46H8v-4h32v4zm6-6H2V23h44v17z" fill="currentColor"/>
            <path d="M18.254 26.72a2.82 2.82 0 00-1.097-.586 4.453 4.453 0 00-1.19-.17h-3.332V38h2.006v-4.828h1.428c.419 0 .827-.074 1.224-.221a2.9 2.9 0 001.054-.68c.306-.306.552-.688.74-1.148.187-.459.281-.994.281-1.606 0-.68-.105-1.247-.315-1.7a3.19 3.19 0 00-.799-1.097zm-1.283 4.285c-.306.334-.697.501-1.173.501h-1.156v-3.825h1.156c.476 0 .867.147 1.173.442.306.295.459.765.459 1.411s-.153 1.136-.459 1.471zM30.723 38h2.057v-5.168h3.077v-1.751h-3.093V27.8h3.348v-1.836h-5.389zM24.076 25.964H21.05V38h3.009c1.553 0 2.729-.524 3.528-1.572.799-1.049 1.198-2.525 1.198-4.429 0-1.904-.399-3.386-1.198-4.446-.799-1.059-1.969-1.589-3.511-1.589zm2.474 7.879c-.13.528-.315.967-.552 1.318a2.385 2.385 0 01-.85.79 2.235 2.235 0 01-1.071.264h-.969v-8.466h.969c.385 0 .742.088 1.071.264.329.175.612.439.85.79.238.351.422.793.552 1.326s.196 1.156.196 1.87c0 .703-.066 1.317-.196 1.844z" fill="currentColor"/>
        </svg>
    )
}

export default IconPdfSvg
