export const GAUSS = "GAUSS";
export const ASM = "ASM";
export const MTS = "MTS";
export const CODE = "CODE";

export function getMeasurementError(mfScale : string){
    switch (mfScale){
        case GAUSS:
        case ASM:
            return 0.05;
        case MTS:
            return 0.001;
        case CODE:
            return 0.1;
        default:
            return 0.1;
    }
}