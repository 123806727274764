import {BaseRecord} from "./BaseRecord";
import {MediaRecord} from "./MediaRecord";
import {TpMeasurement} from "./TpMeasurement";
import { TpSurfaceTemperatureMeasurement } from "./TpSurfaceTemperatureMeasurement";
import {TpDewPointMeasurement} from "./TpDewPointMeasurement";

export const COATING_THICKNESS_MEASUREMENT_TYPE = 0;
export const SURFACE_TEMPERATURE_MEASUREMENT_TYPE = 1;
export const DEW_POINT_MEASUREMENT_TYPE = 2;

export interface TpRecord extends BaseRecord {
    probe? : number;
    probeId?: string;
    measurementType? : number;
    measurements?: TpMeasurement[];
    surfaceTemperatureMeasurements? : TpSurfaceTemperatureMeasurement[];
    dewPointMeasurements?: TpDewPointMeasurement[];
    notes?: string;
    photo?: MediaRecord;
    audio?: MediaRecord[];
    video?: MediaRecord[];
}