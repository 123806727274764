import {MediaRecord} from "../../models/MediaRecord";
import {useEffect, useState} from "react";
import {getMediaFile} from "../../helpers/GoogleStorageApiHelper";
import {Error} from "../Error/Error";
import './PhotoViewer.css';
import {UtMeasurement} from "../../models/UtMeasurement";
import {MeasurementMarker, MeasurementsWithPhoto} from "./MeasurementsWithPhoto";
import {formatUtScaleName, formatUtScaleValue} from "../../helpers/FormatHelper";
import {convertValue} from "../../models/UtScale";
import {LoaderSmall} from "../Loader/LoaderSmall";
import {ResourceNotFoundError} from "../../errors/GoogleDriveErrors";
import {NotFoundSmall} from "../NotFound/NotFoundSmall";
import {ErrorSmall} from "../Error/ErrorSmall";
import {useTranslation} from "react-i18next";

interface Props {
    drive: any;
    recordId: string;
    mediaRecord: MediaRecord;
    measurement: UtMeasurement[];
    showAverage: boolean;
    markerClickListener?: (measurement: UtMeasurement) => void;
    isFullScreen: boolean;
    imageLoadListener?: () => void;
}

export function UtPhotoViewer(props: Props) {
    const {t} = useTranslation();
    const [mediaUrl, setMediaUrl] = useState(null as string | null);
    const [error, setError] = useState(null as Error | null);
    const [retry, setRetry] = useState(0);
    useEffect(() => {
        setError(null);
        getMediaFile(props.drive, props.recordId, props.mediaRecord.fileName).then(url => {
            setMediaUrl(url);
            setError(null);
        }).catch((e) => setError(e));
    }, [props.drive, props.recordId, props.mediaRecord, retry]);
    if (error) {
        return error instanceof ResourceNotFoundError ? <NotFoundSmall/> :
            <ErrorSmall error={error} retryClickHandler={() => setRetry(retry + 1)}/>
    }
    const markers = new Array<MeasurementMarker>();
    props.measurement.forEach(m => {
        let value;
        if (props.showAverage){
            let sum = 0;
            for (const contact of m.measurementContacts){
                if (contact.rawMeasurements.length > 0) {
                    let contactSum = 0;
                    for (const rawMeasurement of contact.rawMeasurements){
                        contactSum += rawMeasurement.thickness;
                    }
                    sum += contactSum / contact.rawMeasurements.length;
                }
            }
            value = m.measurementContacts.length > 0 ? sum / m.measurementContacts.length : 0;
        } else {
            const contact = m.measurementContacts[m.measurementContacts.length - 1];
            const rawMeasurement = contact.rawMeasurements[contact.rawMeasurements.length - 1];
            value = rawMeasurement.thickness;
        }
        if (m.location) {
            markers.push({
                text: `${formatUtScaleValue(convertValue(m.utScale, value), m.discreteness)} ${formatUtScaleName(t, m.utScale)}`,
                location: m.location
            } as MeasurementMarker);
        }
    });
    const handleClick = props.markerClickListener ? (idx : number) => props.markerClickListener!(props.measurement[idx]) : undefined;
    return (
        <div className="d-flex flex-column flex-grow-1">
            {!mediaUrl ? <LoaderSmall/> : <MeasurementsWithPhoto key={`ph-${retry}`}  url={mediaUrl} markers={markers}
                                                                 markerClickListener={handleClick}
                                                                 retryClickListener={() => setRetry(retry + 1)} isFullScreen={props.isFullScreen}
                                                                 imageLoadListener={props.imageLoadListener}/>}
        </div>
    );
}