import {useState} from "react";
import {Circle, Group, Path} from "react-konva";

const FILL_COLOR = "#212121";
const SIZE = 20;

interface Props {
    fullWidth: number;
    fullHeight: number;
    clickListener: () => void;
}

export function AwpFullScreenCloseButton(props: Props) {
    const [isHovered, setHovered] = useState(false);
    const onMouseEnter = (e: any) => {
        const container = e.target.getStage().container();
        container.style.cursor = "pointer";
        setHovered(true);
    };
    const onMouseLeave = (e: any) => {
        const container = e.target.getStage().container();
        container.style.cursor = "default";
        setHovered(false);
    };
    return (
        <Group onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onTouchStart={() => setHovered(true)}
               onTouchEnd={() => setHovered(false)} onClick={props.clickListener} onTap={props.clickListener}
               opacity={isHovered ? 1 : 0.7}>
            <Circle x={props.fullWidth - 1.5 * SIZE} y={1.5 * SIZE} radius={SIZE}
                    strokeEnabled={false} fill={FILL_COLOR}/>
            <Path x={props.fullWidth - 1.5 * SIZE - 12} y={1.5 * SIZE - 12} data='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' stroke='white' fill='white'/>
        </Group>
    );

}