import jsPDF from "jspdf";
import {AwpTudRecord} from "../models/AwpTudRecord";
import {getImageDataFromUrl} from "./ImageData";
import {PdfCreationError} from "../errors/PdfErrors";
import {TFunction} from "i18next";
import {
    createAwpIpsmRecordPdf,
    createAwpMfRecordPdf,
    createAwpTpRecordPdf,
    createAwpTudKrcRecordPdf,
    createAwpUd2301RecordPdf,
    createAwpUd2303RecordPdf,
    createAwpUd3701RecordPdf,
    createAwpUt2aRecordPdf,
    createAwpUt3EmaRecordPdf,
    createAwpUtRecordPdf
} from "./AwpPdfDrawer";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA,
    AwpRecord
} from "../helpers/AwpHelper";
import {AwpTpRecord} from "../models/AwpTpRecord";
import {AwpUtRecord} from "../models/AwpUtRecord";
import {AwpMfRecord} from "../models/AwpMfRecord";
import {AwpUd2301Record} from "../models/AwpUd2301Record";
import {AwpUd2303Record} from "../models/AwpUd2303Record";
import {AwpUd3701Record} from "../models/AwpUd3701Record";
import {AwpUt3EmaRecord} from "../models/AwpUt3EmaRecord";
import {AwpUt2aRecord} from "../models/AwpUt2aRecord";
import {AwpIpsmRecord} from "../models/AwpIpsmRecord";

export function createAwpPdf(deviceType: number, t: TFunction<"translation">, locale: string, logo: string, record: AwpRecord) : Promise<jsPDF>{
    switch (deviceType){
        case AWP_DEVICE_TUD:
        case AWP_DEVICE_KRC:
            return createAwpTudKrcPdf(t, locale, logo, deviceType, record as AwpTudRecord);
        case AWP_DEVICE_TP:
            return createAwpTpPdf(t, locale, logo, deviceType, record as AwpTpRecord);
        case AWP_DEVICE_UT:
            return createAwpUtPdf(t, locale, logo, deviceType, record as AwpUtRecord);
        case AWP_DEVICE_MF:
            return createAwpMfPdf(t, locale, logo, deviceType, record as AwpMfRecord);
        case AWP_DEVICE_UD2301:
            return createAwpUd2301Pdf(t, locale, logo, deviceType, record as AwpUd2301Record);
        case AWP_DEVICE_UD2303:
            return createAwpUd2303Pdf(t, locale, logo, deviceType, record as AwpUd2303Record);
        case AWP_DEVICE_UD3701:
            return createAwpUd3701Pdf(t, locale, logo, deviceType, record as AwpUd3701Record);
        case AWP_DEVICE_UT3EMA:
            return createAwpUt3EmaPdf(t, locale, logo, deviceType, record as AwpUt3EmaRecord);
        case AWP_DEVICE_UT2A:
            return createAwpUt2aPdf(t, locale, logo, deviceType, record as AwpUt2aRecord);
        case AWP_DEVICE_IPSM:
            return createAwpIpsmPdf(t, locale, logo, deviceType, record as AwpIpsmRecord);
    }
    throw new Error("Unknown device type");
}

function createAwpTudKrcPdf(t: TFunction<"translation">, locale: string, logo: string, deviceType: number, record: AwpTudRecord) : Promise<jsPDF>{
    return getImageDataFromUrl(logo).then(logoBitmap => {
        let promise: Promise<jsPDF> | undefined = undefined;
        return (record.photo !== undefined ? getImageDataFromUrl(record.photo, false, true) : Promise.resolve(undefined)).then(measurementBitmap => {
            if (logoBitmap) {
                promise = createAwpTudKrcRecordPdf(t, locale, logoBitmap, deviceType, record.name, record.operator, record.object, record.conclusion, record.deviceSerial, record.probeType, record.probeSerial, record.date, measurementBitmap, record.series);
            }
            if (promise) {
                return promise;
            }
            throw new PdfCreationError();
        })
    });
}

function createAwpTpPdf(t: TFunction<"translation">, locale: string, logo: string, deviceType: number, record: AwpTpRecord) : Promise<jsPDF>{
    return getImageDataFromUrl(logo).then(logoBitmap => {
        let promise: Promise<jsPDF> | undefined = undefined;
        return (record.photo !== undefined ? getImageDataFromUrl(record.photo, false, true) : Promise.resolve(undefined)).then(measurementBitmap => {
            if (logoBitmap) {
                promise = createAwpTpRecordPdf(t, locale, logoBitmap, deviceType, record.name, record.operator, record.object, record.conclusion, record.deviceSerial, record.probeName, record.probeSerial, record.date, measurementBitmap, record.series);
            }
            if (promise) {
                return promise;
            }
            throw new PdfCreationError();
        })
    });
}

function createAwpUtPdf(t: TFunction<"translation">, locale: string, logo: string, deviceType: number, record: AwpUtRecord) : Promise<jsPDF>{
    return getImageDataFromUrl(logo).then(logoBitmap => {
        let promise: Promise<jsPDF> | undefined = undefined;
        return (record.photo !== undefined ? getImageDataFromUrl(record.photo, false, true) : Promise.resolve(undefined)).then(measurementBitmap => {
            if (logoBitmap) {
                promise = createAwpUtRecordPdf(t, locale, logoBitmap, deviceType, record.name, record.operator, record.object, record.conclusion, record.deviceSerial, record.probeType, record.probeName, record.date, measurementBitmap, record.series);
            }
            if (promise) {
                return promise;
            }
            throw new PdfCreationError();
        })
    });
}

function createAwpMfPdf(t: TFunction<"translation">, locale: string, logo: string, deviceType: number, record: AwpMfRecord) : Promise<jsPDF>{
    return getImageDataFromUrl(logo).then(logoBitmap => {
        let promise: Promise<jsPDF> | undefined = undefined;
        return (record.photo !== undefined ? getImageDataFromUrl(record.photo, false, true) : Promise.resolve(undefined)).then(measurementBitmap => {
            if (logoBitmap) {
                promise = createAwpMfRecordPdf(t, locale, logoBitmap, deviceType, record.name, record.operator, record.object, record.conclusion, record.deviceSerial, record.probeName, record.probeType, record.probeSerial, record.date, measurementBitmap, record.series);
            }
            if (promise) {
                return promise;
            }
            throw new PdfCreationError();
        })
    });
}

function createAwpUd2301Pdf(t: TFunction<"translation">, locale: string, logo: string, deviceType: number, record: AwpUd2301Record) : Promise<jsPDF>{
    return getImageDataFromUrl(logo).then(logoBitmap => {
        let promise: Promise<jsPDF> | undefined = undefined;
        return (record.photo !== undefined ? getImageDataFromUrl(record.photo, false, false) : Promise.resolve(undefined)).then(measurementBitmap => {
            if (logoBitmap) {
                promise = createAwpUd2301RecordPdf(t, locale, logoBitmap, deviceType, record.name, record.operator, record.object, record.conclusion, record.deviceSerial, record.date, measurementBitmap, record.series);
            }
            if (promise) {
                return promise;
            }
            throw new PdfCreationError();
        })
    });
}

function createAwpUd2303Pdf(t: TFunction<"translation">, locale: string, logo: string, deviceType: number, record: AwpUd2303Record) : Promise<jsPDF>{
    return getImageDataFromUrl(logo).then(logoBitmap => {
        let promise: Promise<jsPDF> | undefined = undefined;
        return (record.photo !== undefined ? getImageDataFromUrl(record.photo, false, false) : Promise.resolve(undefined)).then(measurementBitmap => {
            if (logoBitmap) {
                promise = createAwpUd2303RecordPdf(t, locale, logoBitmap, deviceType, record.name, record.operator, record.object, record.conclusion, record.deviceSerial, record.date, measurementBitmap, record.series);
            }
            if (promise) {
                return promise;
            }
            throw new PdfCreationError();
        })
    });
}

function createAwpUd3701Pdf(t: TFunction<"translation">, locale: string, logo: string, deviceType: number, record: AwpUd3701Record) : Promise<jsPDF>{
    return getImageDataFromUrl(logo).then(logoBitmap => {
        let promise: Promise<jsPDF> | undefined = undefined;
        return (record.photo !== undefined ? getImageDataFromUrl(record.photo, false, false) : Promise.resolve(undefined)).then(measurementBitmap => {
            if (logoBitmap) {
                promise = createAwpUd3701RecordPdf(t, locale, logoBitmap, deviceType, record.name, record.operator, record.object, record.conclusion, record.deviceSerial, record.date, measurementBitmap, record.series);
            }
            if (promise) {
                return promise;
            }
            throw new PdfCreationError();
        })
    });
}

function createAwpUt3EmaPdf(t: TFunction<"translation">, locale: string, logo: string, deviceType: number, record: AwpUt3EmaRecord) : Promise<jsPDF>{
    return getImageDataFromUrl(logo).then(logoBitmap => {
        let promise: Promise<jsPDF> | undefined = undefined;
        return (record.photo !== undefined ? getImageDataFromUrl(record.photo, false, false) : Promise.resolve(undefined)).then(measurementBitmap => {
            if (logoBitmap) {
                promise = createAwpUt3EmaRecordPdf(t, locale, logoBitmap, deviceType, record.name, record.operator, record.object, record.conclusion, record.deviceSerial, record.date, measurementBitmap, record.series);
            }
            if (promise) {
                return promise;
            }
            throw new PdfCreationError();
        })
    });
}

function createAwpUt2aPdf(t: TFunction<"translation">, locale: string, logo: string, deviceType: number, record: AwpUt2aRecord) : Promise<jsPDF>{
    return getImageDataFromUrl(logo).then(logoBitmap => {
        let promise: Promise<jsPDF> | undefined = undefined;
        return (record.photo !== undefined ? getImageDataFromUrl(record.photo, false, false) : Promise.resolve(undefined)).then(measurementBitmap => {
            if (logoBitmap) {
                promise = createAwpUt2aRecordPdf(t, locale, logoBitmap, deviceType, record.name, record.operator, record.object, record.conclusion, record.deviceSerial, record.date, measurementBitmap, record.series);
            }
            if (promise) {
                return promise;
            }
            throw new PdfCreationError();
        })
    });
}

function createAwpIpsmPdf(t: TFunction<"translation">, locale: string, logo: string, deviceType: number, record: AwpIpsmRecord) : Promise<jsPDF>{
    return getImageDataFromUrl(logo).then(logoBitmap => {
        let promise: Promise<jsPDF> | undefined = undefined;
        return (record.photo !== undefined ? getImageDataFromUrl(record.photo, false, true) : Promise.resolve(undefined)).then(measurementBitmap => {
            if (logoBitmap) {
                promise = createAwpIpsmRecordPdf(t, locale, logoBitmap, deviceType, record.name, record.operator, record.object, record.conclusion, record.deviceSerial, record.date, measurementBitmap, record.series);
            }
            if (promise) {
                return promise;
            }
            throw new PdfCreationError();
        })
    });
}