import './AwpFwExpertUpdate.css';
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";

interface Props{
    onContinue: () => void;
}

export function AwpNvpUploadInstruction(props: Props){
    const {t} = useTranslation();
    const onContinue = () => {
        props.onContinue();
    };
    return (
        <div
            className="container-grow d-flex flex-column m-4 justify-content-center align-items-center fw-instruction-text">
            <div className="my-2">{t("awp_fw_upload_instruction")}</div>
            <Button variant="primary" className="my-2"
                    onClick={onContinue}>{t("continue")}</Button>
        </div>
    );
}