import {useTranslation} from "react-i18next";
import {AwpSeries} from "../../helpers/AwpHelper";
import {formatNumber} from "../../helpers/FormatHelper";
import './AwpSeriesInfo.css';
import {AwpUd2303Series} from "../../models/AwpUd2303Series";
import {getUd2303StrobeInfo} from "../../helpers/AwpUd2303FormatHelper";

interface Props {
    data: AwpSeries;
}

export function AwpUd2303StrobeInfo(props: Props) {
    const {t} = useTranslation();
    const data = props.data as AwpUd2303Series;
    const strobeInfo = getUd2303StrobeInfo(t, data);
    let strobeAColor = "#0f0";
    let strobeBColor = "#00f";
    if (data.systemSet.pallete === 1){
        strobeAColor = "#f00";
        strobeBColor = "#0f0";
    }
    return (
        <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-stretch">
            <table className="table-data">
                <thead>
                <tr>
                    <th/>
                    <th>{t('awp_gate_a')}</th>
                    <th>{t('awp_gate_b')}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{t('awp_start_mm')}</td>
                    <td>{strobeInfo.startMm1}</td>
                    <td>{strobeInfo.startMm2}</td>
                </tr>
                <tr>
                    <td>{t('awp_start_inch')}</td>
                    <td>{strobeInfo.startInch1}</td>
                    <td>{strobeInfo.startInch2}</td>
                </tr>
                <tr>
                    <td>{t('awp_width_mm')}</td>
                    <td>{strobeInfo.widthMm1}</td>
                    <td>{strobeInfo.widthMm2}</td>
                </tr>
                <tr>
                    <td>{t('awp_width_inch')}</td>
                    <td>{strobeInfo.widthInch1}</td>
                    <td>{strobeInfo.widthInch2}</td>
                </tr>
                <tr>
                    <td>{t('awp_level')}</td>
                    <td>{strobeInfo.level1}</td>
                    <td>{strobeInfo.level2}</td>
                </tr>
                <tr>
                    <td>{t('awp_control_db')}</td>
                    <td>{strobeInfo.control1}</td>
                    <td>{strobeInfo.control2}</td>
                </tr>
                <tr>
                    <td>{t('awp_search_db')}</td>
                    <td>{strobeInfo.search1}</td>
                    <td>{strobeInfo.search2}</td>
                </tr>
                <tr>
                    <td>{t('awp_mode')}</td>
                    <td>{strobeInfo.mode1}</td>
                    <td>{strobeInfo.mode2}</td>
                </tr>
                <tr>
                    <td>{t('awp_color')}</td>
                    <td className="h-100"><div style={{width: "80%", height: "16px", background:strobeAColor, margin: "auto"}}/></td>
                    <td className="h-100"><div style={{width: "80%", height: "16px", background:strobeBColor, margin: "auto"}}/></td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}