import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import {useFileSystem} from "../../filesystem/FileSystemHook";
import {AWP_DEVICE_IPSM, getAwpFsHandle} from "../../helpers/AwpHelper";
import {useUpdateToken} from "../../hooks/UpdateTokenHook";
import './AwpManager.css';
import {Button, Toast, ToastBody, ToastContainer} from "react-bootstrap";
import {Loader} from "../Loader/Loader";
import {Error} from "../Error/Error";
import {useAwpIpsmSerialDevice} from "../../serial/SerialPortHook";
import {TOAST_DELAY} from "../../AppSettings";
import {ToastData} from "../../models/ToastData";
import {ModalSingleButton} from "../ModalSingleButton/ModalSingleButton";
import {buildAwpIpsmListPath} from "../../routes";
import {AwpIpsmSeries} from "../../models/AwpIpsmSeries";
import {AwpIpsmSeriesPreview} from "./AwpIpsmSeriesPreview";
import {createAwpCsv} from "../../csv/CsvHelper";
import {createAwpPdf} from "../../pdf/AwpPdfHelper";
import logoPng from "../AppBar/logo_main.png";
import {useLocale} from "../../hooks/LocaleHook";
import {AwpIpsmRecord, writeAwpIpsmRecord} from "../../models/AwpIpsmRecord";
import {logError} from "../../helpers/LogHelper";

export function AwpIpsmDataTransfer() {
    const {t} = useTranslation();
    const locale = useLocale();
    const history = useNavigate();
    useEffect(() => {
        document.querySelector('body')?.classList?.add('body-awp');
        return () => {
            document.querySelector('body')?.classList?.remove('body-awp');
        }
    });
    const {path} = useParams();
    const folders = (path && path !== "@") ? path.split("@") : new Array<string>();
    const [updateToken, refreshToken] = useUpdateToken();
    const fsHandle = useFileSystem(getAwpFsHandle(AWP_DEVICE_IPSM), folders, updateToken);
    const device = useAwpIpsmSerialDevice();
    const [isReading, setReading] = useState(false);
    const [toastData, setToastData] = useState(null as ToastData | null);
    const [showReadingFailedModal, setShowReadingFailedModal] = useState(false);
    const [series, setSeries] = useState(undefined as AwpIpsmSeries[] | undefined);
    const startReading = () => {
        device.readRecord(setReading)
            .then((data) => {
                if (data) {
                    setSeries(data);
                    setToastData({
                        message: t('reading_success'),
                        isSuccess: true,
                        delay: TOAST_DELAY
                    });
                }
                return;
            })
            .catch((reason) => {
                setShowReadingFailedModal(true);
                logError("Data read error", reason);
                return;
            });
    };
    const cancelReading = () => {
        device.abort();
    }
    const makeCsv = (record : AwpIpsmRecord) => {
        createAwpCsv(AWP_DEVICE_IPSM, record.name, record);
    };
    const makePdf = (record : AwpIpsmRecord) => {
        createAwpPdf(AWP_DEVICE_IPSM, t, locale, logoPng, record).then(jsPDF => jsPDF.save(`${record.name ?? "report"}.pdf`))
            .catch(() => setToastData({
                message: t('pdf_save_failed'),
                isSuccess: false,
                delay: TOAST_DELAY
            }));
    };
    const save = (record : AwpIpsmRecord) => {
        if (fsHandle?.handle) {
            writeAwpIpsmRecord(fsHandle.handle, record).then(isSuccess => {
                if (isSuccess){
                    goBack();
                } else {
                    setToastData({
                        message: t('record_save_failed'),
                        isSuccess: false,
                        delay: TOAST_DELAY
                    });
                }
            });
        }
    };
    const goBack = () => {
        history(buildAwpIpsmListPath(folders), {replace: true});
    };
    const keyDownListener = (ev: KeyboardEvent) => {
        if (ev.key === 'Escape' || ev.key === 'Esc') {
            goBack();
            return false;
        }
        return true;
    };
    useEffect(() => {
        document.addEventListener("keydown", keyDownListener);
        return () => {
            document.removeEventListener("keydown", keyDownListener);
        }
    });
    return (
        <div className="container-grow d-flex flex-column m-4">
            <div className="container-grow align-self-stretch">
                {fsHandle === undefined && <Loader/>}
                {(fsHandle !== undefined && !fsHandle.isGranted) &&
                    <Error errorMessage={t('fs_permission_missing')} retryText={t('grant_permission')}
                           retryClickHandler={refreshToken}/>}
                {(fsHandle !== undefined && fsHandle.isGranted && !fsHandle.isExist) &&
                    <Error errorMessage={t('fs_archive_missing')} retryText={t('select_archive_location')}
                           retryClickHandler={folders.length === 0 ? refreshToken : undefined}/>}
                {(fsHandle !== undefined && fsHandle.isGranted && fsHandle.isExist) &&
                    <Fragment>
                        {isReading &&
                            <div
                                className="container-grow d-flex flex-column m-4 justify-content-center align-items-center instruction-text">
                                <div className="my-2">{t("awp_ipsm_reading_instruction")}</div>
                                <Loader/>
                                <Button variant="primary" className="my-2"
                                        onClick={cancelReading}>{t("cancel")}</Button>
                            </div>
                        }
                        {!isReading && (!series || series.length === 0) &&
                            <div
                                className="container-grow d-flex flex-column m-4 justify-content-center align-items-center instruction-text">
                                <div className="my-2" dangerouslySetInnerHTML={{__html: t("awp_ipsm_start_instruction")}}/>
                                <div className="my-2" dangerouslySetInnerHTML={{__html: t("awp_ipsm_go_back_instruction")}}/>
                                <Button variant="primary" className="my-2"
                                        onClick={startReading}>{t("awp_ipsm_start")}</Button>
                                <Button variant="primary" className="my-2"
                                        onClick={goBack}>{t("cancel")}</Button>
                            </div>
                        }
                        {!isReading && (series && series.length > 0) &&
                            <AwpIpsmSeriesPreview series={series} onClose={goBack} onMakeCsv={makeCsv} onMakePdf={makePdf} onSave={save}/>
                        }
                    </Fragment>
                }
            </div>
            <ToastContainer position={"bottom-end"} className="mb-4 me-4 position-fixed">
                <Toast onClose={() => setToastData(null)} show={toastData !== null}
                       bg={toastData?.isSuccess !== undefined ? (toastData.isSuccess ? 'success' : 'danger') : 'primary'}
                       delay={toastData?.delay ?? 0} autohide={toastData?.delay !== undefined}>
                    <ToastBody>{toastData?.message}</ToastBody>
                </Toast>
            </ToastContainer>
            <ModalSingleButton show={showReadingFailedModal} title={t("reading_fail")} body={t("reading_fail_message")}
                               buttonText={t("ok")}
                               closeHandler={() => setShowReadingFailedModal(false)}/>
        </div>
    );
}