export interface FetchDataWrapper<T> {
    isLoading : boolean;
    hasError: boolean;
    data?: T;
}

export class FetchData{

    static init<T>() : FetchDataWrapper<T>{
        return {
            isLoading: false,
            hasError: false
        };
    }

    static loading<T>() : FetchDataWrapper<T>{
        return {
            isLoading: true,
            hasError: false
        };
    }

    static error<T>(): FetchDataWrapper<T>{
        return {
            isLoading: false,
            hasError: true
        };
    }

    static value<T>(v : T) : FetchDataWrapper<T>{
        return {
            isLoading: false,
            hasError: false,
            data: v
        };
    }
}