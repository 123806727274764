import {AwpDirectoryHandle} from "./AwpDirectoryHandle";
import {AwpFileHandle} from "./AwpFileHandle";
import {AwpLocalFileHandle} from "./AwpLocalFileHandle";

export class AwpLocalDirectoryHandle implements AwpDirectoryHandle{

    private readonly handle : FileSystemDirectoryHandle | undefined;
    private readonly rootHandleName : string | undefined;

    constructor(handle : FileSystemDirectoryHandle, rootHandleName? : string) {
        this.handle = handle;
        this.rootHandleName = rootHandleName;
    }

    name(): string {
        return this.rootHandleName ?? this.handle?.name ?? "";
    }

    getDirectoryHandle(recordName: string): Promise<AwpDirectoryHandle | null> {
        return new Promise<AwpDirectoryHandle | null>(async resolve => {
            const handle = await this.handle?.getDirectoryHandle(recordName).catch(() => null);
            if (handle) {
                resolve(new AwpLocalDirectoryHandle(handle));
            } else {
                resolve(null);
            }
        });
    }

    getFileHandle(name: string): Promise<AwpFileHandle | null> {
        return new Promise<AwpFileHandle | null>(async resolve => {
            const handle = await this.handle?.getFileHandle(name).catch(() => null);
            if (handle) {
                resolve(new AwpLocalFileHandle(handle));
            } else {
                resolve(null);
            }
        });
    }

    getFileSystemHandle(): FileSystemDirectoryHandle | undefined {
        return this.handle;
    }

    directories(): Promise<Array<AwpDirectoryHandle>>{
        return new Promise<Array<AwpDirectoryHandle>>(async resolve => {
            const directories = new Array<AwpDirectoryHandle>();
            if (this.handle) {
                for await (const entry of this.handle.values()) {
                    if (entry.kind === 'directory'){
                        directories.push(new AwpLocalDirectoryHandle(entry));
                    }
                }
            }
            resolve(directories);
        });
    }

    files(): Promise<Array<AwpFileHandle>>{
        return new Promise<Array<AwpFileHandle>>(async resolve => {
            const directories = new Array<AwpFileHandle>();
            if (this.handle) {
                for await (const entry of this.handle.values()) {
                    if (entry.kind === 'file'){
                        directories.push(new AwpLocalFileHandle(entry));
                    }
                }
            }
            resolve(directories);
        });
    }
    




}