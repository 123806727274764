import {API_KEY} from "../../AppSettings";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import './GeoLocationViewer.css';

interface Props {
    lat: number;
    lng: number;
}

export function GeoLocationViewer(props: Props) {
    return (
        <div>
            <LoadScript googleMapsApiKey={API_KEY}>
                <GoogleMap
                    mapContainerClassName="container-geolocation"
                    options={{
                        streetViewControl: false
                    }}
                    center={{
                        lat: props.lat,
                        lng: props.lng
                    }}
                    zoom={14}>
                    <Marker position={{
                        lat: props.lat,
                        lng: props.lng
                    }}/>
                </GoogleMap>
            </LoadScript>
        </div>
    );
}