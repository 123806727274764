import {TFunction} from "i18next";
import {DATA_PLACEHOLDER} from "../AppSettings";
import {AwpUd2303Series} from "../models/AwpUd2303Series";
import {formatNumber} from "./FormatHelper";

export interface Ud2303DeviceInfo{
    gain : string;
    delayMm : string;
    delayInch : string;
    scanMm : string;
    scanInch : string;
    detector:  string;
    middle:  string;
    filter : string;
    tvg : string;
    velocity : string;
}

export interface Ud2303Result{
    tA: string;
    tB: string;
    tAB: string;
    aA: string;
    aB: string;
    aAB: string;
    xbA: string;
    xbB: string;
    xipA: string;
    xipB: string;
    yA: string;
    yB: string;
    yAB: string;
    sA: string;
    sB: string;
    lA: string;
    lB: string;
    lAB: string;
    daA: string;
    daB: string;
    daAB: string;
}

export interface Ud2303StrobeInfo{
    startMm1 : string;
    startMm2 : string;
    startInch1 : string;
    startInch2 : string;
    widthMm1 : string;
    widthMm2 : string;
    widthInch1 : string;
    widthInch2 : string;
    level1 : string;
    level2 : string;
    control1 : string;
    control2: string;
    search1: string;
    search2: string;
    mode1: string;
    mode2: string;
}

export interface Ud2303TransducerInfo{
    freq: string;
    angle : string;
    delayUs : string;
    vector: string;
}

export function getUd2303DeviceInfo(t: TFunction<"translation">, data : AwpUd2303Series) : Ud2303DeviceInfo{
    const k = 253.99986284007406636000416559775;
    return {
        gain : formatNumber(data.adc.gain / 10, 1, 1),
        delayMm : formatNumber(data.adc.mmDelay / 10, 1, 1),
        delayInch : formatNumber(data.adc.mmDelay / k, 1, 1),
        scanMm : formatNumber(data.adc.mmScan / 10, 1, 1),
        scanInch : formatNumber(data.adc.mmScan / k, 1, 1),
        detector:  formatAwpUd2303DetectorMode(t, data.adc.rfHw),
        middle:  data.screen.midle !== 0 ? formatNumber(Math.pow(2, data.screen.midle), 0, 0) : t('awp_off'),
        filter : data.adc.filterOnOff === 0 ? t("awp_off") : formatNumber(data.adc.filterFreq, 2),
        tvg : data.adc.tvgOnOff === 0 ? t("awp_off") : t("awp_on"),
        velocity : formatNumber(data.adc.speed, 0, 0)
    }
}

export function getUd2303DResult(t: TFunction<"translation">, data : AwpUd2303Series) : Ud2303Result{
    const tA = data.asd.t[0] / 200;
    const tB = data.asd.t[1] / 200;
    const tAB = Math.abs((data.asd.t[0] - data.asd.t[1]) / 200);
    const aA = data.asd.adB[0];
    const aB = data.asd.adB[1];
    const aAB = data.asd.adB[0] - data.asd.adB[1];
    const xbA = data.asd.x[0];
    const xbB = data.asd.x[1];
    const xipA = data.asd.xip[0];
    const xipB = data.asd.xip[1];
    const yA = data.asd.y[0];
    const yB = data.asd.y[1];
    const yAB = data.asd.yab;
    const sA = data.asd.s[0];
    const sB = data.asd.s[1];
    const lA = ((data.asd.t[0] / 200 - data.probe.delayUs / 100) * data.adc.speed) / 2000;
    const lB = ((data.asd.t[1] / 200 - data.probe.delayUs / 100) * data.adc.speed) / 2000;
    const lAB = lA - lB;
    const daA = data.asd.dAdB[0];
    const daB = data.asd.dAdB[1];
    const daAB = daA - daB;
    return {
        tA: data.asd.flag[0] !== 0 ? formatNumber(tA, 2, 2) : DATA_PLACEHOLDER,
        tB: data.asd.flag[1] !== 0 ? formatNumber(tB, 2, 2) : DATA_PLACEHOLDER,
        tAB: data.asd.flag[0] !== 0 && data.asd.flag[1] !== 0 ? formatNumber(tAB, 2, 2) : DATA_PLACEHOLDER,
        aA: data.asd.flag[0] !== 0 ? formatNumber(aA, 1, 1) : DATA_PLACEHOLDER,
        aB: data.asd.flag[1] !== 0 ? formatNumber(aB, 1, 1) : DATA_PLACEHOLDER,
        aAB: data.asd.flag[0] !== 0 && data.asd.flag[1] !== 0 ? formatNumber(aAB, 2, 2) : DATA_PLACEHOLDER,
        xbA: data.asd.flag[0] !== 0 ? formatNumber(xbA, 1, 1) : DATA_PLACEHOLDER,
        xbB: data.asd.flag[1] !== 0 ? formatNumber(xbB, 1, 1) : DATA_PLACEHOLDER,
        xipA: data.asd.flag[0] !== 0 ? formatNumber(xipA, 1, 1) : DATA_PLACEHOLDER,
        xipB: data.asd.flag[1] !== 0 ? formatNumber(xipB, 1, 1) : DATA_PLACEHOLDER,
        yA: data.asd.flag[0] !== 0 ? formatNumber(yA, 1, 1) : DATA_PLACEHOLDER,
        yB: data.asd.flag[1] !== 0 ? formatNumber(yB, 1, 1) : DATA_PLACEHOLDER,
        yAB: data.asd.flag[0] !== 0 && data.asd.flag[1] !== 0 ? formatNumber(yAB, 2, 2) : DATA_PLACEHOLDER,
        sA: data.asd.flag[0] !== 0 ? formatNumber(sA, 1, 1) : DATA_PLACEHOLDER,
        sB: data.asd.flag[1] !== 0 ? formatNumber(sB, 1, 1) : DATA_PLACEHOLDER,
        lA: data.asd.flag[0] !== 0 ? formatNumber(lA, 1, 1) : DATA_PLACEHOLDER,
        lB: data.asd.flag[1] !== 0 ? formatNumber(lB, 1, 1) : DATA_PLACEHOLDER,
        lAB: data.asd.flag[0] !== 0 && data.asd.flag[1] !== 0 ? formatNumber(lAB, 2, 2) : DATA_PLACEHOLDER,
        daA: data.asd.flag[0] !== 0 ? formatNumber(daA, 1, 1) : DATA_PLACEHOLDER,
        daB: data.asd.flag[1] !== 0 ? formatNumber(daB, 1, 1) : DATA_PLACEHOLDER,
        daAB: data.asd.flag[0] !== 0 && data.asd.flag[1] !== 0 ? formatNumber(daAB, 2, 2) : DATA_PLACEHOLDER
    }
}

export function getUd2303StrobeInfo(t: TFunction<"translation">, data : AwpUd2303Series) : Ud2303StrobeInfo{
    const k = 253.99986284007406636000416559775;
    return {
        startMm1 : formatNumber(data.strobe.x[0] / 10, 1, 1),
        startMm2 : formatNumber(data.strobe.x[1] / 10, 1, 1),
        startInch1 : formatNumber(data.strobe.x[0] / k, 1, 1),
        startInch2 : formatNumber(data.strobe.x[1] / k, 1, 1),
        widthMm1 : formatNumber(data.strobe.len[0] / 10, 1, 1),
        widthMm2 : formatNumber(data.strobe.len[1] / 10, 1, 1),
        widthInch1 : formatNumber(data.strobe.len[0] / k, 1, 1),
        widthInch2 : formatNumber(data.strobe.len[1] / k, 1, 1),
        level1 : formatNumber(data.strobe.y[0], 1, 1),
        level2 : formatNumber(data.strobe.y[1], 1, 1),
        control1 : formatNumber(data.strobe.yContr[0] / 10, 1, 1),
        control2: formatNumber(data.strobe.yContr[1] / 10, 1, 1),
        search1: formatNumber(data.strobe.ySearch[0] / 10, 1, 1),
        search2: formatNumber(data.strobe.ySearch[1] / 10, 1, 1),
        mode1: data.strobe.frontPic[0] === 0 ? t('awp_flank') : t('awp_peak'),
        mode2: data.strobe.frontPic[1] === 0 ? t('awp_flank') : t('awp_peak')
    }
}

export function getUd2303TransducerInfo(t: TFunction<"translation">, data : AwpUd2303Series) : Ud2303TransducerInfo{
    return {
        freq: formatNumber(data.probe.freq / 100, 1, 1),
        angle : formatNumber(data.probe.angle / 10, 1, 1),
        delayUs : formatNumber(data.probe.delayUs / 100, 1, 1),
        vector: formatNumber(data.probe.vector / 10, 1, 1)
    };
}

export function formatAwpUd2303ProbeType(t: TFunction<"translation">, type?: number){
    switch (type){
        case 0:
            return t('awp_probe_p111');
        case 1:
            return t('awp_probe_p112');
        case 2:
            return t('awp_probe_p121');
        case 3:
            return t('awp_probe_p122');
        default:
            return DATA_PLACEHOLDER;
    }
}

export function formatAwpUd2303DetectorMode(t: TFunction<"translation">, type: number){
    switch (type){
        case 0:
            return t("awp_radio");
        case 1:
            return t("awp_module_1");
        case 2:
            return t("awp_module_2");
        default:
            return "";
    }
}