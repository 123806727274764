import './ExecutorDetailsDialog.css';
import {ExecutorData} from "../../models/ExecutorData";
import {Button, Form, Modal} from "react-bootstrap";
import IconCopySvg from "../Icons/IconCopySvg";
import {useTranslation} from "react-i18next";
import {formatDateTime, formatDeviceName} from "../../helpers/FormatHelper";
import {useLocale} from "../../hooks/LocaleHook";
import {useMemo} from "react";

interface Props {
    executor?: ExecutorData;
    dataCopyHandler: (data?: string) => void;
    closeHandler: () => void;
}

export function ExecutorDetailsDialog(props: Props) {
    const {t} = useTranslation();
    const locale = useLocale();
    const copyPhone = () => {
        props.dataCopyHandler(props.executor?.phone);
    };
    const copyEmail = () => {
        props.dataCopyHandler(props.executor?.email);
    };
    const devices = useMemo(() => {
        const arr = new Array<string>();
        if (props.executor?.devices){
            props.executor.devices.forEach((v, k) => {
                if (v){
                    arr.push(k);
                }
            });
        }
        return arr;
    }, [props.executor]);
    const showModal = props.executor !== undefined;
    return (
        <Modal show={showModal} size="lg" centered onHide={props.closeHandler}>
            <Modal.Header closeButton>
                <div className="d-flex flex-column align-items-stretch">
                    <div className="executor-details-name">{props.executor?.name}</div>
                    <div className="executor-details-timestamp">{`${t("executors_last_update")}: ${formatDateTime(locale, new Date(props.executor?.timestamp ?? 0))}`}</div>
                </div>
            </Modal.Header>
            <Modal.Body className="executor-details">
                <div className="d-flex flex-column align-items-stretch my-2">
                    <div className="title">{t("executors_phone")}:</div>
                    <div className="d-flex align-self-stretch flex-row justify-content-between align-items-center my-1">
                        <Form.Control type="text" disabled className="flex-grow" defaultValue={props.executor?.phone}/>
                        <Button className="ms-4" variant="outline-primary"
                                onClick={copyPhone}><IconCopySvg/></Button>
                    </div>
                    {props.executor?.email &&
                    <div className="title mt-2">{t("executors_email")}:</div>
                    }
                    {props.executor?.email &&
                    <div className="d-flex align-self-stretch flex-row justify-content-between align-items-center my-1">
                        <Form.Control type="text" disabled className="flex-grow" defaultValue={props.executor?.email}/>
                        <Button className="ms-4" variant="outline-primary"
                                onClick={copyEmail}><IconCopySvg/></Button>
                    </div>
                    }
                    <div className="title mt-2">{t("executors_available_devices")}:</div>
                    {devices.map(d => <div key={`d-${d}`}>{`- ${formatDeviceName(t, d)}`}</div>)}
                    {props.executor?.comments &&
                    <div className="title mt-2">{t("executors_about_me")}:</div>
                    }
                    {props.executor?.comments &&
                    <div>{props.executor?.comments}</div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}