import {jsPDF} from "jspdf";
import {Rect} from "./Rect";
import {ImageData} from "./ImageData";
import {TFunction} from "i18next";
import {roboto} from "./RobotoFont";
import {robotoBold} from "./RobotoBoldFont";
import {formatNumber} from "../helpers/FormatHelper";
import {buildLineChart} from "./PdfLineChart";
import {buildBarChart} from "./PdfBarChart";
import {LineChartData} from "../components/MeasurementDetails/MeasurementsLineChart";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA,
    AwpSeries,
    formatAwpValue
} from "../helpers/AwpHelper";
import {
    getAwpAreaChartName,
    getAwpLineChartName,
    getAwpMeasurementError,
    prepareAwpAreaChartData,
    prepareAwpBarChartData,
    prepareAwpLineChartData
} from "../helpers/AwpChartHelper";
import {prepareAwpSeriesInfoData} from "../components/AwpSeriesInfo/AwpMeasurementSeriesInfo";
import {buildAreaChart} from "./PdfAreaChart";
import {AwpUtSeries} from "../models/AwpUtSeries";
import {formatAwpUtScaleName} from "../helpers/AwpUtFormatHelper";
import {AwpMfSeries} from "../models/AwpMfSeries";
import {formatAwpMfScaleName, formatAwpMfValue} from "../helpers/AwpMfFormatHelper";
import {AwpUd2301Series} from "../models/AwpUd2301Series";
import {AwpUd2303Series} from "../models/AwpUd2303Series";
import {AwpUd3701Series} from "../models/AwpUd3701Series";
import {AwpUt3EmaSeries} from "../models/AwpUt3EmaSeries";
import {DATA_PLACEHOLDER} from "../AppSettings";
import {AreaChartData} from "../components/MeasurementDetails/MeasurementsAreaChart";
import {AwpUt2aSeries} from "../models/AwpUt2aSeries";
import {AwpIpsmSeries} from "../models/AwpIpsmSeries";
import {formatAwpIpsmScaleName, formatAwpIpsmValue} from "../helpers/AwpIpsmFormatHelper";
import {formatUt3EmaProbeInfo, getUt3EmaDeviceInfo, getUt3EmaTransducerInfo} from "../helpers/AwpUt3EmaFormatHelper";
import {formatAwpUt2AProbeInfo, getUt2ADeviceInfo, getUt2ATransducerInfo} from "../helpers/AwpUt2AFormatHelper";
import {
    formatAwpUd2301DetectorMode,
    getUd2301DeviceInfo,
    getUd2301Result,
    getUd2301StrobeInfo, getUd2301TransducerInfo
} from "../helpers/AwpUd2301FormatHelper";
import {
    formatAwpUd3701DetectorMode,
    getUd3701DeviceInfo,
    getUd3701Result,
    getUd3701StrobeInfo, getUd3701TransducerInfo
} from "../helpers/AwpUd3701FormatHelper";
import {
    formatAwpUd2303DetectorMode,
    getUd2303DeviceInfo,
    getUd2303DResult,
    getUd2303StrobeInfo, getUd2303TransducerInfo
} from "../helpers/AwpUd2303FormatHelper";

const PAGE_WIDTH_MM = 210;
const PAGE_HEIGHT_MM = 297;

const MARGIN_TOP_MM = 10;
const MARGIN_LEFT_MM = 20;
const MARGIN_RIGHT_MM = 10;
const MARGIN_BOTTOM_MM = 10;
const HEADER_HEIGHT_MM = 15;
const HEADER_BOTTOM_MARGIN_MM = 5;

const RECORD_INFO_MARGIN_TOP_MM = 5;

const RECORD_DATE_TIME_BLOCK_WIDTH_MM = 35;

const FOOTER_HEIGHT_MM = 10;
const FOOTER_TOP_MARGIN_MM = 5;
const DIVIDER_STROKE_WIDTH_MM = 1;

const PT_TO_MM_FACTOR = 0.35277777777778;

const TITLE_TEXT_SIZE_PT = 14;
const PRIMARY_TEXT_SIZE_PT = 11;
const SECONDARY_TEXT_SIZE_PT = 10;
const TABLE_TEXT_SIZE_PT = 10;

const CONTENT_MARGIN_MM = 3;
const TABLE_STROKE_MM = 0.4;

const LOGO_ALIAS = "logo";
const PHOTO_ALIAS = "photo";

export const FONT_FAMILY = "ROBOTO";

enum Align {
    START,
    CENTER,
    END
}

interface DrawTextTask {
    text: string;
    width: number;
}

export type Drawer = (document: jsPDF) => void;

class InfoDataBuilder {
    pages: Array<Array<Drawer>>;
    t: TFunction<"translation">;
    document: jsPDF;
    left: number;
    top: number;
    width: number;
    height: number;
    conclusion?: string;
    currentPage?: Array<Drawer>;
    y: number;

    constructor(t: TFunction<"translation">, left: number, top: number, width: number, height: number, conclusion: string | undefined) {
        this.t = t;
        this.document = new jsPDF("p", "mm", "a4");
        this.document.addFileToVFS("Roboto.ttf", roboto);
        this.document.addFont('Roboto.ttf', FONT_FAMILY, 'normal');
        this.document.addFileToVFS("RobotoBold.ttf", robotoBold);
        this.document.addFont('RobotoBold.ttf', FONT_FAMILY, 'bold');
        this.left = left;
        this.top = top;
        this.width = width;
        this.height = height;
        this.conclusion = conclusion;
        this.pages = new Array<Array<Drawer>>();
        this.y = 0;
    }

    setupPrimaryBoldText(doc: jsPDF) {
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(PRIMARY_TEXT_SIZE_PT);
        doc.setFont(FONT_FAMILY, "bold");
    }

    setupPrimaryNormalText(doc: jsPDF) {
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(PRIMARY_TEXT_SIZE_PT);
        doc.setFont(FONT_FAMILY, "normal");
    }

    setupSecondaryNormalText(doc: jsPDF) {
        doc.setTextColor(136, 136, 136);
        doc.setFontSize(SECONDARY_TEXT_SIZE_PT);
        doc.setFont(FONT_FAMILY, "normal");
    }

    build(): Array<Array<Drawer>> {
        if (this.conclusion) {
            this.closePage();
            this.setupPrimaryBoldText(this.document);
            this.y = getBaselineOffset(this.document, true);
            this.setupPrimaryBoldText(this.document)
            this.y += getBaselineOffset(this.document, true);
            this.drawText(this.setupPrimaryBoldText, this.t("conclusion"), Align.CENTER, 2);
            this.drawText(this.setupPrimaryNormalText, this.conclusion, Align.START, 1);
            this.closePage();
        }
        return this.pages;
    }

    drawText(initializer: (doc: jsPDF) => void, text: string, horizontalAlignment: Align, minLines: number) {
        initializer(this.document);
        const strings = breakText(this.document, text, this.width);
        const baselineOffset = getBaselineOffset(this.document, true);
        if (this.y + (minLines - 1) * baselineOffset > this.height) {
            this.closePage();
            this.y += baselineOffset;
        }
        for (const s of strings) {
            if (this.y >= this.height) {
                this.closePage();
                this.y += baselineOffset;
            }
            const localY = this.top + this.y;
            this.currentPage!.push(document => {
                initializer(document);
                drawText(document, s, this.left, localY, this.width, horizontalAlignment, Align.END);
            });
            this.y += baselineOffset;
        }
    }

    closePage() {
        if (this.currentPage) {
            this.pages.push(this.currentPage);
        }
        this.currentPage = new Array<Drawer>();
        this.y = 0;
    }

}

function drawBitmap(document: jsPDF, bitmap: ImageData, bounds: Rect, horizontalAlignment: Align, verticalAlignment: Align, alias?: string) {
    if (bitmap.width > 0 && bitmap.height > 0) {
        const aspectRatio = bitmap.width / bitmap.height;
        let targetWidth = bounds.width;
        let targetHeight = targetWidth / aspectRatio;
        if (targetHeight > bounds.height) {
            targetHeight = bounds.height;
            targetWidth = targetHeight * aspectRatio;
        }
        let xOffset = 0;
        switch (horizontalAlignment) {
            case Align.CENTER:
                xOffset = (bounds.width - targetWidth) / 2;
                break;
            case Align.END:
                xOffset = bounds.width - targetWidth;
                break;
        }
        let yOffset = 0;
        switch (verticalAlignment) {
            case Align.CENTER:
                yOffset = (bounds.height - targetHeight) / 2;
                break;
            case Align.END:
                yOffset = bounds.height - targetHeight;
                break;
        }
        document.addImage(bitmap.data, "JPEG", bounds.left + xOffset, bounds.top + yOffset, targetWidth, targetHeight, alias);
    }
}

function drawText(document: jsPDF, text: string, x: number, yBaseline: number, maxWidth: number, horizontalAlignment: Align, verticalAlignment: Align) {
    const tasks = prepareDrawTextTasks(document, text, maxWidth);
    const isDirectionToBottom = verticalAlignment !== Align.START;
    return drawTextTasks(document, tasks, x, yBaseline, maxWidth, horizontalAlignment, isDirectionToBottom);
}

function getBaselineOffset(document: jsPDF, isDirectionToBottom: boolean) {
    return (isDirectionToBottom ? 1 : -1) * 1.35 * document.getFontSize() * PT_TO_MM_FACTOR;
}

function drawTextWithBounds(document: jsPDF, text: string, bounds: Rect, horizontalAlignment: Align, verticalAlignment: Align) {
    const tasks = prepareDrawTextTasks(document, text, bounds.width);
    let lineHeight = document.getFontSize() * PT_TO_MM_FACTOR;
    const height = (tasks.length - 1) * getBaselineOffset(document, true) + lineHeight;
    let yOffset = lineHeight;
    switch (verticalAlignment) {
        case Align.CENTER:
            yOffset += (bounds.height - height) / 2;
            break;
        case Align.END:
            yOffset += bounds.height - height;
            break;
    }
    return drawTextTasks(document, tasks, bounds.left, bounds.top + yOffset, bounds.width, horizontalAlignment, true);
}

function breakText(document: jsPDF, text: string, width: number): Array<string> {
    const tasks = prepareDrawTextTasks(document, text, width);
    return tasks.map(t => t.text);
}

function drawTextTasks(document: jsPDF, tasks: Array<DrawTextTask>, x: number, yBaseline: number, maxWidth: number, horizontalAlignment: Align, isDirectionToBottom: boolean) {
    let y = yBaseline;
    let isFirstLine = true;
    for (const task of tasks) {
        if (isFirstLine) {
            isFirstLine = false;
        } else {
            y += getBaselineOffset(document, isDirectionToBottom);
        }
        let offset = 0;
        switch (horizontalAlignment) {
            case Align.CENTER:
                offset = (maxWidth - task.width) / 2;
                break;
            case Align.END:
                offset = maxWidth - task.width;
                break;
        }
        document.text(task.text, x + offset, y);
    }
    return y;
}

function prepareDrawTextTasks(document: jsPDF, text: string, maxWidth: number) {
    const tasks = new Array<DrawTextTask>();
    const strings = text.split("\n");
    for (const s of strings) {
        let string: string | null = s;
        do {
            string = string.trim();
            let pos: number = string.length;
            let width = document.getTextWidth(string.substring(0, pos));
            while (width > maxWidth) {
                const newPos = string.lastIndexOf(' ', pos - 1);
                pos = newPos > 0 ? newPos : pos - 1;
                if (pos === 1) {
                    break;
                }
                width = document.getTextWidth(string.substring(0, pos));
            }
            tasks.push({
                text: string.substring(0, pos),
                width: width
            });
            string = pos < string.length ? string.substring(pos) : null;
        } while (string != null);
    }
    return tasks;
}

export function drawHeader(t: TFunction<"translation">, document: jsPDF, logo: ImageData, recordName: string, deviceName: string, deviceInfo: string, probeInfo: string, operator: string | undefined, object: string | undefined, recordDateTime: string | undefined): Rect {
    document.setTextColor(0, 0, 0);
    document.setDrawColor(0, 0, 0);
    document.setLineWidth(DIVIDER_STROKE_WIDTH_MM);
    const pageLeft = MARGIN_LEFT_MM;
    const pageTop = MARGIN_TOP_MM;
    const pageRight = PAGE_WIDTH_MM - MARGIN_RIGHT_MM;
    const pageBottom = PAGE_HEIGHT_MM - MARGIN_BOTTOM_MM;
    const logoBounds = new Rect(pageLeft, pageTop, pageRight, pageTop + HEADER_HEIGHT_MM);
    drawBitmap(document, logo, logoBounds, Align.START, Align.CENTER, LOGO_ALIAS);
    document.setFont(FONT_FAMILY, "normal");
    const topDividerY = MARGIN_TOP_MM + HEADER_HEIGHT_MM + HEADER_BOTTOM_MARGIN_MM;
    document.line(pageLeft, topDividerY, pageRight, topDividerY);
    document.setFontSize(TITLE_TEXT_SIZE_PT);
    document.setFont(FONT_FAMILY, "bold");
    let textY = topDividerY + RECORD_INFO_MARGIN_TOP_MM + getBaselineOffset(document, true);
    textY = drawText(document, t("measurement_protocol"), pageLeft, textY, (pageRight - pageLeft), Align.CENTER, Align.END);
    textY += getBaselineOffset(document, true);
    const recordInfoWidth = pageRight - RECORD_DATE_TIME_BLOCK_WIDTH_MM - pageLeft;
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    document.setFont(FONT_FAMILY, "bold");
    textY = drawText(document, recordName, pageLeft, textY, recordInfoWidth, Align.START, Align.END);
    textY += getBaselineOffset(document, true);
    document.setFont(FONT_FAMILY, "normal");
    textY = drawText(document, deviceName, pageLeft, textY, recordInfoWidth, Align.START, Align.END);
    textY += getBaselineOffset(document, true);
    textY = drawText(document, deviceInfo, pageLeft, textY, recordInfoWidth, Align.START, Align.END);
    if (probeInfo !== "") {
        textY += getBaselineOffset(document, true);
        textY = drawText(document, probeInfo, pageLeft, textY, recordInfoWidth, Align.START, Align.END);
    }
    if (operator) {
        textY += getBaselineOffset(document, true);
        textY = drawText(document, operator, pageLeft, textY, recordInfoWidth, Align.START, Align.END);
    }
    if (object) {
        textY += getBaselineOffset(document, true);
        textY = drawText(document, object, pageLeft, textY, recordInfoWidth, Align.START, Align.END);
    }
    if (recordDateTime) {
        const recordDateTimeLeft = pageRight - RECORD_DATE_TIME_BLOCK_WIDTH_MM;
        drawText(document, recordDateTime, recordDateTimeLeft, textY, RECORD_DATE_TIME_BLOCK_WIDTH_MM, Align.CENTER, Align.START);
    }
    const contentTop = textY + getBaselineOffset(document, true);
    const bottomDividerY = pageBottom - FOOTER_HEIGHT_MM;
    document.line(pageLeft, bottomDividerY, pageRight, bottomDividerY);
    return new Rect(pageLeft, contentTop, pageRight, bottomDividerY - FOOTER_TOP_MARGIN_MM);
}

export function drawFooter(t: TFunction<"translation">, document: jsPDF, printDateTime: string, pageInfo: string) {
    document.setTextColor(136, 136, 136);
    document.setFontSize(SECONDARY_TEXT_SIZE_PT);
    document.setFont(FONT_FAMILY, "normal");
    const pageBottom = PAGE_HEIGHT_MM - MARGIN_BOTTOM_MM;
    const pageLeft = MARGIN_LEFT_MM;
    const pageRight = PAGE_WIDTH_MM - MARGIN_RIGHT_MM;
    const pageMid = pageLeft + (pageRight - pageLeft) / 2;
    const footerTop = pageBottom - FOOTER_HEIGHT_MM;
    const printDateTimeBounds = new Rect(pageLeft, footerTop, pageMid, pageBottom);
    const pageInfoBounds = new Rect(pageMid, footerTop, pageRight, pageBottom);
    drawTextWithBounds(document, printDateTime, printDateTimeBounds, Align.START, Align.END);
    drawTextWithBounds(document, pageInfo, pageInfoBounds, Align.END, Align.END);
}

export function prepareInfoPages(t: TFunction<"translation">, document: jsPDF, contentBounds: Rect, conclusion: string | undefined): Array<Array<Drawer>> {
    const builder = new InfoDataBuilder(t, contentBounds.left, contentBounds.top, contentBounds.width, contentBounds.height, conclusion);
    return builder.build();
}

export function drawAwpScreen(t: TFunction<"translation">, document: jsPDF, bounds: Rect, title: string, bitmap: ImageData) {
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    let y = drawText(document, title, bounds.left, bounds.top + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const contentBounds = new Rect(bounds.left, y, bounds.right, bounds.bottom);
    drawMeasurementsPhoto(t, document, contentBounds, bitmap);
}

export function drawPhotoWithMeasurements(t: TFunction<"translation">, document: jsPDF, bounds: Rect, bitmap: ImageData) {
    const title = t("measurements");
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    let y = drawText(document, title, bounds.left, bounds.top + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const contentBounds = new Rect(bounds.left, y, bounds.right, bounds.bottom);
    drawMeasurementsPhoto(t, document, contentBounds, bitmap);
}

export function drawMeasurementsWithoutPhoto(t: TFunction<"translation">, document: jsPDF, bounds: Rect, deviceType: number, count: number, i: number, measurement: AwpSeries, options: any) {
    const title = deviceType !== AWP_DEVICE_IPSM ? (count > 1 ? t("series_index", {index: i + 1}) : t("series")) : (count > 1 ? t("group_index", {index: i + 1}) : t("group"));
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    let y = drawText(document, title, bounds.left, bounds.top + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const contentBounds = new Rect(bounds.left, y, bounds.right, bounds.bottom);
    const topHorizontalGuideline = contentBounds.top + 0.45 * contentBounds.height;
    const bottomHorizontalGuideline = contentBounds.top + 0.85 * contentBounds.height;
    const verticalGuideline = contentBounds.left + contentBounds.width / 2;
    const tableBounds = new Rect(contentBounds.left, contentBounds.top, contentBounds.right, topHorizontalGuideline);
    if (deviceType === AWP_DEVICE_TUD || deviceType === AWP_DEVICE_KRC || deviceType === AWP_DEVICE_TP) {
        drawMeasurementTable(t, document, tableBounds, deviceType, measurement);
    }
    if (deviceType === AWP_DEVICE_UT) {
        drawUtMeasurementTable(t, document, tableBounds, deviceType, measurement as AwpUtSeries);
    }
    if (deviceType === AWP_DEVICE_MF) {
        drawMfMeasurementTable(t, document, tableBounds, deviceType, measurement as AwpMfSeries);
    }
    if (deviceType === AWP_DEVICE_IPSM) {
        drawIpsmMeasurementTable(t, document, contentBounds, deviceType, measurement as AwpIpsmSeries);
    }
    if (deviceType === AWP_DEVICE_TUD || deviceType === AWP_DEVICE_KRC || deviceType === AWP_DEVICE_TP) {
        const leftChartBounds = new Rect(contentBounds.left, topHorizontalGuideline, verticalGuideline, bottomHorizontalGuideline);
        drawLineChart(t, document, leftChartBounds, deviceType, measurement);
        const rightChartBounds = new Rect(verticalGuideline, topHorizontalGuideline, contentBounds.right, bottomHorizontalGuideline);
        drawBarChart(t, document, rightChartBounds, deviceType, measurement);
    }
    if (deviceType === AWP_DEVICE_UT) {
        const areaChartBounds = new Rect(contentBounds.left, topHorizontalGuideline, contentBounds.right, bottomHorizontalGuideline);
        drawAreaChart(t, document, areaChartBounds, deviceType, measurement);
    }
    if (deviceType === AWP_DEVICE_MF) {
        const areaChartBounds = new Rect(contentBounds.left, topHorizontalGuideline, contentBounds.right, bottomHorizontalGuideline);
        drawAreaChart(t, document, areaChartBounds, deviceType, measurement);
    }

}

function drawMeasurementTable(t: TFunction<"translation">, document: jsPDF, bounds: Rect, deviceType: number, measurement: AwpSeries) {
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const mid = left + (right - left) / 2;
    const bottom = bounds.bottom - CONTENT_MARGIN_MM;
    const rows = 10;
    const rowHeight = (bottom - top) / rows;
    const data = prepareAwpSeriesInfoData(deviceType, measurement);
    if (data) {
        document.setLineWidth(TABLE_STROKE_MM);
        document.setFillColor(229, 229, 229);
        document.rect(left, top, right - left, rowHeight, "f");
        document.setDrawColor(192, 192, 192);
        document.line(left, top, right, top);
        document.line(left, top, left, bottom);
        document.line(mid, top, mid, bottom);
        document.line(right, top, right, bottom);
        for (let i = 1; i < rows; i++) {
            const lineY = top + rowHeight * i;
            document.line(left, lineY, right, lineY);
        }
        document.line(left, bottom, right, bottom);
        document.setTextColor(0, 0, 0);
        document.setFont(FONT_FAMILY, "bold");
        document.setFontSize(TABLE_TEXT_SIZE_PT);
        let y = top;
        drawTextWithBounds(document, t("parameter"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
        drawTextWithBounds(document, t("value"), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
        document.setFont(FONT_FAMILY, "normal");
        y += rowHeight;
        drawTextWithBounds(document, t("maximum"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
        drawTextWithBounds(document, formatAwpValue(deviceType, data.max, measurement), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
        y += rowHeight;
        drawTextWithBounds(document, t("minimum"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
        drawTextWithBounds(document, formatAwpValue(deviceType, data.min, measurement), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
        y += rowHeight;
        drawTextWithBounds(document, t("deviation"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
        drawTextWithBounds(document, formatAwpValue(deviceType, data.deviation, measurement), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
        y += rowHeight;
        drawTextWithBounds(document, t("average"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
        drawTextWithBounds(document, formatAwpValue(deviceType, data.avg, measurement), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
        y += rowHeight;
        drawTextWithBounds(document, t("standard_deviation"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
        drawTextWithBounds(document, formatNumber(data.standardDeviation, 1), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
        y += rowHeight;
        drawTextWithBounds(document, t("mean_square_deviation"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
        drawTextWithBounds(document, formatNumber(data.meanSquareDeviation, 1), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
        y += rowHeight;
        drawTextWithBounds(document, t("variation_coefficient"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
        drawTextWithBounds(document, `${formatNumber(data.variationCoefficient, 1)} %`, new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
        y += rowHeight;
        drawTextWithBounds(document, t("homogeneity_of_series"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
        drawTextWithBounds(document, data.variationCoefficient < 33 ? t('yes') : t('no'), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
        y += rowHeight;
        drawTextWithBounds(document, t("number_of_measurements"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
        drawTextWithBounds(document, formatNumber(data.count), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
    }
}

function drawUtMeasurementTable(t: TFunction<"translation">, document: jsPDF, bounds: Rect, deviceType: number, measurement: AwpUtSeries) {
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const mid = left + (right - left) / 2;
    const bottom = bounds.bottom - CONTENT_MARGIN_MM;
    const rows = 4;
    const rowHeight = (bottom - top) / rows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, top, right - left, rowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, top, right, top);
    document.line(left, top, left, bottom);
    document.line(mid, top, mid, bottom);
    document.line(right, top, right, bottom);
    for (let i = 1; i < rows; i++) {
        const lineY = top + rowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, bottom, right, bottom);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    let y = top;
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("value"), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += rowHeight;
    drawTextWithBounds(document, t("speed_material"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, formatNumber(measurement.measure.speedMaterial), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
    y += rowHeight;
    drawTextWithBounds(document, t("scale"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, formatAwpUtScaleName(t, measurement.measure.scale), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
    y += rowHeight;
    drawTextWithBounds(document, t("thickness"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
    let thinc = measurement.measure.saveThinc;
    if (measurement.measure.scale === 1){
        thinc /= 25.4;
    }
    drawTextWithBounds(document, formatAwpValue(AWP_DEVICE_UT, thinc, measurement), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
}

function drawMfMeasurementTable(t: TFunction<"translation">, document: jsPDF, bounds: Rect, deviceType: number, measurement: AwpMfSeries) {
    const scale = measurement.scale;
    let scaleIdx = 0;
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const mid = left + (right - left) / 2;
    const bottom = bounds.bottom - CONTENT_MARGIN_MM;
    const rows = 5;
    const rowHeight = (bottom - top) / rows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, top, right - left, rowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, top, right, top);
    document.line(left, top, left, bottom);
    document.line(mid, top, mid, bottom);
    document.line(right, top, right, bottom);
    for (let i = 1; i < 3; i++) {
        const lineY = top + rowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, bottom, right, bottom);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    let y = top;
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("value"), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += rowHeight;
    drawTextWithBounds(document, t("measured_value"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, `${formatAwpMfValue(measurement.strength[scale], scale)} ${formatAwpMfScaleName(t, scale)}`, new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
    y += rowHeight;
    if (scale === scaleIdx) {
        scaleIdx++;
    }
    drawTextWithBounds(document, `${formatAwpMfValue(measurement.strength[scaleIdx], scaleIdx)} ${formatAwpMfScaleName(t, scaleIdx)}`, new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
    scaleIdx++;
    y += rowHeight;
    if (scale === scaleIdx) {
        scaleIdx++;
    }
    drawTextWithBounds(document, t("estimated_values"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, `${formatAwpMfValue(measurement.strength[scaleIdx], scaleIdx)} ${formatAwpMfScaleName(t, scaleIdx)}`, new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
    scaleIdx++;
    y += rowHeight;
    if (scale === scaleIdx) {
        scaleIdx++;
    }
    drawTextWithBounds(document, `${formatAwpMfValue(measurement.strength[scaleIdx], scaleIdx)} ${formatAwpMfScaleName(t, scaleIdx)}`, new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
}

function drawIpsmMeasurementTable(t: TFunction<"translation">, document: jsPDF, bounds: Rect, deviceType: number, series: AwpIpsmSeries) {
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const mid = left + (right - left) / 2;
    const bottom = bounds.bottom - CONTENT_MARGIN_MM;
    const rows = series.values.length + 1;
    const rowHeight = (bottom - top) / rows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, top, right - left, rowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, top, right, top);
    document.line(left, top, left, bottom);
    document.line(mid, top, mid, bottom);
    document.line(right, top, right, bottom);
    for (let i = 1; i < rows; i++) {
        const lineY = top + rowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, bottom, right, bottom);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    let y = top;
    drawTextWithBounds(document, t("measurement"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("value"), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    let idx = 1;
    for (const measurement of series.values) {
        y += rowHeight;
        drawTextWithBounds(document, `${idx}`, new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
        drawTextWithBounds(document, `${formatAwpIpsmValue(measurement.value, measurement.scale)} ${formatAwpIpsmScaleName(t, measurement.scale)}`, new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
        idx++;
    }
}

export function drawAwpDeviceAndProbeInfo(t: TFunction<"translation">, document: jsPDF, bounds: Rect, deviceType: number, measurements: Array<AwpSeries>) {
    if (measurements.length > 0) {
        switch (deviceType) {
            case AWP_DEVICE_UD2301:
                drawAwpUd2301DeviceAndProbeInfo(t, document, bounds, measurements[0] as AwpUd2301Series);
                break;
            case AWP_DEVICE_UD2303:
                drawAwpUd2303DeviceAndProbeInfo(t, document, bounds, measurements[0] as AwpUd2303Series);
                break
            case AWP_DEVICE_UD3701:
                drawAwpUd3701DeviceAndProbeInfo(t, document, bounds, measurements[0] as AwpUd3701Series);
                break
            case AWP_DEVICE_UT3EMA:
                drawAwpUt3EmaDeviceAndProbeInfo(t, document, bounds, measurements[0] as AwpUt3EmaSeries);
                break
            case AWP_DEVICE_UT2A:
                drawAwpUt2aDeviceAndProbeInfo(t, document, bounds, measurements[0] as AwpUt2aSeries);
                break
        }
    }
}

function drawAwpUd2301DeviceAndProbeInfo(t: TFunction<"translation">, document: jsPDF, bounds: Rect, data: AwpUd2301Series) {
    const deviceInfo = getUd2301DeviceInfo(t, data);
    const transducerInfo = getUd2301TransducerInfo(t, data);
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const midX = left + (right - left) / 2;
    const bottom = bounds.bottom - CONTENT_MARGIN_MM;
    const deviceInfoRows = 11;
    const probeInfoRows = 5;
    const midY = top + (bottom - top) / (deviceInfoRows + probeInfoRows) * deviceInfoRows;
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    let y = drawText(document, t('parameters_ud2301'), bounds.left, bounds.top + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const deviceInfoRowHeight = (midY - y) / deviceInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, deviceInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, midY);
    document.line(midX, y, midX, midY);
    document.line(right, y, right, midY);
    for (let i = 1; i < deviceInfoRows; i++) {
        const lineY = y + deviceInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, midY, right, midY);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("value"), new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("amplification_db"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.gain, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("delay_mm"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.delayMm, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("delay_us"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.delayUs, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("scanning_mm"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.scanningMm, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("scanning_us"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.scanningUs, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("detector"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.detector, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("averaging_points"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.middle, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("filter_mhz"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.filter, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("tvg"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.tvg, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("velocity_m_s"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.velocity, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    y = midY + getBaselineOffset(document, true);
    y = drawText(document, t('transducer'), bounds.left, y + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const probeInfoRowHeight = (bottom - y) / probeInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, probeInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, bottom);
    document.line(midX, y, midX, bottom);
    document.line(right, y, right, bottom);
    for (let i = 1; i < probeInfoRows; i++) {
        const lineY = y + probeInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, bottom, right, bottom);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("value"), new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("frequency_mhz"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.freq, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("input_angle"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.angle, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("delay_in_prism_us"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.delay, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("arrow_mm"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.vector, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
}

function drawAwpUd2303DeviceAndProbeInfo(t: TFunction<"translation">, document: jsPDF, bounds: Rect, data: AwpUd2303Series) {
    const deviceInfo = getUd2303DeviceInfo(t, data);
    const transucerInfo = getUd2303TransducerInfo(t, data);
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const midX = left + (right - left) / 2;
    const bottom = bounds.bottom - CONTENT_MARGIN_MM;
    const deviceInfoRows = 11;
    const probeInfoRows = (data.probe.type !== 0 && data.probe.type !== 1) ? 5 : 4;
    const midY = top + (bottom - top) / (deviceInfoRows + probeInfoRows) * deviceInfoRows;
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    let y = drawText(document, t('parameters_ud2303'), bounds.left, bounds.top + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const deviceInfoRowHeight = (midY - y) / deviceInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, deviceInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, midY);
    document.line(midX, y, midX, midY);
    document.line(right, y, right, midY);
    for (let i = 1; i < deviceInfoRows; i++) {
        const lineY = y + deviceInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, midY, right, midY);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("value"), new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("amplification_db"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.gain, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("delay_mm"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.delayMm, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("delay_inch"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.delayInch, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("scanning_mm"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.scanMm, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("scanning_inch"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.scanInch, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("detector"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.detector, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("averaging_points"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.middle, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("filter_mhz"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.filter, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("tvg"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.tvg, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("velocity_m_s"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.velocity, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    y = midY + getBaselineOffset(document, true);
    y = drawText(document, t('transducer'), bounds.left, y + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const probeInfoRowHeight = (bottom - y) / probeInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, probeInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, bottom);
    document.line(midX, y, midX, bottom);
    document.line(right, y, right, bottom);
    for (let i = 1; i < probeInfoRows; i++) {
        const lineY = y + probeInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, bottom, right, bottom);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("value"), new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("frequency_mhz"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transucerInfo.freq, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    if (data.probe.type !== 0 && data.probe.type !== 1) {
        drawTextWithBounds(document, t("input_angle"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
        drawTextWithBounds(document, transucerInfo.angle, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
        y += probeInfoRowHeight;
    }
    drawTextWithBounds(document, t("delay_in_prism_us"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transucerInfo.delayUs, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("arrow_mm"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transucerInfo.vector, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
}

function drawAwpUd3701DeviceAndProbeInfo(t: TFunction<"translation">, document: jsPDF, bounds: Rect, data: AwpUd3701Series) {
    const deviceInfo = getUd3701DeviceInfo(t, data);
    const transducerInfo = getUd3701TransducerInfo(t, data);
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const midX = left + (right - left) / 2;
    const bottom = bounds.bottom - CONTENT_MARGIN_MM;
    const deviceInfoRows = 10;
    const probeInfoRows = 6;
    const midY = top + (bottom - top) / (deviceInfoRows + probeInfoRows) * deviceInfoRows;
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    let y = drawText(document, t('parameters_ud3701'), bounds.left, bounds.top + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const deviceInfoRowHeight = (midY - y) / deviceInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, deviceInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, midY);
    document.line(midX, y, midX, midY);
    document.line(right, y, right, midY);
    for (let i = 1; i < deviceInfoRows; i++) {
        const lineY = y + deviceInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, midY, right, midY);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("value"), new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("amplification_db"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.gain, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("delay_mm"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.delayMm, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("delay_us"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.delayUs, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("scanning_mm"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.scanMm, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("scanning_us"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.scanUs, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("detector"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.detector, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("averaging_points"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.middle, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("filter_mhz"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.filter, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("tvg"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.tvg, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    y = midY + getBaselineOffset(document, true);
    y = drawText(document, t('transducer'), bounds.left, y + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const probeInfoRowHeight = (bottom - y) / probeInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, probeInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, bottom);
    document.line(midX, y, midX, bottom);
    document.line(right, y, right, bottom);
    for (let i = 1; i < probeInfoRows; i++) {
        const lineY = y + probeInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, bottom, right, bottom);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("value"), new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("frequency_mhz"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.freq, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("input_angle"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.angle, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("delay_in_prism_us"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.delay, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("arrow_mm"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.vector, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("diameter_mm"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.diamSyzeA, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
}

function drawAwpUt3EmaDeviceAndProbeInfo(t: TFunction<"translation">, document: jsPDF, bounds: Rect, data: AwpUt3EmaSeries) {
    const deviceInfo = getUt3EmaDeviceInfo(t, data);
    const transducerInfo = getUt3EmaTransducerInfo(t, data);
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const midX = left + (right - left) / 2;
    const bottom = bounds.bottom - CONTENT_MARGIN_MM;
    const deviceInfoRows = 8;
    const probeInfoRows = 6;
    const midY = top + (bottom - top) / (deviceInfoRows + probeInfoRows) * deviceInfoRows;
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    let y = drawText(document, t('parameters_ut3ema'), bounds.left, bounds.top + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const deviceInfoRowHeight = (midY - y) / deviceInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, deviceInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, midY);
    document.line(midX, y, midX, midY);
    document.line(right, y, right, midY);
    for (let i = 1; i < deviceInfoRows; i++) {
        const lineY = y + deviceInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, midY, right, midY);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("value"), new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("working_mode"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.workMode, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("measurement_mode"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.measMode, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("amplification_db"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.gain, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("averaging_points"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.middle, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, deviceInfo.durationTitle, new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.duration, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, deviceInfo.delayTitle, new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.delay, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, deviceInfo.velocityTitle, new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.velocity, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    y = midY + getBaselineOffset(document, true);
    y = drawText(document, t('transducer'), bounds.left, y + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const probeInfoRowHeight = (bottom - y) / probeInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, probeInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, bottom);
    document.line(midX, y, midX, bottom);
    document.line(right, y, right, bottom);
    for (let i = 1; i < probeInfoRows; i++) {
        const lineY = y + probeInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, bottom, right, bottom);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("value"), new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("sensor_name"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.name, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("sensor_type"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.type, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("frequency_mhz"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.freq, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("amplitude"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.amplV, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("pulses"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.nPulses, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
}

function drawAwpUt2aDeviceAndProbeInfo(t: TFunction<"translation">, document: jsPDF, bounds: Rect, data: AwpUt2aSeries) {
    const deviceInfo = getUt2ADeviceInfo(t, data);
    const transducerInfo = getUt2ATransducerInfo(t, data);
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const midX = left + (right - left) / 2;
    const bottom = bounds.bottom - CONTENT_MARGIN_MM;
    const deviceInfoRows = 8;
    const probeInfoRows = 7;
    const midY = top + (bottom - top) / (deviceInfoRows + probeInfoRows) * deviceInfoRows;
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    let y = drawText(document, t('parameters_ut2a'), bounds.left, bounds.top + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const deviceInfoRowHeight = (midY - y) / deviceInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, deviceInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, midY);
    document.line(midX, y, midX, midY);
    document.line(right, y, right, midY);
    for (let i = 1; i < deviceInfoRows; i++) {
        const lineY = y + deviceInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, midY, right, midY);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("value"), new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("working_mode"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.workMode !== "" ? deviceInfo.workMode : DATA_PLACEHOLDER, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("measurement_mode"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.measMode !== "" ? deviceInfo.measMode : DATA_PLACEHOLDER, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("amplification_db"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.gain, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("averaging_points"), new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.middle, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, deviceInfo.scanningTitle, new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.scanning, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, deviceInfo.delayTitle, new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.delay, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, deviceInfo.velocityTitle, new Rect(left, y, midX, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, deviceInfo.velocity, new Rect(midX, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    y = midY + getBaselineOffset(document, true);
    y = drawText(document, t('transducer'), bounds.left, y + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const probeInfoRowHeight = (bottom - y) / probeInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, probeInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, bottom);
    document.line(midX, y, midX, bottom);
    document.line(right, y, right, bottom);
    for (let i = 1; i < probeInfoRows; i++) {
        const lineY = y + probeInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, bottom, right, bottom);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("value"), new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("sensor_name"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.name, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("sensor_type"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.type, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("frequency_mhz"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.freq, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("delay_us"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.delay, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("amplitude"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.amplV, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("pulses"), new Rect(left, y, midX, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, transducerInfo.nPulses, new Rect(midX, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
}

export function drawAwpStrobeInfo(t: TFunction<"translation">, document: jsPDF, bounds: Rect, deviceType: number, measurements: Array<AwpSeries>) {
    if (measurements.length > 0) {
        switch (deviceType) {
            case AWP_DEVICE_UD2301:
                drawAwpUd2301StrobeInfo(t, document, bounds, measurements[0] as AwpUd2301Series);
                break;
            case AWP_DEVICE_UD2303:
                drawAwpUd2303StrobeInfo(t, document, bounds, measurements[0] as AwpUd2303Series);
                break;
            case AWP_DEVICE_UD3701:
                drawAwpUd3701StrobeInfo(t, document, bounds, measurements[0] as AwpUd3701Series);
                break;
        }
    }
}

function drawAwpUd2301StrobeInfo(t: TFunction<"translation">, document: jsPDF, bounds: Rect, data: AwpUd2301Series) {
    const strobeInfo = getUd2301StrobeInfo(t, data);
    let strobeAColor = "#00f";
    let strobeBColor = "#0f0";
    if (data.systemSet.pallete === 1){
        strobeAColor = "#f00";
        strobeBColor = "#0f0";
    }
    const result = getUd2301Result(t, data);
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const midX13 = left + (right - left) / 3;
    const midX23 = left + (right - left) * 2 / 3;
    const midX14 = left + (right - left) / 4;
    const midX24 = left + (right - left) * 2 / 4;
    const midX34 = left + (right - left) * 3 / 4;
    const bottom = bounds.bottom - CONTENT_MARGIN_MM;
    const deviceInfoRows = 10;
    const probeInfoRows = 9;
    const midY = top + (bottom - top) / (deviceInfoRows + probeInfoRows) * deviceInfoRows;
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    let y = drawText(document, "", bounds.left, bounds.top + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const deviceInfoRowHeight = (midY - y) / deviceInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, deviceInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, midY);
    document.line(midX13, y, midX13, midY);
    document.line(midX23, y, midX23, midY);
    document.line(right, y, right, midY);
    for (let i = 1; i < deviceInfoRows; i++) {
        const lineY = y + deviceInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, midY, right, midY);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_a"), new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_b"), new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_start_mm"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.startMm1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.startMm2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_start_us"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.startUs1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.startUs2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_width_mm"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.widthMm1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.widthMm2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_width_us"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.widthUs1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.widthUs2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_level"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.level1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.level2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_control_db"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, formatNumber(data.strobe.yContr[0] / 10, 1, 1), new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, formatNumber(data.strobe.yContr[1] / 10, 1, 1), new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_search_db"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.control1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.control2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_mode"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.mode1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.mode2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_color"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFillColor(strobeAColor);
    document.rect(midX13 + 0.2 * (midX23 - midX13), y + 0.2 * deviceInfoRowHeight, 0.6 * (midX23 - midX13), 0.6 * deviceInfoRowHeight, "f");
    document.setFillColor(strobeBColor);
    document.rect(midX23 + 0.2 * (midX23 - midX13), y + 0.2 * deviceInfoRowHeight, 0.6 * (midX23 - midX13), 0.6 * deviceInfoRowHeight, "f");
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    y = midY + getBaselineOffset(document, true);
    y = drawText(document, "", bounds.left, y + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const probeInfoRowHeight = (bottom - y) / probeInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, probeInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, bottom);
    document.line(midX14, y, midX14, bottom);
    document.line(midX24, y, midX24, bottom);
    document.line(midX34, y, midX34, bottom);
    document.line(right, y, right, bottom);
    for (let i = 1; i < probeInfoRows; i++) {
        const lineY = y + probeInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, bottom, right, bottom);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_a"), new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_b"), new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_a_gate_b"), new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_t_us"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.tA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.tB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.tAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_a_db"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.aA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.aB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.aAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_xb_mm"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.xbA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.xbB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, "", new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_xip_mm"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.xipA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.xipB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, "", new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_y_mm"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.yA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.yB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.yAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_s_mm2"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.sA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.sB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, "", new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_l_mm"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.lA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.lB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.lAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_da_db"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.daA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.daB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.daAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
}

function drawAwpUd2303StrobeInfo(t: TFunction<"translation">, document: jsPDF, bounds: Rect, data: AwpUd2303Series) {
    const result = getUd2303DResult(t, data);
    const strobeInfo = getUd2303StrobeInfo(t, data);
    let strobeAColor = "#0f0";
    let strobeBColor = "#00f";
    if (data.systemSet.pallete === 1){
        strobeAColor = "#f00";
        strobeBColor = "#0f0";
    }
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const midX13 = left + (right - left) / 3;
    const midX23 = left + (right - left) * 2 / 3;
    const midX14 = left + (right - left) / 4;
    const midX24 = left + (right - left) * 2 / 4;
    const midX34 = left + (right - left) * 3 / 4;
    const bottom = bounds.bottom - CONTENT_MARGIN_MM;
    const deviceInfoRows = 10;
    const probeInfoRows = 9;
    const midY = top + (bottom - top) / (deviceInfoRows + probeInfoRows) * deviceInfoRows;
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    let y = drawText(document, "", bounds.left, bounds.top + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const deviceInfoRowHeight = (midY - y) / deviceInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, deviceInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, midY);
    document.line(midX13, y, midX13, midY);
    document.line(midX23, y, midX23, midY);
    document.line(right, y, right, midY);
    for (let i = 1; i < deviceInfoRows; i++) {
        const lineY = y + deviceInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, midY, right, midY);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_a"), new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_b"), new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_start_mm"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.startMm1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.startMm2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_start_inch"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.startInch1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.startInch2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_width_mm"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.widthMm1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.widthMm2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_width_inch"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.widthInch1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.widthInch2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_level"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.level1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.level2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_control_db"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.control1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.control2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_search_db"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.search1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.search2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_mode"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.mode1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.mode2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_color"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFillColor(strobeAColor);
    document.rect(midX13 + 0.2 * (midX23 - midX13), y + 0.2 * deviceInfoRowHeight, 0.6 * (midX23 - midX13), 0.6 * deviceInfoRowHeight, "f");
    document.setFillColor(strobeBColor);
    document.rect(midX23 + 0.2 * (midX23 - midX13), y + 0.2 * deviceInfoRowHeight, 0.6 * (midX23 - midX13), 0.6 * deviceInfoRowHeight, "f");
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    y = midY + getBaselineOffset(document, true);
    y = drawText(document, "", bounds.left, y + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const probeInfoRowHeight = (bottom - y) / probeInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, probeInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, bottom);
    document.line(midX14, y, midX14, bottom);
    document.line(midX24, y, midX24, bottom);
    document.line(midX34, y, midX34, bottom);
    document.line(right, y, right, bottom);
    for (let i = 1; i < probeInfoRows; i++) {
        const lineY = y + probeInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, bottom, right, bottom);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_a"), new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_b"), new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_a_gate_b"), new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_t_us"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.tA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.tB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.tAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_a_db"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.aA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.aB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.aAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_xb_mm"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.xbA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.xbB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, "", new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_xip_mm"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.xipA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.xipB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, "", new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_y_mm"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.yA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.yB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.yAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_s_mm2"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.sA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.sB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, "", new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_l_mm"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.lA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.lB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.lAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_da_db"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.daA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.daB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.daAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
}

function drawAwpUd3701StrobeInfo(t: TFunction<"translation">, document: jsPDF, bounds: Rect, data: AwpUd3701Series) {
    const result = getUd3701Result(t, data);
    const strobeInfo = getUd3701StrobeInfo(t, data);
    let strobeAColor = "#0f0";
    let strobeBColor = "#00f";
    if (data.systemSet.pallete === 1){
        strobeAColor = "#f00";
        strobeBColor = "#0f0";
    }
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const midX13 = left + (right - left) / 3;
    const midX23 = left + (right - left) * 2 / 3;
    const midX14 = left + (right - left) / 4;
    const midX24 = left + (right - left) * 2 / 4;
    const midX34 = left + (right - left) * 3 / 4;
    const bottom = bounds.bottom - CONTENT_MARGIN_MM;
    const deviceInfoRows = 10;
    const probeInfoRows = 9;
    const midY = top + (bottom - top) / (deviceInfoRows + probeInfoRows) * deviceInfoRows;
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    let y = drawText(document, "", bounds.left, bounds.top + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const deviceInfoRowHeight = (midY - y) / deviceInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, deviceInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, midY);
    document.line(midX13, y, midX13, midY);
    document.line(midX23, y, midX23, midY);
    document.line(right, y, right, midY);
    for (let i = 1; i < deviceInfoRows; i++) {
        const lineY = y + deviceInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, midY, right, midY);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_a"), new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_b"), new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_start_mm"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.startMm1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.startMm2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_start_us"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.startUs1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.startUs2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_width_mm"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.widthMm1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.widthMm2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_width_us"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.widthUs1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.widthUs2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_level"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.level1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.level2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_control_db"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.control1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.control2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_search_db"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.search1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.search2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_mode"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.mode1, new Rect(midX13, y, midX23, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, strobeInfo.mode2, new Rect(midX23, y, right, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    y += deviceInfoRowHeight;
    drawTextWithBounds(document, t("awp_color"), new Rect(left, y, midX13, y + deviceInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFillColor(strobeAColor);
    document.rect(midX13 + 0.2 * (midX23 - midX13), y + 0.2 * deviceInfoRowHeight, 0.6 * (midX23 - midX13), 0.6 * deviceInfoRowHeight, "f");
    document.setFillColor(strobeBColor);
    document.rect(midX23 + 0.2 * (midX23 - midX13), y + 0.2 * deviceInfoRowHeight, 0.6 * (midX23 - midX13), 0.6 * deviceInfoRowHeight, "f");
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    y = midY + getBaselineOffset(document, true);
    y = drawText(document, "", bounds.left, y + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const probeInfoRowHeight = (bottom - y) / probeInfoRows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, y, right - left, probeInfoRowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, y, right, y);
    document.line(left, y, left, bottom);
    document.line(midX14, y, midX14, bottom);
    document.line(midX24, y, midX24, bottom);
    document.line(midX34, y, midX34, bottom);
    document.line(right, y, right, bottom);
    for (let i = 1; i < probeInfoRows; i++) {
        const lineY = y + probeInfoRowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, bottom, right, bottom);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_a"), new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_b"), new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("awp_gate_a_gate_b"), new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_t_us"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.tA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.tB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.tAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_a_db"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.aA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.aB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.aAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_xb_mm"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.xbA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.xbB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, "", new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_xip_mm"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.xipA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.xipB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, "", new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_y_mm"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.yA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.yB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.yAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_s_mm2"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.sA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.sB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, "", new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_l_mm"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.lA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.lB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.lAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    y += probeInfoRowHeight;
    drawTextWithBounds(document, t("awp_da_db"), new Rect(left, y, midX14, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.daA, new Rect(midX14, y, midX24, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.daB, new Rect(midX24, y, midX34, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, result.daAB, new Rect(midX34, y, right, y + probeInfoRowHeight), Align.CENTER, Align.CENTER);
}

function drawLineChart(t: TFunction<"translation">, document: jsPDF, chartBounds: Rect, deviceType: number, measurement: AwpSeries) {
    const lineChartData = prepareAwpLineChartData(deviceType, measurement);
    const label = getAwpLineChartName(deviceType, t, measurement);
    const formatter = (v: LineChartData) => formatAwpValue(deviceType, v.y, measurement);
    const measurementError = getAwpMeasurementError(deviceType, measurement);
    if (lineChartData) {
        const chart = buildLineChart(chartBounds, label, lineChartData, formatter, measurementError);
        drawBitmap(document, chart, chartBounds, Align.CENTER, Align.CENTER);
    }
}

function drawBarChart(t: TFunction<"translation">, document: jsPDF, chartBounds: Rect, deviceType: number, measurement: AwpSeries) {
    const barChartData = prepareAwpBarChartData(deviceType, measurement);
    const label = getAwpLineChartName(deviceType, t, measurement);
    if (barChartData) {
        const chart = buildBarChart(chartBounds, label, barChartData);
        drawBitmap(document, chart, chartBounds, Align.CENTER, Align.CENTER);
    }
}

function drawAreaChart(t: TFunction<"translation">, document: jsPDF, chartBounds: Rect, deviceType: number, measurement: AwpSeries) {
    const areaChartData = prepareAwpAreaChartData(deviceType, measurement);
    const label = getAwpAreaChartName(deviceType, t, measurement);
    const formatter = (v: AreaChartData) => formatAwpValue(deviceType, v.y[1], measurement);
    const measurementError = getAwpMeasurementError(deviceType, measurement);
    if (areaChartData) {
        const chart = buildAreaChart(chartBounds, label, areaChartData, formatter, measurementError, false);
        drawBitmap(document, chart, chartBounds, Align.CENTER, Align.CENTER);
    }
}

export function drawMeasurementsPhoto(t: TFunction<"translation">, doc: jsPDF, bounds: Rect, bitmap: ImageData) {
    const aspectRatio = bitmap.width / bitmap.height;
    let w = bounds.width;
    let h = w / aspectRatio;
    if (h > bounds.height) {
        h = bounds.height;
        w = h * aspectRatio;
    }
    let left = bounds.left + (bounds.width - w) / 2;
    let top = bounds.top + (bounds.height - h) / 2;
    const imageBounds = new Rect(left, top, left + w, top + h);
    drawBitmap(doc, bitmap, imageBounds, Align.CENTER, Align.CENTER, PHOTO_ALIAS);
}