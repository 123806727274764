import {Chart} from "chart.js";
import {Rect} from "./Rect";
import {IMAGE_QUALITY, IMAGE_TYPE, ImageData} from "./ImageData";
import {LineChartData} from "../components/MeasurementDetails/MeasurementsLineChart";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {backgroundPlugin, MM_TO_PX_FACTOR} from "./PdfChartPlugins";

function getRounder(min: number, max: number) {
    let delta = max - min;
    let rounder = 0.00001;
    while (delta > rounder * 10) {
        rounder *= 10;
    }
    return Math.min(rounder, 100);
}

function roundMin(min: number, rounder: number) {
    let value;
    if (rounder < 1) {
        value = min;
    } else {
        value = Math.floor(min);
    }
    return Math.floor(value / rounder) * rounder;
}

function roundMax(max: number, rounder: number) {
    let value;
    if (rounder < 1) {
        value = max;
    } else {
        value = Math.ceil(max) - 1;
    }
    return (Math.floor(value / rounder) + 1) * rounder;
}

function getDividerX(count : number){
    let divider = 1;
    while (count / divider > 10){
        divider++;
    }
    return divider;
}

function calculateAxisX(count : number) : [max : number, ticks : number] {
    if (count <= 10){
        return [10, 11];
    }
    const divider = getDividerX(count);
    const max = count % divider === 0 ? count : (Math.floor(count / divider) + 1) * divider;
    return [max, max / divider + 1];
}

export function buildLineChart(bounds: Rect, label: string, chartData: Array<LineChartData>, formatter: (v: LineChartData) => string, measurementError: number): ImageData {
    const canvas = document.getElementById("pdf-canvas") as HTMLCanvasElement;
    if (canvas) {
        canvas.setAttribute("width", `${bounds.width * MM_TO_PX_FACTOR / 1.25}px`);
        canvas.setAttribute("height", `${bounds.height * MM_TO_PX_FACTOR / 1.25}px`);
        const context = canvas.getContext('2d');
        if (context) {
            const points = chartData.map(d => d.y);
            const minValue = Math.min(...points);
            const maxValue = Math.max(...points);
            const delta = maxValue - minValue;
            let axisMin = minValue;
            let axisMax = maxValue;
            let minDelta = measurementError * 10;
            if (delta < minDelta) {
                let middle = axisMin + (axisMax - axisMin) / 2;
                axisMin = minValue < 0 ? middle - minDelta / 2 : Math.max(0, middle - minDelta / 2);
                axisMax = axisMin + minDelta;
            }
            let rounder = getRounder(axisMin, axisMax);
            let min = roundMin(axisMin, rounder);
            let max = roundMax(axisMax, rounder);
            let divisions = Math.min(12, Math.round((max - min) / rounder) + 1);
            while (divisions < 4) {
                rounder /= 2;
                min = roundMin(axisMin, rounder);
                max = roundMax(axisMax, rounder);
                divisions = Math.min(12, Math.round((max - min) / rounder) + 1);
            }
            const log = Math.log10(rounder) - 1;
            const fractionDigits = log >= 0 ? 0 : Math.abs(Math.floor(log));
            const minY = Number(min.toFixed(fractionDigits));
            const maxY = Number(max.toFixed(fractionDigits));
            const [maxX, ticksX] = calculateAxisX(chartData.length);
            const data = {
                datasets: [{
                    label: label,
                    backgroundColor: '#9E1B1B',
                    borderColor: '#9E1B1B',
                    showLine: true,
                    borderWidth: 1,
                    data: chartData,
                }]
            };
            const chart = new Chart(context, {
                type: "scatter",
                data: data,
                plugins: points.length < 10 ? [backgroundPlugin, ChartDataLabels] : [backgroundPlugin],
                options: {
                    animation: false,
                    scales: {
                        xAxis: {
                            min: 0,
                            max: maxX,
                            ticks: {
                                count: ticksX
                            },
                            grid: {
                                display: false,
                                borderColor: "#666666"
                            }
                        },
                        yAxis: {
                            min: minY,
                            max: maxY,
                            ticks: {
                                count: divisions,
                                callback: v => Number(v).toFixed(fractionDigits)
                            },
                            grid: {
                                tickLength: 0,
                                borderColor: "#666666"
                            }
                        }
                    },
                    elements: {
                        point: {
                            radius: 3,
                            borderWidth: 0.5,
                            pointStyle: "circle"
                        }
                    },
                    plugins: {
                        datalabels: {
                            align: 'top',
                            offset: 5,
                            font: {
                                size: 9
                            },
                            color: "#000000",
                            formatter: formatter
                        },
                        legend: {
                            labels: {
                                font: {
                                    size: 9
                                },
                                boxWidth: 6,
                                boxHeight: 6
                            }
                        }
                    }
                }
            });
            const imageData = {
                width: bounds.width,
                height: bounds.height,
                data: chart.toBase64Image(IMAGE_TYPE, IMAGE_QUALITY)
            };
            chart.destroy();
            return imageData;
        }
    }
    return {
        width: 0,
        height: 0,
        data: ""
    };
}