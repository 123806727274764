import useImage from "use-image";
import {useEffect, useRef, useState} from "react";
import './AwpPhotoViewer.css';
import {LoaderSmall} from "../Loader/LoaderSmall";
import {useTranslation} from "react-i18next";
import {useScreenSize} from "../../hooks/ScreenSizeHooks";
import {Error} from "../Error/Error";
import {Image, Layer, Stage} from "react-konva";
import {AwpMarkerView} from "./AwpMarkerView";
import IconCloseSvg from "../Icons/IconCloseSvg";
import {AwpFullScreenCloseButton} from "./AwpFullScreenCloseButton";

interface Marker {
    index: number;
    x: number;
    y: number;
}

interface Props {
    url: string;
    markers?: Array<Marker>;
    imageLoadListener: () => void;
    isFullScreen: boolean;
    fullScreenCloseClickListener: () => void;
    markerClickListener: (index: number) => void;
    flipImage: boolean;
}

export function AwpPhotoViewer(props: Props) {
    const {t} = useTranslation();
    const [image, status] = useImage(props.url);
    const [isNotified, setNotified] = useState(false);
    const container = useRef<HTMLDivElement>(null);
    const [, screenHeight] = useScreenSize();
    const markers = props.markers ?? new Array<Marker>();
    let h: number | null = null;
    let w: number | null = null;
    let scaleFactor: number | null = null;
    if (image) {
        h = props.isFullScreen ? screenHeight : Math.min(screenHeight * 0.8, image.height);
        w = image.width * h / image.height;
        w = Math.min(container.current?.clientWidth ?? 0, w);
        h = image.height * w / image.width;
        scaleFactor = w / image.width;
    }
    useEffect(() => {
        if (status === "loaded" && !isNotified) {
            setNotified(true);
            props.imageLoadListener();
        }
    }, [status, isNotified]);
    return (
        <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center" ref={container}>
            {status === "failed" && <Error errorMessage={t('photo_loading_error')}/>}
            {(status === "loading" || (status !== "failed" && !image)) && <LoaderSmall/>}
            {(w && h && scaleFactor) &&
                <Stage width={w} height={h}>
                    <Layer>
                        <Image image={image} x={0} y={0} width={w} height={h} preventDefault={false}
                               scaleY={props.flipImage ? -1 : 1} offsetY={props.flipImage ? h : 0}/>
                        {markers.map((m, i) => <AwpMarkerView x={m.x} y={m.y} w={w ?? 0} h={h ?? 0}
                                                              scaleFactor={scaleFactor ?? 0}
                                                              clickListener={() => props.markerClickListener(m.index)}
                                                              key={`marker-${i}`}/>)}
                        {props.isFullScreen &&
                            <AwpFullScreenCloseButton fullWidth={w} fullHeight={h} clickListener={props.fullScreenCloseClickListener}/>
                        }
                    </Layer>
                </Stage>
            }
            {(!props.isFullScreen && props.markers && w && h) &&
                <span className="marker-instruction no-select">{t('click_on_marker')}</span>
            }
        </div>
    );
}