import {useEffect, useState} from "react";
import {del, get, set} from 'idb-keyval';
import {ArchiveFileSystemHandle, createLocalArchiveFileSystemHandle} from "./FileSystemHelpers";
import {logError, logger} from "../helpers/LogHelper";

export const useFileSystem: (handleId: string, folders: string[], updateToken?: string) => ArchiveFileSystemHandle | undefined = ((handleId, folders, updateToken) => {
    const [handle, setHandle] = useState(undefined as ArchiveFileSystemHandle | undefined);
    useEffect(() => {
        get<FileSystemDirectoryHandle>(handleId).then(handle => {
            if (handle) {
                return checkPermissions(handleId, handle, folders);
            } else {
                if (updateToken) {
                    return window.showDirectoryPicker().then(newHandle => {
                        return set(handleId, newHandle).then(() => checkPermissions(handleId, newHandle, folders));
                    }).catch(error => {
                        if ("AbortError: The user aborted a request." === `${error}`){
                            return get<FileSystemDirectoryHandle>(`${handleId}-old`).then(oldHandle => {
                               if (oldHandle){
                                   return set(handleId, oldHandle).then(() => checkPermissions(handleId, oldHandle, folders));
                               } else {
                                   return createLocalArchiveFileSystemHandle(undefined, undefined, undefined);
                               }
                            });
                        } else {
                            logError("File system handle error", error);
                            return createLocalArchiveFileSystemHandle(undefined, undefined, undefined);
                        }
                    });
                } else {
                    return createLocalArchiveFileSystemHandle(undefined, undefined, undefined);
                }
            }
        }).then(handle => {
            setHandle(handle);
        });
    }, [handleId, folders.length, updateToken]);
    return handle;
})

export function backupFsHandle(handleId : string) : Promise<void>{
    return get<FileSystemDirectoryHandle>(handleId).then(handle => {
        if (handle){
            return set(`${handleId}-old`, handle);
        }
        return Promise.resolve();
    }).then(() => {
        return del(handleId);
    });
}

function checkPermissions(handleId: string, handle: FileSystemDirectoryHandle, folders: string[]): Promise<ArchiveFileSystemHandle> {
    return handle.queryPermission({mode: 'readwrite'}).then(state => {
        if (state === 'granted') {
            return handle;
        } else {
            return handle.requestPermission({mode: 'readwrite'}).then(state => {
                if (state === 'granted') {
                    return handle;
                } else {
                    return del(handleId).then(() => null);
                }
            })
        }
    }).then(async handle => {
        if (handle) {
            let delHandleIfFailed = true;
            try {
                let newHandle = handle;
                for (const folder of folders){
                    newHandle = await newHandle.getDirectoryHandle(folder);
                    delHandleIfFailed = false;
                }
                for await (let {} of newHandle.values()) {
                    break
                }
                return createLocalArchiveFileSystemHandle(newHandle, handle.name, true, true);
            } catch (reason){
                logError("File system handle error", reason);
                if (delHandleIfFailed) {
                    await del(handleId);
                }
                return createLocalArchiveFileSystemHandle(undefined, undefined, true, false);
            }
        } else {
            return createLocalArchiveFileSystemHandle(undefined, undefined, false, undefined);
        }
    }).catch(error => {
        logError("File system permission handle error", error);
        return createLocalArchiveFileSystemHandle(undefined, undefined, false, undefined);
    });
}
