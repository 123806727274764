export const MM = "MM";
export const MKM = "MKM";
export const INCH = "INCH";
export const MILS = "MILS";

export function convertValue(tpScale: string, value: number) {
    switch (tpScale) {
        case MM:
            return value / 1000;
        case MKM:
            return value;
        case INCH:
            return value / 25400;
        case MILS:
            return value / 25.4;
        default:
            return value;
    }
}

export function getMeasurementError(tpScale : string){
    switch (tpScale){
        case MM:
            return 0.005;
        case MKM:
            return 5;
        case INCH:
            return 0.0001;
        case MILS:
            return 0.1;
        default:
            return 5;
    }
}