import {TFunction} from "i18next";

const SERVICE_MODE_KEY = "sc";
const SERVICE_MODE_CODE_QC = "31£f14k]G2v/nKVibkUHq%+6A`j/|c";
const SERVICE_MODE_CODE_PRODUCTION = "715LFsqii0Ybd-Y5g./<Eb3'qrNS'Y";

export const SERVICE_MODE_DISABLED = "dis";
export const SERVICE_MODE_QC = "qc";
export const SERVICE_MODE_PRODUCTION = "man";


export function enterServiceMode(code: string): string {
    if (code === SERVICE_MODE_CODE_QC) {
        localStorage.setItem(SERVICE_MODE_KEY, code);
        return SERVICE_MODE_QC;
    }
    if (code === SERVICE_MODE_CODE_PRODUCTION) {
        localStorage.setItem(SERVICE_MODE_KEY, code);
        return SERVICE_MODE_PRODUCTION;
    } else {
        return SERVICE_MODE_DISABLED;
    }
}

export function exitServiceMode() {
    localStorage.removeItem(SERVICE_MODE_KEY);
}

export function getServiceMode(): string {
    const code = localStorage.getItem(SERVICE_MODE_KEY);
    if (code === SERVICE_MODE_CODE_QC) {
        return SERVICE_MODE_QC;
    }
    if (code === SERVICE_MODE_CODE_PRODUCTION) {
        return SERVICE_MODE_PRODUCTION;
    }
    return SERVICE_MODE_DISABLED;
}

export function formatServiceMode(t: TFunction<"translation">, mode: string) {
    switch (mode) {
        case SERVICE_MODE_QC:
            return t('service_mode_qc');
        case SERVICE_MODE_PRODUCTION:
            return t('service_mode_production');
    }
    return "";
}
