import {useTranslation} from "react-i18next";
import './GeoLocationPlaceholder.css';
import GeoLocationNotAvailableSvg from "./GeoLocationNotAvailableSvg";

export function GeoLocationPlaceholder(){
    const {t} = useTranslation();
    return (
        <div className="container-grow d-flex flex-column justify-content-center align-items-center">
            <div className="not-location-icon">
                <GeoLocationNotAvailableSvg/>
            </div>
            <span className="mt-4 not-location text-center no-select">{t('location_not_available_placeholder')}</span>
        </div>
    );
}