import * as React from "react"

function GeoLocationNotAvailableSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 297 297"
            {...props}
        >
            <path d="M279.149 78.656a10.235 10.235 0 00-8.088-3.942c-2.207 0-4.432.71-6.307 2.175L19.618 268.409c-4.463 3.487-5.254 9.932-1.768 14.395a10.234 10.234 0 008.088 3.941c2.207 0 4.432-.709 6.307-2.174l245.137-191.52c4.463-3.488 5.254-9.932 1.767-14.395zM234.931 81.972C222.214 34.814 179.116 0 128.013 0 66.942 0 17.259 49.703 17.259 110.797c0 39.28 18.574 78.152 40.029 109.962L234.931 81.972zm-106.918-2.279c16.963 0 30.766 13.953 30.766 31.104s-13.803 31.104-30.766 31.104-30.766-13.953-30.766-31.104 13.803-31.104 30.766-31.104zM87.37 259.721c17.744 20.373 32.299 33.342 33.865 34.721a10.234 10.234 0 006.777 2.559c2.42 0 4.842-.853 6.777-2.559 3.711-3.27 80.399-71.627 99.617-149.597L87.37 259.721z" fill="currentColor"/>
        </svg>
    )
}

export default GeoLocationNotAvailableSvg
