export class Rect{
    private readonly _left : number;
    private readonly _top : number;
    private readonly _right : number;
    private readonly _bottom: number;

    constructor(left: number, top: number, right: number, bottom: number) {
        this._left = left;
        this._top = top;
        this._right = right;
        this._bottom = bottom;
    }


    get left(): number {
        return this._left;
    }

    get top(): number {
        return this._top;
    }

    get right(): number {
        return this._right;
    }

    get bottom(): number {
        return this._bottom;
    }

    get width() : number{
        return this.right - this.left;
    }

    get height() : number{
        return this.bottom - this.top;
    }
}