import './AwpBreadcrumbs.css';
import {AwpBreadcrumbItem} from "./AwpBreadcrumbItem";

interface Props {
    path: Array<AwpBreadcrumbItem>;
    locationChangeListener : (newLocation : string) => void;
}

export function AwpBreadcrumbs(props: Props) {
    const itemClickHandler = (idx : number) => {
        if (idx < props.path.length - 1) {
            props.locationChangeListener(props.path[idx].path);
        }
    };
    return (
        <div className="awp-breadcrumbs no-select">
            {props.path.flatMap((item, i) => {
                const result = [];
                if (i > 0) {
                    result.push(<span key={`d-${i}`}> / </span>);
                }
                result.push(<span key={`bc-${i}`} onClick={() => itemClickHandler(i)} className={`awp-breadcrumbs-item ${i === props.path.length - 1 ? "active-item" : ""}`}>{item.displayName}</span>);
                return result;
            })}
        </div>
    );
}