import {formatNumber} from "../../helpers/FormatHelper";
import {useTranslation} from "react-i18next";
import {TpDewPointMeasurement} from "../../models/TpDewPointMeasurement";

interface Props {
    measurement: TpDewPointMeasurement;
}

export function TpDewPointMeasurementWithoutPhoto(props: Props) {
    const {t} = useTranslation();
    return (
        <div className="d-flex flex-row flex-grow-1 align-items-center">
            <table className="measurement-info-table w-100">
                <tbody>
                <tr>
                    <th>{t('air_temperature')}</th>
                    <td>{`${formatNumber(props.measurement.airTemperature, 1, 1)} ⁰C`}</td>
                </tr>
                <tr>
                    <th>{t('air_humidity')}</th>
                    <td>{`${formatNumber(props.measurement.airHumidity, 1, 1)} %`}</td>
                </tr>
                <tr>
                    <th>{t('dew_point')}</th>
                    <td>{`${formatNumber(props.measurement.dewPoint, 1, 1)} ⁰C`}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}