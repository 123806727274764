import {MediaRecord} from "../../models/MediaRecord";
import {useEffect, useState} from "react";
import {getMediaFile} from "../../helpers/GoogleStorageApiHelper";
import {Error} from "../Error/Error";
import './PhotoViewer.css';
import {MeasurementMarker, MeasurementsWithPhoto} from "./MeasurementsWithPhoto";
import {LoaderSmall} from "../Loader/LoaderSmall";
import {ResourceNotFoundError} from "../../errors/GoogleDriveErrors";
import {NotFoundSmall} from "../NotFound/NotFoundSmall";
import {ErrorSmall} from "../Error/ErrorSmall";
import {TpSurfaceTemperatureMeasurement} from "../../models/TpSurfaceTemperatureMeasurement";

interface Props {
    drive: any;
    recordId: string;
    mediaRecord: MediaRecord;
    measurement: TpSurfaceTemperatureMeasurement[];
    isFullScreen: boolean;
    imageLoadListener?: () => void;
}

export function TpSurfaceTemperatureMeasurementPhotoViewer(props: Props) {
    const [mediaUrl, setMediaUrl] = useState(null as string | null);
    const [error, setError] = useState(null as Error | null);
    const [retry, setRetry] = useState(0);
    useEffect(() => {
        getMediaFile(props.drive, props.recordId, props.mediaRecord.fileName).then(url => {
            setMediaUrl(url);
            setError(null);
        }).catch((e) => setError(e));
    }, [props.drive, props.recordId, props.mediaRecord, retry]);
    if (error) {
        return error instanceof ResourceNotFoundError ? <NotFoundSmall/> :
            <ErrorSmall error={error} retryClickHandler={() => setRetry(retry + 1)}/>
    }
    const markers = new Array<MeasurementMarker>();
    props.measurement.forEach(m => {
        if (m.location) {
            markers.push({
                text: `${m.temperature} ⁰C`,
                location: m.location
            } as MeasurementMarker);
        }
    });
    return (
        <div className="d-flex flex-column flex-grow-1">
            {!mediaUrl ? <LoaderSmall/> : <MeasurementsWithPhoto key={`ph-${retry}`} url={mediaUrl} markers={markers}
                                                                 retryClickListener={() => setRetry(retry + 1)}
                                                                 isFullScreen={props.isFullScreen}
                                                                 imageLoadListener={props.imageLoadListener}/>}
        </div>
    );
}