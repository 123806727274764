import {AwpSerialDevice} from "../serial/AwpSerialDevice";
import {parseString, parseStrings} from "../helpers/BinaryParseHelper";
import moment from "moment/moment";
import {AWP_RECORD_TIMESTAMP_FORMAT} from "../helpers/AwpHelper";
import {encodeBase64} from "../helpers/Utils";
import {AwpUt3EmaSeries, parseAwpUt3EmaSeries} from "./AwpUt3EmaSeries";
import {AwpDirectoryHandle} from "../helpers/AwpDirectoryHandle";

export interface AwpUt3EmaRecord{
    name: string;
    date?: Date;
    series: Array<AwpUt3EmaSeries>;
    deviceSerial?: string;
    probeName?: string;
    probeType?: number;
    probeSerial?: string;
    photo?: string;
    operator?: string;
    object?: string;
    conclusion?: string;
}

export function readAwpUt3EmaRecord(fsHandle: AwpDirectoryHandle, recordName: string): Promise<AwpUt3EmaRecord | null> {
    return new Promise<AwpUt3EmaRecord | null>(async resolve => {
        let name = "";
        let date = new Date();
        let series = new Array<AwpUt3EmaSeries>();
        let photo;
        let operator;
        let object;
        let conclusion;
        const recordHandle = await fsHandle.getDirectoryHandle(recordName).catch(() => null);
        if (recordHandle) {
            const nameHandle = await recordHandle.getFileHandle(AwpSerialDevice.nameFile).catch(() => null);
            if (nameHandle) {
                const buffer = await nameHandle.getData().catch(() => null);
                if (buffer) {
                    name = parseString(buffer);
                }
                const lastModified = await nameHandle.lastModified();
                date = new Date(lastModified);
            } else {
                resolve(null);
                return;
            }
            const dateHandle = await recordHandle.getFileHandle(AwpSerialDevice.dateFile).catch(() => null);
            if (dateHandle) {
                const buffer = await dateHandle.getData().catch(() => null);
                if (buffer) {
                    date = moment(parseString(buffer), AWP_RECORD_TIMESTAMP_FORMAT).toDate();
                }
            }
            const photoFile = await recordHandle.getFileHandle(AwpSerialDevice.sygnalFile).catch(() => null);
            if (photoFile) {
                const buffer = await photoFile.getData().catch(() => null);
                if (buffer) {
                    photo = "data:image/bmp;base64," + encodeBase64(new Uint8Array(buffer));
                }
            }
            const infoFile = await recordHandle.getFileHandle(AwpSerialDevice.infoFile).catch(() => null);
            if (infoFile) {
                const info = await infoFile.getData().catch(() => null);
                if (info) {
                    const parts = parseStrings(info);
                    if (parts.length >= 1) {
                        operator = parts[0];
                    }
                    if (parts.length >= 2) {
                        object = parts[1];
                    }
                }
            }
            const conclusionFile = await recordHandle.getFileHandle(AwpSerialDevice.conclusionFile).catch(() => null);
            if (conclusionFile) {
                const info = await conclusionFile.getData().catch(() => null);
                if (info) {
                    conclusion = parseString(info);
                }
            }
            const configFile = await recordHandle.getFileHandle(AwpSerialDevice.configFile).catch(() => null);
            if (configFile){
                const configBuffer = await configFile.getData().catch(() => null);
                if (configBuffer) {
                    const ut3EmaSeries = parseAwpUt3EmaSeries(configBuffer);
                    if (ut3EmaSeries) {
                        series.push(ut3EmaSeries);
                    } else {
                        resolve(null);
                        return;
                    }
                }
            } else {
                resolve(null);
                return;
            }
            let deviceSerial;
            if (series.length > 0) {
                deviceSerial = series[0].systemSet.serial;
                if (deviceSerial.startsWith("34") && deviceSerial.length === 10){
                    deviceSerial = "0" + deviceSerial;
                }
                if ((deviceSerial.length === 10 || deviceSerial.length === 11) && deviceSerial.match(/\d*/gm)) {
                    deviceSerial = `${deviceSerial.substring(0, 3)}.${deviceSerial.substring(3, 6)}.${deviceSerial.substring(6)}`;
                }
                if (deviceSerial.length === 14 && deviceSerial.match(/\d*/gm)) {
                    deviceSerial = `${deviceSerial.substring(0, 2)}.${deviceSerial.substring(2, 6)}.${deviceSerial.substring(6, 10)}.${deviceSerial.substring(10)}`;
                }
            }
            resolve({
                name: name,
                date: date,
                deviceSerial: deviceSerial,
                series: series,
                photo: photo,
                operator: operator,
                object: object,
                conclusion: conclusion
            });
        }
    }).catch(() => {
        return null;
    });
}