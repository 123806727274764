import {useAwpDataStreamingSerialDevice} from "../../serial/SerialPortHook";
import {Fragment, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {logError, logger} from "../../helpers/LogHelper";

const SEPARATOR = ";";

interface DataValue {
    timestamp: number;
    value: string;
}

export function AwpDataStreaming() {
    const {t} = useTranslation();
    const device = useAwpDataStreamingSerialDevice();
    const [isReading, setReading] = useState(undefined as boolean | undefined);
    const [data, setData] = useState(undefined as DataValue | undefined);
    const [value, setValue] = useState(undefined as string | undefined);
    const [folderHandle, setFolderHandle] = useState(undefined as FileSystemDirectoryHandle | undefined);
    const [fileHandle, setFileHandle] = useState(undefined as FileSystemFileHandle | undefined);
    const [isRecording, setRecording] = useState(false);
    const processData = (rawData: string) => {
        setData({
            timestamp: Date.now(),
            value: rawData
        });
    }
    const connect = () => {
        setData(undefined);
        setFileHandle(undefined);
        setRecording(false);
        device.readData(setReading, processData)
            .then((isSuccess) => {
                return;
            })
            .catch((reason) => {
                logError("Data read error", reason);
                return;
            });
    };
    const disconnect = () => {
        device.abort();
    };
    const saveValue = async (handle: FileSystemDirectoryHandle | undefined, valueToSave: string | undefined) => {
        if (!handle) {
            await selectFolder();
        } else {
            const now = moment();
            if (valueToSave) {
                try {
                    const fHandle = await handle.getFileHandle(`${now.format("DD.MM.YYYY")}_${now.format("HH.mm.ss")}.csv`, {create: true});
                    const stream = await fHandle.createWritable();
                    const writer = stream.getWriter();
                    await writer.write(`${now.format("DD/MM/YYYY")}${SEPARATOR}${now.format("HH.mm.ss")}${SEPARATOR}${valueToSave}`);
                    writer.releaseLock();
                    await stream.close();
                } catch (e : any) {
                    logError("Data save error", e.message);
                }
            }
        }
    };
    const selectFolder = async () => {
        const handle = await window.showDirectoryPicker();
        if (handle) {
            const state = await handle.requestPermission({mode: 'readwrite'});
            if (state === "granted") {
                setFolderHandle(handle);
                await saveValue(handle, value);
            }
        }
    };
    const saveSingleValue = async (e: any) => {
        e.preventDefault();
        await saveValue(folderHandle, value);
    }
    const selectFile = async () => {
        const options = {
            types: [
                {
                    description: 'Text Files',
                    accept: {
                        'text/csv': ['.csv'],
                    },
                },
            ],
        };
        const handle = await window.showSaveFilePicker(options);
        setFileHandle(handle);
    };
    const saveValueToFile = async (valueToSave: string) => {
        const now = moment();
        if (valueToSave && fileHandle) {
            const writable = await fileHandle.createWritable({keepExistingData: true});
            const offset = (await fileHandle.getFile()).size
            await writable.seek(offset)
            await writable.write(`${now.format("DD/MM/YYYY")}${SEPARATOR}${now.format("HH.mm.ss")}${SEPARATOR}${valueToSave}`);
            await writable.close();
        }
    };
    const toggleRecording = () => {
        setRecording(!isRecording);
    }
    const formatData = (data?: string) => {
        if (data) {
            if (data.toLowerCase().startsWith("need to activate mode")) {
                return t("data_streaming_activation_error");
            }
            if (data.toLowerCase().startsWith("error command")) {
                return t("data_streaming_error_message");
            }
            if (data.match(/.+;.+/gm)) {
                return data.replace(SEPARATOR, " ");
            }
        }
        return "----";
    };
    useEffect(() => {
        const v = data?.value;
        if (v && v.match(/.+;.+/gm)) {
            if (isRecording) {
                saveValueToFile(v).then();
            }
            setValue(v);
        } else {
            setValue(undefined);
        }
    }, [data]);
    return (
        <div className="container-grow d-flex flex-column m-4">
            <h4 className='text-center'>{t('awp_ut3ema')}</h4>
            <div className="container-grow align-self-stretch">
                {!isReading &&
                    <div
                        className="container-grow d-flex flex-column m-4 justify-content-center align-items-center instruction-text">
                        {isReading !== undefined &&
                            <h2 className="my-2">{t('data_streaming_connection_lost_message')}</h2>
                        }
                        <Button variant="primary" className="my-2"
                                onClick={connect}>{t("connect")}</Button>
                    </div>
                }
                {isReading &&
                    <div
                        className="container-grow d-flex flex-column m-4 justify-content-center align-items-center instruction-text">
                        <h2 className="my-2">{formatData(data?.value)}</h2>
                        {value &&
                            <Fragment>
                                <Button variant="primary" className="my-2"
                                        onMouseDown={saveSingleValue}>{t("save_value")}</Button>
                                <Button variant="primary" className="my-2"
                                        onClick={selectFolder}>{t("select_folder")}</Button>
                                <Button variant="primary" className="my-2 d-none" disabled={!fileHandle}
                                        onClick={toggleRecording}>{isRecording ? t("stop_recording") : t("start_recording")}</Button>
                                <Button variant="primary" className="my-2 d-none"
                                        onClick={selectFile}>{t("select_file")}</Button>
                            </Fragment>
                        }
                        <Button variant="primary" className="my-2"
                                onClick={disconnect}>{t("disconnect")}</Button>
                    </div>
                }
            </div>
        </div>
    );
}