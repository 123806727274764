import {Button, ButtonGroup} from "react-bootstrap";

interface Props {
    className?: string;
    modes: Array<string>;
    mode: number;
    modeChangeListener: (mode: number) => void;
    size?: undefined | "sm" | "lg";
    minWidth? : string;
}

export function ModeSwitcher(props: Props) {
    return (
        <ButtonGroup className={props.className} size={props.size}>
            {props.modes.map((m, idx) => (
                <Button key={`sw-${idx}`} style={{minWidth: props.minWidth ?? "0"}} variant={props.mode === idx ? "primary" : "outline-primary"}
                        onClick={() => props.modeChangeListener(idx)}>{m}</Button>))
            }
        </ButtonGroup>
    );
}