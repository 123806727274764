import {useTranslation} from "react-i18next";
import {Button, Modal} from "react-bootstrap";
import {DeviceReadyConfirmationData} from "./AwpFwLoaderCommon";

interface Props {
    data?: DeviceReadyConfirmationData;
    closeHandler: (confirmed: boolean, type?: number, content?: ArrayBuffer) => void;
}

export function DeviceReadyConfirmationDialog(props: Props) {
    const {t} = useTranslation();
    return (
        <Modal show={props.data !== undefined} onHide={() => props.closeHandler(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t("awp_fw_upload_warning_1")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{__html: props.data?.instructions ?? ""}}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.closeHandler(false)}>{t("cancel")}</Button>
                <Button variant="primary"
                        onClick={() => props.closeHandler(true, props.data?.type, props.data?.content)}>{t("continue")}</Button>
            </Modal.Footer>
        </Modal>
    );
}