import {parseFloat, parseInt32, parseString, parseUint32, parseUint8} from "../helpers/BinaryParseHelper";

export interface AwpUd2301Series {
    probe: Sensor;
    dac: DAC;
    adc: ADC;
    asd: ASD;
    strobe: Strobe;
    screen: Screen;
    systemSet: Sys;
    // ard: ARD;
    signal: Signal
}

const CONFIG_SIZE = 1249;
const CURVE_SIZE = 160000;

const SERIAL_SIZE = 11;

const SENSOR_SIZE = 6 * 4;
const DAC_SIZE = 4 * 4 + 16 * 4 + 16 * 4;
const ADC_SIZE = 10 * 4 + 16 * 4 + 16 * 4 + 3 * 4 + 4 * 4;
const ASD_SIZE = 4 * 2 * 2 + 2 * 2 * 4 + 4 + 3 * 2 * 4 + 2 + 2 * 2 + 2 * 4;
const STROBE_SIZE = 6 * 2 * 4 + 4 + 2 * 2 * 4;
const SCREEN_SIZE = 3 * 4 + 9 * 4 + 3 * 4;
const LIST_BOX_SIZE = 4 * 2 + 2 + 4 + 3 + 2 * 4 + 7 * 2 + 2;
const SYS_SIZE = SERIAL_SIZE * 11 * LIST_BOX_SIZE;
const PALLETE_SIZE = 9 * 2 + 6 * 2 + 4 * 2 + 8 * 2 + 7 * 2;
const ARD_SIZE = 7 * 4 + 4 * 4;

const DAC_OFFSET = SENSOR_SIZE;
const ADC_OFFSET = DAC_OFFSET + DAC_SIZE;
const ASD_OFFSET = ADC_OFFSET + ADC_SIZE;
const STROBE_OFFSET = ASD_OFFSET + ASD_SIZE;
const SCREEN_OFFSET = STROBE_OFFSET + STROBE_SIZE;
const SYS_OFFSET = SCREEN_OFFSET + SCREEN_SIZE;
const ARD_OFFSET = SYS_OFFSET + SYS_SIZE + PALLETE_SIZE + LIST_BOX_SIZE;
const PALLETE_INDEX_OFFSET = 7 * 41 + 16;

interface Signal {
    data: Array<number>;
}

interface Sensor {
    type: number;
    delay: number;
    angle: number;
    vector: number;
    freq: number;
    diameter: number;
}

interface ARD {
    enable: number;
    refLevel: number;
    dBrack: number;
    dContr: number;
    dSearch: number;
    dTune: number;
    callDistans: number;
    nearZone: number;
    dBrackD: number;
    dContrD: number;
    dTuneD: number;
}

interface DAC {
    enable: number;
    refLevel: number;
    refS: number;
    pointDAC: number;
    mmDAC: Array<number>;
    levelDAC: Array<number>;
}

interface ADC {
    mmDelay: number;
    mmScan: number;
    pulseP: number;
    pulseN: number;
    pulsePause: number;
    pulseAmplitude: number;
    mode: number;
    otsechka: number;
    referens: number;
    fat: number;
    mmTVG: Array<number>;
    deltaTVG: Array<number>;
    pointTvg: number;
    tvgOnOff: number;
    tvgClear: number;
    gain: number;
    speed: number;
    filterOnOff: number;
    filterFreq: number;
}

interface ASD {
    tFront: Array<number>;
    tPic: Array<number>;
    t: Array<number>;
    aCod: Array<number>;
    adB: Array<number>;
    y: Array<number>;
    yab: number;
    x: Array<number>;
    xip: Array<number>;
    s: Array<number>;
    flag: Array<number>;
    dACod: Array<number>;
    dAdB: Array<number>;
}

interface Strobe {
    x: Array<number>;
    y: Array<number>;
    ySearch: Array<number>;
    yContr: Array<number>;
    len: Array<number>;
    mode: Array<number>;
    select: number;
    sygnal: Array<number>;
    frontPic: Array<number>;
}

interface Screen {
    filling: number;
    envelope: number;
    view: number;
    info: Array<number>;
    mmuS: number;
    midle: number;
    orientation: number;
}

interface Sys {
    serial: string;
    pallete: number;
}

function parseProbe(buffer: ArrayBuffer, baseOffset: number): Sensor {
    let offset = baseOffset;
    const type = parseInt32(buffer, offset);
    offset += 4;
    const delay = parseInt32(buffer, offset);
    offset += 4;
    const angle = parseInt32(buffer, offset);
    offset += 4;
    const vector = parseInt32(buffer, offset);
    offset += 4;
    const freq = parseInt32(buffer, offset);
    offset += 4;
    const diameter = parseInt32(buffer, offset);
    return {
        type: type,
        delay: delay,
        angle: angle,
        vector: vector,
        freq: freq,
        diameter: diameter
    }
}

function parseDac(buffer: ArrayBuffer, baseOffset: number): DAC {
    let offset = baseOffset;
    const enable = parseInt32(buffer, offset);
    offset += 4;
    const refLevel = parseInt32(buffer, offset);
    offset += 4;
    const refS = parseInt32(buffer, offset);
    offset += 4;
    const pointDAC = parseInt32(buffer, offset);
    offset += 4;
    const arraySize = 16;
    const mmDac = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const levelDac = new Int32Array(buffer, offset, arraySize);
    return {
        enable: enable,
        refLevel: refLevel,
        refS: refS,
        pointDAC: pointDAC,
        mmDAC: Array.from(mmDac),
        levelDAC: Array.from(levelDac),
    }
}

function parseAdc(buffer: ArrayBuffer, baseOffset: number): ADC {
    let offset = baseOffset;
    const mmDelay = parseInt32(buffer, offset);
    offset += 4;
    const mmScan = parseInt32(buffer, offset);
    offset += 4;
    const pulseP = parseInt32(buffer, offset);
    offset += 4;
    const pulseN = parseInt32(buffer, offset);
    offset += 4;
    const pulsePause = parseInt32(buffer, offset);
    offset += 4;
    const pulseAmplitude = parseInt32(buffer, offset);
    offset += 4;
    const mode = parseInt32(buffer, offset);
    offset += 4;
    const otsechka = parseInt32(buffer, offset);
    offset += 4;
    const referens = parseInt32(buffer, offset);
    offset += 4;
    const fat = parseInt32(buffer, offset);
    offset += 4;
    const arraySize = 16;
    const mmTVG = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const deltaTVG = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const pointTvg = parseInt32(buffer, offset);
    offset += 4;
    const tvgOnOff = parseInt32(buffer, offset);
    offset += 4;
    const tvgClear = parseInt32(buffer, offset);
    offset += 4;
    const gain = parseUint32(buffer, offset);
    offset += 4;
    const speed = parseUint32(buffer, offset);
    offset += 4;
    const filterOnOff = parseUint32(buffer, offset);
    offset += 4;
    const filterFreq = parseUint32(buffer, offset);
    return {
        mmDelay: mmDelay,
        mmScan: mmScan,
        pulseP: pulseP,
        pulseN: pulseN,
        pulsePause: pulsePause,
        pulseAmplitude: pulseAmplitude,
        mode: mode,
        otsechka: otsechka,
        referens: referens,
        fat: fat,
        mmTVG: Array.from(mmTVG),
        deltaTVG: Array.from(deltaTVG),
        pointTvg: pointTvg,
        tvgOnOff: tvgOnOff,
        tvgClear: tvgClear,
        gain: gain,
        speed: speed,
        filterOnOff: filterOnOff,
        filterFreq: filterFreq
    }
}

function parseAsd(buffer: ArrayBuffer, baseOffset: number): ASD {
    const arraySize = 2;
    let offset = baseOffset;
    const tFront = new Int16Array(buffer, offset, arraySize);
    offset += 2 * arraySize;
    const tPic = new Int16Array(buffer, offset, arraySize);
    offset += 2 * arraySize;
    const t = new Int16Array(buffer, offset, arraySize);
    offset += 2 * arraySize;
    const aCod = new Int16Array(buffer, offset, arraySize);
    offset += 2 * arraySize;
    const adB = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const y = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const yab = parseFloat(buffer, offset);
    offset += 4;
    const x = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const xip = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const s = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const flag = new Uint8Array(buffer, offset, arraySize);
    offset += arraySize;
    const daCod = new Int16Array(buffer, offset, arraySize);
    offset += 2 * arraySize;
    const newBuffer = buffer.slice(offset);
    offset = 0;
    const daDb = new Float32Array(newBuffer, offset, arraySize);
    return {
        tFront: Array.from(tFront),
        tPic: Array.from(tPic),
        t: Array.from(t),
        aCod: Array.from(aCod),
        adB: Array.from(adB),
        y: Array.from(y),
        yab: yab,
        x: Array.from(x),
        xip: Array.from(xip),
        s: Array.from(s),
        flag: Array.from(flag),
        dACod: Array.from(daCod),
        dAdB: Array.from(daDb),
    }
}

function parseStrobe(initialBuffer: ArrayBuffer, baseOffset: number): Strobe {
    const arraySize = 2;
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const x = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const y = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const ySearch = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const yContr = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const len = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const mode = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const select = parseInt32(buffer, offset);
    offset += 4;
    const sygnal = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const frontPic = new Int32Array(buffer, offset, arraySize);
    return {
        x: Array.from(x),
        y: Array.from(y),
        ySearch: Array.from(ySearch),
        yContr: Array.from(yContr),
        len: Array.from(len),
        mode: Array.from(mode),
        select: select,
        sygnal: Array.from(sygnal),
        frontPic: Array.from(frontPic),
    }
}

function parseScreen(initialBuffer: ArrayBuffer, baseOffset: number): Screen {
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const filling = parseInt32(buffer, offset);
    offset += 4;
    const envelope = parseInt32(buffer, offset);
    offset += 4;
    const view = parseInt32(buffer, offset);
    offset += 4;
    const arraySize = 9;
    const info = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const mmuS = parseInt32(buffer, offset);
    offset += 4;
    const midle = parseInt32(buffer, offset);
    offset += 4;
    const orientation = parseInt32(buffer, offset);
    return {
        filling: filling,
        envelope: envelope,
        view: view,
        info: Array.from(info),
        mmuS: mmuS,
        midle: midle,
        orientation: orientation
    }
}

function parseSys(buffer: ArrayBuffer, baseOffset: number): Sys {
    const serial = parseString(buffer, baseOffset, SERIAL_SIZE);
    const pallete = parseUint8(buffer, baseOffset + serial.length + 1 + PALLETE_INDEX_OFFSET);
    return {
        serial: serial,
        pallete: pallete
    }
}

function parseArd(initialBuffer: ArrayBuffer, baseOffset: number): ARD {
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const enable = parseInt32(buffer, offset);
    offset += 4;
    const refLevel = parseInt32(buffer, offset);
    offset += 4;
    const dBrack = parseInt32(buffer, offset);
    offset += 4;
    const dContr = parseInt32(buffer, offset);
    offset += 4;
    const dSearch = parseInt32(buffer, offset);
    offset += 4;
    const dTune = parseInt32(buffer, offset);
    offset += 4;
    const callDistans = parseInt32(buffer, offset);
    offset += 4;
    const nearZone = parseFloat(buffer, offset);
    offset += 4;
    const dBrackD = parseFloat(buffer, offset);
    offset += 4;
    const dContrD = parseFloat(buffer, offset);
    offset += 4;
    const dTuneD = parseFloat(buffer, offset);
    return {
        enable: enable,
        refLevel: refLevel,
        dBrack: dBrack,
        dContr: dContr,
        dSearch: dSearch,
        dTune: dTune,
        callDistans: callDistans,
        nearZone: nearZone,
        dBrackD: dBrackD,
        dContrD: dContrD,
        dTuneD: dTuneD
    }
}

function parseSignal(buffer: ArrayBuffer): Signal {
    const data = new Int16Array(buffer);
    return {
        data: Array.from(data)
    };
}

export function parseAwpUd2301Series(configBuffer: ArrayBuffer, signalBuffer: ArrayBuffer): AwpUd2301Series | null{
    if (configBuffer.byteLength !== CONFIG_SIZE && signalBuffer.byteLength !== CURVE_SIZE){
        return null;
    }
    const probe = parseProbe(configBuffer, 0);
    const dac = parseDac(configBuffer, DAC_OFFSET);
    const adc = parseAdc(configBuffer, ADC_OFFSET);
    const asd = parseAsd(configBuffer, ASD_OFFSET);
    const strobe = parseStrobe(configBuffer, STROBE_OFFSET);
    const screen = parseScreen(configBuffer, SCREEN_OFFSET);
    const systemSet = parseSys(configBuffer, SYS_OFFSET);
    // const ard = parseArd(configBuffer, ARD_OFFSET);
    const signal = parseSignal(signalBuffer);
    return {
        probe: probe,
        dac: dac,
        adc: adc,
        asd: asd,
        strobe: strobe,
        screen: screen,
        systemSet: systemSet,
        // ard: ard,
        signal: signal
    }
}