import './MeasurementInfo.css';
import {useTranslation} from "react-i18next";

interface Props {
    avg: string;
    min?: string;
    max?: string;
    count?: string;
    detailsClickListener?: () => void;
}

export function MeasurementInfo(props: Props) {
    const {t} = useTranslation();
    return (
        <div className="d-flex flex-column align-items-center">
            <div className="d-flex flex-row align-items-center align-self-stretch">
                <div className="d-flex flex-grow-1 justify-content-center align-items-center measurement-info-avg">
                    {props.avg}
                </div>
                {props.min && props.max &&
                    <table className="measurement-info-table">
                        <tbody>
                        <tr>
                            <th>{t('min')}</th>
                            <td>{props.min}</td>
                        </tr>
                        <tr>
                            <th>{t('max')}</th>
                            <td>{props.max}</td>
                        </tr>
                        {props.count &&
                            <tr>
                                <th>{t('measurements')}</th>
                                <td>{props.count}</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                }
            </div>
            {props.detailsClickListener &&
                <div className="measurement-info-details" onClick={props.detailsClickListener}>{t('details')}</div>
            }
        </div>
    );
}