import {IS_DEMO_APP} from "../../AppSettings";
import {AwpDemoManager} from "./AwpDemoManager";
import {AwpLocalManager} from "./AwpLocalManager";

interface Props {
    deviceType: number;
}

export function AwpManager(props: Props) {
    if (IS_DEMO_APP) {
        return AwpDemoManager(props);
    } else {
        return AwpLocalManager(props);
    }
}

