import {getAllMeasurements, UtMeasurement} from "../../models/UtMeasurement";
import {MeasurementInfo} from "./MeasurementInfo";
import {formatUtScaleName, formatUtScaleValue} from "../../helpers/FormatHelper";
import {convertValue} from "../../models/UtScale";
import {useTranslation} from "react-i18next";

interface Props{
    measurement : UtMeasurement;
    showAverage: boolean;
    detailsClickListener: (measurement : UtMeasurement) => void;
}

export function UtMeasurementWithoutPhoto(props : Props){
    const {t} = useTranslation();
    let min = Number.MAX_VALUE;
    let max = -Number.MAX_VALUE;
    let measurement = props.measurement;
    let totalSum = 0;
    let count = 0;
    for (const contact of measurement.measurementContacts) {
        if (contact.rawMeasurements.length > 0) {
            let value;
            if (props.showAverage) {
                let sum = 0;
                for (const rawMeasurement of contact.rawMeasurements) {
                    sum += rawMeasurement.thickness;
                }
                value = sum / contact.rawMeasurements.length;
            } else {
                value = contact.rawMeasurements[contact.rawMeasurements.length - 1].thickness;
            }
            min = Math.min(min, value);
            max = Math.max(max, value);
            totalSum += value;
            count++;
        }
    }
    let totalAvg = totalSum / count;
    const avgString = `${formatUtScaleValue(convertValue(props.measurement.utScale, totalAvg), props.measurement.discreteness)} ${formatUtScaleName(t, props.measurement.utScale)}`;
    const minString = formatUtScaleValue(convertValue(props.measurement.utScale, min), props.measurement.discreteness);
    const maxString = formatUtScaleValue(convertValue(props.measurement.utScale, max), props.measurement.discreteness);
    const detailsClickHandler = () => {
        props.detailsClickListener(props.measurement);
    };
    return (
        <MeasurementInfo avg={avgString} min={minString} max={maxString} detailsClickListener={detailsClickHandler}/>
    );
}