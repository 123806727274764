import NotFoundSvg from "./NotFoundSvg";
import './NotFound.css';
import {useNavigate} from "react-router-dom";
import {buildListPath} from "../../routes";
import {useTranslation} from "react-i18next";

export function NotFound(){
    const  {t} = useTranslation();
    const history = useNavigate();
    return (
        <div className="container-grow d-flex flex-column justify-content-center align-items-center">
            <div className="not-found-icon">
                <NotFoundSvg/>
            </div>
            <span className="mt-4 not-found">{t('not_found')}</span>
            <span className="mt-4 not-found-link" onClick={() => history(buildListPath())}>{t('return_to_main_page')}</span>
        </div>
    );
}