import {AwpSerialDevice} from "../serial/AwpSerialDevice";
import {AwpTudRecord, readAwpTudRecord} from "../models/AwpTudRecord";
import {AwpTpRecord, readAwpTpRecord} from "../models/AwpTpRecord";
import {
    AwpTudSeries,
    getAwpTudSeriesTitle,
    parseTudSeries,
    prepareAwpTudMarkers
} from "../models/AwpTudSeries";
import {AwpTpSeries, getAwpTpSeriesTitle, parseTpSeries} from "../models/AwpTpSeries";
import {TFunction} from "i18next";
import {formatAwpTudKrcProbeType, formatAwpTudKrcValue} from "./AwpTudKrcFormatHelper";
import {formatAwpTpProbeType, formatAwpTpValue} from "./AwpTpFormatHelper";
import {formatNumber} from "./FormatHelper";
import {AwpRecordInfo} from "../models/AwpRecordInfo";
import {openFileHandle} from "../filesystem/FileSystemHelpers";
import {parseString} from "./BinaryParseHelper";
import moment from "moment/moment";
import {AwpUtRecord, readAwpUtRecord} from "../models/AwpUtRecord";
import {AwpUtSeries, parseUtSeries} from "../models/AwpUtSeries";
import {formatAwpUtValue} from "./AwpUtFormatHelper";
import {AwpMfRecord, readAwpMfRecord} from "../models/AwpMfRecord";
import {AwpMfSeries, parseMfSeries} from "../models/AwpMfSeries";
import {formatAwpMfValue} from "./AwpMfFormatHelper";
import {AwpUd2301Record, readAwpUd2301Record} from "../models/AwpUd2301Record";
import {AwpUd2301Series, parseAwpUd2301Series} from "../models/AwpUd2301Series";
import {AwpUd2303Record, readAwpUd2303Record} from "../models/AwpUd2303Record";
import {AwpUd2303Series, parseAwpUd2303Series} from "../models/AwpUd2303Series";
import {formatAwpUd2303ProbeType} from "./AwpUd2303FormatHelper";
import {AwpUd3701Series, parseAwpUd3701Series} from "../models/AwpUd3701Series";
import {AwpUd3701Record, readAwpUd3701Record} from "../models/AwpUd3701Record";
import {AwpUt3EmaRecord, readAwpUt3EmaRecord} from "../models/AwpUt3EmaRecord";
import {AwpUt3EmaSeries, parseAwpUt3EmaSeries} from "../models/AwpUt3EmaSeries";
import {AwpUt2aRecord, readAwpUt2aRecord} from "../models/AwpUt2aRecord";
import {AwpUt2aSeries, parseAwpUt2aSeries} from "../models/AwpUt2aSeries";
import {TextEncoder} from "text-encoding";
import {AwpDirectoryHandle} from "./AwpDirectoryHandle";
import {readAwpKrcRecord} from "../models/AwpKrcRecord";
import {AwpKrcSeries, getAwpKrcSeriesTitle, parseKrcSeries, prepareAwpKrcMarkers} from "../models/AwpKrcSeries";
import {AwpIpsmRecord, readAwpIpsmRecord} from "../models/AwpIpsmRecord";
import {AwpIpsmMeasurement, AwpIpsmSeries, parseIpsmSeries} from "../models/AwpIpsmSeries";
import {formatAwpIpsmValue} from "./AwpIpsmFormatHelper";
import {logError, logger} from "./LogHelper";

const TUD_HANDLE = "awp_tud";
const KRC_HANDLE = "awp_krc";
const TP_HANDLE = "awp_tp";
const UT_HANDLE = "awp_ut";
const MF_HANDLE = "awp_mf";
const UD2301_HANDLE = "awp_ud2301";
const UD2303_HANDLE = "awp_ud2303";
const UD3701_HANDLE = "awp_ud3701";
const UT3EMA_HANDLE = "awp_ut3ema";
const UT2A_HANDLE = "awp_ut2a";
const IPSM_HANDLE = "awp_ipsm";

export const AWP_RECORD_TIMESTAMP_FORMAT = "DD.MM.YYYY HH.mm";

export const AWP_DEVICE_TUD = 1;
export const AWP_DEVICE_KRC = 2;
export const AWP_DEVICE_TP = 3;
export const AWP_DEVICE_UT = 4;
export const AWP_DEVICE_MF = 5;
export const AWP_DEVICE_UD2301 = 6;
export const AWP_DEVICE_UD2303 = 7;
export const AWP_DEVICE_UD3701 = 8;
export const AWP_DEVICE_UT3EMA = 9;
export const AWP_DEVICE_UT2A = 10;
export const AWP_DEVICE_IPSM = 11;

export type AwpRecord =
    AwpTudRecord
    | AwpTpRecord
    | AwpUtRecord
    | AwpMfRecord
    | AwpUd2301Record
    | AwpUd2303Record
    | AwpUd3701Record
    | AwpUt3EmaRecord
    | AwpUt2aRecord
    | AwpIpsmRecord;

export type AwpSeries =
    AwpTudSeries
    | AwpTpSeries
    | AwpUtSeries
    | AwpMfSeries
    | AwpUd2301Series
    | AwpUd2303Series
    | AwpUd3701Series
    | AwpUt3EmaSeries
    | AwpUt2aSeries
    | AwpIpsmSeries;

export function hasAwpPhoto(deviceType: number): boolean {
    switch (deviceType) {
        case AWP_DEVICE_TUD:
        case AWP_DEVICE_KRC:
        case AWP_DEVICE_TP:
            return true;
    }
    return false;
}

export function hasAwpScreen(deviceType: number): boolean {
    switch (deviceType) {
        case AWP_DEVICE_UD2301:
        case AWP_DEVICE_UD2303:
        case AWP_DEVICE_UD3701:
        case AWP_DEVICE_UT3EMA:
        case AWP_DEVICE_UT2A:
            return true;
    }
    return false;
}

export function getAwpFsHandle(deviceType: number): string {
    switch (deviceType) {
        case AWP_DEVICE_TUD:
            return TUD_HANDLE;
        case AWP_DEVICE_KRC:
            return KRC_HANDLE;
        case AWP_DEVICE_TP:
            return TP_HANDLE;
        case AWP_DEVICE_UT:
            return UT_HANDLE;
        case AWP_DEVICE_MF:
            return MF_HANDLE;
        case AWP_DEVICE_UD2301:
            return UD2301_HANDLE;
        case AWP_DEVICE_UD2303:
            return UD2303_HANDLE;
        case AWP_DEVICE_UD3701:
            return UD3701_HANDLE;
        case AWP_DEVICE_UT3EMA:
            return UT3EMA_HANDLE;
        case AWP_DEVICE_UT2A:
            return UT2A_HANDLE;
        case AWP_DEVICE_IPSM:
            return IPSM_HANDLE;
    }
    return "";
}

export function readAwpRecord(deviceType: number, fsHandle: AwpDirectoryHandle, recordName: string): Promise<AwpRecord | null> {
    switch (deviceType) {
        case AWP_DEVICE_TUD:
            return readAwpTudRecord(fsHandle, recordName);
        case AWP_DEVICE_KRC:
            return readAwpKrcRecord(fsHandle, recordName);
        case AWP_DEVICE_TP:
            return readAwpTpRecord(fsHandle, recordName);
        case AWP_DEVICE_UT:
            return readAwpUtRecord(fsHandle, recordName);
        case AWP_DEVICE_MF:
            return readAwpMfRecord(fsHandle, recordName);
        case AWP_DEVICE_UD2301:
            return readAwpUd2301Record(fsHandle, recordName);
        case AWP_DEVICE_UD2303:
            return readAwpUd2303Record(fsHandle, recordName);
        case AWP_DEVICE_UD3701:
            return readAwpUd3701Record(fsHandle, recordName);
        case AWP_DEVICE_UT3EMA:
            return readAwpUt3EmaRecord(fsHandle, recordName);
        case AWP_DEVICE_UT2A:
            return readAwpUt2aRecord(fsHandle, recordName);
        case AWP_DEVICE_IPSM:
            return readAwpIpsmRecord(fsHandle, recordName);
        default:
            return Promise.resolve(null);
    }
}

export async function readAwpRecordInfo(deviceType: number, entry: AwpDirectoryHandle): Promise<AwpRecordInfo | null> {
    try {
        const [nameFile, seriesFile, dateFile, configFile, curveFile, probeFile] = await Promise.allSettled([
            openFileHandle(entry, AwpSerialDevice.nameFile),
            openFileHandle(entry, AwpSerialDevice.getSeriesFileName(1)),
            openFileHandle(entry, AwpSerialDevice.dateFile),
            openFileHandle(entry, AwpSerialDevice.configFile),
            openFileHandle(entry, AwpSerialDevice.curveFile),
            openFileHandle(entry, AwpSerialDevice.probeFile)
        ]);
        if (nameFile.status === "fulfilled") {
            const name = parseString(nameFile.value.data);
            let timestamp;
            if (dateFile.status === "fulfilled") {
                const date = parseString(dateFile.value.data);
                if (date !== '03.07.1963 23.00') {
                    timestamp = moment(date, AWP_RECORD_TIMESTAMP_FORMAT).toDate();
                }
            } else {
                timestamp = nameFile.value.timestamp;
            }
            let probeName;
            let probeType;
            let probeId;
            let deviceId;
            if (deviceType === AWP_DEVICE_TUD || deviceType === AWP_DEVICE_KRC || deviceType === AWP_DEVICE_TP || deviceType === AWP_DEVICE_UT || deviceType === AWP_DEVICE_MF || deviceType === AWP_DEVICE_MF || deviceType === AWP_DEVICE_IPSM) {
                if (seriesFile.status === "fulfilled") {
                    switch (deviceType) {
                        case AWP_DEVICE_TUD:
                            const tudSeries = parseTudSeries(seriesFile.value.data);
                            if (!tudSeries) {
                                return null;
                            }
                            probeType = tudSeries.probeParam.typeProbe;
                            probeId = tudSeries.probeParam.serial;
                            deviceId = tudSeries.systemSet.serial;
                            if (deviceId.startsWith("006")){
                                timestamp = undefined;
                            }
                            break;
                        case AWP_DEVICE_KRC:
                            const krcSeries = parseKrcSeries(seriesFile.value.data);
                            if (!krcSeries) {
                                return null;
                            }
                            probeType = krcSeries.probeParam.typeProbe;
                            probeId = krcSeries.probeParam.serial;
                            deviceId = krcSeries.systemSet.serial;
                            break;
                        case AWP_DEVICE_TP:
                            const tpSeries = parseTpSeries(seriesFile.value.data);
                            if (!tpSeries) {
                                return null;
                            }
                            probeType = tpSeries.probeType;
                            probeName = tpSeries.probeName;
                            probeId = tpSeries.probeSerial;
                            deviceId = tpSeries.deviceSerial;
                            break;
                        case AWP_DEVICE_UT:
                            const utSeries = parseUtSeries(seriesFile.value.data);
                            if (!utSeries) {
                                return null;
                            }
                            probeName = utSeries.probeName;
                            deviceId = utSeries.deviceSerial;
                            break;
                        case AWP_DEVICE_MF:
                            const mfSeries = parseMfSeries(seriesFile.value.data);
                            if (!mfSeries) {
                                return null;
                            }
                            probeName = mfSeries.probeName;
                            probeId = mfSeries.probeSerial;
                            deviceId = mfSeries.deviceSerial;
                            break;
                        case AWP_DEVICE_IPSM:
                            const ipsmSeries = parseIpsmSeries(seriesFile.value.data);
                            if (!ipsmSeries) {
                                return null;
                            }
                            deviceId = ipsmSeries[0].deviceSerial;
                            break;
                    }
                } else {
                    return null;
                }
            }
            if (deviceType === AWP_DEVICE_UT3EMA) {
                if (configFile.status === "fulfilled") {
                    const ut3emaSeries = parseAwpUt3EmaSeries(configFile.value.data);
                    if (!ut3emaSeries) {
                        return null;
                    }
                    deviceId = ut3emaSeries.systemSet.serial;
                    if (deviceId.startsWith("34") && deviceId.length === 10){
                        deviceId = "0" + deviceId;
                    }
                    if (deviceId.length === 14 && deviceId.match(/\d*/gm)) {
                        deviceId = `${deviceId.substring(0, 2)}.${deviceId.substring(2, 6)}.${deviceId.substring(6, 10)}.${deviceId.substring(10)}`;
                    }
                } else {
                    return null;
                }
            }
            if (deviceType === AWP_DEVICE_UT2A) {
                if (configFile.status === "fulfilled" && probeFile.status === "fulfilled") {
                    const ut2aSeries = parseAwpUt2aSeries(configFile.value.data, probeFile.value.data);
                    if (!ut2aSeries) {
                        return null;
                    }
                    deviceId = ut2aSeries.systemSet.serial;
                    if (deviceId.startsWith("42") && deviceId.length === 10){
                        deviceId = "0" + deviceId;
                    }
                } else {
                    return null;
                }
            }
            if (deviceType === AWP_DEVICE_UD2301 || deviceType === AWP_DEVICE_UD2303 || deviceType === AWP_DEVICE_UD3701) {
                if (configFile.status === "fulfilled" && curveFile.status === "fulfilled") {
                    switch (deviceType) {
                        case AWP_DEVICE_UD2301:
                            const ud2301Series = parseAwpUd2301Series(configFile.value.data, curveFile.value.data);
                            if (!ud2301Series) {
                                return null;
                            }
                            deviceId = ud2301Series.systemSet.serial;
                            break;
                        case AWP_DEVICE_UD2303:
                            const ud2303Series = parseAwpUd2303Series(configFile.value.data, curveFile.value.data);
                            if (!ud2303Series) {
                                return null;
                            }
                            deviceId = ud2303Series.systemSet.serial;
                            probeType = ud2303Series.probe.type;
                            break;
                        case AWP_DEVICE_UD3701:
                            const ud3701Series = parseAwpUd3701Series(configFile.value.data, curveFile.value.data);
                            if (!ud3701Series) {
                                return null;
                            }
                            deviceId = ud3701Series.systemSet.serial;
                            break;
                    }
                } else {
                    return null;
                }
            }
            if (!deviceId){
                deviceId = "";
            }
            if ((deviceId.length === 10 || deviceId.length === 11) && deviceId.match(/\d*/gm)) {
                deviceId = `${deviceId.substring(0, 3)}.${deviceId.substring(3, 6)}.${deviceId.substring(6)}`;
            }
            if (deviceId.length === 14 && deviceId.match(/\d*/gm)) {
                deviceId = `${deviceId.substring(0, 2)}.${deviceId.substring(2, 6)}.${deviceId.substring(6, 10)}.${deviceId.substring(10)}`;
            }
            return {
                fileName: entry.name(),
                isFolder: false,
                name: name,
                date: timestamp,
                probeName: probeName,
                probeType: probeType,
                probeId: probeId,
                deviceId: deviceId
            };
        }
    } catch (exception) {
        logError("Record read error", exception);
    }
    return null;
}

export function saveAwpRecordInfo(fsHandle: AwpDirectoryHandle, recordName: string, operator: string, object: string): Promise<void> {
    return new Promise(async resolve => {
            const handle = await fsHandle.getDirectoryHandle(recordName);
            if (handle) {
                const recordHandle = handle.getFileSystemHandle();
                if (recordHandle) {
                    const infoFile = await recordHandle.getFileHandle(AwpSerialDevice.infoFile, {create: true});
                    const stream = await infoFile.createWritable();
                    const writer = stream.getWriter();
                    const encodedData = new TextEncoder('windows-1251', {NONSTANDARD_allowLegacyEncoding: true}).encode(operator + "\0" + object);
                    await writer.write(encodedData);
                    writer.releaseLock();
                    await stream.close();
                }
            }
            resolve();
        }
    );
}

export function saveAwpRecordConclusion(fsHandle: AwpDirectoryHandle, recordName: string, conclusion: string): Promise<void> {
    return new Promise(async resolve => {
            const handle = await fsHandle.getDirectoryHandle(recordName);
            if (handle) {
                const recordHandle = handle.getFileSystemHandle();
                if (recordHandle) {
                    const conclusionFile = await recordHandle.getFileHandle(AwpSerialDevice.conclusionFile, {create: true});
                    const stream = await conclusionFile.createWritable();
                    const writer = await stream.getWriter();
                    const encodedData = new TextEncoder('windows-1251', {NONSTANDARD_allowLegacyEncoding: true}).encode(conclusion + "\0");
                    await writer.write(encodedData);
                    writer.releaseLock();
                    await stream.close();
                }
            }
            resolve();
        }
    );
}

export function prepareAwpMarkers(deviceType: number, series: AwpSeries[]) {
    switch (deviceType) {
        case AWP_DEVICE_TUD:
            return prepareAwpTudMarkers(series as AwpTudSeries[]);
        case AWP_DEVICE_KRC:
            return prepareAwpKrcMarkers(series as AwpKrcSeries[]);
        case AWP_DEVICE_TP:
        case AWP_DEVICE_UT:
        case AWP_DEVICE_MF:
        case AWP_DEVICE_UD2301:
        case AWP_DEVICE_UD2303:
        case AWP_DEVICE_UD3701:
        case AWP_DEVICE_UT3EMA:
        case AWP_DEVICE_UT2A:
        case AWP_DEVICE_IPSM:
            throw new Error("Wrong device");
    }
    return [];
}

export function getAwpSeriesTitle(deviceType: number, t: TFunction<"translation">, series: AwpSeries) {
    switch (deviceType) {
        case AWP_DEVICE_TUD:
            return getAwpTudSeriesTitle(t, series as AwpTudSeries);
        case AWP_DEVICE_KRC:
            return getAwpKrcSeriesTitle(t, series as AwpKrcSeries);
        case AWP_DEVICE_TP:
            return getAwpTpSeriesTitle(t, series as AwpTpSeries);
        case AWP_DEVICE_IPSM:
        case AWP_DEVICE_UT:
        case AWP_DEVICE_MF:
        case AWP_DEVICE_UD2301:
        case AWP_DEVICE_UD2303:
        case AWP_DEVICE_UD3701:
        case AWP_DEVICE_UT3EMA:
        case AWP_DEVICE_UT2A:
            return undefined;
    }
    return "";
}

export function formatAwpValue(deviceType: number, value: number, series: AwpSeries) {
    switch (deviceType) {
        case AWP_DEVICE_TUD:
        case AWP_DEVICE_KRC:
            return formatAwpTudKrcValue(value);
        case AWP_DEVICE_TP:
            const tpSeries = series as AwpTpSeries;
            return formatAwpTpValue(value, tpSeries.scale);
        case AWP_DEVICE_UT:
            return formatAwpUtValue(value);
        case AWP_DEVICE_MF:
            const mfSeries = series as AwpMfSeries;
            return formatAwpMfValue(value, mfSeries.scale);
        case AWP_DEVICE_IPSM:
        case AWP_DEVICE_UD2301:
        case AWP_DEVICE_UD2303:
        case AWP_DEVICE_UD3701:
        case AWP_DEVICE_UT3EMA:
        case AWP_DEVICE_UT2A:
            break;
    }
    return formatNumber(value, 1, 1);
}

export function formatAwpProbeName(t: TFunction<"translation">, deviceType: number, record: AwpRecord) {
    switch (deviceType) {
        case AWP_DEVICE_TUD:
        case AWP_DEVICE_KRC:
            return formatAwpTudKrcProbeType(t, record.probeType);
        case AWP_DEVICE_TP:
            return formatAwpTpProbeType(t, record.probeSerial, record.probeName);
        case AWP_DEVICE_UT:
            return record.probeName;
        case AWP_DEVICE_MF:
            return record.probeName;
        case AWP_DEVICE_UD2301:
        case AWP_DEVICE_UD3701:
        case AWP_DEVICE_UT3EMA:
        case AWP_DEVICE_UT2A:
        case AWP_DEVICE_IPSM:
            throw new Error("Wrong device");
        case AWP_DEVICE_UD2303:
            return formatAwpUd2303ProbeType(t, record.probeType);
    }
    return "";
}