import {useTranslation} from "react-i18next";
import {AwpSeries} from "../../helpers/AwpHelper";
import './AwpSeriesInfo.css';
import {AwpUd3701Series} from "../../models/AwpUd3701Series";
import {getUd3701StrobeInfo} from "../../helpers/AwpUd3701FormatHelper";

interface Props {
    data: AwpSeries;
}

export function AwpUd3701StrobeInfo(props: Props) {
    const {t} = useTranslation();
    const data = props.data as AwpUd3701Series;
    const strobeInfo = getUd3701StrobeInfo(t, data);
    let strobeAColor = "#0f0";
    let strobeBColor = "#00f";
    if (data.systemSet.pallete === 1){
        strobeAColor = "#f00";
        strobeBColor = "#0f0";
    }
    return (
        <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-stretch">
            <table className="table-data">
                <thead>
                <tr>
                    <th/>
                    <th>{t('awp_gate_a')}</th>
                    <th>{t('awp_gate_b')}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{t('awp_start_mm')}</td>
                    <td>{strobeInfo.startMm1}</td>
                    <td>{strobeInfo.startMm2}</td>
                </tr>
                <tr>
                    <td>{t('awp_start_us')}</td>
                    <td>{strobeInfo.startUs1}</td>
                    <td>{strobeInfo.startUs2}</td>
                </tr>
                <tr>
                    <td>{t('awp_width_mm')}</td>
                    <td>{strobeInfo.widthMm1}</td>
                    <td>{strobeInfo.widthMm2}</td>
                </tr>
                <tr>
                    <td>{t('awp_width_us')}</td>
                    <td>{strobeInfo.widthUs1}</td>
                    <td>{strobeInfo.widthUs2}</td>
                </tr>
                <tr>
                    <td>{t('awp_level')}</td>
                    <td>{strobeInfo.level1}</td>
                    <td>{strobeInfo.level2}</td>
                </tr>
                <tr>
                    <td>{t('awp_control_db')}</td>
                    <td>{strobeInfo.control1}</td>
                    <td>{strobeInfo.control2}</td>
                </tr>
                <tr>
                    <td>{t('awp_search_db')}</td>
                    <td>{strobeInfo.search1}</td>
                    <td>{strobeInfo.search2}</td>
                </tr>
                <tr>
                    <td>{t('awp_mode')}</td>
                    <td>{strobeInfo.mode1}</td>
                    <td>{strobeInfo.mode2}</td>
                </tr>
                <tr>
                    <td>{t('awp_color')}</td>
                    <td className="h-100">
                        <div style={{width: "80%", height: "16px", background: strobeAColor, margin: "auto"}}/>
                    </td>
                    <td className="h-100">
                        <div style={{width: "80%", height: "16px", background: strobeBColor, margin: "auto"}}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}