import {Alert, Button, Form} from "react-bootstrap";
import IconPasteSvg from "../Icons/IconPasteSvg";
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import {
    enterServiceMode,
    exitServiceMode,
    formatServiceMode,
    getServiceMode,
    SERVICE_MODE_DISABLED
} from "../../helpers/ServiceModeHelper";
import {useNavigate} from "react-router-dom";
import "./Service.css"

export function Service() {
    const {t} = useTranslation();
    const history = useNavigate();
    const codeRef = useRef<HTMLInputElement>(null);
    const [showToast, setShowToast] = useState(false);
    const [timerId, setTimerId] = useState(null as NodeJS.Timeout | null);
    const [activeServiceMode, setActiveServiceMode] = useState(null as string | null);
    useEffect(() => {
        setActiveServiceMode(getServiceMode());
    }, []);
    const pasteButtonClickHandler = () => {
        navigator.clipboard.readText().then(text => {
            const inputElement = codeRef.current;
            if (inputElement) {
                inputElement.value = text;
            }
        });
    };
    const hideToast = () => {
        if (timerId) {
            clearTimeout(timerId);
            setTimerId(null);
        }
        setShowToast(false);
    }
    const applyButtonClickHandler = () => {
        const inputElement = codeRef.current;
        if (inputElement) {
            const code = inputElement.value as string;
            const mode = enterServiceMode(code);
            if (mode !== SERVICE_MODE_DISABLED) {
                window.location.reload();
            } else {
                if (timerId) {
                    clearTimeout(timerId);
                    setTimerId(null);
                }
                setShowToast(true);
                const id = setTimeout(hideToast, 2000);
                setTimerId(id);
            }
        }
    };
    const exitButtonClickHandler = () => {
        exitServiceMode();
        window.location.reload();
    }
    if (activeServiceMode !== null && activeServiceMode === SERVICE_MODE_DISABLED) {
        return (<div
            className="container-grow d-flex flex-column m-4 justify-content-center align-items-center code-input-text">
            <div className="d-flex flex-column align-items-center mt-2">
                <div className="my-2">{t('service_enter_code')}</div>
                <div className="d-flex align-self-stretch flex-row justify-content-between align-items-center my-2">
                    <Form.Control type="text" placeholder={t("service_mode_code")} ref={codeRef}
                                  className="flex-grow"/>
                    <Button className="ms-4" variant="outline-primary"
                            onClick={pasteButtonClickHandler}><IconPasteSvg/></Button>
                </div>
            </div>
            <Button variant="primary" className="mb-4"
                    onClick={applyButtonClickHandler}>{t('service_apply_code')}</Button>
            <Alert show={showToast} variant={"danger"} className="toast-container-service" onClose={() => hideToast()}
                   dismissible>
                <span
                    className="d-flex justify-content-center align-items-center toast-text-service">{t("service_wrong_code")}</span>
            </Alert>
        </div>);
    }
    if (activeServiceMode !== null && activeServiceMode !== SERVICE_MODE_DISABLED) {
        return (<div
            className="container-grow d-flex flex-column m-4 justify-content-center align-items-center">
            <h5 className="my-2">{t('service_mode')}</h5>
            <div className="my-2">{formatServiceMode(t, activeServiceMode)}</div>
            <div
                className="container-grow d-flex flex-column m-4 justify-content-center align-items-center">
                <Button variant="primary" className="mb-4"
                        onClick={exitButtonClickHandler}>{t('service_mode_exit')}</Button>
            </div>
        </div>);
    }
    return <div/>
}
