import {MediaRecord} from "../../models/MediaRecord";
import {useEffect, useState} from "react";
import {getMediaFile, getMediaRecordUrl} from "../../helpers/GoogleStorageApiHelper";
import {Error} from "../Error/Error";
import {useAbortStatus} from "../../hooks/AbortHook";
import {LoaderSmall} from "../Loader/LoaderSmall";
import {ResourceNotFoundError} from "../../errors/GoogleDriveErrors";
import {NotFoundSmall} from "../NotFound/NotFoundSmall";
import {ErrorSmall} from "../Error/ErrorSmall";
import {isIOS, isMacOs} from "react-device-detect";

interface Props{
    drive: any;
    recordId: string;
    mediaRecord: MediaRecord;
}

export function AudioPlayer(props : Props){
    const isAborted = useAbortStatus();
    const [mediaUrl, setMediaUrl] = useState(null as string | null);
    const [error, setError] = useState(null as Error | null);
    const [retry, setRetry] = useState(0);
    const isApple = isMacOs || isIOS;

    useEffect(() => {
        (isApple ? getMediaFile(props.drive, props.recordId, props.mediaRecord.fileName) : getMediaRecordUrl(props.drive, props.recordId, props.mediaRecord.fileName)).then(url => {
            if (!isAborted) {
                setMediaUrl(url);
                setError(null);
            }
        }).catch((e) => {
            if (!isAborted) {
                setError(e);
            }
        });
    }, [props.drive, props.recordId, props.mediaRecord, retry]);
    return (
        <div className="d-flex flex-column my-2">
            {(!error && !mediaUrl) && <LoaderSmall/>}
            {(error || mediaUrl) && <span className="view-item-name align-self-center">{props.mediaRecord.fileName}</span>}
            {error && ((error instanceof ResourceNotFoundError) ? <NotFoundSmall/> : <ErrorSmall error={error} retryClickHandler={() => setRetry(retry + 1)}/>)}
            {(!error && mediaUrl) &&
            <div className={"d-flex flex-column align-self-stretch mt-2"}>
                <audio className="w-100" controls>
                    <source src={mediaUrl} type="audio/wav"/>
                </audio>
            </div>
            }
        </div>
    );
}