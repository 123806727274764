import EmptyFolderSvg from "./EmptyFolderSvg";
import './ArchiveListPlaceholder.css';
import {useTranslation} from "react-i18next";

export function ArchiveListPlaceholder() {
    const {t} = useTranslation();
    return (
        <div className="container-grow d-flex flex-column justify-content-center align-items-center">
            <div className="placeholder-icon">
                <EmptyFolderSvg/>
            </div>
            <span className="mt-4 placeholder-text">{t("no_records")}</span>
        </div>
    );
}