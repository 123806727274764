import {Alert, Button, Form} from "react-bootstrap";
import {useRef, useState} from "react";
import './Welcome.css';
import IconPasteSvg from "../Icons/IconPasteSvg";
import {useTranslation} from "react-i18next";
import {useAnalytics} from "../../hooks/AnalyticsHook";
import {WELCOME_PAGE_NAME} from "../../routes";
import {GoogleSignInButton} from "../GoogleSignInButton/GoogleSignInButton";
import {GoogleSignInNotAvailableDialog} from "../GoogleSignInNotAvailableDialog/GoogleSignInNotAvailableDialog";
import {useGoogleApi} from "../../google_api/GoogleApiProvider";

export function Welcome() {
    useAnalytics(WELCOME_PAGE_NAME);
    const {t} = useTranslation();
    const googleData = useGoogleApi();
    const [showToast, setShowToast] = useState(false);
    const [timerId, setTimerId] = useState(null as NodeJS.Timeout | null);
    const [showSignInNotAvailableDialog, setShowSignInNotAvailableDialog] = useState(false);
    const linkRef = useRef<HTMLInputElement>(null);
    const pasteButtonClickHandler = () => {
        navigator.clipboard.readText().then(text => {
            const inputElement = linkRef.current;
            if (inputElement) {
                inputElement.value = text;
            }
        });
    };
    const hideToast = () => {
        if (timerId) {
            clearTimeout(timerId);
            setTimerId(null);
        }
        setShowToast(false);
    }
    const openButtonClickHandler = () => {
        const inputElement = linkRef.current;
        if (inputElement) {
            const url = inputElement.value as string;
            if (url.match(/https:\/\/archive\.novotest\.biz\/[a-zA-Z0-9]+/gm)) {
                setShowToast(false);
                window.location.replace(url);
            } else {
                if (timerId) {
                    clearTimeout(timerId);
                    setTimerId(null);
                }
                setShowToast(true);
                const id = setTimeout(hideToast, 2000);
                setTimerId(id);
            }
        }
    };
    return (
        <div
            className="container-grow d-flex flex-column m-4 justify-content-center align-items-center link-input-text">
            <div className="my-2">{t('sign_in_to_google_account')}</div>
            <GoogleSignInButton className="mb-4" clickHandler={() => googleData?.signIn?.()}/>
            <div className="d-flex flex-column align-items-center mt-2">
                <div className="my-2">{t('or_paste_link')}</div>
                <div className="d-flex align-self-stretch flex-row justify-content-between align-items-center my-2">
                    <Form.Control type="text" placeholder={t("link_placeholder")} ref={linkRef}
                                  className="flex-grow"/>
                    <Button className="ms-4" variant="outline-primary" onClick={pasteButtonClickHandler}><IconPasteSvg/></Button>
                </div>
            </div>
            <Button variant="primary" className="mb-4" onClick={openButtonClickHandler}>{t('open')}</Button>
            <Alert show={showToast} variant={"danger"} className="toast-container-welcome" onClose={() => hideToast()}
                   dismissible>
                <span
                    className="d-flex justify-content-center align-items-center toast-text-welcome">{t("link_not_recognized")}</span>
            </Alert>
            <GoogleSignInNotAvailableDialog show={showSignInNotAvailableDialog}
                                            closeHandler={() => setShowSignInNotAvailableDialog(false)}/>
        </div>
    );
}