import {useTranslation} from "react-i18next";
import {Button, Modal, ProgressBar} from "react-bootstrap";
import {useEffect, useState} from "react";
import mqtt from "mqtt";
import {MqttCancellationToken, sendFwData} from "../../mqtt/MqttHelper";
import {MQTT_TOPIC_SUPPORT} from "../../AppSettings";
import {AwpFwInfo} from "../../models/AwpFwInfo";

interface Props {
    mqttClient?: mqtt.MqttClient;
    code?: string;
    fwInfo?: AwpFwInfo;
    fwData?: ArrayBuffer;
    fwName?: string;
    fwType?: number;
    closeHandler: (success?: boolean) => void;
}

export function FwTransferDialog(props: Props) {
    const {t} = useTranslation();
    const [progress, setProgress] = useState(0);
    const [done, setDone] = useState(false);
    const [isSuccess, setIsSuccess] = useState(undefined as boolean | undefined);
    useEffect(() => {
        setIsSuccess(undefined);
        const token = new MqttCancellationToken();
        if (props.fwData !== undefined && props.fwType !== undefined && props.fwName !== undefined) {
            if (props.mqttClient && props.code) {
                setProgress(0);
                setDone(false);
                sendFwData(props.mqttClient, props.code, MQTT_TOPIC_SUPPORT, props.fwInfo, props.fwName, props.fwType, props.fwData, token, setProgress)
                    .then(() => {
                        setDone(true);
                        setIsSuccess(true);
                    })
                    .catch(() => {
                        setDone(true);
                        setIsSuccess(false);
                    });
            }
        }
        return () => {
            token.cancel();
        }
    }, [props.mqttClient, props.code, props.fwType, props.fwData]);
    const close = () => {
        props.closeHandler(isSuccess);
    };
    return (
        <Modal show={props.fwData !== undefined} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{t("assist_fw_data_transfer")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isSuccess === undefined &&
                    <ProgressBar min={0} max={100} now={progress}/>
                }
                {isSuccess !== undefined &&
                    <div>{t(done ? "assist_fw_data_transfer_complete" : "assist_fw_data_transfer_failed")}</div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant={done ? "primary" : "secondary"} className="mx-2"
                        onClick={() => close()}>{t(done ? "ok" : "cancel")}</Button>
            </Modal.Footer>
        </Modal>
    );
}