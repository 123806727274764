import {MeasurementInfo} from "./MeasurementInfo";
import {formatUciScaleName, formatUciScaleValue} from "../../helpers/FormatHelper";
import {useTranslation} from "react-i18next";
import {UciMeasurement} from "../../models/UciMeasurement";

interface Props{
    measurement : UciMeasurement;
    detailsClickListener: (measurement : UciMeasurement) => void;
}

export function UciMeasurementWithoutPhoto(props : Props){
    const {t} = useTranslation();
    const avg = `${formatUciScaleValue(props.measurement.uciScale, props.measurement.avg)} ${formatUciScaleName(t, props.measurement.uciScale, props.measurement.uciScaleCustomName)}`;
    const min = formatUciScaleValue(props.measurement.uciScale, props.measurement.min);
    const max = formatUciScaleValue(props.measurement.uciScale, props.measurement.max);
    const count = props.measurement.count.toFixed(0);
    const detailsClickHandler = () => {
        props.detailsClickListener(props.measurement);
    };
    return (
        <MeasurementInfo avg={avg} min={min} max={max} count={count} detailsClickListener={detailsClickHandler}/>
    );
}