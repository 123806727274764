import {delay} from "../helpers/Utils";
import {logError, logInfo} from "../helpers/LogHelper";

export class SerialDevice {

    private readonly filters: SerialPortFilter[] | undefined;
    private baudRate: number;

    private port?: SerialPort;
    private reader?: ReadableStreamDefaultReader<Uint8Array>;
    private writer?: WritableStreamDefaultWriter<Uint8Array>;
    private keepReading: boolean = false;
    private isValid: boolean = true;

    constructor(baudRate: number, filter?: SerialPortFilter[]) {
        this.baudRate = baudRate;
        this.filters = filter;
    }

    private requestPort(): Promise<SerialPort | undefined> {
        if (this.port && this.isValid) {
            return Promise.resolve(this.port);
        } else {
            return navigator.serial.requestPort({
                filters: this.filters
            }).then(port => {
                this.port = port;
                return port;
            }).catch(() => {
                this.port = undefined;
                return undefined;
            })
        }
    }

    private openPort(port? : SerialPort) : Promise<boolean>{
        if (port) {
            return port.open({
                baudRate: this.baudRate,
                bufferSize: 1000000
            }).then(() => {
                this.port = port;
                this.writer = this.port?.writable?.getWriter();
                return true;
            }).catch(reason => {
                this.port = undefined;
                this.writer = undefined;
                throw reason;
            })
        } else {
            return new Promise<boolean>(resolve => resolve(false));
        }
    }

    open(portInfo : SerialPortInfo | undefined = undefined): Promise<boolean> {
        if (portInfo){
            return navigator.serial.getPorts().then(ports => {
                const port = ports.find(p => p.getInfo().usbProductId === portInfo.usbProductId && p.getInfo().usbVendorId === portInfo.usbVendorId);
                return this.openPort(port);
            }).catch(reason => {
                throw reason;
            });
        } else {
            return this.requestPort().then(port => {
                return this.openPort(port);
            });
        }
    }

    close() {
        this.keepReading = false;
        this.writer?.releaseLock();
        this.reader?.cancel("Closed by user").then().catch(error => logError("Serial port reader close error", error));
        this.isValid = false;
        this.port?.close().then().catch(error => logError("Serial port close error", error));
    }

    setBaudRate(baudRate: number) : Promise<boolean | undefined>{
        if (this.port) {
            if (this.baudRate !== baudRate) {
                this.baudRate = baudRate;
                this.close();
                return delay(500).then(() => {
                    this.isValid = true;
                    return this.open();
                });
            } else {
                return Promise.resolve(undefined);
            }
        } else {
            return Promise.resolve(false);
        }
    }

    async writeTextAsync(encoder: TextEncoder, data: string){
        logInfo("Sending message", data);
        await this.writeAsync(encoder.encode(data));
    }

    async writeBinaryAsync(data: Uint8Array) {
        if (data.length > 0){
            if (data.length === 1){
                logInfo("Sending binary data", data[0]);
            } else {
                logInfo(`Sending binary data (${data.length} bytes)`);
            }
        } else {
            logInfo("Sending empty binary data");
        }
        await this.writeAsync(data);
    }

    private async writeAsync(data: Uint8Array) {
        await this.writer?.write(data);
    }

    listen(callback: (data: Uint8Array | null) => void) {
        new Promise<void>(async (resolve) => {
            if (this.port) {
                this.keepReading = true;
                while (this.port.readable && this.keepReading) {
                    this.reader = this.port.readable.getReader();
                    try {
                        while (true) {
                            const {value, done} = await this.reader.read();
                            if (done) {
                                this.reader.releaseLock();
                                break;
                            }
                            if (value) {
                                callback(value);
                            }
                            await delay(50);
                        }
                    } catch (error) {
                        logError("Serial port listening error", error);
                        callback(null);
                    } finally {
                        this.reader.releaseLock();
                    }
                }
                return this.port.close().then(() => {
                    this.reader = undefined;
                    resolve();
                }).catch((error) => {
                    logError("Serial port close error", error)
                    this.reader = undefined;
                    resolve();
                });
            }
        }).then();
    }

    addEventListener(type: 'connect' | 'disconnect', listener: (this: this, ev: Event) => any, useCapture?: boolean) {
        this.port?.addEventListener(type, listener, useCapture);
    }

    removeEventListener(type: 'connect' | 'disconnect', callback: (this: this, ev: Event) => any, useCapture?: boolean) {
        this.port?.removeEventListener(type, callback, useCapture);
    }

}
