import {Container} from "react-bootstrap";
import {Loader} from "../Loader/Loader";
import React from "react";

export function LoadingScreen(){
    return (
        <Container fluid>
            <div className="container-content">
                <Loader/>
            </div>
        </Container>
    );
}