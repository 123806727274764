import {MeasurementLocation} from "../../models/MeasurementLocation";
import {Image, Layer, Stage} from "react-konva";
import useImage from "use-image";
import {useEffect, useRef, useState} from "react";
import {MarkerView} from "./MarkerView";
import './MeasurementsWithPhoto.css';
import {LoaderSmall} from "../Loader/LoaderSmall";
import {ErrorSmall} from "../Error/ErrorSmall";
import {useTranslation} from "react-i18next";
import {useScreenSize} from "../../hooks/ScreenSizeHooks";

export interface MeasurementMarker {
    text: string;
    location: MeasurementLocation;
}

interface Props {
    url: string;
    markers: MeasurementMarker[];
    markerClickListener?: (idx: number) => void;
    retryClickListener: () => void;
    imageLoadListener?: () => void;
    isFullScreen: boolean;
}

export function MeasurementsWithPhoto(props: Props) {
    const {t} = useTranslation();
    const [image, status] = useImage(props.url);
    const [isNotified, setNotified] = useState(false);
    const container = useRef<HTMLDivElement>(null);
    const [, screenHeight] = useScreenSize();
    let h: number | null = null;
    let w: number | null = null;
    if (image) {
        h = props.isFullScreen ? screenHeight : Math.min(screenHeight * 0.8, image.height);
        w = image.width * h / image.height;
        w = Math.min(container.current?.clientWidth ?? 0, w);
        h = image.height * w / image.width;
    }
    useEffect(() => {
        if (status === "loaded" && !isNotified) {
            setNotified(true);
            if (props.imageLoadListener) {
                props.imageLoadListener();
            }
        }
    }, [status, isNotified]);
    const handleClick = (idx : number) => {
        if (props.markerClickListener){
            props.markerClickListener(idx);
        }
    }
    return (
        <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center" ref={container}>
            {status === "failed" && <ErrorSmall retryClickHandler={props.retryClickListener}/>}
            {(status === "loading" || (status !== "failed" && !image)) && <LoaderSmall/>}
            {(w && h) &&
            <Stage width={w} height={h}>
                <Layer>
                    <Image image={image} x={0} y={0} width={w} height={h} preventDefault={false}/>
                    {props.markers.map((m, i) => <MarkerView text={m.text} x={m.location.x} y={m.location.y}
                                                             imageWidth={w ?? 0} imageHeight={h ?? 0} key={`m-${i}`}
                                                             clickListener={() => handleClick(i)} disabled={props.markerClickListener === undefined}/>)}
                </Layer>
            </Stage>
            }
            {(props.markerClickListener && !props.isFullScreen && w && h) &&
            <span className="marker-instruction no-select">{t('click_on_marker')}</span>
            }
        </div>
    );
}