import {parseFloat, parseString, parseUint32, parseUint8} from "../helpers/BinaryParseHelper";
import {LineChartData} from "../components/MeasurementDetails/MeasurementsLineChart";
import {TFunction} from "i18next";
import {
    convertAwpTpValue,
    formatAwpTpBaseName,
    formatAwpTpCoverName,
    formatAwpTpScaleName
} from "../helpers/AwpTpFormatHelper";

const SERIES_SIZE = 524;
const SERIES_SIZE_NEW = 543;

const MAX_MEASURE = 100;

const NAME_SIZE = 21;
const PROBE_SERIAL_SIZE = 11;
const DEVICE_SERIAL_SIZE = 11;
const DEVICE_SERIAL_SIZE_NEW = 30;
const DIR_NAME_SIZE = 15;
const PROBE_NAME_SIZE = 21;

export interface AwpTpSeries {
    name: string;
    probeSerial: string;
    deviceSerial: string;
    dirName: string;
    real: Array<number>;
    scale: number;
    baseMat: number;
    coverMat: number;
    n: number;
    r: number;
    avg: number;
    s: number;
    max: number;
    min: number;
    nMax: number;
    nMin: number;
    probeType: number;
    probeName: string;
}

export function parseTpSeries(buffer: ArrayBuffer): AwpTpSeries | null {
    let deviceSerialSize;
    if (buffer.byteLength === SERIES_SIZE) {
        deviceSerialSize = DEVICE_SERIAL_SIZE;
    } else if (buffer.byteLength === SERIES_SIZE_NEW) {
        deviceSerialSize = DEVICE_SERIAL_SIZE_NEW;
    } else {
        return null;
    }
    let offset = 0;
    const name = parseString(buffer, offset, NAME_SIZE);
    offset += NAME_SIZE;
    const probeSerial = parseString(buffer, offset, PROBE_SERIAL_SIZE);
    offset += PROBE_SERIAL_SIZE;
    const deviceSerial = parseString(buffer, offset, deviceSerialSize);
    offset += deviceSerialSize;
    const dirName = parseString(buffer, offset, DIR_NAME_SIZE);
    offset += DIR_NAME_SIZE;
    buffer = buffer.slice(offset);
    offset = 0;
    const real = new Float32Array(buffer, offset, MAX_MEASURE);
    offset += MAX_MEASURE * 4;
    const scale = parseUint32(buffer, offset);
    offset += 4;
    const baseMat = parseUint32(buffer, offset);
    offset += 4;
    const coverMat = parseUint32(buffer, offset);
    offset += 4;
    const n = parseUint32(buffer, offset);
    offset += 4;
    const r = parseFloat(buffer, offset);
    offset += 4;
    const avg = parseFloat(buffer, offset);
    offset += 4;
    const s = parseFloat(buffer, offset);
    offset += 4;
    const max = parseFloat(buffer, offset);
    offset += 4;
    const min = parseFloat(buffer, offset);
    offset += 4;
    const nMax = parseUint32(buffer, offset);
    offset += 4;
    const nMin = parseUint32(buffer, offset);
    offset += 4;
    const typeProbe = parseUint8(buffer, offset);
    offset += 1;
    const nameProbe = parseString(buffer, offset, PROBE_NAME_SIZE);
    return {
        name: name,
        probeSerial: probeSerial,
        deviceSerial: deviceSerial,
        dirName: dirName,
        real: Array.from(real),
        scale: scale,
        baseMat: baseMat,
        coverMat: coverMat,
        n: n,
        r: r,
        avg: avg,
        s: s,
        max: max,
        min: min,
        nMax: nMax,
        nMin: nMin,
        probeType: typeProbe,
        probeName: nameProbe
    }
}

export function prepareAwpTpLineChartData(series: AwpTpSeries) {
    const data = new Array<LineChartData>();
    for (let i = 0; i < series.n; i++) {
        data.push({
            x: i + 1,
            y: convertAwpTpValue(series.scale, series.real[i])
        });
    }
    return data;
}

export function prepareAwpTpBarChartData(series: AwpTpSeries) {
    const data = new Array<number>();
    for (let i = 0; i < series.n; i++) {
        data.push(convertAwpTpValue(series.scale, series.real[i]));
    }
    return data;
}

export function prepareAwpTpSeriesInfoData(series: AwpTpSeries) {
    const count = series.n;
    let max = -Number.MAX_VALUE;
    let min = Number.MAX_VALUE;
    let sum = 0;
    for (let i = 0; i < count; i++) {
        const value = convertAwpTpValue(series.scale, series.real[i]);
        max = Math.max(value, max);
        min = Math.min(value, min);
        sum += value;
    }
    const deviation = max - min;
    const avg = sum / count;
    const standardDeviation = series.s;
    sum = 0;
    for (let i = 0; i < count; i++) {
        const value = convertAwpTpValue(series.scale, series.real[i]);
        sum += Math.pow(value - avg, 2);
    }
    const meanSquareDeviation = Math.pow(sum / count, 0.5);
    const variationCoefficient = meanSquareDeviation / avg * 100;
    return {
        count: count,
        max: max,
        min: min,
        deviation: deviation,
        avg: avg,
        standardDeviation: standardDeviation,
        meanSquareDeviation: meanSquareDeviation,
        variationCoefficient: variationCoefficient
    };
}

export function getAwpTpSeriesTitle(t: TFunction<"translation">, series: AwpTpSeries) {
    return t('tp_scale_material_format', {
        scale: formatAwpTpScaleName(t, series.scale),
        cover: formatAwpTpCoverName(t, series.coverMat),
        base: formatAwpTpBaseName(t, series.baseMat)
    });
}

