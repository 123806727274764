import {formatNumber} from "../../helpers/FormatHelper";
import {useTranslation} from "react-i18next";
import {DpmMeasurement} from "../../models/DpmMeasurement";

interface Props {
    measurement: DpmMeasurement;
    detailsClickListener: (measurement: DpmMeasurement) => void;
}

export function DpmMeasurementWithoutPhoto(props: Props) {
    const {t} = useTranslation();
    const measurement = props.measurement.measurements[props.measurement.measurements.length - 1];
    return (
        <div className="d-flex flex-column align-items-center">
            <div className="d-flex flex-row flex-grow-1 align-self-stretch">
                <table className="measurement-info-table w-100">
                    <tbody>
                    <tr>
                        <th>{t('air_humidity')}</th>
                        <td>{`${formatNumber(measurement.humidity, 1, 1)} %`}</td>
                    </tr>
                    <tr>
                        <th>{t('surface_temperature')}</th>
                        <td>{`${formatNumber(measurement.tempSurface, 1, 1)} ⁰C`}</td>
                    </tr>
                    <tr>
                        <th>{t('air_temperature')}</th>
                        <td>{`${formatNumber(measurement.tempAir, 1, 1)} ⁰C`}</td>
                    </tr>
                    <tr>
                        <th>{t('dew_point')}</th>
                        <td>{`${formatNumber(measurement.devPoint, 1, 1)} ⁰C`}</td>
                    </tr>
                    <tr>
                        <th>{t('difference')}</th>
                        <td>{`${formatNumber(measurement.devSurface, 1, 1)} ⁰C`}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            {props.detailsClickListener &&
                <div className="measurement-info-details"
                     onClick={() => props.detailsClickListener(props.measurement)}>{t('details')}</div>
            }
        </div>
    );
}