import IconReloadSvg from "../Icons/IconReloadSvg";
import "./Error.css";
import {Error} from "./Error";
import {useTranslation} from "react-i18next";
import {logError, logger} from "../../helpers/LogHelper";

interface Props {
    error?: Error;
    retryClickHandler: () => void;
}

export function ErrorSmall(props: Props) {
    const {t} = useTranslation();
    if (props.error && process.env.NODE_ENV === 'development') {
        logError("Error", props.error);
    }
    return (
        <div className="container-grow d-flex flex-column justify-content-center align-items-center p-2 no-select">
            <div className="d-flex flex-row justify-content-center align-items-center error-screen-small-link">
                <span className="me-1" onClick={props.retryClickHandler}>{t('error_message')}</span>
                <IconReloadSvg style={{marginBottom: 2}}/>
            </div>
        </div>
    );
}