export function parseString(buffer : ArrayBuffer, offset? : number, length? : number) : string{
    const decoder = new TextDecoder("windows-1251");
    const arr = new Uint8Array(buffer, offset ?? 0, length ?? buffer.byteLength);
    return decoder.decode(arr).split('\0')[0];
}

export function parseStrings(buffer : ArrayBuffer, offset? : number, length? : number) : Array<string>{
    const decoder = new TextDecoder("windows-1251");
    const arr = new Uint8Array(buffer, offset ?? 0, length ?? buffer.byteLength - (offset ?? 0));
    return decoder.decode(arr).split('\0');
}

export function parseUint8(buffer: ArrayBuffer, offset: number) : number{
    return new Uint8Array(buffer, offset, 1)[0];
}

export function parseUint16(buffer: ArrayBuffer, offset: number) : number{
    return new Uint16Array(buffer, offset, 1)[0];
}

export function parseInt16(buffer: ArrayBuffer, offset: number) : number{
    return new Int16Array(buffer, offset, 1)[0];
}

export function parseUint32(buffer: ArrayBuffer, offset: number) : number{
    return new Uint32Array(buffer, offset, 1)[0];
}

export function parseInt32(buffer: ArrayBuffer, offset: number) : number{
    return new Int32Array(buffer, offset, 1)[0];
}

export function parseFloat(buffer: ArrayBuffer, offset: number) : number{
    return new Float32Array(buffer, offset, 1)[0];
}

export function parseFloatArray(buffer : ArrayBuffer, offset : number, length : number) : Array<number>{
    const arr = new Float32Array(buffer, offset, length);
    const result = new Array<number>();
    for (let i = 0; i < length; i++) {
        result.push(arr[i]);
    }
    return result;
}