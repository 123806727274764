import {useTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import {AwpFwInfo} from "../../models/AwpFwInfo";
import {getDownloadURL, ref} from "firebase/storage";
import {firebaseStorage, firestore} from "../../index";
import {GSP, GSPS, NVP, NVT, ZIP} from "../../models/AwpFwFileType";
import {logError} from "../../helpers/LogHelper";
import {Button, Modal} from "react-bootstrap";
import {Loader} from "../Loader/Loader";
import {doc, getDoc} from "firebase/firestore";

interface Message {
    text: string;
    isLoader: boolean;
}

interface Props {
    show: number;
    closeHandler: () => void;
    fwSelectionHandler: (fwInfo: AwpFwInfo, fileName: string, fileType: number, content: ArrayBuffer) => void;
    fwId: string;
}

function fetchFirmware(id: string): Promise<AwpFwInfo | null> {
    const fwRef = doc(firestore, "firmwares", id);
    return getDoc(fwRef).then(snapshot => {
        let fwData = null;
        const docData = snapshot.data();
        if (docData) {
            fwData = {
                deviceType: docData.deviceType,
                sw: docData.SW,
                swVersion: docData.SWVersion,
                hwMin: docData.HWmin,
                hwMax: docData.HWmax,
                bootloader: docData.bootloader,
                type: docData.type,
                changeLog: docData.changeLog,
                status: docData.status,
                fileId: id,
            } as AwpFwInfo;
            return fwData;
        } else {
            return null;
        }
    }).catch(() => {
        return null;
    });
}

export function FwPrepareDialog(props: Props) {
    const {t} = useTranslation();
    const [message, setMessage] = useState(undefined as Message | undefined)
    useEffect(() => {
        if (props.show % 2 === 1) {
            setMessage({text: t("awp_fw_device_version_awaiting_data"), isLoader: true})
            fetchFirmware(props.fwId).then(fw => {
                if (fw) {
                    selectFw(fw);
                } else {
                    setMessage({text: t("awp_fw_bad_link"), isLoader: false});
                }
            });
        }
    }, [t, props.show, props.fwId]);
    const selectFw = (fwInfo: AwpFwInfo) => {
        setMessage({text: t("awp_fw_downloading"), isLoader: true});
        if (fwInfo) {
            const fileRef = ref(firebaseStorage, `Firmwares/${fwInfo.fileId}`);
            getDownloadURL(fileRef)
                .then(url => fetch(url))
                .then(async response => {
                    if (response.ok) {
                        try {
                            const fwData = await response.arrayBuffer();
                            let fwType;
                            switch (fwInfo.type) {
                                case "gsp":
                                    fwType = GSP;
                                    break;
                                case "gsps":
                                    fwType = GSPS;
                                    break;
                                case "nvt":
                                    fwType = NVT;
                                    break;
                                case "nvp":
                                    fwType = NVP;
                                    break;
                                case "zip":
                                    fwType = ZIP;
                                    break;
                                default:
                                    throw new Error("Unsupported file");
                            }
                            let device = fwInfo.deviceType.toUpperCase();
                            if (device === "LEEB_PLAIN") {
                                device = "LEEB_LITE";
                            }
                            const version = fwInfo.swVersion ?? fwInfo.sw.toString().replaceAll(".", "_");
                            const type = fwInfo.type;
                            const name = `${device}_SW_${version}.${type}`;
                            props.fwSelectionHandler(fwInfo, name, fwType, fwData);
                            close();
                        } catch (e) {
                            logError("Response process error", e);
                            setMessage({text: t("awp_fw_download_failed"), isLoader: false});
                        }
                    }
                }).catch(() => setMessage({text: t("awp_fw_download_failed"), isLoader: false}));
        }
    }
    const close = () => {
        props.closeHandler();
    }
    return (
        <Modal show={props.show % 2 === 1} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{t("loader_fw_search")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message &&
                    <Fragment>
                        {message.isLoader && <Loader className={"mt-4"} message={message.text}/>}
                        {!message.isLoader && <div>{message.text}</div>}
                    </Fragment>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>{t("cancel")}</Button>
            </Modal.Footer>
        </Modal>
    );
}
