import {AwpSeries} from "../../helpers/AwpHelper";
import {useTranslation} from "react-i18next";
import {AwpUt2aSeries} from "../../models/AwpUt2aSeries";
import {getUt2AResult} from "../../helpers/AwpUt2AFormatHelper";


interface Props {
    data: AwpSeries;
}

export function AwpUt2aResultInfo(props: Props) {
    const {t} = useTranslation();
    const data = props.data as AwpUt2aSeries;
    const result = getUt2AResult(t, data);
    return <h5 className="mt-2">
        <strong>{result.display}</strong>
    </h5>
}