import {parseFloat, parseInt16, parseInt32, parseString} from "../helpers/BinaryParseHelper";

const DATA_SIZE = 7131;
const DATA_SIZE_NEW = 7150;

const SERIAL_SIZE = 11;
const SERIAL_SIZE_NEW = 16;
const PARAM_PROBE_SIZE = 32 + 56;
const PROBES_SIZE = 4 + 20 * PARAM_PROBE_SIZE;
const ADC_SIZE = 2 * 9 * 4 + 18 * 4;
const ASD_SIZE = 4148;
const PARAM_STROBE_SIZE = 24;
const STROBE_SIZE = 4 + 9 * PARAM_STROBE_SIZE;
const SCREEN_SIZE = 16 * 4;

const ADC_OFFSET = PROBES_SIZE;
const ASD_OFFSET = ADC_OFFSET + ADC_SIZE;
const STROBE_OFFSET = ASD_OFFSET + ASD_SIZE;
const SCREEN_OFFSET = STROBE_OFFSET + STROBE_SIZE;
const SYS_OFFSET = SCREEN_OFFSET + SCREEN_SIZE;

export interface AwpUt3EmaSeries {
    probes: Probes;
    adc: ADC;
    asd: ASD;
    strobe: Strobe;
    screen: Screen;
    systemSet: Sys;
}

interface Probes {
    usedProbe: number;
    probes: Array<ParamProbe>;
}

interface ParamProbe {
    name: string;
    type: number;
    deadTime: number;
    freq: number;
    nPulses: number;
    amplV: number;
    gain: number;
    agcOnOff: number;
    porog: number;
    gate: number;
    v: number;
    kV: number;
    delay: number;
    typeWave: number;
    edit: number;
}

interface ADC {
    mmDelay: Array<number>;
    mmScan: Array<number>;
    modePIC2: number;
    rfHw: number;
    gain: number;
    speed: number;
    etalonMm: number;
    startCalibr: number;
    maxMeasAgc: number;
    enableAdc: number;
    gainAgc: number;
    maxLevelAgc: number;
    minLevelAgc: number;
    maxGainAgc: number;
    minGainAgc: number;
    agcOnOff: number;
    nc1: number;
    nc2: number;
    nc3: number;
    nc4: number;
}

interface ASD {
    max_BSCAN: number;
    min_BSCAN: number;
    sygnall: number;
    max_CONT: number;
    min_CONT: number;
    nominal_CONT: number;
    bScan: Array<number>;
    headBSCAN: number;
    T_ZERRO2: Array<number>;
    N_ZERRO2: Array<number>;
    AC_ZERRO2: Array<number>;
    T_ZERRO1: number;
    N_ZERRO1: number;
    AC_ZERRO1: number;
    T_RASSING: number;
    N_RASSING: number;
    AC_RASSING: number;
    T_PIC_PIC: Array<number>;
    N_PIC_PIC: Array<number>;
    AC_PIC_PIC: Array<number>;
    T_PIC_PIC_T: Array<number>;
    N_PIC_PIC_T: number;
    AC_PIC_PIC_T: Array<number>;
    T_AKF: number;
    N_AKF: number;
    AC_AKF: number;
    T_AVTO: number;
    N_AVTO: number;
    AC_AVTO: number;
    NC: Array<number>;
}

interface Strobe {
    paramStrobe: Array<ParamStrobe>;
    select: number;
}

interface ParamStrobe {
    x: Array<number>;
    y: Array<number>;
    len: Array<number>;
}

interface Screen {
    Filling: number;
    Envelope: number;
    View: number;
    Type_Filter_Measure: number;
    Source_Work: number;
    Global_Source: number;
    Source_BSCAN: number;
    Source_CONTROL: number;
    NC6: number;
    NC7: number;
    NC8: number;
    NC9: number;
    mm_uS: number;
    Midle: number;
    Orientation: number;
    Enable_View: number;
}

interface Sys {
    serial: string;
}

function parseProbes(buffer: ArrayBuffer, baseOffset: number) {
    let offset = baseOffset;
    const usedProbe = parseInt32(buffer, offset);
    offset += 4;
    const probes = new Array<ParamProbe>();
    for (let i = 0; i < 20; i++) {
        probes.push(parseParamProbe(buffer, offset));
        offset += PARAM_PROBE_SIZE;
    }
    return {
        usedProbe: usedProbe,
        probes: probes
    }
}

function parseParamProbe(buffer: ArrayBuffer, baseOffset: number): ParamProbe {
    let offset = baseOffset;
    const name = parseString(buffer, offset, 32);
    offset += 32;
    const type = parseInt32(buffer, offset);
    offset += 4;
    const deadTime = parseInt32(buffer, offset);
    offset += 4;
    const freq = parseInt32(buffer, offset);
    offset += 4;
    const nPulses = parseInt32(buffer, offset);
    offset += 4;
    const amplV = parseInt32(buffer, offset);
    offset += 4;
    const gain = parseInt32(buffer, offset);
    offset += 4;
    const agcOnOff = parseInt32(buffer, offset);
    offset += 4;
    const porog = parseInt32(buffer, offset);
    offset += 4;
    const gate = parseInt32(buffer, offset);
    offset += 4;
    const v = parseInt32(buffer, offset);
    offset += 4;
    const kV = parseInt32(buffer, offset);
    offset += 4;
    const delay = parseInt32(buffer, offset);
    offset += 4;
    const typeWave = parseInt32(buffer, offset);
    offset += 4;
    const edit = parseInt32(buffer, offset);
    return {
        name: name,
        type: type,
        deadTime: deadTime,
        freq: freq,
        nPulses: nPulses,
        amplV: amplV,
        gain: gain,
        agcOnOff: agcOnOff,
        porog: porog,
        gate: gate,
        v: v,
        kV: kV,
        delay: delay,
        typeWave: typeWave,
        edit: edit
    }
}

function parseAdc(initialBuffer: ArrayBuffer, baseOffset: number): ADC {
    const buffer = initialBuffer.slice(baseOffset);
    const arraySize = 9;
    let offset = 0;
    const mmDelay = new Int32Array(buffer, offset, arraySize);
    offset += arraySize * 4;
    const mmScan = new Int32Array(buffer, offset, arraySize);
    offset += arraySize * 4;
    const modePIC2 = parseInt32(buffer, offset);
    offset += 4;
    const rfHw = parseInt32(buffer, offset);
    offset += 4;
    const gain = parseInt32(buffer, offset);
    offset += 4;
    const speed = parseInt32(buffer, offset);
    offset += 4;
    const etalonMm = parseInt32(buffer, offset);
    offset += 4;
    const startCalibr = parseInt32(buffer, offset);
    offset += 4;
    const maxMeasAgc = parseInt32(buffer, offset);
    offset += 4;
    const enableAdc = parseInt32(buffer, offset);
    offset += 4;
    const gainAgc = parseInt32(buffer, offset);
    offset += 4;
    const maxLevelAgc = parseInt32(buffer, offset);
    offset += 4;
    const minLevelAgc = parseInt32(buffer, offset);
    offset += 4;
    const maxGainAgc = parseInt32(buffer, offset);
    offset += 4;
    const minGainAgc = parseInt32(buffer, offset);
    offset += 4;
    const agcOnOff = parseInt32(buffer, offset);
    offset += 4;
    const nc1 = parseInt32(buffer, offset);
    offset += 4;
    const nc2 = parseInt32(buffer, offset);
    offset += 4;
    const nc3 = parseInt32(buffer, offset);
    offset += 4;
    const nc4 = parseInt32(buffer, offset);

    return {
        mmDelay: Array.from(mmDelay),
        mmScan: Array.from(mmScan),
        modePIC2: modePIC2,
        rfHw: rfHw,
        gain: gain,
        speed: speed,
        etalonMm: etalonMm,
        startCalibr: startCalibr,
        maxMeasAgc: maxMeasAgc,
        enableAdc: enableAdc,
        gainAgc: gainAgc,
        maxLevelAgc: maxLevelAgc,
        minLevelAgc: minLevelAgc,
        maxGainAgc: maxGainAgc,
        minGainAgc: minGainAgc,
        agcOnOff: agcOnOff,
        nc1: nc1,
        nc2: nc2,
        nc3: nc3,
        nc4: nc4
    }
}

function parseAsd(initialBuffer: ArrayBuffer, baseOffset: number): ASD {
    let buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const max_BSCAN = parseInt32(buffer, offset);
    offset += 4;
    const min_BSCAN = parseInt32(buffer, offset);
    offset += 4;
    const sygnall = parseInt32(buffer, offset);
    offset += 4;
    const max_CONT = parseInt32(buffer, offset);
    offset += 4;
    const min_CONT = parseInt32(buffer, offset);
    offset += 4;
    const nominal_CONT = parseInt32(buffer, offset);
    offset += 4;
    const bScan = new Float32Array(buffer, offset, 1000);
    offset += 4 * 1000;
    const headBSCAN: number = parseInt16(buffer, offset);
    offset += 2;
    buffer = buffer.slice(offset);
    offset = 0
    const T_ZERRO2 = new Float32Array(buffer, offset, 2);
    offset += 4 * 2;
    const N_ZERRO2 = new Int16Array(buffer, offset, 2);
    offset += 2 * 2;
    const AC_ZERRO2 = new Int32Array(buffer, offset, 2);
    offset += 4 * 2;
    const T_ZERRO1 = parseFloat(buffer, offset);
    offset += 4;
    const N_ZERRO1 = parseInt16(buffer, offset);
    offset += 2;
    buffer = buffer.slice(offset);
    offset = 0
    const AC_ZERRO1 = parseInt32(buffer, offset);
    offset += 4;
    const T_RASSING = parseFloat(buffer, offset);
    offset += 4;
    const N_RASSING = parseInt16(buffer, offset);
    offset += 2;
    buffer = buffer.slice(offset);
    offset = 0
    const AC_RASSING = parseInt32(buffer, offset);
    offset += 4;
    const T_PIC_PIC = new Float32Array(buffer, offset, 2);
    offset += 4 * 2;
    const N_PIC_PIC = new Int16Array(buffer, offset, 2);
    offset += 2 * 2;
    const AC_PIC_PIC = new Int32Array(buffer, offset, 2);
    offset += 4 * 2;
    const T_PIC_PIC_T = new Float32Array(buffer, offset, 2);
    offset += 4 * 2;
    const N_PIC_PIC_T = parseInt16(buffer, offset);
    offset += 2;
    buffer = buffer.slice(offset);
    offset = 0
    const AC_PIC_PIC_T = new Int32Array(buffer, offset, 2);
    offset += 4 * 2;
    const T_AKF = parseFloat(buffer, offset);
    offset += 4;
    const N_AKF = parseInt16(buffer, offset);
    offset += 2;
    buffer = buffer.slice(offset);
    offset = 0
    const AC_AKF = parseInt32(buffer, offset);
    offset += 4;
    const T_AVTO = parseFloat(buffer, offset);
    offset += 4;
    const N_AVTO = parseInt16(buffer, offset);
    offset += 2;
    buffer = buffer.slice(offset);
    offset = 0
    const AC_AVTO = parseInt32(buffer, offset);
    offset += 4;
    const NC = new Int32Array(buffer, offset, 6);
    return {
        max_BSCAN: max_BSCAN,
        min_BSCAN: min_BSCAN,
        sygnall: sygnall,
        max_CONT: max_CONT,
        min_CONT: min_CONT,
        nominal_CONT: nominal_CONT,
        bScan: Array.from(bScan),
        headBSCAN: headBSCAN,
        T_ZERRO2: Array.from(T_ZERRO2),
        N_ZERRO2: Array.from(N_ZERRO2),
        AC_ZERRO2: Array.from(AC_ZERRO2),
        T_ZERRO1: T_ZERRO1,
        N_ZERRO1: N_ZERRO1,
        AC_ZERRO1: AC_ZERRO1,
        T_RASSING: T_RASSING,
        N_RASSING: N_RASSING,
        AC_RASSING: AC_RASSING,
        T_PIC_PIC: Array.from(T_PIC_PIC),
        N_PIC_PIC: Array.from(N_PIC_PIC),
        AC_PIC_PIC: Array.from(AC_PIC_PIC),
        T_PIC_PIC_T: Array.from(T_PIC_PIC_T),
        N_PIC_PIC_T: N_PIC_PIC_T,
        AC_PIC_PIC_T: Array.from(AC_PIC_PIC_T),
        T_AKF: T_AKF,
        N_AKF: N_AKF,
        AC_AKF: AC_AKF,
        T_AVTO: T_AVTO,
        N_AVTO: N_AVTO,
        AC_AVTO: AC_AVTO,
        NC: Array.from(NC),
    }
}

function parseStrobe(initialBuffer: ArrayBuffer, baseOffset: number): Strobe {
    let buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const paramStrobe = new Array<ParamStrobe>();
    for (let i = 0; i < 9; i++) {
        paramStrobe.push(parseParamStrobe(buffer, offset));
        offset += PARAM_STROBE_SIZE;
    }
    buffer = buffer.slice(offset);
    offset = 0;
    const select = parseInt32(buffer, offset);
    return {
        paramStrobe: paramStrobe,
        select: select
    }
}

function parseParamStrobe(initialBuffer: ArrayBuffer, baseOffset: number): ParamStrobe {
    const arraySize = 2;
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const x = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const y = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const len = new Int32Array(buffer, offset, arraySize);
    return {
        x: Array.from(x),
        y: Array.from(y),
        len: Array.from(len)
    }
}

function parseScreen(initialBuffer: ArrayBuffer, baseOffset: number): Screen {
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const Filling = parseInt32(buffer, offset);
    offset += 4;
    const Envelope = parseInt32(buffer, offset);
    offset += 4;
    const View = parseInt32(buffer, offset);
    offset += 4;
    const Type_Filter_Measure = parseInt32(buffer, offset);
    offset += 4;
    const Source_Work = parseInt32(buffer, offset);
    offset += 4;
    const Global_Source = parseInt32(buffer, offset);
    offset += 4;
    const Source_BSCAN = parseInt32(buffer, offset);
    offset += 4;
    const Source_CONTROL = parseInt32(buffer, offset);
    offset += 4;
    const NC6 = parseInt32(buffer, offset);
    offset += 4;
    const NC7 = parseInt32(buffer, offset);
    offset += 4;
    const NC8 = parseInt32(buffer, offset);
    offset += 4;
    const NC9 = parseInt32(buffer, offset);
    offset += 4;
    const mm_uS = parseInt32(buffer, offset);
    offset += 4;
    const Midle = parseInt32(buffer, offset);
    offset += 4;
    const Orientation = parseInt32(buffer, offset);
    offset += 4;
    const Enable_View = parseInt32(buffer, offset);
    return {
        Filling: Filling,
        Envelope: Envelope,
        View: View,
        Type_Filter_Measure: Type_Filter_Measure,
        Source_Work: Source_Work,
        Global_Source: Global_Source,
        Source_BSCAN: Source_BSCAN,
        Source_CONTROL: Source_CONTROL,
        NC6: NC6,
        NC7: NC7,
        NC8: NC8,
        NC9: NC9,
        mm_uS: mm_uS,
        Midle: Midle,
        Orientation: Orientation,
        Enable_View: Enable_View
    }
}

function parseSys(buffer: ArrayBuffer, baseOffset: number, isNew : boolean): Sys {
    const serial = parseString(buffer, baseOffset, isNew ? SERIAL_SIZE_NEW : SERIAL_SIZE);
    return {
        serial: serial
    }
}


export function parseAwpUt3EmaSeries(configBuffer: ArrayBuffer): AwpUt3EmaSeries | null {
    let isNew;
    if (configBuffer.byteLength === DATA_SIZE){
        isNew = false;
    } else if (configBuffer.byteLength === DATA_SIZE_NEW){
        isNew = true;
    } else {
        return null;
    }
    const probes = parseProbes(configBuffer, 0);
    const adc = parseAdc(configBuffer, ADC_OFFSET);
    const asd = parseAsd(configBuffer, ASD_OFFSET);
    const strobe = parseStrobe(configBuffer, STROBE_OFFSET);
    const screen = parseScreen(configBuffer, SCREEN_OFFSET);
    const systemSet = parseSys(configBuffer, SYS_OFFSET, isNew);
    return {
        probes: probes,
        adc: adc,
        asd: asd,
        strobe: strobe,
        screen: screen,
        systemSet: systemSet
    }
}