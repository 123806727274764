import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './css/colors.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import {BrowserRouter} from "react-router-dom";
import {ANALYTICS_ID, CLIENT_ID, DEMO_ANALYTICS_ID, FIREBASE_CONFIG, IS_DEMO_APP, IS_LOCAL_APP} from "./AppSettings";
import {initializeApp} from "firebase/app"
import {getDatabase} from "firebase/database";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import AppDemo from "./AppDemo";
import ReactGA from "react-ga4";
import {AppLocal} from "./AppLocal";
import {GoogleApiProvider} from "./google_api/GoogleApiProvider";

export const firebaseApp = initializeApp(FIREBASE_CONFIG);
export const firebaseDb = getDatabase();
export const firestore = getFirestore(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);

ReactGA.initialize(IS_DEMO_APP ? DEMO_ANALYTICS_ID : ANALYTICS_ID);

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <GoogleApiProvider>
                    <I18nextProvider i18n={i18n}>
                        {IS_DEMO_APP ? <AppDemo/> : (IS_LOCAL_APP ? <AppLocal/> : <App/>)}
                    </I18nextProvider>
            </GoogleApiProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
