import {useTranslation} from "react-i18next";
import {Button, Modal} from "react-bootstrap";

interface Props {
    message?: string;
    closeHandler: (confirmed: boolean) => void;
}

export function FwAttentionDialog(props: Props) {
    const {t} = useTranslation();
    return (
        <Modal show={props.message !== undefined} onHide={() => props.closeHandler(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t("awp_fw_upload_warning_1")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>{props.message}</div>
                <div className="my-4"/>
                <div>{t("loader_fw_can_break_device")}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.closeHandler(false)}>{t("cancel")}</Button>
                <Button variant="danger"
                        onClick={() => props.closeHandler(true)}>{t("loader_continue_anyway")}</Button>
            </Modal.Footer>
        </Modal>
    );
}