import {formatDateTime} from "../../helpers/FormatHelper";
import './ViewContainer.css';
import {useLocale} from "../../hooks/LocaleHook";

interface Props {
    title?: string;
    dateTime?: number;
    reserveSpaceForDataTime?: boolean;
    className?: string;
    children: JSX.Element | JSX.Element[];
}

export function ViewContainer(props: Props) {
    const locale = useLocale();
    return (
        <div className={`d-flex flex-grow-1 flex-column my-2 ${props.className ?? ""}`}>
            {props.title && <span className="align-self-start mb-2 view-title">{props.title}</span>}
            <div className="view-container flex-grow-1 p-2">
                {props.children}
            </div>
            <span className="align-self-end mt-2 view-date-time"
                  style={{visibility: `${props.dateTime ? "visible" : "hidden"}`}}>{props.dateTime ? formatDateTime(locale, new Date(props.dateTime)) : props.reserveSpaceForDataTime ? "-" : ""}</span>
        </div>
    );
}