import './AwpFwExpertUpdate.css';
import {useTranslation} from "react-i18next";
import {Button, ProgressBar} from "react-bootstrap";
import {Loader} from "../Loader/Loader";
import {useEffect, useState} from "react";
import {CONFIG, GSP, GSPS, NVP, NVT, PICS} from "../../models/AwpFwFileType";
import {AwpFwSerialDevice} from "../../serial/AwpFwSerialDevice";


interface Props {
    device?: AwpFwSerialDevice;
    fileType?: number;
    content?: ArrayBuffer;
    onUploadComplete: () => void;
    onUploadError: () => void;
}

export function AwpFwUploadProcess(props: Props) {
    const {t} = useTranslation();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (props.device && props.fileType && props.content) {
            switch (props.fileType) {
                case GSP:
                    props.device.sendGsp(props.content, AwpFwSerialDevice.speed921600, setProgress).then(() => props.onUploadComplete()).catch(() => props.onUploadError());
                    break;
                case GSPS:
                    props.device.sendGsp(props.content, AwpFwSerialDevice.speedOldLoader, setProgress).then(() => props.onUploadComplete()).catch(() => props.onUploadError());
                    break;
                case NVT:
                    props.device.sendNvt(props.content, setProgress).then(() => props.onUploadComplete()).catch(() => props.onUploadError());
                    break;
                case PICS:
                    props.device.sendPics(props.content, setProgress).then(() => props.onUploadComplete()).catch(() => props.onUploadError());
                    break;
                case NVP:
                    props.device.sendNvp(props.content, setProgress).then(() => props.onUploadComplete()).catch(() => props.onUploadError());
                    break;
                case CONFIG:
                    props.device.sendConfig(props.content, setProgress).then(() => props.onUploadComplete()).catch(() => props.onUploadError());
                    break;
                default:
                    props.onUploadError();
                    break;
            }
        } else {
            props.onUploadError();
        }
    }, []);
    return (
        <div
            className="container-grow d-flex flex-column m-4 justify-content-center align-items-center fw-instruction-text">
            <div className="my-2">{t('awp_fw_upload_in_progress')}</div>
            <div className="my-4 align-self-stretch">
                <Loader/>
                <ProgressBar className="flex-grow-1 mx-2 my-4" animated now={progress} min={0}
                             max={props.content?.byteLength ?? 100}/>
            </div>
            <Button variant="primary" className="my-2"
                    onClick={() => {
                    }}>{t("cancel")}</Button>
            <div className="my-2 fw-warning-text">{t('awp_fw_upload_warning_1')}</div>
            <div className="my-2 fw-warning-text">{t('awp_fw_upload_warning_2')}</div>
        </div>
    );
}