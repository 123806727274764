import {useTranslation} from "react-i18next";
import {AwpSeries} from "../../helpers/AwpHelper";
import {AwpUt2aSeries} from "../../models/AwpUt2aSeries";
import {formatNumber} from "../../helpers/FormatHelper";
import {DATA_PLACEHOLDER} from "../../AppSettings";
import {getUt2ATransducerInfo} from "../../helpers/AwpUt2AFormatHelper";

interface Props {
    data: AwpSeries;
}

export function AwpUt2ATransducerInfo(props: Props) {
    const {t} = useTranslation();
    const data = props.data as AwpUt2aSeries;
    const transducerInfo = getUt2ATransducerInfo(t, data);
    return (
        <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-stretch">
            <table className="table-measurement-info">
                <tbody>
                <tr>
                    <th>{t('sensor_name')}</th>
                    <td>{transducerInfo.name}</td>
                </tr>
                <tr>
                    <th>{t('sensor_type')}</th>
                    <td>{transducerInfo.type}</td>
                </tr>
                <tr>
                    <th>{t('frequency_mhz')}</th>
                    <td>{transducerInfo.freq}</td>
                </tr>
                <tr>
                    <th>{t('delay_us')}</th>
                    <td>{transducerInfo.delay}</td>
                </tr>
                <tr>
                    <th>{t('amplitude')}</th>
                    <td>{transducerInfo.amplV}</td>
                </tr>
                <tr>
                    <th>{t('pulses')}</th>
                    <td>{transducerInfo.nPulses}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}