import {useTranslation} from "react-i18next";
import {AwpSeries} from "../../helpers/AwpHelper";
import {formatNumber} from "../../helpers/FormatHelper";
import {AwpUd2303Series} from "../../models/AwpUd2303Series";
import {formatAwpUd2303DetectorMode, getUd2303DeviceInfo} from "../../helpers/AwpUd2303FormatHelper";

interface Props {
    data: AwpSeries;
}

export function AwpUd2303DeviceInfo(props: Props) {
    const {t} = useTranslation();
    const data = props.data as AwpUd2303Series;
    const deviceInfo = getUd2303DeviceInfo(t, data);
    return (
        <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-stretch">
            <table className="table-measurement-info">
                <tbody>
                <tr>
                    <th>{t('amplification_db')}</th>
                    <td>{deviceInfo.gain}</td>
                </tr>
                <tr>
                    <th>{t('delay_mm')}</th>
                    <td>{deviceInfo.delayMm}</td>
                </tr>
                <tr>
                    <th>{t('delay_inch')}</th>
                    <td>{deviceInfo.delayInch}</td>
                </tr>
                <tr>
                    <th>{t('scanning_mm')}</th>
                    <td>{deviceInfo.scanMm}</td>
                </tr>
                <tr>
                    <th>{t('scanning_inch')}</th>
                    <td>{deviceInfo.scanInch}</td>
                </tr>
                <tr>
                    <th>{t('detector')}</th>
                    <td>{deviceInfo.detector}</td>
                </tr>
                <tr>
                    <th>{t('averaging_points')}</th>
                    <td>{deviceInfo.middle}</td>
                </tr>
                <tr>
                    <th>{t('filter_mhz')}</th>
                    <td>{deviceInfo.filter}</td>
                </tr>
                <tr>
                    <th>{t('tvg')}</th>
                    <td>{deviceInfo.tvg}</td>
                </tr>
                <tr>
                    <th>{t('velocity_m_s')}</th>
                    <td>{deviceInfo.velocity}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}