import {AwpSeries} from "../../helpers/AwpHelper";
import {useTranslation} from "react-i18next";
import {AwpMfSeries} from "../../models/AwpMfSeries";
import {formatAwpMfScaleName, formatAwpMfValue} from "../../helpers/AwpMfFormatHelper";
import './AwpSeriesInfo.css';

interface Props{
    data: AwpSeries;
}

export function AwpMfEstimatedInfo(props: Props){
    const {t} = useTranslation();
    const data = props.data as AwpMfSeries;
    const scales = [0, 1, 2, 3].filter(value => value !== data.scale);
    return (
        <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
            <table className="table-mf-measurement-info">
                <tbody>
                {scales.map(scale =>
                    <tr key={`estimated-info-value-${scale}`}>
                        <th>{formatAwpMfValue(data.strength[scale], scale)}</th>
                        <td>{formatAwpMfScaleName(t, scale)}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}