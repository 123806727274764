import {Button, Modal} from "react-bootstrap";

interface Props{
    show: boolean;
    title: string;
    body: string;
    positiveButtonText: string;
    negativeButtonText: string;
    closeHandler: (hasResult? : boolean) => void;
    positiveButtonAction?: () => void;
    negativeButtonAction?: () => void;
}

export function ModalTwoButtons(props : Props) {
    const cancel = () => {
        props.closeHandler(true);
        if (props.negativeButtonAction) {
            props.negativeButtonAction();
        }
    };
    const ok = () => {
        props.closeHandler(true);
        if (props.positiveButtonAction) {
            props.positiveButtonAction();
        }
    }
    return (<Modal
        show={props.show}
        onHide={props.closeHandler}
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.body}</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => cancel()}>{props.negativeButtonText}</Button>
            <Button variant="primary" onClick={() => ok()}>{props.positiveButtonText}</Button>
        </Modal.Footer>
    </Modal>);
}