import {useMemo} from "react";
import './MeasurementsTable.css';
import {useTranslation} from "react-i18next";

interface Props {
    data: number[];
    formatter: (v : number) => string;
    hideCount? : boolean;
}

interface Result {
    min: number;
    max: number;
    avg: number;
    count?: number;
}

export function MeasurementsTable(props: Props) {
    const {t} = useTranslation();
    const result = useMemo(() => {
        let min = Number.MAX_VALUE;
        let max = -Number.MAX_VALUE;
        let sum = 0;
        for (const datum of props.data) {
            min = Math.min(min, datum);
            max = Math.max(max, datum);
            sum += datum;
        }
        const avg = sum / props.data.length;
        const count = props.data.length;
        return {
            min: min,
            max: max,
            avg: avg,
            count: count
        } as Result;
    }, [props]);
    return (
        <div className="d-flex flex-column align-items-center justify-content-center measurement-details-container">
            <table className="measurements-table">
                <thead>
                <tr>
                    <th>{t('parameter')}</th>
                    <th>{t('value')}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{t('min')}</td>
                    <td>{props.formatter(result.min)}</td>
                </tr>
                <tr>
                    <td>{t('max')}</td>
                    <td>{props.formatter(result.max)}</td>
                </tr>
                <tr>
                    <td>{t('average')}</td>
                    <td>{props.formatter(result.avg)}</td>
                </tr>
                {!props.hideCount &&
                    <tr>
                        <td>{t('count')}</td>
                        <td>{result.count}</td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    );
}