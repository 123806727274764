import {TFunction} from "i18next";

export const STATE_CONNECTING = 1;
export const STATE_AWAITING_SUPPORT = 2;
export const STATE_SUPPORT_ONLINE = 3;
export const STATE_SUPPORT_DISCONNECTED = 4;
export const STATE_AWAITING_CLIENT = 5;
export const STATE_CLIENT_ONLINE = 6;
export const STATE_CLIENT_TERMINATED_SESSION = 7;

export const STATE_ERROR = 100;

export function formatMqttState(t: TFunction<"translation">, state? : number){
    if (state){
        switch (state){
            case STATE_CONNECTING:
                return t("mqtt_connecting");
            case STATE_AWAITING_SUPPORT:
                return t("mqtt_awaiting_support");
            case STATE_SUPPORT_ONLINE:
                return t("mqtt_support_online");
            case STATE_SUPPORT_DISCONNECTED:
                return t("mqtt_support_disconnected");
            case STATE_AWAITING_CLIENT:
                return t("mqtt_awaiting_client");
            case STATE_CLIENT_ONLINE:
                return t("mqtt_client_online");
            case STATE_CLIENT_TERMINATED_SESSION:
                return t("mqtt_client_disconnected");
            case STATE_ERROR:
                return t("mqtt_error");
        }
    }
    return "";
}