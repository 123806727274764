export class UnknownPlayerError extends Error{
    constructor() {
        super("Unknown media player error");
    }
}

export class PlaybackError extends Error{
    constructor() {
        super("Media player error");
    }
}