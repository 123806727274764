import {parseString, parseUint16} from "../helpers/BinaryParseHelper";
import {logError} from "../helpers/LogHelper";
import {AwpFwInfo} from "./AwpFwInfo";

const VER_HW_LENGTH = 20;
const VER_SW_LENGTH = 50;

function getDeviceType(type: number): string | null {
    switch (type) {
        case 1:
            return "tp";
        case 5:
            return "ut1m";
        case 6:
            return "tud2";
        case 7:
            return "mf1m";
        case 35:
            return "mf1m";
        case 9:
            return "tud3";
        //case 11: return "Lab DPM";
        //case 12: return "IPSM";
        //case 13: return "ED-3D";
        //case 16: return "BETON";
        case 20:
            return "krc";
        case 21:
            return "ud2301";
        case 24:
            return "ud3701";
        case 27:
            return "ut1m";
        case 34:
            return "ut3mema";
        case 41:
            return "ut2a";
        case 42:
            return "ud2303";
        case 43:
            return "tp";
        case 44:
            return "ut1mip";
        case 45:
            return "ut1mst";
        case 48:
            return "uci";
        //case 050: return "Lab BETON";
        case 53:
            return "leeb_plain";
        //case 051: return "Lab UCI Pro";
        case 54:
            return "leeb";
        //case 055: return "VD1M";
    }
    return null;
}

export function parseGspInfo(buffer: ArrayBuffer): AwpFwInfo | undefined {
    let offset = 2;
    const type = parseUint16(buffer, offset);
    offset += 2;
    const verHw = new Uint16Array(buffer.slice(offset), 0, VER_HW_LENGTH);
    offset += 2 * VER_HW_LENGTH;
    const swString = parseString(buffer, offset, VER_SW_LENGTH);
    const hw = new Array<number>();
    for (let i = 0; i < VER_HW_LENGTH; i++) {
        if (verHw[i] !== 0) {
            hw.push(verHw[i] / 100);
        } else {
            break;
        }
    }
    const deviceType = getDeviceType(type);
    let sw;
    try {
        sw = Number(swString)
    } catch (e) {
        logError("Error parsing SW", e);
    }
    if (deviceType && sw && hw.length > 0) {
        return {
            deviceType: deviceType,
            sw: sw,
            swVersion: swString,
            hwMin: Math.min(...hw),
            hwMax: Math.max(...hw),
            type: "",
            status: "",
            fileId: "",
            changeLog: ""
        }
    } else {
        return undefined;
    }

}