import './ExecutorsNotFoundPlaceholder.css';
import {useTranslation} from "react-i18next";
import ExecutorsNotFoundPlaceholderSvg from "./ExecutorsNotFoundPlaceholderSvg";

export function ExecutorsNotFoundPlaceholder(){
    const {t} = useTranslation();
    return (
        <div className="container-grow d-flex flex-column justify-content-center align-items-center">
            <div className="executors-not-found-icon">
                <ExecutorsNotFoundPlaceholderSvg/>
            </div>
            <span className="mt-4 executors-not-found text-center no-select">{t('executors_not_found')}</span>
        </div>
    );
}