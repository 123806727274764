import * as React from "react"

function EmptyFolderSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 412.533 412.533"
            {...props}
        >
            <path
                d="M412.485 203.954h.041c0-14.323-5.609-27.336-14.729-37.042l.016-.016-79.27-101.819H90.479L13.493 168.961l.033.033C6.243 177.61 1.764 188.559.992 200.508.415 201.629 0 202.84 0 204.19v135.138a8.127 8.127 0 008.129 8.129h396.276a8.121 8.121 0 008.129-8.129V204.19c-.001-.081-.049-.155-.049-.236zM97.844 81.335H311.43l48.389 68.5c-.512-.016-1-.081-1.52-.081h-97.502v24.369c0 27.67-29.052 21.687-37.96 21.687h-32.466c-8.909 0-37.96 5.983-37.96-21.687v-24.369H54.9c-1.016 0-2.008.098-3.016.146l45.96-68.565zm298.432 249.864H16.265V204.19c0-.081-.041-.154-.049-.236h.723c0-20.923 17.029-37.944 37.96-37.944h81.253v8.112c0 27.987 21.281 37.944 54.218 37.944h32.466c32.945 0 54.218-9.957 54.218-37.944v-8.112h81.261c10.461 0 19.948 4.251 26.824 11.12l.016.016c6.869 6.869 11.112 16.347 11.112 26.808h.057c0 .081-.049.154-.049.236l.001 127.009z"
                fill="#010002"
            />
        </svg>
    )
}

export default EmptyFolderSvg
