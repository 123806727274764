import EmptyFolderSvg from "./EmptyFolderSvg";
import './EmptyArchivePlaceholder.css';
import {useTranslation} from "react-i18next";

export function EmptyArchivePlaceholder() {
    const {t} = useTranslation();
    return (
        <div className="container-grow d-flex flex-column justify-content-center align-items-center">
            <div className="record-list-placeholder-icon">
                <EmptyFolderSvg/>
            </div>
            <span className="mt-4 record-list-placeholder">{t("empty_archive")}</span>
        </div>
    );
}