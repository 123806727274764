import Logger from "idb-logger";
import moment from "moment/moment";

export const MAX_LOG_RECORDS = 1000;

export const logger = new Logger({
    maxRecords: MAX_LOG_RECORDS
});

export function logError(message: string, reason: any) {
    console.log(reason);
    logger.error(message, reason.message ?? reason).then();
}

export function logInfo(message: string, payload?: any) {
    logger.log(message, payload).then();
}

export function downloadLogs() {
    logger.download({
        name: `novotest_archive_${moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss')}`,
        keys: ["msg"]
    }).then();
}
