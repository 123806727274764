import './AwpFwExpertUpdate.css';
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import {CONFIG, GSP, GSPS, NVP, NVT, PICS} from "../../models/AwpFwFileType";

interface Props{
    onFileSelect: (fileType: number, content: ArrayBuffer) => void;
}

export function AwpFwFileSelect(props : Props){
    const {t} = useTranslation();
    const select = async () => {
        const pickerOptions = {
            types: [
                {
                    description: 'Novotest New FW',
                    accept: {
                        'application/nvt': ['.gsp']
                    }
                },
                {
                    description: 'Novotest FW',
                    accept: {
                        'application/nvt': ['.nvt']
                    }
                },
                {
                    description: 'Novotest PICS',
                    accept: {
                        'application/nvt': ['.bin']
                    }
                },
                {
                    description: 'Novotest Full',
                    accept: {
                        'application/nvt': ['.nvp']
                    }
                },
                {
                    description: 'Novotest Config',
                    accept: {
                        'application/nvt': ['.ncf']
                    }
                }
            ],
            excludeAcceptAllOption: true,
            multiple: false
        };
        const [fileHandle] = await window.showOpenFilePicker(pickerOptions);
        let fileType;
        if (fileHandle.name.endsWith(".gsp")){
            fileType = GSP;
        } else if (fileHandle.name.endsWith(".gsps")){
            fileType = GSPS;
        } else if (fileHandle.name.endsWith(".nvt")){
            fileType = NVT;
        } else if (fileHandle.name.endsWith(".bin")){
            fileType = PICS;
        } else if (fileHandle.name.endsWith(".nvp")){
            fileType = NVP;
        } else if (fileHandle.name.endsWith(".ncf")){
            fileType = CONFIG;
        } else{
            throw new Error("Wrong file type");
        }
        const file = await fileHandle.getFile();
        const content = await file.arrayBuffer();
        props.onFileSelect(fileType, content);
    };
    return (
        <div
            className="container-grow d-flex flex-column m-4 justify-content-center align-items-center fw-instruction-text">
            <div className="my-2">{t('awp_fw_file_selection_instruction')}</div>
            <Button variant="primary" className="my-2"
                    onClick={select}>{t("select")}</Button>
        </div>
    );
}