import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

interface Props{
    to: string
}

export function Redirect(props: Props){
    const navigate = useNavigate();
    useEffect(() => {
        navigate(props.to);
    }, [navigate, props.to]);
    return <div/>
}