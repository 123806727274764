import LoaderSvg from "./LoaderSvg";
import './Loader.css';

export function LoaderSmall() {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center p-2">
            <div className="loader-small">
                <LoaderSvg/>
            </div>
        </div>
    );
}