import {Carousel, Modal} from "react-bootstrap";
import {useState} from "react";
import {TpMeasurement} from "../../models/TpMeasurement";
import './MeasurementDetails.css';
import {LineChartData, MeasurementsLineChart} from "./MeasurementsLineChart";
import {convertValue, getMeasurementError} from "../../models/TpScale";
import {formatTpScaleName, formatTpScaleValue} from "../../helpers/FormatHelper";
import {Loader} from "../Loader/Loader";
import {MeasurementsBarChart} from "./MeasurementsBarChart";
import {MeasurementsTable} from "./MeasurementsTable";
import {useTranslation} from "react-i18next";

interface Props {
    measurement: TpMeasurement | null;
    closeHandler: () => void;
    modalContainer: HTMLDivElement | undefined;
}

export function TpMeasurementDetails(props: Props) {
    const {t} = useTranslation();
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };
    const getLinesChartData = () => {
        const data = new Array<LineChartData>();
        let measurement = props.measurement;
        if (measurement) {
            for (let i = 0; i < measurement.n; i++) {
                data.push({
                    x: i + 1,
                    y: convertValue(measurement.tpScale, measurement.real[i])
                });
            }
        }
        return data;
    };
    const getBarChartData = () => {
        const data = new Array<number>();
        let measurement = props.measurement;
        if (measurement){
            for (let i = 0; i < measurement.n; i++) {
                data.push(convertValue(measurement.tpScale, measurement.real[i]));
            }
        }
        return data;
    };
    const getTableData = () => {
        const data = new Array<number>();
        let measurement = props.measurement;
        if (measurement){
            for (let i = 0; i < measurement.n; i++) {
                data.push(convertValue(measurement.tpScale, measurement.real[i]));
            }
        }
        return data;
    };
    return (
        <Modal show={props.measurement !== null} size="lg" centered onHide={props.closeHandler} container={props.modalContainer}>
            <Modal.Header closeButton>{t('measurements')}</Modal.Header>
            <Modal.Body>
                {props.measurement ?
                    <Carousel activeIndex={index} onSelect={handleSelect} controls interval={null}>
                        <Carousel.Item>
                            <MeasurementsLineChart classNames="measurement-details-container" data={getLinesChartData()}
                                                   measurementError={getMeasurementError(props.measurement.tpScale)}
                                                   name={formatTpScaleName(t, props.measurement.tpScale)}
                                                   valueFormatter={v => `${formatTpScaleValue(props.measurement?.tpScale ?? "", v, true)} ${formatTpScaleName(t,props.measurement?.tpScale ?? "")}`}/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <MeasurementsBarChart classNames="measurement-details-container" data={getBarChartData()}/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <MeasurementsTable data={getTableData()} formatter={v => formatTpScaleValue(props.measurement?.tpScale ?? "", v, true)}/>
                        </Carousel.Item>
                    </Carousel> : <Loader/>
                }
            </Modal.Body>
        </Modal>
    );
}