export const HL = "HL";
export const HRC = "HRC";
export const HRB = "HRB";
export const HB = "HB";
export const HV = "HV";
export const HSD = "HSD";
export const HRA = "HRA";
export const MPA = "MPA";
export const USER1 = "USER1";
export const USER2 = "USER2";


export function getMeasurementError(leebScale: string) {
    switch (leebScale) {
        case HL:
            return 10;
        case HRC:
            return 4;
        case HRB:
            return 4;
        case HB:
            return 20;
        case HV:
            return 20;
        case HSD:
            return 10;
        case HRA:
            return 4;
        case MPA:
            return 10;
        case USER1:
            return 10;
        case USER2:
            return 10;
        default:
            return 10;
    }
}