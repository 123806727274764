import {Fragment, useEffect, useRef, useState} from "react";
import {Col, Row, Toast, ToastBody, ToastContainer} from "react-bootstrap";
import {Loader} from "../Loader/Loader";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import {Error} from "../Error/Error";
import {useUpdateToken} from "../../hooks/UpdateTokenHook";
import {del, set} from 'idb-keyval';
import {useAwpSerialDevice} from "../../serial/SerialPortHook";
import {useTranslation} from "react-i18next";
import {ToastData} from "../../models/ToastData";
import {ModalTwoButtons} from "../ModalTwoButtons/ModalTwoButtons";
import {ModalSingleButton} from "../ModalSingleButton/ModalSingleButton";
import {EmptyArchivePlaceholder} from "../AwpRecordList/EmptyArchivePlaceholder";
import {FetchData} from "../../models/FetchData";
import {ControlledMenu, MenuItem, useMenuState} from "@szhsin/react-menu";
import '@szhsin/react-menu/dist/index.css';
import {ArchiveLocation} from "../ArchiveLocation/ArchiveLocation";
import {ArchiveDownloadControl} from "../ArchiveDownloadControl/ArchiveDownloadControl";
import {ToolBarButton} from "../ToolBarButton/ToolBarButton";
import IconTrashSvg from "../Icons/IconTrashSvg";
import IconInfoSvg from "../Icons/IconInfoSvg";
import './AwpManager.css';
import {backupFsHandle, useFileSystem} from "../../filesystem/FileSystemHook";
import {IS_LOCAL_APP, MIN_SCREEN_WIDTH_TO_SHOW_RECORD_PREVIEW, TOAST_DELAY} from "../../AppSettings";
import {AwpRecordInfo} from "../../models/AwpRecordInfo";
import {AwpRecordList} from "../AwpRecordList/AwpRecordList";
import {useNavigate, useParams} from "react-router-dom";
import {AwpRecordPreview} from "../AwpRecordPreview/AwpRecordPreview";
import {buildAwpIpsmDataTransferPath, buildAwpListPath, buildAwpRecordPath} from "../../routes";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_UD3701,
    AwpRecord,
    getAwpFsHandle,
    readAwpRecord,
    readAwpRecordInfo
} from "../../helpers/AwpHelper";
import {usePersistentState} from "../../hooks/PersistentStateHook";
import {SHOW_PREVIEW} from "../../persistence";
import {isMobile} from "react-device-detect";
import {useScreenSize} from "../../hooks/ScreenSizeHooks";
import {formatAwpDeviceName} from "../../helpers/AwpOptions";
import {AwpBreadcrumbs} from "./AwpBreadcrumbs";
import {AwpBreadcrumbItem} from "./AwpBreadcrumbItem";
import {logError, logger} from "../../helpers/LogHelper";

interface Props {
    deviceType: number;
}

interface ConfirmDownloadModalData {
    count: number;
    resolve: (value: boolean) => void;
}

interface Ud3701InstructionModalData {
    resolve: () => void;
}

export function AwpLocalManager(props: Props) {
    const {t} = useTranslation();
    const history = useNavigate();
    useEffect(() => {
        document.querySelector('body')?.classList?.add('body-awp');
        return () => {
            document.querySelector('body')?.classList?.remove('body-awp');
        }
    });
    const firstSelectedItemId = useRef(null as string | null);
    const sortedIds = useRef(null as Array<string> | null);
    const [readProgress, setReadProgress] = useState(null as number | null);
    const [wholeArchive, setWholeArchive] = useState(true);
    const [fromDate, setFromDate] = useState(new Date(2001, 0, 1));
    const [toDate, setToDate] = useState(new Date());
    const [baudRate, setBaudRate] = useState(undefined as number | undefined);
    const [toastData, setToastData] = useState(null as ToastData | null);
    const [showUd3701InstructionModal, setShowUd3701InstructionModal] = useState(null as Ud3701InstructionModalData | null);
    const [showMoveConfirmModal, setShowMoveConfirmModal] = useState(false);
    const [showNoRecordsModal, setShowNoRecordsModal] = useState(false);
    const [showConfirmDownloadModal, setShowConfirmDownloadModal] = useState(null as ConfirmDownloadModalData | null);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [showReadingFailedModal, setShowReadingFailedModal] = useState(false);
    const [updateToken, refreshToken] = useUpdateToken();
    const {path} = useParams();
    const folders = (path && path !== "@") ? path.split("@") : new Array<string>();
    const fsHandle = useFileSystem(getAwpFsHandle(props.deviceType), folders, updateToken);
    const device = useAwpSerialDevice(props.deviceType, baudRate);
    const [records, setRecords] = useState(FetchData.init<Array<AwpRecordInfo>>());
    const [highlightedItems, setHighlightedItems] = useState(() => new Array<string>());
    const singleHighlightedItem = highlightedItems.length === 1 ? highlightedItems[0] : undefined;
    const [activeRecord, setActiveRecord] = useState(FetchData.init<AwpRecord>());
    const {toggleMenu, ...menuProps} = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0});
    const [contextRecordFileNames, setContextRecordFileNames] = useState(null as Array<string> | null);
    const [showPreview, setShowPreview] = usePersistentState(SHOW_PREVIEW, !isMobile || window.innerWidth > window.innerHeight);
    const [width] = useScreenSize();
    const isPreviewAvailable = width > MIN_SCREEN_WIDTH_TO_SHOW_RECORD_PREVIEW;
    useEffect(() => {
        if (singleHighlightedItem && (!records.data || records.data.every(r => r.fileName !== singleHighlightedItem))) {
            setHighlightedItems(new Array<string>());
        }
    }, [records]);
    useEffect(() => {
        if (fsHandle?.handle && singleHighlightedItem) {
            readAwpRecord(props.deviceType, fsHandle.handle, singleHighlightedItem).then(record => {
                setActiveRecord(record ? FetchData.value(record) : FetchData.error<AwpRecord>());
            });
        } else {
            setActiveRecord(FetchData.init<AwpRecord>());
        }
    }, [fsHandle, singleHighlightedItem]);
    useEffect(() => {
        const keyboardListener = (ev: KeyboardEvent) => {
            if ((ev.key.toLowerCase() === 'a' || ev.key.toLowerCase() === 'ф') && ev.ctrlKey) {
                setHighlightedItems(sortedIds.current ?? []);
                ev.preventDefault();
            } else if (ev.key === 'Delete') {
                deleteHighlightedRecord();
                ev.preventDefault();
            }
        };
        window.addEventListener('keydown', keyboardListener);
        return () => {
            window.removeEventListener('keydown', keyboardListener);
        }
    }, [highlightedItems])
    const updateRecords = () => {
        if (fsHandle?.handle) {
            const promise: Promise<Array<AwpRecordInfo>> = new Promise(async (resolve) => {
                const results = new Array<AwpRecordInfo>();
                if (fsHandle.handle) {
                    const entries = await fsHandle.handle.directories();
                    for (const entry of entries) {
                        const info = await readAwpRecordInfo(props.deviceType, entry);
                        if (info) {
                            results.push(info);
                        } else {
                            results.push({
                                fileName: entry.name(),
                                isFolder: true,
                                name: entry.name(),
                                date: undefined,
                                probeName: "",
                                probeType: undefined,
                                probeId: "",
                                deviceId: ""
                            });
                        }
                    }
                }
                resolve(results);
            });
            promise.then(records => setRecords(FetchData.value(records)));
        } else {
            setRecords(FetchData.init<Array<AwpRecordInfo>>());
        }
    }
    useEffect(() => {
        updateRecords();
    }, [fsHandle]);
    const updateFileSystemHandle = () => {
        if (fsHandle !== undefined && !fsHandle.isGranted) {
            del(getAwpFsHandle(props.deviceType)).then(() => {
                refreshToken();
            });
        } else {
            backupFsHandle(getAwpFsHandle(props.deviceType)).then(() => {
                if (folders.length > 0) {
                    history(buildAwpListPath(props.deviceType, []));
                } else {
                    refreshToken();
                }
            });
        }
    };
    const updateProgress = (progress: number) => {
        setReadProgress(progress);
    };
    const copyRecords = () => {
        if (props.deviceType === AWP_DEVICE_IPSM) {
            history(buildAwpIpsmDataTransferPath(folders));
        } else {
            readRecords(false);
        }
    }
    const moveRecords = () => {
        setShowMoveConfirmModal(true);
    }
    const onMoveRecordsConfirm = () => {
        readRecords(true);
    }
    const downloadConfirmation: (count: number) => Promise<boolean> = (count) => {
        if (count === 0) {
            setShowNoRecordsModal(true);
            return Promise.resolve(false);
        } else {
            return new Promise<boolean>(resolve => {
                setShowConfirmDownloadModal({
                    count: count,
                    resolve: resolve
                });
            });
        }
    }
    const readRecords = (move: boolean) => {
        if (fsHandle?.handle) {
            setReadProgress(0);
            let instructionCallback;
            if (props.deviceType === AWP_DEVICE_UD3701) {
                instructionCallback = () => new Promise<void>(resolve => {
                    setShowUd3701InstructionModal({
                        resolve: resolve
                    });
                });
            }
            const from = wholeArchive ? new Date(2001, 0, 1) : fromDate;
            const to = wholeArchive ? new Date(2099, 11, 31) : toDate;
            device.readRecords(fsHandle.handle, from, to, move, updateProgress, downloadConfirmation, instructionCallback)
                .then((isDone) => {
                    if (isDone) {
                        setToastData({
                            message: t('reading_success'),
                            isSuccess: true,
                            delay: TOAST_DELAY
                        });
                    }
                    return;
                })
                .catch((reason) => {
                    if (reason) {
                        setShowReadingFailedModal(true);
                        logError("Data read error", reason);
                    }
                    return;
                })
                .then(() => {
                    setReadProgress(null);
                    updateRecords();
                });
        }
    };
    const cancelReading = () => {
        device.abort();
    };
    const showRecord = (fileNames: Array<string>) => {
        if (fileNames.length > 0) {
            const fileName = fileNames[0];
            if (fileName === "" && folders.length > 0) {
                folders.pop();
                const newPath = buildAwpListPath(props.deviceType, [...folders]);
                history(newPath);
            }
            if (records.data) {
                const info = records.data.find(v => v.fileName === fileName);
                if (info) {
                    const newPath = info.isFolder ? buildAwpListPath(props.deviceType, [...folders, fileName]) : buildAwpRecordPath(props.deviceType, folders, fileName);
                    history(newPath);
                }
            }
        }
    };
    const showRecordNewTab = (fileNames: Array<string>) => {
        if (fileNames.length > 0) {
            window.open(buildAwpRecordPath(props.deviceType, folders, fileNames[0]), '_blank');
        }
    };
    const contextMenuHandler = (id: string, x: number, y: number) => {
        setAnchorPoint({x: x, y: y});
        if (highlightedItems.includes(id)) {
            const records = new Array<string>();
            records.push(id);
            records.push(...highlightedItems.filter(i => i !== id));
            setContextRecordFileNames(records);
        } else {
            setContextRecordFileNames([id]);
        }
        toggleMenu(true);
    };
    const openContextRecord = () => {
        if (contextRecordFileNames) {
            showRecord(contextRecordFileNames);
        }
    };
    const openContextRecordNewTab = () => {
        if (contextRecordFileNames) {
            showRecordNewTab(contextRecordFileNames);
        }
    };
    const deleteContextRecord = () => {
        if (contextRecordFileNames) {
            setShowDeleteConfirmModal(true);
        }
    };
    const deleteHighlightedRecord = () => {
        if (highlightedItems.length > 0) {
            setContextRecordFileNames(highlightedItems);
            setShowDeleteConfirmModal(true);
        }
    }
    const onDeleteContextRecordsConfirm = () => {
        if (contextRecordFileNames) {
            if (fsHandle?.handle) {
                Promise.all(contextRecordFileNames.map(contextRecordFileName => fsHandle?.handle?.getFileSystemHandle()?.removeEntry(contextRecordFileName, {recursive: true})))
                    .then(() => {
                        if (contextRecordFileNames && contextRecordFileNames.length > 1) {
                            setHighlightedItems(new Array<string>());
                        }
                        setContextRecordFileNames(null);
                        updateRecords();
                    });
            }
        }
    };
    const handleItemClick = (id?: string, ctrl?: boolean, shift?: boolean) => {
        if (id) {
            if (ctrl) {
                if (highlightedItems.includes(id)) {
                    setHighlightedItems(highlightedItems.filter(i => i !== id));
                } else {
                    highlightedItems.push(id)
                    setHighlightedItems(new Array(...highlightedItems));
                }
                firstSelectedItemId.current = id;
            } else if (shift) {
                if (sortedIds.current) {
                    const items = [];
                    let started = false;
                    if (firstSelectedItemId.current === null) {
                        started = true;
                    }
                    for (const item of sortedIds.current) {
                        if (!started) {
                            if (item === firstSelectedItemId.current || item === id) {
                                started = true;
                            }
                        }
                        if (started) {
                            items.push(item);
                        }
                        if (items.includes(id) && (!firstSelectedItemId.current || items.includes(firstSelectedItemId.current))) {
                            break;
                        }
                    }
                    setHighlightedItems(items);
                }
            } else {
                setHighlightedItems(new Array<string>(...[id]));
                firstSelectedItemId.current = id;
            }
        } else {
            setHighlightedItems(new Array<string>());
            firstSelectedItemId.current = null;
        }
    }

    const areFsControlsDisabled = fsHandle === undefined || readProgress !== null;
    const areReadControlsDisabled = !fsHandle || !fsHandle.handle || readProgress !== null;
    const isReadAbortControlDisabled = readProgress === null;
    const breadcrumbs = new Array<AwpBreadcrumbItem>();
    breadcrumbs.push({
        displayName: formatAwpDeviceName(t, props.deviceType) ?? "",
        path: buildAwpListPath(props.deviceType, [])
    });
    breadcrumbs.push(...folders.map((folder, i) => {
        return {
            displayName: folder,
            path: buildAwpListPath(props.deviceType, folders.slice(0, i + 1))
        } as AwpBreadcrumbItem;
    }));
    return (
        <div className="container-grow d-flex flex-column mx-4 mt-4">
            <Row>
                <Col>
                    <h5>{formatAwpDeviceName(t, props.deviceType)}</h5>
                </Col>
            </Row>
            <Row md={1} lg={2}>
                <Col lg={4} className="my-2">
                    <ArchiveLocation disabled={areFsControlsDisabled} selectClickHandler={updateFileSystemHandle}
                                     fsHandle={fsHandle}/>
                </Col>
                <Col lg={8} className="my-2">
                    <ArchiveDownloadControl deviceType={props.deviceType} readProgress={readProgress}
                                            readControlsDisabled={areReadControlsDisabled}
                                            readAbortDisabled={isReadAbortControlDisabled} moveClick={moveRecords}
                                            copyClick={copyRecords}
                                            cancelClick={cancelReading}
                                            wholeArchive={wholeArchive} wholeArchiveCallback={setWholeArchive}
                                            fromDate={fromDate} fromDateCallback={setFromDate}
                                            toDate={toDate} toDateCallback={setToDate}
                                            baudRate={baudRate} baudRateCallback={setBaudRate}/>
                </Col>
            </Row>
            <HorizontalDivider className="my-1"/>
            <div className="container-grow align-self-stretch">
                {fsHandle === undefined && <Loader/>}
                {(fsHandle !== undefined && fsHandle.isGranted === undefined) &&
                    <Error errorMessage={t('fs_archive_location_not_set')} retryText={t('select_archive_location')}
                           retryClickHandler={refreshToken}/>}
                {(fsHandle !== undefined && fsHandle.isGranted === false) &&
                    <Error errorMessage={t('fs_permission_missing')} retryText={t('grant_permission')}
                           retryClickHandler={refreshToken}/>}
                {(fsHandle !== undefined && fsHandle.isGranted && !fsHandle.isExist) &&
                    <Error errorMessage={t('fs_archive_missing')} retryText={t('select_archive_location')}
                           retryClickHandler={folders.length === 0 ? refreshToken : undefined}/>}
                {readProgress !== null &&
                    <Loader message={t(IS_LOCAL_APP ? 'do_not_close_message' : 'do_not_switch_tab_message')}
                            isDanger={true}>
                        {props.deviceType === AWP_DEVICE_UD3701 &&
                            <div
                                className="loader-message-danger align-self-center">{t('ud3701_will_be_turned_off')}</div>}
                    </Loader>}

                {readProgress === null && records.data &&
                    <Fragment>
                        <div className="d-flex flex-row justify-content-end">
                            <div className="d-flex flex-grow-1">
                                <AwpBreadcrumbs
                                    path={breadcrumbs}
                                    locationChangeListener={path => history(path)}/>
                            </div>
                            <div className="mx-2"/>
                            <ToolBarButton icon={<IconTrashSvg/>} disabled={highlightedItems.length === 0}
                                           clickHandler={deleteHighlightedRecord}
                                           popoverText={t('record_list_instruction_2')}/>
                            {isPreviewAvailable &&
                                <Fragment>
                                    <div className="mx-2"/>
                                    <ToolBarButton icon={<IconInfoSvg/>} activated={showPreview}
                                                   clickHandler={() => setShowPreview(!showPreview)}/>
                                </Fragment>
                            }
                        </div>
                        <HorizontalDivider className="my-1"/>
                        <div className="container-grow container-records flex-row">
                            <div
                                className="d-flex flex-grow-1 flex-column align-self-stretch m-2 overflow-y-auto"
                                onClick={() => handleItemClick()}>
                                <AwpRecordList isRoot={folders.length === 0} deviceType={props.deviceType}
                                               records={records.data}
                                               highlightedItems={highlightedItems}
                                               itemHighlightListener={handleItemClick}
                                               itemDoubleClickListener={(id) => showRecord([id])}
                                               itemContextClickListener={contextMenuHandler}
                                               sortedIdsRef={sortedIds}/>
                            </div>
                            {isPreviewAvailable &&
                                <AwpRecordPreview deviceType={props.deviceType} showPreview={showPreview}
                                                  record={activeRecord} count={highlightedItems.length}
                                                  onClose={() => setShowPreview(false)}
                                                  onDetailsClick={() => showRecord(highlightedItems)}/>
                            }
                        </div>
                    </Fragment>
                }
            </div>
            <ToastContainer position={"bottom-end"} className="mb-4 me-4 position-fixed">
                <Toast onClose={() => setToastData(null)} show={toastData !== null}
                       bg={toastData?.isSuccess !== undefined ? (toastData.isSuccess ? 'success' : 'danger') : 'primary'}
                       delay={toastData?.delay ?? 0} autohide={toastData?.delay !== undefined}>
                    <ToastBody>{toastData?.message}</ToastBody>
                </Toast>
            </ToastContainer>
            <ModalSingleButton show={showUd3701InstructionModal !== null} title={t("records_read_title")}
                               body={t("ud3701_data_read_instruction")}
                               buttonText={t("ok")}
                               closeHandler={() => {
                                   showUd3701InstructionModal?.resolve();
                                   setShowUd3701InstructionModal(null);
                               }}/>
            <ModalTwoButtons show={showMoveConfirmModal} title={t("move_confirmation_title")}
                             body={t("move_confirmation_message")} negativeButtonText={t("no")}
                             positiveButtonText={t("yes")}
                             closeHandler={() => setShowMoveConfirmModal(false)}
                             positiveButtonAction={onMoveRecordsConfirm}/>
            <ModalSingleButton show={showReadingFailedModal} title={t("reading_fail")} body={t("reading_fail_message")}
                               buttonText={t("ok")}
                               closeHandler={() => setShowReadingFailedModal(false)}/>
            <ModalSingleButton show={showNoRecordsModal} title={t("records_read_title")} body={t("no_records_message")}
                               buttonText={t("ok")}
                               closeHandler={() => setShowNoRecordsModal(false)}/>
            <ModalTwoButtons show={showConfirmDownloadModal !== null} title={t("records_read_title")}
                             body={t("records_count_message", {count: showConfirmDownloadModal?.count ?? 0})}
                             negativeButtonText={t("no")} positiveButtonText={t("yes")}
                             closeHandler={(hasResult?: boolean) => {
                                 if (!hasResult) {
                                     showConfirmDownloadModal?.resolve(false);
                                 }
                                 setShowConfirmDownloadModal(null);
                             }}
                             negativeButtonAction={() => showConfirmDownloadModal?.resolve(false)}
                             positiveButtonAction={() => showConfirmDownloadModal?.resolve(true)}/>
            <ModalTwoButtons show={showDeleteConfirmModal} title={t("delete_confirmation_title")}
                             body={(contextRecordFileNames?.length ?? 0) > 1 ? t("delete_multiple_records_confirmation_message") : t("delete_single_record_confirmation_message")}
                             negativeButtonText={t("no")}
                             positiveButtonText={t("yes")}
                             closeHandler={() => setShowDeleteConfirmModal(false)}
                             positiveButtonAction={onDeleteContextRecordsConfirm}/>
            <ControlledMenu {...menuProps} anchorPoint={anchorPoint}
                            onClose={() => toggleMenu(false)} menuClassName={"context-menu"}>
                <MenuItem onClick={openContextRecord}>{t("open")}</MenuItem>
                {!IS_LOCAL_APP &&
                    <MenuItem onClick={openContextRecordNewTab}>{t("open_new_tab")}</MenuItem>
                }
                <MenuItem onClick={deleteContextRecord}>{t("delete")}</MenuItem>
            </ControlledMenu>
        </div>
    );
}

