import {ArchiveItem} from "../../models/ArchiveItem";
import './Breadcrumbs.css';
import {getArchiveItemName} from "../../helpers/ArchiveFormatHelper";
import {useTranslation} from "react-i18next";

interface Props {
    path: Array<ArchiveItem>;
    locationChangeListener : (newLocation? : string) => void;
}

export function Breadcrumbs(props: Props) {
    const {t} = useTranslation();
    const itemClickHandler = (idx : number) => {
        if (idx < props.path.length - 1) {
            props.locationChangeListener(idx > 0 ? props.path[idx].id : undefined);
        }
    };
    return (
        <div className="breadcrumbs no-select">
            {props.path.flatMap((item, i) => {
                const result = [];
                if (i > 0) {
                    result.push(<span key={`d-${item.id}`}> / </span>);
                }
                result.push(<span key={`bc-${item.id}`} onClick={() => itemClickHandler(i)} className={`breadcrumbs-item ${i === props.path.length - 1 ? "active-item" : ""}`}>{getArchiveItemName(t, i, item.name)}</span>);
                return result;
            })}
        </div>
    );
}