import * as React from "react"

function IconCsvSg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            width="1em"
            height="1em"
            {...props}
        >
            <path d="M47.987 21.938a.99.99 0 00-.053-.264c-.011-.032-.019-.063-.033-.094a.957.957 0 00-.193-.285l-.001-.001L42 15.586V10c0-.022-.011-.041-.013-.063a1.028 1.028 0 00-.051-.257c-.011-.032-.019-.063-.034-.094a.997.997 0 00-.196-.293l-9-9a.97.97 0 00-.294-.196c-.03-.014-.06-.022-.091-.032a.937.937 0 00-.263-.052C32.039.01 32.021 0 32 0H7a1 1 0 00-1 1v14.586L.293 21.293l-.002.002a.98.98 0 00-.192.285c-.014.031-.022.062-.033.094a.953.953 0 00-.053.264C.011 21.96 0 21.978 0 22v19a1 1 0 001 1h5v5a1 1 0 001 1h34a1 1 0 001-1v-5h5a1 1 0 001-1V22c0-.022-.011-.04-.013-.062zM44.586 21H42v-2.586L44.586 21zm-6-12H33V3.414L38.586 9zM8 2h23v8a1 1 0 001 1h8v10H8V2zM6 18.414V21H3.414L6 18.414zM40 46H8v-4h32v4zm6-6H2V23h44v17z" fill="currentColor"/>
            <path d="M23.422 27.885c.147-.136.312-.235.493-.298a1.72 1.72 0 01.561-.093c.669 0 1.224.266 1.666.799l1.122-1.462a3.164 3.164 0 00-1.215-.876c-.482-.198-1.028-.297-1.64-.297-.419 0-.833.071-1.241.212-.408.142-.774.36-1.097.655a3.285 3.285 0 00-.782 1.113c-.198.448-.298.984-.298 1.607 0 .499.065.926.195 1.283.13.358.306.669.527.935.221.267.476.496.765.689.289.193.598.368.927.527.521.261.952.544 1.292.85.34.306.51.72.51 1.241 0 .533-.142.946-.425 1.241s-.64.442-1.071.442c-.385 0-.762-.091-1.131-.272s-.683-.431-.944-.748l-1.105 1.496c.34.397.793.725 1.36.986a4.375 4.375 0 001.853.391c.465 0 .907-.079 1.326-.238a3.076 3.076 0 001.097-.706 3.46 3.46 0 00.748-1.147c.187-.453.281-.975.281-1.564 0-.51-.079-.952-.238-1.326a3.376 3.376 0 00-.612-.969 4.065 4.065 0 00-.833-.697 10.324 10.324 0 00-.901-.51c-.499-.249-.901-.513-1.207-.791-.306-.277-.459-.671-.459-1.181 0-.295.042-.55.128-.765.085-.215.201-.391.348-.527zM15.177 28.854c.204-.397.459-.711.765-.944a1.723 1.723 0 011.071-.348c.737 0 1.337.334 1.802 1.003l1.173-1.428a3.284 3.284 0 00-1.275-1.097c-.521-.255-1.128-.382-1.819-.382-.669 0-1.278.156-1.828.467a4.229 4.229 0 00-1.403 1.309c-.385.562-.683 1.23-.892 2.006a9.72 9.72 0 00-.314 2.542c0 .918.105 1.762.314 2.533s.504 1.436.884 1.997c.38.562.844 1.001 1.394 1.318s1.165.476 1.845.476c.748 0 1.368-.147 1.861-.442s.898-.68 1.215-1.156l-1.173-1.377c-.193.295-.434.544-.722.748s-.632.306-1.028.306c-.419 0-.785-.116-1.097-.349-.312-.232-.569-.546-.774-.943s-.357-.864-.459-1.403a9.158 9.158 0 01-.153-1.708c0-.612.051-1.187.153-1.726a5.199 5.199 0 01.46-1.402zM32.007 35.62h-.051l-2.006-9.656h-2.278L30.613 38h2.584l2.992-12.036h-2.176z" fill="currentColor"/>
        </svg>
    )
}

export default IconCsvSg
