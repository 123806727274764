import {Button, ButtonGroup, Dropdown, Form, ProgressBar} from "react-bootstrap";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {Fragment} from "react";
import {AWP_DEVICE_IPSM, AWP_DEVICE_MF, AWP_DEVICE_TP, AWP_DEVICE_UT} from "../../helpers/AwpHelper";
import {HelpButton} from "../HelpButton/HelpButton";
import {useScreenSize} from "../../hooks/ScreenSizeHooks";
import {MIN_SCREEN_WIDTH_TO_SHOW_RECORD_PREVIEW} from "../../AppSettings";

interface Props {
    deviceType: number;
    readControlsDisabled: boolean;
    readAbortDisabled: boolean;
    readProgress: number | null;
    moveClick: () => void;
    copyClick: () => void;
    cancelClick: () => void;
    wholeArchive: boolean;
    wholeArchiveCallback: (wholeArchive: boolean) => void;
    fromDate: Date;
    fromDateCallback: (date: Date) => void;
    toDate: Date;
    toDateCallback: (date: Date) => void;
    baudRate?: number;
    baudRateCallback: (baudRate?: number) => void;
}

export function ArchiveDownloadControl(props: Props) {
    const {t} = useTranslation();
    let helpText;
    switch (props.deviceType) {
        case AWP_DEVICE_TP:
        case AWP_DEVICE_UT:
        case AWP_DEVICE_MF:
            helpText = t('awp_device_instruction');
            break;
        default:
            helpText = t('awp_device_instruction_with_date');
            break;
    }
    const [width] = useScreenSize();
    const singleRow = width > MIN_SCREEN_WIDTH_TO_SHOW_RECORD_PREVIEW;
    const dateRange = (
        <Fragment>
            <Form.Check type={"checkbox"} label={t('whole_archive')} className="me-4 single-line"
                        checked={props.wholeArchive} disabled={props.readControlsDisabled}
                        onChange={() => props.wholeArchiveCallback(!props.wholeArchive)}/>
            <div className="mx-2 single-line">{t('from_prompt')}</div>
            <Form.Control size="sm" type="date"
                          disabled={props.readControlsDisabled || props.wholeArchive}
                          value={moment(props.fromDate).format("YYYY-MM-DD")}
                          onChange={v => {
                              if (!v.target.value){
                                  v.target.value = moment(new Date()).format("YYYY-MM-DD")
                              }
                              props.fromDateCallback(moment(v.target.value).toDate());
                          }}/>
            <div className="mx-2 single-line">{t('to_prompt')}</div>
            <Form.Control size="sm" type="date"
                          disabled={props.readControlsDisabled || props.wholeArchive}
                          value={moment(props.toDate).format("YYYY-MM-DD")}
                          onChange={v => {
                              if (!v.target.value){
                                  v.target.value = moment(new Date()).format("YYYY-MM-DD")
                              }
                              props.toDateCallback(moment(v.target.value).toDate());
                          }}/>
        </Fragment>
    );
    const deviceControls = (
        <Fragment>
            {props.deviceType !== AWP_DEVICE_IPSM &&
                <Fragment>
                    {props.deviceType === AWP_DEVICE_UT &&
                        <Form.Check type={"checkbox"} label={t('ut_1m_ip_short')} className="me-4"
                                    checked={props.baudRate !== undefined} disabled={props.readControlsDisabled}
                                    onChange={() => props.baudRateCallback(props.baudRate === undefined ? 19200 : undefined)}/>
                    }
                    <Dropdown as={ButtonGroup} className="mx-2">
                        <Button onClick={props.copyClick} size="sm"
                                disabled={props.readControlsDisabled}>{t('copy')}</Button>

                        <Dropdown.Toggle id="dropdown-split-basic" size="sm"
                                         disabled={props.readControlsDisabled}/>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={props.copyClick}
                                           disabled={props.readControlsDisabled}>{t('copy')}</Dropdown.Item>
                            <Dropdown.Item onClick={props.moveClick}
                                           disabled={props.readControlsDisabled}>{t('move')}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {helpText && <HelpButton text={helpText}/>}
                </Fragment>
            }
            {props.deviceType === AWP_DEVICE_IPSM &&
                <Button className="mx-2" size="sm"
                        onClick={props.copyClick}>{t("read_data_from_device")}</Button>
            }
        </Fragment>
    );
    return (
        <div className="d-flex flex-grow-1 flex-column align-self-stretch align-items-center">
            <div className="d-flex flex-row align-self-stretch align-items-center">
                <div className="mx-2 single-line">{t('archive_download')}</div>
            </div>
            {props.readProgress === null &&
                <Fragment>
                    {singleRow &&
                        <div className="d-flex flex-row align-self-stretch align-items-center">
                            {props.deviceType !== AWP_DEVICE_TP && props.deviceType !== AWP_DEVICE_UT && props.deviceType !== AWP_DEVICE_MF && props.deviceType !== AWP_DEVICE_IPSM &&
                                dateRange
                            }
                            {deviceControls}
                        </div>
                    }
                    {!singleRow &&
                        <Fragment>
                            {props.deviceType !== AWP_DEVICE_TP && props.deviceType !== AWP_DEVICE_UT && props.deviceType !== AWP_DEVICE_MF &&
                                <Fragment>
                                    <div className="d-flex flex-row align-self-stretch align-items-center">
                                        {dateRange}
                                    </div>
                                    <div className="my-2"/>
                                </Fragment>
                            }
                            <div className="d-flex flex-row align-self-stretch justify-content-end">
                                {deviceControls}
                            </div>
                        </Fragment>
                    }
                </Fragment>
            }
            {props.readProgress !== null &&
                <div className="d-flex flex-row align-self-stretch align-items-center mb-2">
                    <ProgressBar className="flex-grow-1 mx-2" animated now={props.readProgress} min={0}
                                 max={100}/>
                    <Button size="sm" className="mx-2" disabled={props.readAbortDisabled}
                            onClick={props.cancelClick}>{t('abort')}</Button>
                </div>
            }
        </div>
    );
}