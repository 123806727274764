import {Button, ButtonGroup} from "react-bootstrap";
import * as ExecutorsMode from "../../models/ExecutorsMode";
import IconListSvg from "../Icons/IconListSvg";
import IconMapSvg from "../Icons/IconMapSvg";

interface Props {
    mode: number;
    modeChangeListener: (mode: number) => void;
}

export function ExecutorsModeSwitcher(props: Props) {
    return (
        <ButtonGroup>
            <Button variant={props.mode === ExecutorsMode.LIST ? "primary" : "outline-primary"}
                    onClick={() => props.modeChangeListener(ExecutorsMode.LIST)}><IconListSvg/></Button>
            <Button variant={props.mode === ExecutorsMode.MAP ? "primary" : "outline-primary"}
                    onClick={() => props.modeChangeListener(ExecutorsMode.MAP)}><IconMapSvg/></Button>
        </ButtonGroup>
    );
}