import './AwpFwExpertUpdate.css';
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";


interface Props{
    onPortSelect: () => void;
}


export function AwpFwPortSelect(props: Props){
    const {t} = useTranslation();
    return (
        <div
            className="container-grow d-flex flex-column m-4 justify-content-center align-items-center fw-instruction-text">
            <div className="my-2">{t('awp_fw_port_selection_instruction')}</div>
            <Button variant="primary" className="my-2"
                    onClick={props.onPortSelect}>{t("select")}</Button>
        </div>
    );
}