import {jsPDF} from "jspdf";
import {Rect} from "./Rect";
import {GeoLocation} from "../models/GeoLocation";
import {TudMeasurement} from "../models/TudMeasurement";
import {TpMeasurement} from "../models/TpMeasurement";
import {UtMeasurement} from "../models/UtMeasurement";
import {ImageData} from "./ImageData";
import {MediaRecord} from "../models/MediaRecord";
import {TFunction} from "i18next";
import {roboto} from "./RobotoFont";
import {robotoBold} from "./RobotoBoldFont";
import {DPM, LEEB, MF1M, TP1M, TUD2, TUD3, UCI, UT1M, UT1M_CT, UT1M_IP} from "../models/DeviceType";
import {
    formatLeebScaleName,
    formatLeebScaleValue,
    formatMfScaleName,
    formatMfScaleValue,
    formatNumber,
    formatTpScaleName,
    formatTpScaleValue,
    formatTudScaleName,
    formatTudScaleValue,
    formatUciScaleName,
    formatUciScaleValue,
    formatUtScaleName,
    formatUtScaleValue
} from "../helpers/FormatHelper";
import * as TpScale from '../models/TpScale';
import * as TudScale from '../models/TudScale';
import * as LeebScale from '../models/LeebScale';
import * as UciScale from '../models/UciScale';
import * as UtScale from '../models/UtScale';
import {convertValue} from '../models/UtScale';
import * as MfScale from '../models/MfScale';
import {buildLineChart} from "./PdfLineChart";
import {LineChartData} from "../components/MeasurementDetails/MeasurementsLineChart";
import {buildBarChart} from "./PdfBarChart";
import {AreaChartData} from "../components/MeasurementDetails/MeasurementsAreaChart";
import {buildAreaChart} from "./PdfAreaChart";
import {MeasurementLocation} from "../models/MeasurementLocation";
import {MeasurementMarker} from "../components/PhotoViewer/MeasurementsWithPhoto";
import {UciMeasurement} from "../models/UciMeasurement";
import {MfMeasurement} from "../models/MfMeasurement";
import {TpSurfaceTemperatureMeasurement} from "../models/TpSurfaceTemperatureMeasurement";
import {TpDewPointMeasurement} from "../models/TpDewPointMeasurement";
import {
    COATING_THICKNESS_MEASUREMENT_TYPE,
    DEW_POINT_MEASUREMENT_TYPE,
    SURFACE_TEMPERATURE_MEASUREMENT_TYPE
} from "../models/TpRecord";
import {LeebMeasurement} from "../models/LeebMeasurement";
import {DpmMeasurement} from "../models/DpmMeasurement";
import {buildDpmChart} from "./PdfDpmChart";

const PAGE_WIDTH_MM = 210;
const PAGE_HEIGHT_MM = 297;

const MARGIN_TOP_MM = 10;
const MARGIN_LEFT_MM = 20;
const MARGIN_RIGHT_MM = 10;
const MARGIN_BOTTOM_MM = 10;
const HEADER_HEIGHT_MM = 15;
const HEADER_BOTTOM_MARGIN_MM = 5;
const QR_CODE_BLOCK_WIDTH_MM = 40;
const QR_CODE_IMAGE_SIZE_MM = 15;
const QR_CODE_IMAGE_INTERNAL_MARGINS_MM = 1.5;
const QR_CODE_IMAGE_RIGHT_MARGIN_MM = 5;

const RECORD_INFO_MARGIN_TOP_MM = 5;

const RECORD_DATE_TIME_BLOCK_WIDTH_MM = 35;

const FOOTER_HEIGHT_MM = 10;
const FOOTER_TOP_MARGIN_MM = 5;
const DIVIDER_STROKE_WIDTH_MM = 1;

const PT_TO_MM_FACTOR = 0.35277777777778;

const QR_CODE_INSTRUCTION_TEXT_SIZE_PT = 5;
const TITLE_TEXT_SIZE_PT = 14;
const PRIMARY_TEXT_SIZE_PT = 11;
const SECONDARY_TEXT_SIZE_PT = 10;
const TABLE_TEXT_SIZE_PT = 10;

const MARKER_TEXT_SIZE_PT = 8;

const CONTENT_MARGIN_MM = 3;
const TABLE_STROKE_MM = 0.4;

const LOGO_ALIAS = "logo";
const QR_ALIAS = "qr";
const PHOTO_ALIAS = "photo";

export const FONT_FAMILY = "ROBOTO";

enum Align {
    START,
    CENTER,
    END
}

interface DrawTextTask {
    text: string;
    width: number;
}

export type Drawer = (document: jsPDF) => void;

class InfoDataBuilder {
    pages: Array<Array<Drawer>>;
    t: TFunction<"translation">;
    document: jsPDF;
    left: number;
    top: number;
    width: number;
    height: number;
    location?: GeoLocation;
    locationBitmap?: ImageData;
    notes?: string;
    audioRecords?: Array<MediaRecord>;
    videoRecords?: Array<MediaRecord>;
    link: string;
    inspectorName: string | undefined;
    inspectorSignature: ImageData | undefined;
    currentPage?: Array<Drawer>;
    y: number;

    constructor(t: TFunction<"translation">, left: number, top: number, width: number, height: number, location: GeoLocation | undefined, locationBitmap: ImageData | undefined, notes: string | undefined, audioRecords: Array<MediaRecord> | undefined, videoRecords: Array<MediaRecord> | undefined, link: string, inspectorName: string | undefined, inspectorSignature: ImageData | undefined) {
        this.t = t;
        this.document = new jsPDF("p", "mm", "a4");
        this.document.addFileToVFS("Roboto.ttf", roboto);
        this.document.addFont('Roboto.ttf', FONT_FAMILY, 'normal');
        this.document.addFileToVFS("RobotoBold.ttf", robotoBold);
        this.document.addFont('RobotoBold.ttf', FONT_FAMILY, 'bold');
        this.left = left;
        this.top = top;
        this.width = width;
        this.height = height;
        this.location = location;
        this.locationBitmap = locationBitmap;
        this.notes = notes;
        this.audioRecords = audioRecords;
        this.videoRecords = videoRecords;
        this.link = link;
        this.inspectorName = inspectorName;
        this.inspectorSignature = inspectorSignature;
        this.pages = new Array<Array<Drawer>>();
        this.y = 0;
    }

    setupPrimaryBoldText(doc: jsPDF) {
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(PRIMARY_TEXT_SIZE_PT);
        doc.setFont(FONT_FAMILY, "bold");
    }

    setupPrimaryNormalText(doc: jsPDF) {
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(PRIMARY_TEXT_SIZE_PT);
        doc.setFont(FONT_FAMILY, "normal");
    }

    setupSecondaryNormalText(doc: jsPDF) {
        doc.setTextColor(136, 136, 136);
        doc.setFontSize(SECONDARY_TEXT_SIZE_PT);
        doc.setFont(FONT_FAMILY, "normal");
    }

    build(): Array<Array<Drawer>> {
        this.closePage();
        this.setupPrimaryBoldText(this.document);
        this.y = getBaselineOffset(this.document, true);
        if (this.location) {
            const localY1 = this.top + this.y;
            let geoLocationString = this.t("geolocation");
            this.currentPage!.push(document => {
                this.setupPrimaryBoldText(document);
                drawText(document, geoLocationString, this.left, localY1, this.width, Align.CENTER, Align.END);
            });
            this.y = drawText(this.document, geoLocationString, this.left, this.y, this.width, Align.CENTER, Align.END);
            this.y += getBaselineOffset(this.document, true);
            const coordinates = this.t("geolocation_format", {
                f1: this.location.latitude.toFixed(5),
                f2: this.location.longitude.toFixed(5)
            });
            const localY2 = this.top + this.y;
            this.currentPage!.push(document => {
                this.setupPrimaryNormalText(document);
                drawText(document, coordinates, this.left, localY2, this.width, Align.START, Align.END);
            });
            this.setupPrimaryNormalText(this.document);
            this.y = drawText(this.document, coordinates, this.left, this.y, this.width, Align.START, Align.END);
            this.y += getBaselineOffset(this.document, true);
            if (this.locationBitmap) {
                const locationBitmapSize = Math.min(this.width, this.height - this.y);
                const locationBitmapBounds = new Rect(this.left, this.top + this.y, this.left + this.width, this.top + this.y + locationBitmapSize);
                this.currentPage!.push(document => drawBitmap(document, this.locationBitmap!, locationBitmapBounds, Align.CENTER, Align.CENTER));
                this.y += locationBitmapSize + getBaselineOffset(this.document, true);
            }
            this.closePage();
        }
        if (this.notes) {
            this.setupPrimaryBoldText(this.document)
            this.y += getBaselineOffset(this.document, true);
            this.drawText(this.setupPrimaryBoldText, this.t("notes"), Align.CENTER, 2);
            this.drawText(this.setupPrimaryNormalText, this.notes, Align.START, 1);
        }
        this.setupPrimaryBoldText(this.document);
        this.y += getBaselineOffset(this.document, true);
        let additionalFilesMinLines = 3;
        if ((this.audioRecords && this.audioRecords.length > 0) || (this.videoRecords && this.videoRecords.length > 0)) {
            if ((this.audioRecords && this.audioRecords.length > 0) && (this.videoRecords && this.videoRecords.length > 0)) {
                additionalFilesMinLines = 4;
            } else {
                const numberOfFiles = Math.max(this.audioRecords?.length ?? 0, this.videoRecords?.length ?? 0);
                additionalFilesMinLines = numberOfFiles > 1 ? 4 : 7;
            }
        }
        this.drawText(this.setupPrimaryBoldText, this.t("additional_files"), Align.CENTER, additionalFilesMinLines);
        if ((!this.audioRecords || this.audioRecords.length === 0) && (!this.videoRecords || this.videoRecords.length === 0)) {
            this.y += getBaselineOffset(this.document, true);
            this.drawText(this.setupPrimaryNormalText, this.t("no_audio_and_video_records"), Align.CENTER, 1);
        } else {
            if (this.audioRecords && this.audioRecords.length > 0) {
                this.y += getBaselineOffset(this.document, true);
                const minLines = ((!this.videoRecords || this.videoRecords.length === 0) && this.audioRecords.length === 1) ? 5 : 2;
                this.drawText(this.setupPrimaryBoldText, this.t("audio_records"), Align.CENTER, minLines);
                for (const record of this.audioRecords) {
                    this.drawText(this.setupPrimaryNormalText, record.fileName, Align.START, 1);
                }
            }
            if (this.videoRecords && this.videoRecords.length > 0) {
                this.y += getBaselineOffset(this.document, true);
                const minLines = this.videoRecords.length === 1 ? 5 : 2;
                this.drawText(this.setupPrimaryBoldText, this.t("video_records"), Align.CENTER, minLines);
                for (const record of this.videoRecords) {
                    this.drawText(this.setupPrimaryNormalText, record.fileName, Align.START, 1);
                }
            }
            this.y += getBaselineOffset(this.document, true);
            this.drawText(this.setupSecondaryNormalText, this.t("audio_and_video_link_format", {link: this.link}), Align.CENTER, 2);
            this.drawText(this.setupSecondaryNormalText, this.t("audio_and_video_qr_instruction"), Align.CENTER, 1);
        }
        this.drawSignature();
        this.closePage();
        return this.pages;
    }

    drawText(initializer: (doc: jsPDF) => void, text: string, horizontalAlignment: Align, minLines: number) {
        initializer(this.document);
        const strings = breakText(this.document, text, this.width);
        const baselineOffset = getBaselineOffset(this.document, true);
        if (this.y + (minLines - 1) * baselineOffset > this.height) {
            this.closePage();
            this.y += baselineOffset;
        }
        for (const s of strings) {
            if (this.y >= this.height) {
                this.closePage();
                this.y += baselineOffset;
            }
            const localY = this.top + this.y;
            this.currentPage!.push(document => {
                initializer(document);
                drawText(document, s, this.left, localY, this.width, horizontalAlignment, Align.END);
            });
            this.y += baselineOffset;
        }
    }

    drawSignature() {
        const forwardLines = 5;
        const additionalLines = forwardLines + 1;
        const promptMaxWidth = 0.25 * this.width;
        const nameMaxWidth = 0.35 * this.width;
        const promptText = this.t("inspector");
        const prompt = breakText(this.document, promptText, promptMaxWidth);
        const name = breakText(this.document, this.inspectorName ?? "", nameMaxWidth);
        const lines = Math.max(prompt.length, name.length);
        const baselineOffset = getBaselineOffset(this.document, true);
        const requiredHeight = baselineOffset * (lines + additionalLines);
        if (this.y + requiredHeight > this.height) {
            this.closePage();
        }
        const promptY = this.top + this.y + baselineOffset * (lines + additionalLines - prompt.length);
        this.currentPage!.push(document => {
            this.setupPrimaryNormalText(document);
            drawText(document, promptText, this.left, promptY, promptMaxWidth, Align.CENTER, Align.END);
        });
        const nameY = this.top + this.y + baselineOffset * (lines + additionalLines - name.length);
        this.currentPage!.push(document => {
            this.setupPrimaryNormalText(document);
            drawText(document, this.inspectorName ?? "", this.left + (this.width - nameMaxWidth), nameY, nameMaxWidth, Align.CENTER, Align.END);
        });
        const lineY = this.top + this.y + baselineOffset * (lines + forwardLines);
        const signatureLineMargin = 5;
        const signatureLineStartX = this.left + promptMaxWidth + signatureLineMargin;
        const signatureLineEndX = this.left + promptMaxWidth + this.width - promptMaxWidth - nameMaxWidth - signatureLineMargin;
        const signatureLineWidth = signatureLineEndX - signatureLineStartX;
        this.currentPage!.push(document => {
            document.setLineWidth(0.5);
            document.setDrawColor(136, 136, 136);
            document.line(signatureLineStartX, lineY, signatureLineEndX, lineY);
        });
        const signatureText = this.t("signature");
        this.setupSecondaryNormalText(this.document);
        const signatureY = lineY + getBaselineOffset(this.document, true);
        this.currentPage!.push(document => {
            this.setupSecondaryNormalText(document);
            drawText(document, signatureText, this.left + promptMaxWidth, signatureY, this.width - promptMaxWidth - nameMaxWidth, Align.CENTER, Align.END);
        });
        if (this.inspectorSignature) {
            const signatureWidth = signatureLineWidth * 0.5;
            const signatureHeight = signatureWidth / 2;
            const signatureBounds = new Rect(signatureLineStartX + (signatureLineWidth - signatureWidth) / 2, lineY + 0.2 * signatureHeight - signatureHeight, signatureLineEndX - (signatureLineWidth - signatureWidth) / 2, lineY + 0.2 * signatureHeight);
            this.currentPage!.push(document => {
                drawBitmap(document, this.inspectorSignature!, signatureBounds, Align.CENTER, Align.END);
            });
        }
    }

    closePage() {
        if (this.currentPage) {
            this.pages.push(this.currentPage);
        }
        this.currentPage = new Array<Drawer>();
        this.y = 0;
    }

}

enum MarkerOrientation {
    W,
    NW,
    N,
    NE,
    E,
    SE,
    S,
    SW
}

class MarkerView {

    private readonly borderRadius = 2;
    private readonly padding = 1;
    private readonly arrowSize = 2;
    private readonly strokeWidth = 0.5;

    private readonly lines: string[];
    private readonly width: number;
    private readonly height: number;

    constructor(canvas: jsPDF, text: string) {
        this.lines = text.split("\n");
        canvas.setFont(FONT_FAMILY, "normal");
        canvas.setFontSize(MARKER_TEXT_SIZE_PT);
        let maxWidth = 0;
        for (const line of this.lines) {
            const textWidth = canvas.getTextWidth(line);
            maxWidth = Math.max(maxWidth, textWidth);
        }
        const textHeight = canvas.getFontSize() * PT_TO_MM_FACTOR;
        this.width = maxWidth + 2 * this.padding;
        this.height = textHeight * this.lines.length + 0.5 * textHeight * (this.lines.length - 1) + 2 * this.padding;
    }

    draw(canvas: jsPDF, x: number, y: number, orientation: MarkerOrientation) {
        const markerX = x + this.getLeftOffset(orientation);
        const markerY = y + this.getTopOffset(orientation);
        this.drawMarker(canvas, markerX, markerY, orientation);
    }

    private drawMarker(canvas: jsPDF, x: number, y: number, orientation: MarkerOrientation) {
        canvas.setLineWidth(this.strokeWidth);
        canvas.setDrawColor(39, 118, 157);
        canvas.roundedRect(x, y, this.width, this.height, this.borderRadius, this.borderRadius, "S");
        this.drawArrow(canvas, x, y, orientation);
        canvas.setFillColor(33, 33, 33);
        canvas.roundedRect(x, y, this.width, this.height, this.borderRadius, this.borderRadius, "F");
        canvas.setFont(FONT_FAMILY, "normal");
        canvas.setFontSize(MARKER_TEXT_SIZE_PT);
        canvas.setTextColor(255, 255, 255);
        const textHeight = canvas.getFontSize() * PT_TO_MM_FACTOR;
        let textY = y + this.padding + textHeight;
        for (const line of this.lines) {
            canvas.text(line, x + this.padding, textY, {
                align: "left",
                baseline: "bottom"
            });
            textY += 1.5 * textHeight;
        }
    }

    getWidthWithOrientation(orientation: MarkerOrientation) {
        switch (orientation) {
            case MarkerOrientation.W:
            case MarkerOrientation.NW:
            case MarkerOrientation.SW:
            case MarkerOrientation.E:
            case MarkerOrientation.NE:
            case MarkerOrientation.SE:
                return this.width + this.arrowSize;
        }
        return this.width;
    }

    getHeightWithOrientation(orientation: MarkerOrientation) {
        switch (orientation) {
            case MarkerOrientation.N:
            case MarkerOrientation.NW:
            case MarkerOrientation.NE:
            case MarkerOrientation.S:
            case MarkerOrientation.SW:
            case MarkerOrientation.SE:
                return this.height + this.arrowSize;
        }
        return this.height;
    }

    getLeftOffset(orientation: MarkerOrientation) {
        switch (orientation) {
            case MarkerOrientation.W:
            case MarkerOrientation.NW:
            case MarkerOrientation.SW:
                return this.arrowSize;
            case MarkerOrientation.N:
            case MarkerOrientation.S:
                return -this.getWidthWithOrientation(orientation) / 2;
            case MarkerOrientation.E:
            case MarkerOrientation.NE:
            case MarkerOrientation.SE:
                return -this.getWidthWithOrientation(orientation);
            default:
                return 0;
        }
    }

    getRightOffset(orientation: MarkerOrientation) {
        switch (orientation) {
            case MarkerOrientation.W:
            case MarkerOrientation.NW:
            case MarkerOrientation.SW:
                return this.getWidthWithOrientation(orientation);
            case MarkerOrientation.N:
            case MarkerOrientation.S:
                return this.getWidthWithOrientation(orientation) / 2;
            case MarkerOrientation.E:
            case MarkerOrientation.NE:
            case MarkerOrientation.SE:
                return -this.arrowSize;
            default:
                return 0;
        }
    }

    getTopOffset(orientation: MarkerOrientation) {
        switch (orientation) {
            case MarkerOrientation.W:
            case MarkerOrientation.E:
                return -this.getHeightWithOrientation(orientation) / 2;
            case MarkerOrientation.N:
            case MarkerOrientation.NW:
            case MarkerOrientation.NE:
                return this.arrowSize;
            case MarkerOrientation.S:
            case MarkerOrientation.SW:
            case MarkerOrientation.SE:
                return -this.getHeightWithOrientation(orientation);
            default:
                return 0;
        }
    }

    private drawArrow(canvas: jsPDF, x: number, y: number, orientation: MarkerOrientation) {
        let x1: number;
        let x2: number;
        let x3: number;
        let y1: number;
        let y2: number;
        let y3: number;
        switch (orientation) {
            case MarkerOrientation.W:
                x1 = 0;
                y1 = this.height / 2 - this.arrowSize / 2;
                x2 = -this.arrowSize;
                y2 = this.height / 2;
                x3 = 0;
                y3 = this.height / 2 + this.arrowSize / 2;
                break;
            case MarkerOrientation.NW:
                x1 = 0;
                y1 = Math.max(this.arrowSize / 2, this.borderRadius);
                x2 = -this.arrowSize;
                y2 = -this.arrowSize;
                x3 = Math.max(this.arrowSize / 2, this.borderRadius);
                y3 = 0;
                break;
            case MarkerOrientation.N:
                x1 = this.width / 2 - this.arrowSize / 2;
                y1 = 0;
                x2 = this.width / 2;
                y2 = -this.arrowSize;
                x3 = this.width / 2 + this.arrowSize / 2;
                y3 = 0;
                break;
            case MarkerOrientation.NE:
                x1 = this.width;
                y1 = Math.max(this.arrowSize / 2, this.borderRadius);
                x2 = this.width + this.arrowSize;
                y2 = -this.arrowSize;
                x3 = this.width - Math.max(this.arrowSize / 2, this.borderRadius);
                y3 = 0;
                break;
            case MarkerOrientation.E:
                x1 = this.width;
                y1 = this.height / 2 - this.arrowSize / 2;
                x2 = this.width + this.arrowSize;
                y2 = this.height / 2;
                x3 = this.width;
                y3 = this.height / 2 + this.arrowSize / 2;
                break;
            case MarkerOrientation.SE:
                x1 = this.width;
                y1 = this.height - Math.max(this.arrowSize / 2, this.borderRadius);
                x2 = this.width + this.arrowSize;
                y2 = this.height + this.arrowSize;
                x3 = this.width - Math.max(this.arrowSize / 2, this.borderRadius);
                y3 = this.height;
                break;
            case MarkerOrientation.S:
                x1 = this.width / 2 - this.arrowSize / 2;
                y1 = this.height;
                x2 = this.width / 2;
                y2 = this.height + this.arrowSize;
                x3 = this.width / 2 + this.arrowSize / 2;
                y3 = this.height;
                break;
            case MarkerOrientation.SW:
                x1 = 0;
                y1 = this.height - Math.max(this.arrowSize / 2, this.borderRadius);
                x2 = -this.arrowSize;
                y2 = this.height + this.arrowSize;
                x3 = Math.max(this.arrowSize / 2, this.borderRadius);
                y3 = this.height;
                break;
        }
        canvas.setLineWidth(this.strokeWidth);
        canvas.setDrawColor(39, 118, 157);
        canvas.line(x + x1, y + y1, x + x2, y + y2);
        canvas.line(x + x2, y + y2, x + x3, y + y3);
        canvas.setFillColor(33, 33, 33);
        canvas.triangle(x + x1, y + y1, x + x2, y + y2, x + x3, y + y3, "F");
    }
}

function drawBitmap(document: jsPDF, bitmap: ImageData, bounds: Rect, horizontalAlignment: Align, verticalAlignment: Align, alias?: string) {
    if (bitmap.width > 0 && bitmap.height > 0) {
        const aspectRatio = bitmap.width / bitmap.height;
        let targetWidth = bounds.width;
        let targetHeight = targetWidth / aspectRatio;
        if (targetHeight > bounds.height) {
            targetHeight = bounds.height;
            targetWidth = targetHeight * aspectRatio;
        }
        let xOffset = 0;
        switch (horizontalAlignment) {
            case Align.CENTER:
                xOffset = (bounds.width - targetWidth) / 2;
                break;
            case Align.END:
                xOffset = bounds.width - targetWidth;
                break;
        }
        let yOffset = 0;
        switch (verticalAlignment) {
            case Align.CENTER:
                yOffset = (bounds.height - targetHeight) / 2;
                break;
            case Align.END:
                yOffset = bounds.height - targetHeight;
                break;
        }
        document.addImage(bitmap.data, "JPEG", bounds.left + xOffset, bounds.top + yOffset, targetWidth, targetHeight, alias);
    }
}

function drawText(document: jsPDF, text: string, x: number, yBaseline: number, maxWidth: number, horizontalAlignment: Align, verticalAlignment: Align) {
    const tasks = prepareDrawTextTasks(document, text, maxWidth);
    const isDirectionToBottom = verticalAlignment !== Align.START;
    return drawTextTasks(document, tasks, x, yBaseline, maxWidth, horizontalAlignment, isDirectionToBottom);
}

function getBaselineOffset(document: jsPDF, isDirectionToBottom: boolean) {
    return (isDirectionToBottom ? 1 : -1) * 1.35 * document.getFontSize() * PT_TO_MM_FACTOR;
}

function drawTextWithBounds(document: jsPDF, text: string, bounds: Rect, horizontalAlignment: Align, verticalAlignment: Align) {
    const tasks = prepareDrawTextTasks(document, text, bounds.width);
    let lineHeight = document.getFontSize() * PT_TO_MM_FACTOR;
    const height = (tasks.length - 1) * getBaselineOffset(document, true) + lineHeight;
    let yOffset = lineHeight;
    switch (verticalAlignment) {
        case Align.CENTER:
            yOffset += (bounds.height - height) / 2;
            break;
        case Align.END:
            yOffset += bounds.height - height;
            break;
    }
    return drawTextTasks(document, tasks, bounds.left, bounds.top + yOffset, bounds.width, horizontalAlignment, true);
}

function breakText(document: jsPDF, text: string, width: number): Array<string> {
    const tasks = prepareDrawTextTasks(document, text, width);
    return tasks.map(t => t.text);
}

function drawTextTasks(document: jsPDF, tasks: Array<DrawTextTask>, x: number, yBaseline: number, maxWidth: number, horizontalAlignment: Align, isDirectionToBottom: boolean) {
    let y = yBaseline;
    let isFirstLine = true;
    for (const task of tasks) {
        if (isFirstLine) {
            isFirstLine = false;
        } else {
            y += getBaselineOffset(document, isDirectionToBottom);
        }
        let offset = 0;
        switch (horizontalAlignment) {
            case Align.CENTER:
                offset = (maxWidth - task.width) / 2;
                break;
            case Align.END:
                offset = maxWidth - task.width;
                break;
        }
        document.text(task.text, x + offset, y);
    }
    return y;
}

function prepareDrawTextTasks(document: jsPDF, text: string, maxWidth: number) {
    const tasks = new Array<DrawTextTask>();
    const strings = text.split("\n");
    for (const s of strings) {
        let string: string | null = s;
        do {
            string = string.trim();
            let pos: number = string.length;
            let width = document.getTextWidth(string.substring(0, pos));
            while (width > maxWidth) {
                const newPos = string.lastIndexOf(' ', pos - 1);
                pos = newPos > 0 ? newPos : pos - 1;
                if (pos === 1) {
                    break;
                }
                width = document.getTextWidth(string.substring(0, pos));
            }
            tasks.push({
                text: string.substring(0, pos),
                width: width
            });
            string = pos < string.length ? string.substring(pos) : null;
        } while (string != null);
    }
    return tasks;
}

export function drawHeader(t: TFunction<"translation">, document: jsPDF, logo: ImageData, qrCode: ImageData, recordName: string, inspectorName: string, inspectorOrganization: string, deviceInfo: string, probeInfo: string, recordDateTime: string): Rect {
    document.setTextColor(0, 0, 0);
    document.setDrawColor(0, 0, 0);
    document.setLineWidth(DIVIDER_STROKE_WIDTH_MM);
    const pageLeft = MARGIN_LEFT_MM;
    const pageTop = MARGIN_TOP_MM;
    const pageRight = PAGE_WIDTH_MM - MARGIN_RIGHT_MM;
    const pageBottom = PAGE_HEIGHT_MM - MARGIN_BOTTOM_MM;
    const logoBounds = new Rect(pageLeft, pageTop, pageRight - QR_CODE_BLOCK_WIDTH_MM, pageTop + HEADER_HEIGHT_MM);
    drawBitmap(document, logo, logoBounds, Align.START, Align.CENTER, LOGO_ALIAS);
    const qrCodeMargins = QR_CODE_IMAGE_INTERNAL_MARGINS_MM;
    const qrCodeBounds = new Rect(pageRight - QR_CODE_BLOCK_WIDTH_MM - qrCodeMargins, pageTop - qrCodeMargins, pageRight - (QR_CODE_BLOCK_WIDTH_MM - QR_CODE_IMAGE_SIZE_MM) + qrCodeMargins, pageTop + QR_CODE_IMAGE_SIZE_MM + qrCodeMargins);
    drawBitmap(document, qrCode, qrCodeBounds, Align.CENTER, Align.CENTER, QR_ALIAS);
    const qrCodeInstructionBounds = new Rect(pageRight - (QR_CODE_BLOCK_WIDTH_MM - QR_CODE_IMAGE_SIZE_MM - QR_CODE_IMAGE_RIGHT_MARGIN_MM), pageTop, pageRight, pageTop + QR_CODE_IMAGE_SIZE_MM);
    document.setFont(FONT_FAMILY, "normal");
    document.setFontSize(QR_CODE_INSTRUCTION_TEXT_SIZE_PT);
    drawTextWithBounds(document, t("scan_qr_code_instruction"), qrCodeInstructionBounds, Align.CENTER, Align.CENTER);
    const topDividerY = MARGIN_TOP_MM + HEADER_HEIGHT_MM + HEADER_BOTTOM_MARGIN_MM;
    document.line(pageLeft, topDividerY, pageRight, topDividerY);
    document.setFontSize(TITLE_TEXT_SIZE_PT);
    document.setFont(FONT_FAMILY, "bold");
    let textY = topDividerY + RECORD_INFO_MARGIN_TOP_MM + getBaselineOffset(document, true);
    textY = drawText(document, t("measurement_protocol"), pageLeft, textY, (pageRight - pageLeft), Align.CENTER, Align.END);
    textY += getBaselineOffset(document, true);
    const recordInfoWidth = pageRight - RECORD_DATE_TIME_BLOCK_WIDTH_MM - pageLeft;
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    document.setFont(FONT_FAMILY, "bold");
    textY = drawText(document, recordName, pageLeft, textY, recordInfoWidth, Align.START, Align.END);
    textY += getBaselineOffset(document, true);
    document.setFont(FONT_FAMILY, "normal");
    const inspectorInfo = t("inspector_name_format", {name: inspectorName ?? ""});
    textY = drawText(document, inspectorInfo, pageLeft, textY, recordInfoWidth, Align.START, Align.END);
    textY += getBaselineOffset(document, true);
    const companyInfo = t("organization_name_format", {company: inspectorOrganization ?? ""});
    textY = drawText(document, companyInfo, pageLeft, textY, recordInfoWidth, Align.START, Align.END);
    textY += getBaselineOffset(document, true);
    textY = drawText(document, deviceInfo, pageLeft, textY, recordInfoWidth, Align.START, Align.END);
    if (probeInfo !== "") {
        textY += getBaselineOffset(document, true);
        textY = drawText(document, probeInfo, pageLeft, textY, recordInfoWidth, Align.START, Align.END);
    }
    const recordDateTimeLeft = pageRight - RECORD_DATE_TIME_BLOCK_WIDTH_MM;
    drawText(document, recordDateTime, recordDateTimeLeft, textY, RECORD_DATE_TIME_BLOCK_WIDTH_MM, Align.CENTER, Align.START);
    const contentTop = textY + getBaselineOffset(document, true);
    const bottomDividerY = pageBottom - FOOTER_HEIGHT_MM;
    document.line(pageLeft, bottomDividerY, pageRight, bottomDividerY);
    return new Rect(pageLeft, contentTop, pageRight, bottomDividerY - FOOTER_TOP_MARGIN_MM);
}

export function drawFooter(t: TFunction<"translation">, document: jsPDF, printDateTime: string, pageInfo: string) {
    document.setTextColor(136, 136, 136);
    document.setFontSize(SECONDARY_TEXT_SIZE_PT);
    document.setFont(FONT_FAMILY, "normal");
    const pageBottom = PAGE_HEIGHT_MM - MARGIN_BOTTOM_MM;
    const pageLeft = MARGIN_LEFT_MM;
    const pageRight = PAGE_WIDTH_MM - MARGIN_RIGHT_MM;
    const pageMid = pageLeft + (pageRight - pageLeft) / 2;
    const footerTop = pageBottom - FOOTER_HEIGHT_MM;
    const printDateTimeBounds = new Rect(pageLeft, footerTop, pageMid, pageBottom);
    const pageInfoBounds = new Rect(pageMid, footerTop, pageRight, pageBottom);
    drawTextWithBounds(document, printDateTime, printDateTimeBounds, Align.START, Align.END);
    drawTextWithBounds(document, pageInfo, pageInfoBounds, Align.END, Align.END);
}

export function prepareInfoPages(t: TFunction<"translation">, document: jsPDF, contentBounds: Rect, location: GeoLocation | undefined, locationBitmap: ImageData | undefined, notes: string | undefined, audioRecords: Array<MediaRecord> | undefined, videoRecords: Array<MediaRecord> | undefined, link: string, inspectorName: string | undefined, inspectorSignature: ImageData | undefined): Array<Array<Drawer>> {
    const builder = new InfoDataBuilder(t, contentBounds.left, contentBounds.top, contentBounds.width, contentBounds.height, location, locationBitmap, notes, audioRecords, videoRecords, link, inspectorName, inspectorSignature);
    return builder.build();
}

export function drawPhotoWithMeasurements(t: TFunction<"translation">, document: jsPDF, bounds: Rect, deviceType: string, measurementType: number | null, measurements: Array<TudMeasurement> | Array<LeebMeasurement> | Array<UciMeasurement> | Array<TpMeasurement> | Array<TpSurfaceTemperatureMeasurement> | Array<TpDewPointMeasurement> | Array<UtMeasurement> | Array<MfMeasurement> | Array<DpmMeasurement>, showAverage: boolean | null, bitmap: ImageData) {
    const title = t("measurements");
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    let y = drawText(document, title, bounds.left, bounds.top + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const contentBounds = new Rect(bounds.left, y, bounds.right, bounds.bottom);
    drawMeasurementsPhoto(t, document, contentBounds, deviceType, measurementType, measurements, showAverage, bitmap);
}

export function drawMeasurementWithPhoto(t: TFunction<"translation">, document: jsPDF, bounds: Rect, deviceType: string, measurementType: number | null, measurements: Array<TudMeasurement> | Array<LeebMeasurement> | Array<UciMeasurement> | Array<TpMeasurement> | Array<TpSurfaceTemperatureMeasurement> | Array<TpDewPointMeasurement> | Array<UtMeasurement> | Array<MfMeasurement> | Array<DpmMeasurement>, i: number, measurement: TudMeasurement | LeebMeasurement | UciMeasurement | TpMeasurement | TpSurfaceTemperatureMeasurement | TpDewPointMeasurement | UtMeasurement | MfMeasurement | DpmMeasurement, showAverage: boolean | null, bitmap: ImageData) {
    const title = measurements.length > 1 ? t("measurement_series_format", {index: i + 1}) : t("measurement_series");
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    let y = drawText(document, title, bounds.left, bounds.top + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const contentBounds = new Rect(bounds.left, y, bounds.right, bounds.bottom);
    const topHorizontalGuideline = contentBounds.top + 0.45 * contentBounds.height;
    const bottomHorizontalGuideline = contentBounds.top + 0.65 * contentBounds.height;
    const verticalGuideline = contentBounds.left + contentBounds.width / 2;
    const photoBounds = new Rect(contentBounds.left, contentBounds.top, contentBounds.right, topHorizontalGuideline);
    let photoMeasurement: Array<TpMeasurement> | Array<TudMeasurement> | Array<LeebMeasurement> | Array<UciMeasurement> | Array<UtMeasurement> | Array<MfMeasurement> | Array<DpmMeasurement> | undefined = undefined;
    switch (deviceType) {
        case TP1M:
            photoMeasurement = [measurement as TpMeasurement];
            break;
        case TUD2:
        case TUD3:
            photoMeasurement = [measurement as TudMeasurement];
            break;
        case LEEB:
            photoMeasurement = [measurement as LeebMeasurement];
            break;
        case UCI:
            photoMeasurement = [measurement as UciMeasurement];
            break;
        case UT1M:
        case UT1M_IP:
        case UT1M_CT:
            photoMeasurement = [measurement as UtMeasurement];
            break;
        case MF1M:
            photoMeasurement = [measurement as MfMeasurement];
            break;
        case DPM:
            photoMeasurement = [measurement as DpmMeasurement];
            break;
    }
    if (photoMeasurement) {
        drawMeasurementsPhoto(t, document, photoBounds, deviceType, measurementType, photoMeasurement, showAverage, bitmap);
    }
    const tableBounds = new Rect(contentBounds.left, topHorizontalGuideline, contentBounds.right, bottomHorizontalGuideline);
    if (deviceType === TUD2 || deviceType === TUD3 || deviceType === LEEB || deviceType === UCI || deviceType === TP1M || deviceType === UT1M || deviceType === UT1M_IP || deviceType === UT1M_CT) {
        drawMeasurementTable(t, document, tableBounds, deviceType, measurementType, measurement, showAverage);
    }
    const leftChartBounds = new Rect(contentBounds.left, bottomHorizontalGuideline, verticalGuideline, contentBounds.bottom);
    if (deviceType === TUD2 || deviceType === TUD3 || deviceType === LEEB || deviceType === UCI || deviceType === TP1M || deviceType === UT1M || deviceType === UT1M_IP || deviceType === UT1M_CT) {
        drawLineChart(t, document, leftChartBounds, deviceType, measurementType, measurement, showAverage);
    }
    const rightChartBounds = new Rect(verticalGuideline, bottomHorizontalGuideline, contentBounds.right, contentBounds.bottom);
    if (deviceType === TP1M || deviceType === TUD2 || deviceType === TUD3 || deviceType === LEEB || deviceType === UCI) {
        drawBarChart(t, document, rightChartBounds, deviceType, measurementType, measurement);
    }
    if (deviceType === UT1M || deviceType === UT1M_IP || deviceType === UT1M_CT) {
        drawAreaChart(t, document, rightChartBounds, deviceType, measurementType, measurement)
    }
    if (deviceType === MF1M) {
        const mfMeasurement = measurement as MfMeasurement;
        if (mfMeasurement.measurements.length > 1) {
            const mfTableBounds = new Rect(contentBounds.left, topHorizontalGuideline, contentBounds.right, bottomHorizontalGuideline);
            drawMeasurementTable(t, document, mfTableBounds, deviceType, measurementType, measurement, null);
            const mfAreaChartBounds = new Rect(contentBounds.left, bottomHorizontalGuideline, contentBounds.right, contentBounds.bottom);
            drawAreaChart(t, document, mfAreaChartBounds, deviceType, measurementType, measurement)
        }
    }
    if (deviceType === DPM){
        const dpmMeasurement = measurement as DpmMeasurement;
        const dpmTopHorizontalGuideline = contentBounds.top + 0.45 * contentBounds.height;
        const dpmBottomHorizontalGuideline = contentBounds.top + 0.95 * contentBounds.height;
        const dpmChartBounds = new Rect(contentBounds.left, dpmTopHorizontalGuideline, contentBounds.right, dpmBottomHorizontalGuideline);
        drawDpmChart(t, document, dpmChartBounds, dpmMeasurement);
    }
}

export function drawMeasurementsWithoutPhoto(t: TFunction<"translation">, document: jsPDF, bounds: Rect, deviceType: string, measurementType: number | null,  measurements: Array<TudMeasurement> | Array<LeebMeasurement> | Array<UciMeasurement> | Array<TpMeasurement> | Array<TpSurfaceTemperatureMeasurement> | Array<TpDewPointMeasurement> | Array<UtMeasurement> | Array<MfMeasurement> | Array<DpmMeasurement>, i: number, measurement: TudMeasurement | LeebMeasurement | UciMeasurement | TpMeasurement | TpSurfaceTemperatureMeasurement | TpDewPointMeasurement | UtMeasurement | MfMeasurement | DpmMeasurement, showAverage: boolean | null) {
    let title = measurements.length > 1 ? t("measurement_series_without_photo_format", {index: i + 1}) : t("measurement_series_without_photo");
    if (deviceType === TP1M && (measurementType === SURFACE_TEMPERATURE_MEASUREMENT_TYPE || measurementType === DEW_POINT_MEASUREMENT_TYPE)){
        title = t("measurements");
    }
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(PRIMARY_TEXT_SIZE_PT);
    let y = drawText(document, title, bounds.left, bounds.top + getBaselineOffset(document, true), bounds.width, Align.CENTER, Align.END);
    y += getBaselineOffset(document, true);
    const contentBounds = new Rect(bounds.left, y, bounds.right, bounds.bottom);
    const topHorizontalGuideline = contentBounds.top + 0.2 * contentBounds.height;
    const bottomHorizontalGuideline = contentBounds.top + 0.55 * contentBounds.height;
    const verticalGuideline = contentBounds.left + contentBounds.width / 2;
    const tableBounds = new Rect(contentBounds.left, contentBounds.top, contentBounds.right, topHorizontalGuideline);
    if (deviceType === TP1M && measurementType === SURFACE_TEMPERATURE_MEASUREMENT_TYPE){
        drawTpSurfaceTemperatureMeasurementTable(t, document, tableBounds, measurement as TpSurfaceTemperatureMeasurement);
    }
    if (deviceType === TP1M && measurementType === DEW_POINT_MEASUREMENT_TYPE){
        drawTpDewPointMeasurementTable(t, document, tableBounds, measurement as TpDewPointMeasurement);
    }
    if (deviceType === TUD2 || deviceType === TUD3 || deviceType === LEEB || deviceType === UCI || (deviceType === TP1M && measurementType === COATING_THICKNESS_MEASUREMENT_TYPE) || deviceType === UT1M || deviceType === UT1M_IP || deviceType === UT1M_CT) {
        drawMeasurementTable(t, document, tableBounds, deviceType, measurementType, measurement, showAverage);
    }
    const leftChartBounds = new Rect(contentBounds.left, topHorizontalGuideline, verticalGuideline, bottomHorizontalGuideline);
    if (deviceType === TUD2 || deviceType === TUD3 || deviceType === LEEB || deviceType === UCI || (deviceType === TP1M && measurementType === COATING_THICKNESS_MEASUREMENT_TYPE) || deviceType === UT1M || deviceType === UT1M_IP || deviceType === UT1M_CT) {
        drawLineChart(t, document, leftChartBounds, deviceType, measurementType, measurement, showAverage);
    }
    const rightChartBounds = new Rect(verticalGuideline, topHorizontalGuideline, contentBounds.right, bottomHorizontalGuideline);
    if ((deviceType === TP1M && measurementType === COATING_THICKNESS_MEASUREMENT_TYPE) || deviceType === TUD2 || deviceType === TUD3 || deviceType === LEEB || deviceType === UCI) {
        drawBarChart(t, document, rightChartBounds, deviceType, measurementType, measurement);
    }
    if (deviceType === UT1M || deviceType === UT1M_IP || deviceType === UT1M_CT) {
        drawAreaChart(t, document, rightChartBounds, deviceType, measurementType, measurement)
    }
    if (deviceType === MF1M) {
        const mfMeasurement = measurement as MfMeasurement;
        if (mfMeasurement.measurements.length > 1) {
            const mfTableBounds = new Rect(contentBounds.left, contentBounds.top, contentBounds.right, topHorizontalGuideline);
            drawMeasurementTable(t, document, mfTableBounds, deviceType, measurementType, measurement, null);
            const mfAreaChartBounds = new Rect(contentBounds.left, topHorizontalGuideline, contentBounds.right, bottomHorizontalGuideline);
            drawAreaChart(t, document, mfAreaChartBounds, deviceType, measurementType, measurement)
        }
    }
    if (deviceType === DPM){
        const dpmMeasurement = measurement as DpmMeasurement;
        const dpmTopHorizontalGuideline = contentBounds.top + 0.35 * contentBounds.height;
        const dpmBottomHorizontalGuideline = contentBounds.top + 0.8 * contentBounds.height;
        const dpmTableBounds = new Rect(contentBounds.left, contentBounds.top, contentBounds.right, dpmTopHorizontalGuideline);
        const dpmChartBounds = new Rect(contentBounds.left, dpmTopHorizontalGuideline, contentBounds.right, dpmBottomHorizontalGuideline);
        drawDpmMeasurementTable(t, document, dpmTableBounds, dpmMeasurement);
        drawDpmChart(t, document, dpmChartBounds, dpmMeasurement);
    }
}

function drawDpmChart(t: TFunction<"translation">, document: jsPDF, chartBounds: Rect, measurement: DpmMeasurement) {
    const chart = buildDpmChart(t, chartBounds, measurement);
    drawBitmap(document, chart, chartBounds, Align.CENTER, Align.CENTER);
}

function drawDpmMeasurementTable(t: TFunction<"translation">, document: jsPDF, bounds: Rect, measurement: DpmMeasurement){
    const dpmMeasurement = measurement.measurements[measurement.measurements.length - 1];
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const mid = left + (right - left) / 2;
    document.setTextColor(0, 0, 0);
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    let y = top;
    document.setFont(FONT_FAMILY, "bold");
    y = drawTextWithBounds(document, t("measured_values"), new Rect(left, y, right, y + getBaselineOffset(document, true)), Align.START, Align.CENTER);
    y += getBaselineOffset(document, true);
    document.setFont(FONT_FAMILY, "normal");
    drawTextWithBounds(document, t("air_humidity"), new Rect(left, y, mid, y + getBaselineOffset(document, true)), Align.START, Align.CENTER);
    document.setFont(FONT_FAMILY, "bold");
    y = drawTextWithBounds(document, `${formatNumber(dpmMeasurement.humidity, 1, 1)} %`, new Rect(mid, y, right, y + getBaselineOffset(document, true)), Align.END, Align.CENTER);
    y += getBaselineOffset(document, true);
    document.setFont(FONT_FAMILY, "normal");
    drawTextWithBounds(document, t("surface_temperature"), new Rect(left, y, mid, y + getBaselineOffset(document, true)), Align.START, Align.CENTER);
    document.setFont(FONT_FAMILY, "bold");
    y = drawTextWithBounds(document, `${formatNumber(dpmMeasurement.tempSurface, 1, 1)} °C`, new Rect(mid, y, right, y + getBaselineOffset(document, true)), Align.END, Align.CENTER);
    y += getBaselineOffset(document, true);
    document.setFont(FONT_FAMILY, "normal");
    drawTextWithBounds(document, t("air_temperature"), new Rect(left, y, mid, y + getBaselineOffset(document, true)), Align.START, Align.CENTER);
    document.setFont(FONT_FAMILY, "bold");
    y = drawTextWithBounds(document, `${formatNumber(dpmMeasurement.tempAir, 1, 1)} °C`, new Rect(mid, y, right, y + getBaselineOffset(document, true)), Align.END, Align.CENTER);
    y += getBaselineOffset(document, true);
    document.setFont(FONT_FAMILY, "bold");
    y = drawTextWithBounds(document, t("calculated_values"), new Rect(left, y, right, y + getBaselineOffset(document, true)), Align.START, Align.CENTER);
    y += getBaselineOffset(document, true);
    document.setFont(FONT_FAMILY, "normal");
    drawTextWithBounds(document, t("dew_point"), new Rect(left, y, mid, y + getBaselineOffset(document, true)), Align.START, Align.CENTER);
    document.setFont(FONT_FAMILY, "bold");
    y = drawTextWithBounds(document, `${formatNumber(dpmMeasurement.devPoint, 1, 1)} °C`, new Rect(mid, y, right, y + getBaselineOffset(document, true)), Align.END, Align.CENTER);
    y += getBaselineOffset(document, true);
    document.setFont(FONT_FAMILY, "normal");
    drawTextWithBounds(document, t("difference"), new Rect(left, y, mid, y + getBaselineOffset(document, true)), Align.START, Align.CENTER);
    document.setFont(FONT_FAMILY, "bold");
    y = drawTextWithBounds(document, `${formatNumber(dpmMeasurement.devSurface, 1, 1)} °C`, new Rect(mid, y, right, y + getBaselineOffset(document, true)), Align.END, Align.CENTER);
}

function drawTpSurfaceTemperatureMeasurementTable(t: TFunction<"translation">, document: jsPDF, bounds: Rect, measurement: TpSurfaceTemperatureMeasurement){
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const mid = left + (right - left) / 2;
    document.setTextColor(0, 0, 0);
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    let y = top;
    document.setFont(FONT_FAMILY, "normal");
    drawTextWithBounds(document, t("temperature"), new Rect(left, y, mid, y + getBaselineOffset(document, true)), Align.START, Align.CENTER);
    document.setFont(FONT_FAMILY, "bold");
    drawTextWithBounds(document, formatNumber(measurement.temperature, 1, 1), new Rect(mid, y, right, y + getBaselineOffset(document, true)), Align.END, Align.CENTER);
}

function drawTpDewPointMeasurementTable(t: TFunction<"translation">, document: jsPDF, bounds: Rect, measurement: TpDewPointMeasurement){
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const mid = left + (right - left) / 2;
    document.setTextColor(0, 0, 0);
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    let y = top;
    document.setFont(FONT_FAMILY, "normal");
    drawTextWithBounds(document, t("air_temperature"), new Rect(left, y, mid, y + getBaselineOffset(document, true)), Align.START, Align.CENTER);
    document.setFont(FONT_FAMILY, "bold");
    y = drawTextWithBounds(document, `${formatNumber(measurement.airTemperature, 1, 1)} °C`, new Rect(mid, y, right, y + getBaselineOffset(document, true)), Align.END, Align.CENTER);
    y += getBaselineOffset(document, true);
    document.setFont(FONT_FAMILY, "normal");
    drawTextWithBounds(document, t("air_humidity"), new Rect(left, y, mid, y + getBaselineOffset(document, true)), Align.START, Align.CENTER);
    document.setFont(FONT_FAMILY, "bold");
    y = drawTextWithBounds(document, `${formatNumber(measurement.airHumidity, 1, 1)} %`, new Rect(mid, y, right, y + getBaselineOffset(document, true)), Align.END, Align.CENTER);
    y += getBaselineOffset(document, true);
    document.setFont(FONT_FAMILY, "normal");
    drawTextWithBounds(document, t("dew_point"), new Rect(left, y, mid, y + getBaselineOffset(document, true)), Align.START, Align.CENTER);
    document.setFont(FONT_FAMILY, "bold");
    drawTextWithBounds(document, `${formatNumber(measurement.dewPoint, 1, 1)} °C`, new Rect(mid, y, right, y + getBaselineOffset(document, true)), Align.END, Align.CENTER);
}

function drawMeasurementTable(t: TFunction<"translation">, document: jsPDF, bounds: Rect, deviceType: string, measurementType: number | null, measurement: TudMeasurement | LeebMeasurement | UciMeasurement | TpMeasurement | TpSurfaceTemperatureMeasurement | TpDewPointMeasurement | UtMeasurement | MfMeasurement | DpmMeasurement, showAverage : boolean | null) {
    const top = bounds.top + CONTENT_MARGIN_MM;
    const left = bounds.left + CONTENT_MARGIN_MM;
    const right = bounds.right - CONTENT_MARGIN_MM;
    const mid = left + (right - left) / 2;
    const bottom = bounds.bottom - CONTENT_MARGIN_MM;
    let rows = 5;
    let formatter: (v: number) => string = v => v.toString();
    let minValue = Number.MAX_VALUE;
    let maxValue = -Number.MAX_VALUE;
    let sum = 0;
    let avgValue = 0;
    let countValue = 0;
    switch (deviceType) {
        case TP1M:
            const tpMeasurement = measurement as TpMeasurement;
            formatter = v => formatTpScaleValue(tpMeasurement.tpScale ?? "", v, true);
            for (let i = 0; i < tpMeasurement.n; i++) {
                let value = TpScale.convertValue(tpMeasurement.tpScale, tpMeasurement.real[i]);
                minValue = Math.min(minValue, value);
                maxValue = Math.max(maxValue, value);
                sum += value;
            }
            avgValue = sum / tpMeasurement.n;
            countValue = tpMeasurement.n;
            break;
        case TUD2:
        case TUD3:
            const tudMeasurement = measurement as TudMeasurement;
            formatter = v => formatTudScaleValue(tudMeasurement.tudScale ?? "", v);
            for (let i = 0; i < tudMeasurement.n; i++) {
                minValue = Math.min(minValue, tudMeasurement.real[i]);
                maxValue = Math.max(maxValue, tudMeasurement.real[i]);
                sum += tudMeasurement.real[i];
            }
            avgValue = sum / tudMeasurement.n;
            countValue = tudMeasurement.n;
            break;
        case LEEB:
            const leebMeasurement = measurement as LeebMeasurement;
            formatter = v => formatLeebScaleValue(leebMeasurement.leebScale ?? "", v);
            for (let i = 0; i < leebMeasurement.n; i++) {
                minValue = Math.min(minValue, leebMeasurement.real[i]);
                maxValue = Math.max(maxValue, leebMeasurement.real[i]);
                sum += leebMeasurement.real[i];
            }
            avgValue = sum / leebMeasurement.n;
            countValue = leebMeasurement.n;
            break;
        case UCI:
            const uciMeasurement = measurement as UciMeasurement;
            formatter = v => formatUciScaleValue(uciMeasurement.uciScale ?? "", v);
            for (let i = 0; i < uciMeasurement.count; i++) {
                const uciValue = uciMeasurement.data[i].value ?? 0;
                minValue = Math.min(minValue, uciValue);
                maxValue = Math.max(maxValue, uciValue);
                sum += uciValue;
            }
            avgValue = sum / uciMeasurement.count;
            countValue = uciMeasurement.count;
            break;
        case UT1M:
        case UT1M_IP:
        case UT1M_CT:
            rows = 4;
            const utMeasurement = measurement as UtMeasurement;
            formatter = v => formatUtScaleValue(v, utMeasurement.discreteness);
            let totalSum = 0;
            let count = 0;
            for (const contact of utMeasurement.measurementContacts) {
                if (contact.rawMeasurements.length > 0) {
                    let value;
                    if (showAverage) {
                        let sum = 0;
                        for (const rawMeasurement of contact.rawMeasurements) {
                            sum += rawMeasurement.thickness;
                        }
                        value = sum / contact.rawMeasurements.length;
                    } else {
                        value = contact.rawMeasurements[contact.rawMeasurements.length - 1].thickness;
                    }
                    const convertedValue = convertValue(utMeasurement.utScale, value);
                    minValue = Math.min(minValue, convertedValue);
                    maxValue = Math.max(maxValue, convertedValue);
                    totalSum += convertedValue;
                    count++;
                }
            }
            avgValue = totalSum / count;
            break;
        case MF1M:
            rows = 4;
            const mfMeasurement = measurement as MfMeasurement;
            formatter = v => formatMfScaleValue(mfMeasurement.mfScale, v);
            for (let i = 0; i < mfMeasurement.measurements.length; i++) {
                const mfValue = mfMeasurement.measurements[i];
                minValue = Math.min(minValue, mfValue);
                maxValue = Math.max(maxValue, mfValue);
                sum += mfValue;
            }
            avgValue = sum / mfMeasurement.measurements.length;
            break;
    }
    const max = formatter(maxValue);
    const min = formatter(minValue);
    const avg = formatter(avgValue);
    const count = countValue.toString();
    const rowHeight = (bottom - top) / rows;
    document.setLineWidth(TABLE_STROKE_MM);
    document.setFillColor(229, 229, 229);
    document.rect(left, top, right - left, rowHeight, "f");
    document.setDrawColor(192, 192, 192);
    document.line(left, top, right, top);
    document.line(left, top, left, bottom);
    document.line(mid, top, mid, bottom);
    document.line(right, top, right, bottom);
    for (let i = 1; i < rows; i++) {
        const lineY = top + rowHeight * i;
        document.line(left, lineY, right, lineY);
    }
    document.line(left, bottom, right, bottom);
    document.setTextColor(0, 0, 0);
    document.setFont(FONT_FAMILY, "bold");
    document.setFontSize(TABLE_TEXT_SIZE_PT);
    let y = top;
    drawTextWithBounds(document, t("parameter"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, t("value"), new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
    document.setFont(FONT_FAMILY, "normal");
    y += rowHeight;
    drawTextWithBounds(document, t("max"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, max, new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
    y += rowHeight;
    drawTextWithBounds(document, t("min"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, min, new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
    y += rowHeight;
    drawTextWithBounds(document, t("average"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
    drawTextWithBounds(document, avg, new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
    if (deviceType !== UT1M && deviceType !== UT1M_IP && deviceType !== UT1M_CT && deviceType !== MF1M) {
        y += rowHeight;
        drawTextWithBounds(document, t("number_of_measurements"), new Rect(left, y, mid, y + rowHeight), Align.CENTER, Align.CENTER);
        drawTextWithBounds(document, count, new Rect(mid, y, right, y + rowHeight), Align.CENTER, Align.CENTER);
    }
}

function drawLineChart(t: TFunction<"translation">, document: jsPDF, chartBounds: Rect, deviceType: string, measurementType: number | null, measurement: TudMeasurement | LeebMeasurement | UciMeasurement | TpMeasurement | TpSurfaceTemperatureMeasurement | TpDewPointMeasurement | UtMeasurement | MfMeasurement | DpmMeasurement, showAverage : boolean | null) {
    let lineChartData = null;
    let label = "";
    let measurementError = 0;
    let formatter = (v: LineChartData) => v.toString();
    switch (deviceType) {
        case TP1M:
            let tpMeasurement = measurement as TpMeasurement;
            lineChartData = getTpLinesChartData(tpMeasurement);
            label = formatTpScaleName(t, tpMeasurement.tpScale);
            formatter = v => formatTpScaleValue(tpMeasurement.tpScale ?? "", v.y, true);
            measurementError = TpScale.getMeasurementError(tpMeasurement.tpScale);
            break
        case TUD2:
        case TUD3:
            let tudMeasurement = measurement as TudMeasurement;
            lineChartData = getTudLinesChartData(tudMeasurement);
            label = formatTudScaleName(t, tudMeasurement.tudScale);
            formatter = v => formatTudScaleValue(tudMeasurement.tudScale ?? "", v.y);
            measurementError = TudScale.getMeasurementError(tudMeasurement.tudScale);
            break;
        case LEEB:
            let leebMeasurement = measurement as LeebMeasurement;
            lineChartData = getLeebLinesChartData(leebMeasurement);
            label = formatLeebScaleName(t, leebMeasurement.leebScale);
            formatter = v => formatLeebScaleValue(leebMeasurement.leebScale ?? "", v.y);
            measurementError = LeebScale.getMeasurementError(leebMeasurement.leebScale);
            break;
        case UCI:
            let uciMeasurement = measurement as UciMeasurement;
            lineChartData = getUciLinesChartData(uciMeasurement);
            label = formatUciScaleName(t, uciMeasurement.uciScale, uciMeasurement.uciScaleCustomName);
            formatter = v => formatUciScaleValue(uciMeasurement.uciScale ?? "", v.y);
            measurementError = UciScale.getMeasurementError(uciMeasurement.uciScale);
            break;
        case UT1M:
        case UT1M_IP:
        case UT1M_CT:
            let utMeasurement = measurement as UtMeasurement;
            lineChartData = getUtLinesChartData(utMeasurement, showAverage ?? false);
            label = formatUtScaleName(t, utMeasurement.utScale);
            formatter = v => formatUtScaleValue(v.y, utMeasurement.discreteness ?? 0);
            measurementError = UtScale.getMeasurementError(utMeasurement.utScale);
            break;
    }
    if (lineChartData) {
        const chart = buildLineChart(chartBounds, label, lineChartData, formatter, measurementError);
        drawBitmap(document, chart, chartBounds, Align.CENTER, Align.CENTER);
    }
}

function drawBarChart(t: TFunction<"translation">, document: jsPDF, chartBounds: Rect, deviceType: string, measurementType: number | null, measurement: TudMeasurement | LeebMeasurement |UciMeasurement | TpMeasurement | TpSurfaceTemperatureMeasurement | TpDewPointMeasurement | UtMeasurement | MfMeasurement | DpmMeasurement) {
    let barChartData = null;
    let label = "";
    switch (deviceType) {
        case TP1M:
            let tpMeasurement = measurement as TpMeasurement;
            barChartData = getTpBarChartData(tpMeasurement);
            label = formatTpScaleName(t, tpMeasurement.tpScale);
            break
        case TUD2:
        case TUD3:
            let tudMeasurement = measurement as TudMeasurement;
            barChartData = getTudBarChartData(tudMeasurement);
            label = formatTudScaleName(t, tudMeasurement.tudScale);
            break;
        case LEEB:
            let leebMeasurement = measurement as LeebMeasurement;
            barChartData = getLeebBarChartData(leebMeasurement);
            label = formatLeebScaleName(t, leebMeasurement.leebScale);
            break;
        case UCI:
            let uciMeasurement = measurement as UciMeasurement;
            barChartData = getUciBarChartData(uciMeasurement);
            label = formatUciScaleName(t, uciMeasurement.uciScale, uciMeasurement.uciScaleCustomName);
            break;
    }
    if (barChartData) {
        const chart = buildBarChart(chartBounds, label, barChartData);
        drawBitmap(document, chart, chartBounds, Align.CENTER, Align.CENTER);
    }
}

function drawAreaChart(t: TFunction<"translation">, document: jsPDF, chartBounds: Rect, deviceType: string, measurementType: number | null, measurement: TudMeasurement | LeebMeasurement | UciMeasurement | TpMeasurement | TpSurfaceTemperatureMeasurement | TpDewPointMeasurement | UtMeasurement | MfMeasurement | DpmMeasurement) {
    let areaChartData = null;
    let label = "";
    let measurementError = 0;
    let formatter = (v: AreaChartData) => v.toString();
    let symmetric = false;
    switch (deviceType) {
        case UT1M:
        case UT1M_IP:
        case UT1M_CT:
            const utMeasurement = measurement as UtMeasurement;
            areaChartData = getUtAreaChartData(utMeasurement);
            label = formatUtScaleName(t, utMeasurement.utScale);
            formatter = v => formatUtScaleValue(v.y[1], utMeasurement.discreteness ?? 0);
            measurementError = UtScale.getMeasurementError(utMeasurement.utScale);
            break;
        case MF1M:
            const mfMeasurement = measurement as MfMeasurement;
            areaChartData = getMfAreaChartData(mfMeasurement);
            label = formatMfScaleName(t, mfMeasurement.mfScale);
            formatter = v => formatMfScaleValue(mfMeasurement.mfScale, v.y[1]);
            measurementError = MfScale.getMeasurementError(mfMeasurement.mfScale);
            symmetric = true;
            break;
    }
    if (areaChartData) {
        const chart = buildAreaChart(chartBounds, label, areaChartData, formatter, measurementError, symmetric);
        drawBitmap(document, chart, chartBounds, Align.CENTER, Align.CENTER);
    }
}

function getTudLinesChartData(measurement: TudMeasurement) {
    const data = new Array<LineChartData>();
    if (measurement) {
        for (let i = 0; i < measurement.n; i++) {
            data.push({
                x: i + 1,
                y: measurement.real[i]
            });
        }
    }
    return data;
}

function getLeebLinesChartData(measurement: LeebMeasurement) {
    const data = new Array<LineChartData>();
    if (measurement) {
        for (let i = 0; i < measurement.n; i++) {
            data.push({
                x: i + 1,
                y: measurement.real[i]
            });
        }
    }
    return data;
}

function getUciLinesChartData(measurement: UciMeasurement) {
    const data = new Array<LineChartData>();
    if (measurement) {
        for (let i = 0; i < measurement.count; i++) {
            data.push({
                x: i + 1,
                y: measurement.data[i].value ?? 0
            });
        }
    }
    return data;
}

function getTpLinesChartData(measurement: TpMeasurement) {
    const data = new Array<LineChartData>();
    if (measurement) {
        for (let i = 0; i < measurement.n; i++) {
            data.push({
                x: i + 1,
                y: TpScale.convertValue(measurement.tpScale, measurement.real[i])
            });
        }
    }
    return data;
}

function getUtLinesChartData(measurement: UtMeasurement, showAverage : boolean) {
    const data = new Array<LineChartData>();
    if (measurement) {
        for (let i = 0; i < measurement.measurementContacts.length; i++) {
            const c = measurement.measurementContacts[i];
            let value;
            if (showAverage) {
                let sum = 0;
                for (const rawMeasurement of c.rawMeasurements) {
                    sum += rawMeasurement.thickness;
                }
                value = c.rawMeasurements.length > 0 ? sum / c.rawMeasurements.length : 0;
            } else {
                value = c.rawMeasurements[c.rawMeasurements.length - 1].thickness;
            }
            data.push({
                x: i + 1,
                y: UtScale.convertValue(measurement.utScale, value)
            });
        }
    }
    return data;
}

function getTudBarChartData(measurement: TudMeasurement) {
    const data = new Array<number>();
    if (measurement) {
        for (let i = 0; i < measurement.n; i++) {
            data.push(measurement.real[i]);
        }
    }
    return data;
}

function getLeebBarChartData(measurement: LeebMeasurement) {
    const data = new Array<number>();
    if (measurement) {
        for (let i = 0; i < measurement.n; i++) {
            data.push(measurement.real[i]);
        }
    }
    return data;
}

function getUciBarChartData(measurement: UciMeasurement) {
    const data = new Array<number>();
    if (measurement) {
        for (let i = 0; i < measurement.count; i++) {
            data.push(measurement.data[i].value ?? 0);
        }
    }
    return data;
}

function getTpBarChartData(measurement: TpMeasurement) {
    const data = new Array<number>();
    if (measurement) {
        for (let i = 0; i < measurement.n; i++) {
            data.push(TpScale.convertValue(measurement.tpScale, measurement.real[i]));
        }
    }
    return data;
}

function getUtAreaChartData(measurement: UtMeasurement) {
    let i = 0;
    const data = new Array<AreaChartData>();
    if (measurement) {
        for (const contact of measurement.measurementContacts) {
            for (const item of contact.rawMeasurements) {
                data.push({
                    x: i++,
                    y: [0, UtScale.convertValue(measurement.utScale, item.thickness)]
                });
            }
        }
    }
    return data;
}

function getMfAreaChartData(measurement: MfMeasurement) {
    let i = 0;
    const data = new Array<AreaChartData>();
    if (measurement) {
        for (const item of measurement.measurements) {
            data.push({
                x: i++,
                y: [item, item]
            });
        }
    }
    return data;
}

export function drawMeasurementsPhoto(t: TFunction<"translation">, doc: jsPDF, bounds: Rect, deviceType: string, measurementType: number | null, measurements: Array<TudMeasurement> | Array<LeebMeasurement> | Array<UciMeasurement> | Array<TpMeasurement> | Array<TpSurfaceTemperatureMeasurement> | Array<TpDewPointMeasurement> | Array<UtMeasurement> | Array<MfMeasurement> | Array<DpmMeasurement>, showAverage: boolean | null, bitmap: ImageData) {
    const aspectRatio = bitmap.width / bitmap.height;
    let w = bounds.width;
    let h = w / aspectRatio;
    if (h > bounds.height) {
        h = bounds.height;
        w = h * aspectRatio;
    }
    let left = bounds.left + (bounds.width - w) / 2;
    let top = bounds.top + (bounds.height - h) / 2;
    const imageBounds = new Rect(left, top, left + w, top + h);
    drawBitmap(doc, bitmap, imageBounds, Align.CENTER, Align.CENTER, PHOTO_ALIAS);
    let markerLocations: Array<MeasurementMarker> | undefined = undefined;
    switch (deviceType) {
        case TP1M:
            if (measurementType === COATING_THICKNESS_MEASUREMENT_TYPE) {
                const tpMeasurements = measurements as Array<TpMeasurement>;
                markerLocations = tpMeasurements.filter(m => m.location).map(m => {
                    return {
                        text: `${formatTpScaleValue(m.tpScale, m.middle)} ${formatTpScaleName(t, m.tpScale)}`,
                        location: m.location
                    } as MeasurementMarker;
                });
            }
            if (measurementType === SURFACE_TEMPERATURE_MEASUREMENT_TYPE) {
                const tpSurfaceTemperatureMeasurement = measurements as Array<TpSurfaceTemperatureMeasurement>;
                markerLocations = tpSurfaceTemperatureMeasurement.filter(m => m.location).map(m => {
                    return {
                        text: `${m.temperature} ⁰C`,
                        location: m.location
                    } as MeasurementMarker;
                });
            }
            if (measurementType === DEW_POINT_MEASUREMENT_TYPE) {
                const tpDewPointMeasurement = measurements as Array<TpDewPointMeasurement>;
                markerLocations = tpDewPointMeasurement.filter(m => m.location).map(m => {
                    return {
                        text: t("dew_point_marker_format", {airTemperature: formatNumber(m.airTemperature, 1, 1), airHumidity: formatNumber(m.airTemperature, 1, 1), dewPoint: formatNumber(m.dewPoint, 1, 1)}),
                        location: m.location
                    } as MeasurementMarker;
                });
            }
            break;
        case TUD2:
        case TUD3:
            const tudMeasurements = measurements as Array<TudMeasurement>;
            markerLocations = tudMeasurements.filter(m => m.location).map(m => {
                return {
                    text: `${formatTudScaleValue(m.tudScale, m.middle)} ${formatTudScaleName(t, m.tudScale)}`,
                    location: m.location as MeasurementLocation
                } as MeasurementMarker;
            });
            break;
        case LEEB:
            const leebMeasurements = measurements as Array<LeebMeasurement>;
            markerLocations = leebMeasurements.filter(m => m.location).map(m => {
                return {
                    text: `${formatLeebScaleValue(m.leebScale, m.middle)} ${formatLeebScaleName(t, m.leebScale)}`,
                    location: m.location as MeasurementLocation
                } as MeasurementMarker;
            });
            break;
        case UCI:
            const uciMeasurements = measurements as Array<UciMeasurement>;
            markerLocations = uciMeasurements.filter(m => m.location).map(m => {
                return {
                    text: `${formatUciScaleValue(m.uciScale, m.avg)} ${formatUciScaleName(t, m.uciScale, m.uciScaleCustomName)}`,
                    location: m.location as MeasurementLocation
                } as MeasurementMarker;
            });
            break;
        case UT1M:
        case UT1M_IP:
        case UT1M_CT:
            const utMeasurements = measurements as Array<UtMeasurement>;
            markerLocations = utMeasurements.filter(m => m.location).map(m => {
                let value;
                if (showAverage) {
                    let sum = 0;
                    for (const contact of m.measurementContacts) {
                        if (contact.rawMeasurements.length > 0) {
                            let contactSum = 0;
                            for (const rawMeasurement of contact.rawMeasurements) {
                                contactSum += rawMeasurement.thickness;
                            }
                            sum += contactSum / contact.rawMeasurements.length;
                        }
                    }
                    value = m.measurementContacts.length > 0 ? sum / m.measurementContacts.length : 0;
                } else {
                    const contact = m.measurementContacts[m.measurementContacts.length - 1];
                    const rawMeasurement = contact.rawMeasurements[contact.rawMeasurements.length - 1];
                    value = rawMeasurement.thickness;
                }
                return {
                    text: `${formatUtScaleValue(convertValue(m.utScale, value), m.discreteness)} ${formatUtScaleName(t, m.utScale)}`,
                    location: m.location
                } as MeasurementMarker;
            });
            break;
        case MF1M:
            const mfMeasurements = measurements as Array<MfMeasurement>;
            markerLocations = mfMeasurements.filter(m => m.location).map(m => {
                return {
                    text: `${formatMfScaleValue(m.mfScale, m.measurements[m.measurements.length - 1])} ${formatMfScaleName(t, m.mfScale)}`,
                    location: m.location
                } as MeasurementMarker;
            });
            break;
        case DPM:
            const dpmMeasurement = measurements as Array<DpmMeasurement>;
            markerLocations = dpmMeasurement.filter(m => m.location).map(m => {
                const measurement = m.measurements[m.measurements.length - 1];
                return {
                    text: t("dpm_marker_format",
                        {
                            humidity: formatNumber(measurement.humidity, 1, 1),
                            surfaceTemperature: formatNumber(measurement.tempSurface, 1, 1),
                            airTemperature: formatNumber(measurement.tempAir, 1, 1),
                            dewPoint: formatNumber(measurement.devPoint, 1, 1),
                            difference: formatNumber(measurement.devSurface, 1, 1),
                        }),
                    location: m.location
                } as MeasurementMarker;
            });
            break;
    }
    if (markerLocations) {
        markerLocations.forEach(ml => {
            const markerX = imageBounds.left + ml.location.x * imageBounds.width;
            const markerY = imageBounds.top + ml.location.y * imageBounds.height;
            const marker = new MarkerView(doc, ml.text);
            let orientation = MarkerOrientation.N;
            if (markerY > imageBounds.bottom - marker.getHeightWithOrientation(orientation)) {
                orientation = MarkerOrientation.S;
            }
            if (markerX < imageBounds.left + marker.getWidthWithOrientation(orientation)) {
                orientation = MarkerOrientation.W;
                if (markerY < imageBounds.top + marker.getHeightWithOrientation(orientation)) {
                    orientation = MarkerOrientation.NW;
                }
                if (markerY > imageBounds.bottom - marker.getHeightWithOrientation(orientation)) {
                    orientation = MarkerOrientation.SW;
                }
            }
            if (markerX > imageBounds.right - marker.getWidthWithOrientation(orientation)) {
                orientation = MarkerOrientation.E;
                if (markerY < imageBounds.top + marker.getHeightWithOrientation(orientation)) {
                    orientation = MarkerOrientation.NE;
                }
                if (markerY > imageBounds.bottom - marker.getHeightWithOrientation(orientation)) {
                    orientation = MarkerOrientation.SE;
                }
            }
            marker.draw(doc, markerX, markerY, orientation);
        })
    }
}