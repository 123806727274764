import {
    AWP_LIST_SORT_ORDER,
    CALENDAR_DATE, EXECUTORS_DEVICE_FILTER, EXECUTORS_DISTANCE_FILTER, EXECUTORS_PERIOD_FILTER,
    EXPLORER_DEVICE_FILTER,
    EXPLORER_SORT,
    LIST_LOCATION,
    SHOW_PREVIEW, USER_DATA
} from "../persistence";

export function getSavedValue<T>(key : string, defaultValue : T) : T {
    const savedValue = localStorage.getItem(key);
    return savedValue ? JSON.parse(savedValue) : defaultValue;
}

export function saveValue<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
}

export function clearValue(key: string){
    localStorage.removeItem(key);
}

export function resetStoredData(){
    clearValue(LIST_LOCATION);
    clearValue(EXPLORER_DEVICE_FILTER);
    clearValue(CALENDAR_DATE);
    clearValue(EXPLORER_SORT);
    clearValue(SHOW_PREVIEW);
    clearValue(EXECUTORS_DEVICE_FILTER);
    clearValue(EXECUTORS_PERIOD_FILTER);
    clearValue(EXECUTORS_DISTANCE_FILTER);
    clearValue(AWP_LIST_SORT_ORDER);
}