import {useTranslation} from "react-i18next";
import {AwpSeries} from "../../helpers/AwpHelper";
import {formatNumber} from "../../helpers/FormatHelper";
import {AwpUd3701Series} from "../../models/AwpUd3701Series";
import {getUd3701TransducerInfo} from "../../helpers/AwpUd3701FormatHelper";

interface Props {
    data: AwpSeries;
}

export function AwpUd3701TransducerInfo(props: Props) {
    const {t} = useTranslation();
    const data = props.data as AwpUd3701Series;
    const transducerInfo = getUd3701TransducerInfo(t, data);
    return (
        <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-stretch">
            <table className="table-measurement-info">
                <tbody>
                <tr>
                    <th>{t('frequency_mhz')}</th>
                    <td>{transducerInfo.freq}</td>
                </tr>
                <tr>
                    <th>{t('input_angle')}</th>
                    <td>{transducerInfo.angle}</td>
                </tr>
                <tr>
                    <th>{t('delay_in_prism_us')}</th>
                    <td>{transducerInfo.delay}</td>
                </tr>
                <tr>
                    <th>{t('arrow_mm')}</th>
                    <td>{transducerInfo.vector}</td>
                </tr>
                <tr>
                    <th>{t('diameter_mm')}</th>
                    <td>{transducerInfo.diamSyzeA}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}