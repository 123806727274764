import {useState} from "react";
import {clearValue, getSavedValue, saveValue} from "../helpers/PersistenceHelper";

export function usePersistentState<T>(key: string, defaultValue: T): [T, (v: T) => void] {
    const [state, setState] = useState(getSavedValue(key, defaultValue));
    const setPersistentState = (value: T) => {
        saveValue(key, value);
        setState(value);
    };
    return [state, setPersistentState];
}

export function useNullablePersistentState<T>(key: string, defaultValue: T | null): [T | null, (v: T | null) => void] {
    const [state, setState] = useState(getSavedValue(key, defaultValue));
    const setPersistentState = (value: T | null) => {
        if (value !== null) {
            saveValue(key, value);
        } else {
            clearValue(key);
        }
        setState(value);
    };
    return [state, setPersistentState];
}