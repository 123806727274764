import {AwpFileHandle} from "./AwpFileHandle";

export class AwpLocalFileHandle implements AwpFileHandle{

    private readonly handle : FileSystemFileHandle;

    constructor(handle : FileSystemFileHandle) {
        this.handle = handle;
    }

    name(): string {
        return this.handle.name;
    }

    getData(): Promise<ArrayBuffer> {
        return new Promise<ArrayBuffer>(async (resolve, reject) => {
            try {
                const file = await this.handle.getFile();
                const buffer = await file.arrayBuffer();
                resolve(buffer);
            } catch (e) {
                reject(e);
            }
        });
    }

    lastModified(): Promise<number> {
        return new Promise<number>(async resolve => {
            const file = await this.handle.getFile();
            resolve(file.lastModified);
        });
    }



}