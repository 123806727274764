import {TudRecord} from "../../models/TudRecord";
import {AudioPlayer} from "../AudioPlayer/AudioPlayer";
import {VideoPlayer} from "../VideoPlayer/VideoPlayer";
import {formatDeviceName, formatTudProbeName} from "../../helpers/FormatHelper";
import {GeoLocationViewer} from "../GeoLocationViewer/GeoLocationViewer";
import {TudMeasurementWithoutPhoto} from "./TudMeasurementWithoutPhoto";
import {TudPhotoViewer} from "../PhotoViewer/TudPhotoViewer";
import {TudMeasurement} from "../../models/TudMeasurement";
import {TudMeasurementDetails} from "../MeasurementDetails/TudMeasurementDetails";
import {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ViewContainerWithEnlarge} from "../ViewContainer/ViewContainerWithEnlarge";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {Col, Row} from "react-bootstrap";
import {GeoLocationPlaceholder} from "../GeoLocationPlaceholder/GeoLocationPlaceholder";
import {ViewContainer} from "../ViewContainer/ViewContainer";

interface Props {
    drive: any;
    recordId: string;
    record: TudRecord;
}

export function TudViewer(props: Props) {
    const {t} = useTranslation();
    const [activeMeasurement, setActiveMeasurement] = useState(null as TudMeasurement | null);
    const [isPhotoLoaded, setPhotoLoaded] = useState(false);
    const detailsClickHandler = (measurement: TudMeasurement) => {
        setActiveMeasurement(measurement);
    };
    const fullScreenHandle = useFullScreenHandle();
    const modalContainer = useRef<HTMLDivElement>(null);
    return (
        <div className="container-grow">
            <Row xs={1} sm={1} md={2} xl={3}>
                {((props.record.userName && props.record.userName !== "") || (props.record.userOrganization && props.record.userOrganization !== "")) &&
                <Col className="d-flex flex-column flex-grow-1">
                    <ViewContainer title={t('inspector')} reserveSpaceForDataTime={true}>
                        <div className="d-flex flex-column justify-content-start">
                    <span
                        className="view-item-title">{props.record.userName && props.record.userName !== "" ? props.record.userName : t("not_specified")}</span>
                            {props.record.userOrganization && props.record.userOrganization !== "" &&
                            <span className="view-item-description">{props.record.userOrganization}</span>}
                        </div>
                    </ViewContainer>
                </Col>}
                <Col className="d-flex flex-column flex-grow-1">
                    <ViewContainer title={t('device')} reserveSpaceForDataTime={true}>
                        <div className="d-flex flex-column justify-content-start">
                            <span className="view-item-title me-2">{formatDeviceName(t, props.record.deviceType)}</span>
                            <span className="view-item-description">{props.record.deviceId}</span>
                        </div>
                    </ViewContainer>
                </Col>
                {(props.record.probeId && props.record.probe) &&
                <Col className="d-flex flex-column flex-grow-1">
                    <ViewContainer title={t('probe')} reserveSpaceForDataTime={true}>
                        <div className="d-flex flex-column justify-content-start">
                            <span className="view-item-title me-2">{formatTudProbeName(t, props.record.probe)}</span>
                            <span className="view-item-description">{props.record.probeId}</span>
                        </div>
                    </ViewContainer>
                </Col>}
                {props.record.measurements && props.record.measurements.length > 0 && !props.record.photo &&
                <Col className="d-flex flex-column flex-grow-1">
                    <ViewContainer title={t('measurements_without_photo')} dateTime={props.record.dateTime}>
                        <TudMeasurementWithoutPhoto measurement={props.record.measurements[0]}
                                                    detailsClickListener={detailsClickHandler}/>
                    </ViewContainer>
                </Col>
                }
            </Row>
            {props.record.measurements && props.record.measurements.length > 0 && props.record.photo &&
            <Row xs={1} sm={1} md={2} xl={3}>
                <Col md={{span: 12}} lg={{span: 12}} xl={{span: 8}}>
                    <ViewContainerWithEnlarge title={t('measurements')} dateTime={props.record.dateTime}
                                              enlargeClickHandler={fullScreenHandle.enter} isLoaded={isPhotoLoaded}>
                        <FullScreen className="d-flex flex-column flex-grow-1" handle={fullScreenHandle}>
                            <div className="d-flex flex-column flex-grow-1" ref={modalContainer}>
                                <TudPhotoViewer drive={props.drive} recordId={props.recordId}
                                                mediaRecord={props.record.photo}
                                                measurement={props.record.measurements}
                                                isFullScreen={fullScreenHandle.active}
                                                markerClickListener={detailsClickHandler}
                                                imageLoadListener={() => setPhotoLoaded(true)}/>
                            </div>
                        </FullScreen>
                    </ViewContainerWithEnlarge>
                </Col>
            </Row>
            }
            {props.record.measurements && props.record.measurements.length > 0 &&
            <TudMeasurementDetails measurement={activeMeasurement} closeHandler={() => setActiveMeasurement(null)}
                                   modalContainer={modalContainer.current ?? undefined}/>
            }
            <ViewContainer title={t('geolocation')} reserveSpaceForDataTime={true} dateTime={props.record.geoLocation?.dateTime}>
                {props.record.geoLocation ? <GeoLocationViewer lat={props.record.geoLocation.latitude} lng={props.record.geoLocation.longitude}/> : <GeoLocationPlaceholder />}
            </ViewContainer>
            {props.record.notes &&
            <ViewContainer title={t('notes')}>
                <div className="d-flex flex-row justify-content-start">
                    <span className="view-item-description line-break">{props.record.notes}</span>
                </div>
            </ViewContainer>
            }
            {(props.record.audio && props.record.audio.length > 0) &&
            <ViewContainer title={t('audio_records')}>
                <Row xs={Math.min(1, props.record.audio.length)} sm={Math.min(2, props.record.audio.length)}
                     md={Math.min(2, props.record.audio.length)} xl={Math.min(3, props.record.audio.length)}>
                    {props.record.audio.map((mr, i) =>
                        <Col key={`a-${i}`}>
                            <AudioPlayer key={`a${i}`} drive={props.drive}
                                         recordId={props.recordId}
                                         mediaRecord={mr}/>
                        </Col>
                    )}
                </Row>
            </ViewContainer>
            }
            {(props.record.video && props.record.video.length > 0) &&
            <ViewContainer title={t('video_records')}>
                <Row xs={Math.min(1, props.record.video.length)} sm={Math.min(2, props.record.video.length)}
                     md={Math.min(2, props.record.video.length)} xl={Math.min(3, props.record.video.length)}>
                    {props.record.video.map((mr, i) =>
                        <Col key={`v-${i}`}>
                            <VideoPlayer key={`v${i}`} drive={props.drive}
                                         recordId={props.recordId}
                                         mediaRecord={mr}/>
                        </Col>)}
                </Row>
            </ViewContainer>
            }
        </div>
    );
}