import moment from "moment";
import {APP_VERSION} from "../../AppSettings";
import './Footer.css';
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {downloadLogs} from "../../helpers/LogHelper";

export function Footer() {
    const {t} = useTranslation();
    const year = moment().format("YYYY");
    const [logClicks, setLogClicks] = useState(1);
    const versionClick = () => {
        if (logClicks < 5){
            setLogClicks(c => c +1);
        } else {
            setLogClicks(1);
            downloadLogs();
        }
    };
    return (
        <div className="d-flex flex-row justify-content-center align-items-center p-4 footer-text no-select">
            <span className="mx-4" onClick={versionClick}>{APP_VERSION}</span>
            <div className="d-flex flex-wrap">
                <span className="flex-grow-1 text-center me-1">{t("copyright", {year: year})}</span>
                <span className="flex-grow-1 text-center ms-1">{t("all_rights_reserved")}</span>
            </div>
        </div>
    );
}