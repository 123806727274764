import {useMatch} from "react-router-dom";
import {
    AWP_DATA_STREAMING_ROUTE,
    AWP_FW_ASSIST,
    AWP_FW_EXPERT_UPDATE_ROUTE,
    AWP_FW_GSP_UPDATE_ROUTE,
    AWP_FW_LOADER,
    AWP_FW_LOADER_DIRECT,
    AWP_IPSM_BASE_ROUTE,
    AWP_IPSM_DATA_TRANSFER_ROUTE,
    AWP_IPSM_RECORD_ROUTE,
    AWP_IPSM_ROUTE,
    AWP_KRC_BASE_ROUTE,
    AWP_KRC_RECORD_ROUTE,
    AWP_KRC_ROUTE,
    AWP_LIST_ROUTE,
    AWP_MF_BASE_ROUTE,
    AWP_MF_RECORD_ROUTE,
    AWP_MF_ROUTE,
    AWP_TP_BASE_ROUTE,
    AWP_TP_RECORD_ROUTE,
    AWP_TP_ROUTE,
    AWP_TUD_BASE_ROUTE,
    AWP_TUD_RECORD_ROUTE,
    AWP_TUD_ROUTE,
    AWP_UD2301_BASE_ROUTE,
    AWP_UD2301_RECORD_ROUTE,
    AWP_UD2301_ROUTE,
    AWP_UD2303_BASE_ROUTE,
    AWP_UD2303_RECORD_ROUTE,
    AWP_UD2303_ROUTE,
    AWP_UD3701_BASE_ROUTE,
    AWP_UD3701_RECORD_ROUTE,
    AWP_UD3701_ROUTE,
    AWP_UT2A_BASE_ROUTE,
    AWP_UT2A_RECORD_ROUTE,
    AWP_UT2A_ROUTE,
    AWP_UT3EMA_BASE_ROUTE,
    AWP_UT3EMA_RECORD_ROUTE,
    AWP_UT3EMA_ROUTE,
    AWP_UT_BASE_ROUTE,
    AWP_UT_RECORD_ROUTE,
    AWP_UT_ROUTE,
    EXECUTORS_ROUTE,
    HOME_ROUTE
} from "../routes";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA
} from "../helpers/AwpHelper";

export const AWP_DATA_STREAMING_SECTION = -1;
export const AWP_FW_SECTION = -2;

export function useExecutorRouteSection(): boolean {
    return useMatch(EXECUTORS_ROUTE) !== null;
}

export function useWelcomeRouteSection(): boolean {
    return useMatch(HOME_ROUTE) !== null;
}

export function useAwpRouteSection(): undefined | null | number {
    const isList = useMatch(AWP_LIST_ROUTE) !== null;
    const isTud = useMatch(AWP_TUD_ROUTE) !== null;
    const isTudBase = useMatch(AWP_TUD_BASE_ROUTE) !== null;
    const isTudRecord = useMatch(AWP_TUD_RECORD_ROUTE) !== null;
    const isKrc = useMatch(AWP_KRC_ROUTE) !== null;
    const isKrcBase = useMatch(AWP_KRC_BASE_ROUTE) !== null;
    const isKrcRecord = useMatch(AWP_KRC_RECORD_ROUTE) !== null;
    const isTp = useMatch(AWP_TP_ROUTE) !== null;
    const isTpBase = useMatch(AWP_TP_BASE_ROUTE) !== null;
    const isTpRecord = useMatch(AWP_TP_RECORD_ROUTE) !== null;
    const isUt = useMatch(AWP_UT_ROUTE) !== null;
    const isUtBase = useMatch(AWP_UT_BASE_ROUTE) !== null;
    const isUtRecord = useMatch(AWP_UT_RECORD_ROUTE) !== null;
    const isMf = useMatch(AWP_MF_ROUTE) !== null;
    const isMfBase = useMatch(AWP_MF_BASE_ROUTE) !== null;
    const isMfRecord = useMatch(AWP_MF_RECORD_ROUTE) !== null;
    const isUd2301 = useMatch(AWP_UD2301_ROUTE) !== null;
    const isUd2301Base = useMatch(AWP_UD2301_BASE_ROUTE) !== null;
    const isUd2301Record = useMatch(AWP_UD2301_RECORD_ROUTE) !== null;
    const isUd2303 = useMatch(AWP_UD2303_ROUTE) !== null;
    const isUd2303Base = useMatch(AWP_UD2303_BASE_ROUTE) !== null;
    const isUd2303Record = useMatch(AWP_UD2303_RECORD_ROUTE) !== null;
    const isUd3701 = useMatch(AWP_UD3701_ROUTE) !== null;
    const isUd3701Base = useMatch(AWP_UD3701_BASE_ROUTE) !== null;
    const isUd3701Record = useMatch(AWP_UD3701_RECORD_ROUTE) !== null;
    const isUt3Ema = useMatch(AWP_UT3EMA_ROUTE) !== null;
    const isUt3EmaBase = useMatch(AWP_UT3EMA_BASE_ROUTE) !== null;
    const isUt3EmaRecord = useMatch(AWP_UT3EMA_RECORD_ROUTE) !== null;
    const isUt2a = useMatch(AWP_UT2A_ROUTE) !== null;
    const isUt2aBase = useMatch(AWP_UT2A_BASE_ROUTE) !== null;
    const isUt2aRecord = useMatch(AWP_UT2A_RECORD_ROUTE) !== null;
    const isIpsm = useMatch(AWP_IPSM_ROUTE) !== null;
    const isIpsmBase = useMatch(AWP_IPSM_BASE_ROUTE) !== null;
    const isIpsmRecord = useMatch(AWP_IPSM_RECORD_ROUTE) !== null;
    const isIpsmDataTransfer = useMatch(AWP_IPSM_DATA_TRANSFER_ROUTE) !== null;
    const isFwUpdate = useMatch(AWP_FW_GSP_UPDATE_ROUTE) !== null;
    const isFwLoader = useMatch(AWP_FW_LOADER) !== null;
    const isFwLoaderDirect = useMatch(AWP_FW_LOADER_DIRECT) !== null;
    const isFwAssist = useMatch(AWP_FW_ASSIST) !== null;
    const isFwExpertUpdate = useMatch(AWP_FW_EXPERT_UPDATE_ROUTE) !== null;
    const isDataStreaming = useMatch(AWP_DATA_STREAMING_ROUTE) !== null;
    if (isList){
        return null;
    }
    if (isDataStreaming){
        return AWP_DATA_STREAMING_SECTION;
    }
    if (isFwUpdate || isFwLoader || isFwLoaderDirect || isFwExpertUpdate || isFwAssist) {
        return AWP_FW_SECTION;
    }
    if (isTud || isTudBase || isTudRecord){
        return AWP_DEVICE_TUD;
    }
    if (isKrc || isKrcBase || isKrcRecord){
        return AWP_DEVICE_KRC;
    }
    if (isTp || isTpBase || isTpRecord){
        return AWP_DEVICE_TP;
    }
    if (isUt || isUtBase || isUtRecord){
        return AWP_DEVICE_UT;
    }
    if (isMf || isMfBase || isMfRecord){
        return AWP_DEVICE_MF;
    }
    if (isUd2301 || isUd2301Base || isUd2301Record){
        return AWP_DEVICE_UD2301;
    }
    if (isUd2303 || isUd2303Base || isUd2303Record){
        return AWP_DEVICE_UD2303;
    }
    if (isUd3701 || isUd3701Base || isUd3701Record){
        return AWP_DEVICE_UD3701;
    }
    if (isUt3Ema || isUt3EmaBase || isUt3EmaRecord){
        return AWP_DEVICE_UT3EMA;
    }
    if (isUt2a || isUt2aBase || isUt2aRecord){
        return AWP_DEVICE_UT2A;
    }
    if (isIpsm || isIpsmBase || isIpsmRecord || isIpsmDataTransfer){
        return AWP_DEVICE_IPSM;
    }
    return undefined;
}
