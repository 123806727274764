import {parseFloat, parseInt16, parseInt32, parseString, parseUint32, parseUint8} from "../helpers/BinaryParseHelper";

export interface AwpUd2303Series {
    probe: Sensor;
    dac: DAC;
    ard: ARD;
    adc: ADC;
    asd: ASD;
    aws: AWS;
    strobe: Strobe;
    screen: Screen;
    systemSet: Sys;
    signal: Signal
}

const CONFIG_SIZE = 34920;
const NEW_CONFIG_SIZE = 34939;
const CURVE_SIZE = 30000;

const SERIAL_SIZE = 11;
const NEW_SERIAL_SIZE = 30;

const SENSOR_SIZE = 11 * 4 + 4 * 4 + 4;
const DAC_SIZE = 5 * 4 + 3 * 16 * 4 + 8 * 4;
const ADC_SIZE = 8 * 4 + 7 * 4 + 3 * 4 + 2 + 4 + 400 * 2 + 2 * 4096 * 2 * 2 + 4 + 4 * 4;
const ASD_SIZE = 3 * 2 * 4 + 2 * 2 + 2 * 2 + 2 * 4 + 2 * 2 * 2 + 5 * 2 * 4 + 4 + 2 + 2 * 2 + 2 * 4;
const AWS_SIZE = 2 * 4 + 4 * 4 + 2 * 4 + 3;
const STROBE_SIZE = 6 * 2 * 4 + 4 + 2 * 2 * 4 + 2 * 4;
const SCREEN_SIZE = 3 * 4 + 9 * 4 + 4 * 4;
const LIST_BOX_SIZE = 4 * 2 + 2 + 4 + 3 + 2 * 4 + 7 * 2 + 3 + 2 + 4 + 2 * 2 + 2;
const HOR_LIST_BOX_SIZE = 40;
const ARD_SIZE = 7 * 4 + 4 * 4 + 2 * 4;

const DAC_OFFSET = SENSOR_SIZE;
const ARD_OFFSET = DAC_OFFSET + DAC_SIZE;
const ADC_OFFSET = ARD_OFFSET + ARD_SIZE;
const ASD_OFFSET = ADC_OFFSET + ADC_SIZE;
const AWS_OFFSET = ASD_OFFSET + ASD_SIZE;
const STROBE_OFFSET = AWS_OFFSET + AWS_SIZE;
const SCREEN_OFFSET = STROBE_OFFSET + STROBE_SIZE;
const SYS_OFFSET = SCREEN_OFFSET + SCREEN_SIZE;
const PALLETE_INDEX_OFFSET = 8 * HOR_LIST_BOX_SIZE + 13;


interface Signal {
    data: Array<number>;
}

interface Sensor {
    type: number;
    delayUs: number;
    angle: number;
    vector: number;
    freq: number;
    diamSyzeA: number;
    syzeB: number;
    sqCirc: number;
    refH: number;
    metod: number;
    startC: number;
    sinAngle: number;
    cosAngle: number;
    delayMm: number;
    sPrizma: number;
    delaySamples: number;
}

interface ARD {
    enable: number;
    refLevel: number;
    dBrack: number;
    dContr: number;
    dSearch: number;
    dTune: number;
    callDistans: number;
    nearZone: number;
    dBrackD: number;
    dContrD: number;
    dTuneD: number;
    ardAvtoFlag : number;
    autoArd: number;
}

interface DAC {
    mode: number;
    nPoint: number;
    points: number;
    delPoint: number;
    addPoint: number;
    mmPoint: Array<number>;
    dbPoint: Array<number>;
    codPoint: Array<number>;
    updateTVG: number;
    pressPoint: number;
    delPressPoint: number;
    pressNewPoint: number;
    addNewPoint: number;
    startTvgMm: number;
    delAll: number;
    work: number;
}

interface ADC {
    mmDelay : number;
    mmScan : number;
    pulse : number;
    pulseAmplitude : number;
    speed : number;
    gain : number;
    rfHw : number;
    otsechka : number;
    filterOnOff : number;
    filterFreq : number;
    enableAdc : number;
    frize : number;
    symulator : number;
    tvgOnOff : number;
    clearEnvelope : number;
    holdGain : number;
    delayFPGA : number;
    samplesFPGA : number;
    otsechkaFpga : number;
    lnaOff : number;
    timeEnvelope : Array<number>;
    scratch : Array<number>;
    fftSamples : Array<number>;
    freqProbe : number;
    freqPoint : number;
    capAccum : number;
    startRemote : number;
    flagHold : number;
}

interface ASD {
    trMod : Array<number>;
    trR : Array<number>;
    tPic : Array<number>;
    nMod : number;
    nRf : number;
    eventMod : Array<number>;
    eventRf : Array<number>;
    t : Array<number>;
    aCod : Array<number>;
    maxCod : Array<number>;
    adB : Array<number>;
    y : Array<number>;
    yab : number;
    x : Array<number>;
    xip : Array<number>;
    s : Array<number>;
    flag : Array<number>;
    daCod : Array<number>;
    dAdB : Array<number>;
}

interface AWS{
    thincnes : number;
    table : number;
    indA : number;
    refLevelB : number;
    indC : number;
    indD : number;
    class : number;
    work : number;
    oldModeStrob : number;
    oldLevelStrob : number;
    oldSelStob : number;
}

interface Strobe {
    x: Array<number>;
    y: Array<number>;
    ySearch: Array<number>;
    yContr: Array<number>;
    len: Array<number>;
    mode: Array<number>;
    select: number;
    sygnal: Array<number>;
    frontPic: Array<number>;
    linkZonaMode : number;
    zonaCapture : number;
}

interface Screen {
    filling: number;
    envelope: number;
    view: number;
    info: Array<number>;
    mmInch: number;
    midle: number;
    orientation: number;
    enableView: number;
}

interface Sys {
    serial: string;
    pallete: number;
}

function parseProbe(buffer: ArrayBuffer, baseOffset: number): Sensor {
    let offset = baseOffset;
    const type = parseInt32(buffer, offset);
    offset += 4;
    const delayUs = parseInt32(buffer, offset);
    offset += 4;
    const angle = parseInt32(buffer, offset);
    offset += 4;
    const vector = parseInt32(buffer, offset);
    offset += 4;
    const freq = parseInt32(buffer, offset);
    offset += 4;
    const diamSyzeA = parseInt32(buffer, offset);
    offset += 4;
    const syzeB = parseInt32(buffer, offset);
    offset += 4;
    const sqCirc = parseInt32(buffer, offset);
    offset += 4;
    const refH = parseInt32(buffer, offset);
    offset += 4;
    const metod = parseInt32(buffer, offset);
    offset += 4;
    const startC = parseInt32(buffer, offset);
    offset += 4;
    const sinAngle = parseFloat(buffer, offset);
    offset += 4;
    const cosAngle = parseFloat(buffer, offset);
    offset += 4;
    const delayMm = parseFloat(buffer, offset);
    offset += 4;
    const sPrizma = parseFloat(buffer, offset);
    offset += 4;
    const delaySamples = parseInt32(buffer, offset);
    return {
        type: type,
        delayUs: delayUs,
        angle: angle,
        vector: vector,
        freq: freq,
        diamSyzeA: diamSyzeA,
        syzeB: syzeB,
        sqCirc: sqCirc,
        refH: refH,
        metod: metod,
        startC: startC,
        sinAngle: sinAngle,
        cosAngle: cosAngle,
        delayMm: delayMm,
        sPrizma: sPrizma,
        delaySamples: delaySamples
    }
}

function parseDac(buffer: ArrayBuffer, baseOffset: number): DAC {
    let offset = baseOffset;
    const mode = parseInt32(buffer, offset);
    offset += 4;
    const nPoint = parseInt32(buffer, offset);
    offset += 4;
    const points = parseInt32(buffer, offset);
    offset += 4;
    const delPoint = parseInt32(buffer, offset);
    offset += 4;
    const addPoint = parseInt32(buffer, offset);
    offset += 4;
    const arraySize = 16;
    const mmPoint = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const dbPoint = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const codPoint = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const updateTVG = parseInt32(buffer, offset);
    offset += 4;
    const pressPoint = parseInt32(buffer, offset);
    offset += 4;
    const delPressPoint = parseInt32(buffer, offset);
    offset += 4;
    const pressNewPoint = parseInt32(buffer, offset);
    offset += 4;
    const addNewPoint = parseInt32(buffer, offset);
    offset += 4;
    const startTvgMm = parseInt32(buffer, offset);
    offset += 4;
    const delAll = parseInt32(buffer, offset);
    offset += 4;
    const work = parseInt32(buffer, offset);
    return {
        mode: mode,
        nPoint: nPoint,
        points: points,
        delPoint: delPoint,
        addPoint: addPoint,
        mmPoint: Array.from(mmPoint),
        dbPoint: Array.from(dbPoint),
        codPoint: Array.from(codPoint),
        updateTVG: updateTVG,
        pressPoint: pressPoint,
        delPressPoint: delPressPoint,
        pressNewPoint: pressNewPoint,
        addNewPoint: addNewPoint,
        startTvgMm: startTvgMm,
        delAll: delAll,
        work: work
    }
}

function parseAdc(initialBuffer: ArrayBuffer, baseOffset: number): ADC {
    let buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const mmDelay = parseInt32(buffer, offset);
    offset += 4;
    const mmScan = parseInt32(buffer, offset);
    offset += 4;
    const pulse = parseInt32(buffer, offset);
    offset += 4;
    const pulseAmplitude = parseInt32(buffer, offset);
    offset += 4;
    const speed = parseInt32(buffer, offset);
    offset += 4;
    const gain = parseInt32(buffer, offset);
    offset += 4;
    const rfHw = parseInt32(buffer, offset);
    offset += 4;
    const otsechka = parseInt32(buffer, offset);
    offset += 4;
    const filterOnOff = parseInt32(buffer, offset);
    offset += 4;
    const filterFreq = parseInt32(buffer, offset);
    offset += 4;
    const enableAdc = parseInt32(buffer, offset);
    offset += 4;
    const frize = parseInt32(buffer, offset);
    offset += 4;
    const symulator = parseInt32(buffer, offset);
    offset += 4;
    const tvgOnOff = parseInt32(buffer, offset);
    offset += 4;
    const clearEnvelope = parseInt32(buffer, offset);
    offset += 4;
    const holdGain = parseInt32(buffer, offset);
    offset += 4;
    const delayFPGA = parseInt32(buffer, offset);
    offset += 4;
    const samplesFPGA = parseInt32(buffer, offset);
    offset += 4;
    const otsechkaFpga = parseInt16(buffer, offset);
    offset += 2;
    buffer = buffer.slice(offset);
    offset = 0;
    const lnaOff = parseInt32(buffer, offset);
    offset += 4;
    const smallArraySize = 400;
    const timeEnvelope = new Int16Array(buffer, offset, smallArraySize);
    offset += smallArraySize * 2;
    const arraySize = 4096 * 2;
    const scratch = new Int16Array(buffer, offset, arraySize);
    offset += 2 * arraySize;
    const fftSamples = new Int16Array(buffer, offset, arraySize);
    offset += 2 * arraySize;
    const freqProbe = parseFloat(buffer, offset);
    offset += 4;
    const freqPoint = parseInt32(buffer, offset);
    offset += 4;
    const capAccum = parseInt32(buffer, offset);
    offset += 4;
    const startRemote = parseUint32(buffer, offset);
    offset += 4;
    const flagHold = parseUint32(buffer, offset);
    return {
        mmDelay : mmDelay,
        mmScan : mmScan,
        pulse : pulse,
        pulseAmplitude : pulseAmplitude,
        speed :speed,
        gain : gain,
        rfHw : rfHw,
        otsechka : otsechka,
        filterOnOff : filterOnOff,
        filterFreq : filterFreq,
        enableAdc : enableAdc,
        frize : frize,
        symulator : symulator,
        tvgOnOff : tvgOnOff,
        clearEnvelope : clearEnvelope,
        holdGain : holdGain,
        delayFPGA : delayFPGA,
        samplesFPGA : samplesFPGA,
        otsechkaFpga : otsechkaFpga,
        lnaOff : lnaOff,
        timeEnvelope : Array.from(timeEnvelope),
        scratch : Array.from(scratch),
        fftSamples : Array.from(fftSamples),
        freqProbe : freqProbe,
        freqPoint : freqPoint,
        capAccum : capAccum,
        startRemote : startRemote,
        flagHold : flagHold
    }
}

function parseAsd(initialBuffer: ArrayBuffer, baseOffset: number): ASD {
    const arraySize = 2;
    let buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const trMod = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const trR = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const tPic = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const nMod = parseInt16(buffer, offset);
    offset += 2;
    const nRf = parseInt16(buffer, offset);
    offset += 2;
    const eventMod = new Uint8Array(buffer, offset, arraySize);
    offset += arraySize;
    const eventRf = new Uint8Array(buffer, offset, arraySize);
    offset += arraySize;
    const t = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const aCod = new Int16Array(buffer, offset, arraySize);
    offset += 2 * arraySize;
    const maxCod = new Int16Array(buffer, offset, arraySize);
    offset += 2 * arraySize;
    const adB = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const y = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const yab = parseFloat(buffer, offset);
    offset += 4;
    const x = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const xip = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const s = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const flag = new Uint8Array(buffer, offset, arraySize);
    offset += arraySize;
    const daCod = new Int16Array(buffer, offset, arraySize);
    offset += 2 * arraySize;
    buffer = buffer.slice(offset);
    offset = 0;
    const dAdB = new Float32Array(buffer, offset, arraySize);
    return {
        trMod : Array.from(trMod),
        trR : Array.from(trR),
        tPic : Array.from(tPic),
        nMod : nMod,
        nRf : nRf,
        eventMod : Array.from(eventMod),
        eventRf : Array.from(eventRf),
        t :Array.from(t),
        aCod : Array.from(aCod),
        maxCod :Array.from(maxCod),
        adB : Array.from(adB),
        y : Array.from(y),
        yab : yab,
        x : Array.from(x),
        xip : Array.from(xip),
        s : Array.from(s),
        flag : Array.from(flag),
        daCod : Array.from(daCod),
        dAdB : Array.from(dAdB),
    }
}

function parseAws(initialBuffer: ArrayBuffer, baseOffset: number): AWS {
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const thincnes = parseInt32(buffer, offset);
    offset += 4;
    const table = parseInt32(buffer, offset);
    offset += 4;
    const indA = parseFloat(buffer, offset);
    offset += 4;
    const refLevelB = parseFloat(buffer, offset);
    offset += 4;
    const indC = parseFloat(buffer, offset);
    offset += 4;
    const indD = parseFloat(buffer, offset);
    offset += 4;
    const c = parseInt32(buffer, offset);
    offset += 4;
    const work = parseInt32(buffer, offset);
    offset += 4;
    const oldModeStrob = parseUint8(buffer, offset);
    offset += 1;
    const oldLevelStrob = parseUint8(buffer, offset);
    offset += 1;
    const oldSelStob = parseUint8(buffer, offset);
    return {
        thincnes : thincnes,
        table : table,
        indA : indA,
        refLevelB : refLevelB,
        indC : indC,
        indD : indD,
        class : c,
        work : work,
        oldModeStrob : oldModeStrob,
        oldLevelStrob : oldLevelStrob,
        oldSelStob : oldSelStob,
    };
}

function parseStrobe(initialBuffer: ArrayBuffer, baseOffset: number): Strobe {
    const arraySize = 2;
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const x = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const y = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const ySearch = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const yContr = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const len = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const mode = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const select = parseInt32(buffer, offset);
    offset += 4;
    const sygnal = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const frontPic = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const linkZonaMode = parseInt32(buffer, offset);
    offset += 4;
    const zonaCapture = parseInt32(buffer, offset);
    return {
        x: Array.from(x),
        y: Array.from(y),
        ySearch: Array.from(ySearch),
        yContr: Array.from(yContr),
        len: Array.from(len),
        mode: Array.from(mode),
        select: select,
        sygnal: Array.from(sygnal),
        frontPic: Array.from(frontPic),
        linkZonaMode : linkZonaMode,
        zonaCapture: zonaCapture
    }
}

function parseScreen(initialBuffer: ArrayBuffer, baseOffset: number): Screen {
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const filling = parseInt32(buffer, offset);
    offset += 4;
    const envelope = parseInt32(buffer, offset);
    offset += 4;
    const view = parseInt32(buffer, offset);
    offset += 4;
    const arraySize = 9;
    const info = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const mmInch = parseInt32(buffer, offset);
    offset += 4;
    const midle = parseInt32(buffer, offset);
    offset += 4;
    const orientation = parseInt32(buffer, offset);
    offset += 4;
    const enableView = parseInt32(buffer, offset);
    return {
        filling: filling,
        envelope: envelope,
        view: view,
        info: Array.from(info),
        mmInch: mmInch,
        midle: midle,
        orientation: orientation,
        enableView: enableView
    }
}

function parseSys(buffer: ArrayBuffer, baseOffset: number, isNewId : boolean): Sys {
    const serialSize = isNewId ? NEW_SERIAL_SIZE : SERIAL_SIZE;
    const serial = parseString(buffer, baseOffset, serialSize);
    const pallete = parseUint8(buffer, baseOffset + serialSize + PALLETE_INDEX_OFFSET);
    return {
        serial: serial,
        pallete: pallete
    }
}

function parseArd(initialBuffer: ArrayBuffer, baseOffset: number): ARD {
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const enable = parseInt32(buffer, offset);
    offset += 4;
    const refLevel = parseInt32(buffer, offset);
    offset += 4;
    const dBrack = parseInt32(buffer, offset);
    offset += 4;
    const dContr = parseInt32(buffer, offset);
    offset += 4;
    const dSearch = parseInt32(buffer, offset);
    offset += 4;
    const dTune = parseInt32(buffer, offset);
    offset += 4;
    const callDistans = parseInt32(buffer, offset);
    offset += 4;
    const nearZone = parseFloat(buffer, offset);
    offset += 4;
    const dBrackD = parseFloat(buffer, offset);
    offset += 4;
    const dContrD = parseFloat(buffer, offset);
    offset += 4;
    const dTuneD = parseFloat(buffer, offset);
    offset += 4;
    const ardAvtoFlag = parseInt32(buffer, offset);
    offset += 4;
    const autoArd = parseInt32(buffer, offset);
    return {
        enable: enable,
        refLevel: refLevel,
        dBrack: dBrack,
        dContr: dContr,
        dSearch: dSearch,
        dTune: dTune,
        callDistans: callDistans,
        nearZone: nearZone,
        dBrackD: dBrackD,
        dContrD: dContrD,
        dTuneD: dTuneD,
        ardAvtoFlag: ardAvtoFlag,
        autoArd: autoArd
    }
}

function parseSignal(buffer: ArrayBuffer): Signal {
    const data = new Int16Array(buffer);
    return {
        data: Array.from(data)
    };
}

export function parseAwpUd2303Series(configBuffer: ArrayBuffer, signalBuffer: ArrayBuffer): AwpUd2303Series | null {
    if ((configBuffer.byteLength === CONFIG_SIZE || configBuffer.byteLength === NEW_CONFIG_SIZE) && signalBuffer.byteLength === CURVE_SIZE) {
        const probe = parseProbe(configBuffer, 0);
        const dac = parseDac(configBuffer, DAC_OFFSET);
        const ard = parseArd(configBuffer, ARD_OFFSET);
        const adc = parseAdc(configBuffer, ADC_OFFSET);
        const asd = parseAsd(configBuffer, ASD_OFFSET);
        const aws = parseAws(configBuffer, AWS_OFFSET);
        const strobe = parseStrobe(configBuffer, STROBE_OFFSET);
        const screen = parseScreen(configBuffer, SCREEN_OFFSET);
        const systemSet = parseSys(configBuffer, SYS_OFFSET, configBuffer.byteLength === NEW_CONFIG_SIZE);
        const signal = parseSignal(signalBuffer);
        return {
            probe: probe,
            dac: dac,
            ard: ard,
            adc: adc,
            asd: asd,
            aws: aws,
            strobe: strobe,
            screen: screen,
            systemSet: systemSet,
            signal: signal
        }
    } else {
        return null;
    }
}