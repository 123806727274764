export const MM = "MM";
export const INCH = "INCH";

export function convertValue(utScale: string, value: number){
    switch (utScale){
        case MM:
            return value;
        case INCH:
            return value / 25.4;
        default:
            return value;
    }
}

export function getMeasurementError(utScale: string){
    switch (utScale){
        case MM:
            return 0.05;
        case INCH:
            return 0.001;
        default:
            return 0.05;
    }
}