import {FetchDataWrapper} from "../../models/FetchData";
import {VerticalDivider} from "../Divider/VerticalDivider";
import {Error} from "../Error/Error";
import {Loader} from "../Loader/Loader";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import {NoPhotoPlaceholder} from "../NoPhotoPlaceholder/NoPhotoPlaceholder";
import {TFunction} from "i18next";
import {useMemo, useState} from "react";
import {formatDateTime} from "../../helpers/FormatHelper";
import {useLocale} from "../../hooks/LocaleHook";
import './AwpRecordPreview.css';
import IconCloseSvg from "../Icons/IconCloseSvg";
import {MultipleRecordsInfo} from "../MultipleRecordsInfo/MultipleRecordsInfo";
import {DATA_PLACEHOLDER} from "../../AppSettings";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA,
    AwpRecord,
    formatAwpProbeName
} from "../../helpers/AwpHelper";
import {useTranslation} from "react-i18next";

interface Parameter {
    name: string;
    value?: string;
}

interface Props {
    deviceType: number;
    showPreview: boolean;
    count: number;
    record: FetchDataWrapper<AwpRecord>;
    onClose: () => void;
    onDetailsClick: () => void;
}

export function AwpRecordPreview(props: Props) {
    const {t} = useTranslation();
    const locale = useLocale();
    const [photo, setPhoto] = useState(undefined as string | undefined | null);
    const params = useMemo(() => {
        const record = props.record.data;
        setPhoto(record?.photo);
        if (record) {
            return prepareData(t, locale, props.deviceType, record);
        } else {
            return new Array<Parameter>();
        }
    }, [t, locale, props.deviceType, props.record]);
    let imageScaleY = 1;
    if (props.deviceType === AWP_DEVICE_TUD){
        imageScaleY = -1;
    }
    return (
        <div
            className={`d-flex flex-row align-self-stretch overflow-hidden ${props.showPreview ? "container-record-preview-visible" : "container-record-preview-collapsed"}`}>
            <VerticalDivider className="me-2"/>
            <div className='d-flex flex-column flex-grow-1'>
                {props.count > 1 &&
                    <div
                        className="container-grow align-items-center justify-content-center m-2">
                        <MultipleRecordsInfo text={t("selected_elements_count_format", {count: props.count})}/>
                    </div>
                }
                {props.count === 0 && !props.record.data && !props.record.hasError && !props.record.isLoading &&
                    <div
                        className="container-grow align-items-center justify-content-center m-2">
                        <Error errorMessage={t("select_record")}/>
                    </div>
                }
                {props.count === 1 && props.record.hasError &&
                    <div
                        className="container-grow align-items-center justify-content-center m-2">
                        <Error errorMessage={t("select_record")}/>
                    </div>
                }
                {props.count === 1 && props.record.isLoading &&
                    <div
                        className="container-grow align-items-center justify-content-center m-2">
                        <Loader/>
                    </div>
                }
                {props.count === 1 && props.record.data &&
                    <div className="container-grow justify-content-center m-2 overflow-y-clip">
                        <div className="d-flex flex-row align-items-center">
                            <div className="record-preview-title flex-grow-1">{props.record.data.name}</div>
                            <div className="clickable ms-2 record-preview-close-button" onClick={props.onClose}>
                                <IconCloseSvg/></div>
                        </div>
                        <HorizontalDivider className="my-2"/>
                        <div className="container-grow overflow-y-auto">
                            <div className="mb-2 align-self-center">
                                {photo === null &&
                                    <Error errorMessage={t('photo_loading_error')}/>}
                                {photo !== null &&
                                    (photo ? <img className="w-100 h-100"
                                                  alt={props.record.data.name}
                                                  style={{objectFit: 'contain', transform: `scaleY(${imageScaleY})`}}
                                                  src={photo}
                                                  onError={() => setPhoto(null)}/> :
                                        <NoPhotoPlaceholder text={t("no_photo")}/>)
                                }
                            </div>
                            {params.map((p, i) =>
                                <div key={`preview-item-${i}`} className="record-preview-item">
                                    {`${p.name}: ${p.value ?? DATA_PLACEHOLDER}`}
                                </div>
                            )}
                            <div className="record-preview-details no-select clickable align-self-center mt-2" onClick={props.onDetailsClick}>{t('show_details')}</div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

function prepareData(t: TFunction<"translation">, locale: string, deviceType: number, record: AwpRecord) {
    switch (deviceType) {
        case AWP_DEVICE_TUD:
        case AWP_DEVICE_KRC:
            return [
                {name: t('date_save'), value: record.date ? formatDateTime(locale, record.date) : DATA_PLACEHOLDER},
                {name: t('operator'), value: record.operator},
                {name: t('object'), value: record.object},
                {name: t('device_id'), value: record.deviceSerial},
                {name: t('probe'), value: formatAwpProbeName(t, deviceType, record)},
                {name: t('probe_id'), value: record.probeSerial}
            ];
        case AWP_DEVICE_TP:
        case AWP_DEVICE_MF:
            return [
                {name: t('operator'), value: record.operator},
                {name: t('object'), value: record.object},
                {name: t('device_id'), value: record.deviceSerial},
                {name: t('probe'), value: formatAwpProbeName(t, deviceType, record)},
                {name: t('probe_id'), value: record.probeSerial}
            ];
        case AWP_DEVICE_UT:
            return [
                {name: t('operator'), value: record.operator},
                {name: t('object'), value: record.object},
                {name: t('device_id'), value: record.deviceSerial},
                {name: t('probe'), value: formatAwpProbeName(t, deviceType, record)}
            ];
        case AWP_DEVICE_UD2301:
            return [
                {name: t('date_save'), value: record.date ? formatDateTime(locale, record.date) : DATA_PLACEHOLDER},
                {name: t('operator'), value: record.operator},
                {name: t('object'), value: record.object},
                {name: t('device_id'), value: record.deviceSerial},
            ];
        case AWP_DEVICE_UD2303:
            return [
                {name: t('date_save'), value: record.date ? formatDateTime(locale, record.date) : DATA_PLACEHOLDER},
                {name: t('operator'), value: record.operator},
                {name: t('object'), value: record.object},
                {name: t('device_id'), value: record.deviceSerial},
                {name: t('probe'), value: formatAwpProbeName(t, deviceType, record)}
            ];
        case AWP_DEVICE_UD3701:
            return [
                {name: t('date_save'), value: record.date ? formatDateTime(locale, record.date) : DATA_PLACEHOLDER},
                {name: t('operator'), value: record.operator},
                {name: t('object'), value: record.object},
                {name: t('device_id'), value: record.deviceSerial},
            ];
        case AWP_DEVICE_UT3EMA:
        case AWP_DEVICE_UT2A:
            return [
                {name: t('date_save'), value: record.date ? formatDateTime(locale, record.date) : DATA_PLACEHOLDER},
                {name: t('operator'), value: record.operator},
                {name: t('object'), value: record.object},
                {name: t('device_id'), value: record.deviceSerial},
            ];
        case AWP_DEVICE_IPSM:
            return [
                {name: t('date_save'), value: record.date ? formatDateTime(locale, record.date) : DATA_PLACEHOLDER},
                {name: t('operator'), value: record.operator},
                {name: t('object'), value: record.object},
                {name: t('device_id'), value: record.deviceSerial},
            ];
    }
    return [];
}