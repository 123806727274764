import {IS_DEMO_APP} from "../AppSettings";

const REQUEST_URL = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks";

const SHORT_LINK_DOMAIN = "https://archive.novotest.biz/r";
const DEMO_SHORT_LINK_DOMAIN = "https://archive.novotest.biz/d";
const ANDROID_PACKAGE_NAME = "ua.novotest.bluetooth";
const ANDROID_MIN_VERSION_CODE = "9062";

const SOCIAL_MEDIA_IMAGE_URL = "https://www.novotest.info/logo192.png";

function getDevelopmentDomainName() {
    return IS_DEMO_APP ? "https://demo.novotest.info" : "https://novotest.info";
}

export function getDomainName() {
    return process.env.NODE_ENV === 'development' ? getDevelopmentDomainName() : window.location.href.replace(window.location.pathname, "");
}

export function getAndroidDynamicLink(url: string) {
    return `${SHORT_LINK_DOMAIN}/?link=${url}&apn=${ANDROID_PACKAGE_NAME}&amv=${ANDROID_MIN_VERSION_CODE}`;
}


export function getShortLink(url: string): Promise<string | null> {
    return Promise.resolve(url);
}