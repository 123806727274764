import {RecordWrapper} from "../../models/RecordWrapper";
import {formatDateTime, formatDeviceName} from "../../helpers/FormatHelper";
import IconArrowRightSvg from "../Icons/IconArrowRightSvg";
import {useTranslation} from "react-i18next";
import {useLocale} from "../../hooks/LocaleHook";

interface Props {
    item: RecordWrapper;
    clickListener: (id: string) => void;
}

export function MapInfoWindowListItem(props: Props) {
    const {t} = useTranslation();
    const locale = useLocale();
    return (
        <div className="map-info-window-list-item" onClick={() => props.clickListener(props.item.id)}>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="flex-grow-1 mx-1">
                    <div className="my-1 font-weight-bold">{props.item.record.name}</div>
                    <div>{formatDeviceName(t, props.item.record.deviceType)}</div>
                    <div className="mb-1">{formatDateTime(locale, new Date(props.item.record.dateTime))}</div>
                </div>
                <div className="map-info-window-list-item-icon mx-1">
                    <IconArrowRightSvg/>
                </div>
            </div>
        </div>
    );
}