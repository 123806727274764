import {SerialDevice} from "./SerialDevice";
import {awpDataStreamingDecode} from "../components/AwpDataStreaming/AwpDataStreaminDecoder";

export class AwpDataStreamingSerialDevice {

    static readonly defaultBaudRate = 921600;
    static readonly filters: SerialPortFilter[] = [
        {
            usbVendorId: 0x10C4,
            usbProductId: 0xEA60
        },
        {
            usbVendorId: 0x0403,
            usbProductId: 0x6001
        },
        {
            usbVendorId: 0x0403,
            usbProductId: 0x0000
        }
    ];

    private static readonly connectCommand = "measurement start\r\n";
    private static readonly disconnectCommand = "measurement stop\r\n";

    private readonly port: SerialDevice;
    private abortCallback?: () => void;

    private readonly encoder = new TextEncoder();

    constructor(port: SerialDevice) {
        this.port = port;
    }

    readData(readingStateCallback: (isReading?: boolean) => void, dataReceivedCallback: (data: string) => void): Promise<boolean | undefined> {
        return this.port.open().then((isOpen) => {
            if (!isOpen) {
                return undefined;
            }
            readingStateCallback(true);
            return new Promise<boolean>(async (resolve, reject) => {
                this.port.addEventListener("disconnect", () => {
                    resolve(false);
                });
                this.abortCallback = () => resolve(true);
                const decoder = new TextDecoder("windows-1251");
                let localBuffer = "";
                let isFirstError = true;
                this.port.listen(async data => {
                    if (data) {
                        try {
                            const message = decoder.decode(data);
                            localBuffer += message;
                            const modeIndex = localBuffer.toLowerCase().indexOf("need to activate mode");
                            if (modeIndex !== -1) {
                                dataReceivedCallback(localBuffer.substr(modeIndex, "need to activate mode".length));
                                localBuffer = localBuffer.substr(modeIndex + "need to activate mode".length);
                            }
                            const errorIndex = localBuffer.toLowerCase().indexOf("error command");
                            if (errorIndex !== -1) {
                                if (isFirstError) {
                                    isFirstError = false;
                                    await this.port.writeTextAsync(this.encoder, AwpDataStreamingSerialDevice.connectCommand);
                                } else {
                                    dataReceivedCallback(localBuffer.substr(errorIndex, "error command".length));
                                }
                                localBuffer = localBuffer.substr(errorIndex + "error command".length);
                            }
                            let decodeBuffer = awpDataStreamingDecode(localBuffer);
                            let index = decodeBuffer.indexOf("\r\n");
                            while (index !== -1) {
                                dataReceivedCallback(decodeBuffer.substr(0, index));
                                localBuffer = localBuffer.substr(index + 2);
                                decodeBuffer = decodeBuffer.substr(index + 2);
                                index = decodeBuffer.indexOf("\r\n");
                            }
                        } catch (e) {
                            reject(e);
                        }
                    } else {
                        reject("Data read error");
                    }
                });
                await this.port.writeTextAsync(this.encoder, AwpDataStreamingSerialDevice.connectCommand);
            }).then(async isSuccess => {
                readingStateCallback(isSuccess ? undefined : false);
                await this.close();
                return isSuccess;
            }).catch(async reason => {
                readingStateCallback(false);
                await this.close();
                throw new Error(reason);
            });
        });
    }

    abort() {
        if (this.abortCallback){
            this.abortCallback();
        }
        this.abortCallback = undefined;
    }


    private async close(){
        await this.port.writeTextAsync(this.encoder, AwpDataStreamingSerialDevice.disconnectCommand);
        this.port.close();
    }
}
