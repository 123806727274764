import {MediaRecord} from "../../models/MediaRecord";
import {useEffect, useState} from "react";
import {getMediaFile} from "../../helpers/GoogleStorageApiHelper";
import {Error} from "../Error/Error";
import './PhotoViewer.css';
import {MeasurementMarker, MeasurementsWithPhoto} from "./MeasurementsWithPhoto";
import {LoaderSmall} from "../Loader/LoaderSmall";
import {ResourceNotFoundError} from "../../errors/GoogleDriveErrors";
import {NotFoundSmall} from "../NotFound/NotFoundSmall";
import {ErrorSmall} from "../Error/ErrorSmall";
import {useTranslation} from "react-i18next";
import {formatNumber} from "../../helpers/FormatHelper";
import {DpmMeasurement} from "../../models/DpmMeasurement";

interface Props {
    drive: any;
    recordId: string;
    mediaRecord: MediaRecord;
    measurement: DpmMeasurement[];
    markerClickListener?: (measurement: DpmMeasurement) => void;
    isFullScreen: boolean;
    imageLoadListener?: () => void;
}

export function DpmPhotoViewer(props: Props) {
    const {t} = useTranslation();
    const [mediaUrl, setMediaUrl] = useState(null as string | null);
    const [error, setError] = useState(null as Error | null);
    const [retry, setRetry] = useState(0);
    useEffect(() => {
        getMediaFile(props.drive, props.recordId, props.mediaRecord.fileName).then(url => {
            setMediaUrl(url);
            setError(null);
        }).catch((e) => setError(e));
    }, [props.drive, props.recordId, props.mediaRecord, retry]);
    if (error) {
        return error instanceof ResourceNotFoundError ? <NotFoundSmall/> :
            <ErrorSmall error={error} retryClickHandler={() => setRetry(retry + 1)}/>
    }
    const markers = new Array<MeasurementMarker>();
    props.measurement.forEach(m => {
        if (m.location && m.measurements.length > 0) {
            const measurement = m.measurements[m.measurements.length - 1];
            markers.push({
                text: t("dpm_marker_format",
                    {
                        humidity: formatNumber(measurement.humidity, 1, 1),
                        surfaceTemperature: formatNumber(measurement.tempSurface, 1, 1),
                        airTemperature: formatNumber(measurement.tempAir, 1, 1),
                        dewPoint: formatNumber(measurement.devPoint, 1, 1),
                        difference: formatNumber(measurement.devSurface, 1, 1),
                    }),
                location: m.location
            } as MeasurementMarker);
        }
    });
    const handleClick = props.markerClickListener ? (idx: number) => props.markerClickListener!(props.measurement[idx]) : undefined;
    return (
        <div className="d-flex flex-column flex-grow-1">
            {!mediaUrl ? <LoaderSmall/> : <MeasurementsWithPhoto key={`ph-${retry}`} url={mediaUrl} markers={markers}
                                                                 markerClickListener={handleClick}
                                                                 retryClickListener={() => setRetry(retry + 1)}
                                                                 isFullScreen={props.isFullScreen}
                                                                 imageLoadListener={props.imageLoadListener}/>}
        </div>
    );
}