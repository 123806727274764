import {useEffect, useState} from "react";

export function useAbortStatus(){
    const [isAborted, setAbortStatus] = useState(false);
    useEffect(() => {
        return () => {
            setAbortStatus(true);
        }
    }, [setAbortStatus]);
    return isAborted;
}