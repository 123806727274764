import {ArchiveItem} from "../models/ArchiveItem";
import {GoogleDriveError, ResourceNotFoundError} from "../errors/GoogleDriveErrors";
import {RecordWrapper} from "../models/RecordWrapper";
import {TpRecord} from "../models/TpRecord";
import {TudRecord} from "../models/TudRecord";
import {UtRecord} from "../models/UtRecord";
import {getDownloadURL, listAll, ref, StorageReference} from "firebase/storage";
import {BaseRecord} from "../models/BaseRecord";
import * as DeviceType from "../models/DeviceType";
import {firebaseStorage} from "../index";
import {isValidUciRecord, UciRecord} from "../models/UciRecord";
import {UciRecordValidationError} from "../errors/RecordValidationError";
import {MfRecord} from "../models/MfRecord";
import {LeebRecord} from "../models/LeebRecord";
import {DpmRecord} from "../models/DpmRecord";

const ROOT_FOLDER_NAME = "DEMO Archive";
const RECORD_FOLDER_NAME_SUFFIX = ".record";
const RECORD_DATA_FILE_NAME = "data.json";
const MAX_CONCURRENT_REQUESTS = 50;
const CONCURRENT_REQUESTS_DELAY_MS = 250;

const DEFAULT_DATE = new Date(Date.UTC(2021, 9, 1, 12, 0, 0));

export class GoogleCloudStorageApiHelper {

    static getArchiveRootFolder(): Promise<ArchiveItem> {
        const rootRef = ref(firebaseStorage, ROOT_FOLDER_NAME);
        return Promise.resolve(this.makeArchiveItemWithoutIndexing(rootRef));
    }

    static getArchiveItems(where: string | string[], shouldIndexRecords: boolean): Promise<Array<ArchiveItem>> {
        return this.findItems(where).then(files => {
            if (files) {
                return this.makeArchiveItems(files, shouldIndexRecords);
            }
            throw new GoogleDriveError();
        });
    }

    private static makeArchiveItems(files: StorageReference[], shouldIndexRecords: boolean): Promise<Array<ArchiveItem>> {
        const archiveItems = files.map(f => this.makeArchiveItemWithoutIndexing(f)).filter(i => i != null).map(i => i as ArchiveItem);
        if (shouldIndexRecords) {
            const recordFolderIds = archiveItems.filter(i => !i.isFolder).map(i => i.id);
            return this.indexRecordsByRecordIds(recordFolderIds).then(recordWrappers => {
                const result = new Array<ArchiveItem>();
                result.push(...archiveItems.filter(i => i.isFolder));
                const records = archiveItems.filter(i => !i.isFolder);
                records.forEach(r => {
                    const rw = recordWrappers.find(rw => rw.id === r.id);
                    if (rw) {
                        r.lastChanged = new Date(rw.record.dateTime);
                    }
                })
                result.push(...records);
                return result;
            })
        } else {
            return Promise.resolve(archiveItems);
        }
    }

    private static indexRecordsByRecordIds(recordIds: Array<string>): Promise<Array<RecordWrapper>> {
        return this.findRecords(recordIds).then(responseFiles => {
            const recordsData: Array<[string, string]> = responseFiles.map(f => [f.parent?.fullPath ?? "", f.fullPath]);
            return this.indexRecordsByRecordFileIds(recordsData);
        });
    }

    private static indexRecordsByRecordFileIds(recordsData: Array<[recordId: string, fileId: string]>, results?: Array<RecordWrapper>): Promise<Array<RecordWrapper>> {
        const result = results ?? new Array<RecordWrapper>();
        if (recordsData.length === 0) {
            return Promise.resolve(result);
        } else {
            const portion = recordsData.splice(0, MAX_CONCURRENT_REQUESTS);
            const promises = portion.map(([recordId, fileId]) => this.indexRecordByRecordFileId(recordId, fileId));
            return (results ? this.sleep(CONCURRENT_REQUESTS_DELAY_MS) : Promise.resolve()).then(() => Promise.all(promises).then(results => {
                const localResult = new Array<RecordWrapper>();
                for (const record of results) {
                    if (record) {
                        localResult.push(record);
                    }
                }
                return result.concat(localResult);
            })).then(results => this.indexRecordsByRecordFileIds(recordsData, results));
        }
    }

    private static indexRecordByRecordFileId(recordId: string, recordFileId: string): Promise<RecordWrapper | null> {
        return getDownloadURL(ref(firebaseStorage, recordFileId))
            .then(url => fetch(url))
            .then(response => {
                if (response.ok) {
                    return response.text().then(json => {
                        const baseRecord = JSON.parse(json.replaceAll("NaN", "0")) as BaseRecord;
                        return {
                            id: recordId,
                            record: baseRecord
                        } as RecordWrapper;
                    }).catch(() => null);
                }
                throw new ResourceNotFoundError();
            });
    }

    private static findRecords(recordIds: Array<string>): Promise<Array<StorageReference>> {
        return Promise.resolve(recordIds.map(r => ref(firebaseStorage, `${r}/${RECORD_DATA_FILE_NAME}`)));
    }

    private static findItems(where: string | string[]): Promise<Array<StorageReference> | undefined> {
        if (where instanceof Array && where.length === 0) {
            return Promise.resolve([]);
        }
        let parents;
        if (where instanceof Array) {
            parents = where;
        } else {
            parents = [where as string];
        }
        return Promise.all(parents.map(p => listAll(ref(firebaseStorage, p)))).then(r => {
            const files = new Array<StorageReference>();
            r.forEach(f => files.push(...f.prefixes));
            return files;
        }).catch(() => {
            return undefined;
        });
    }

    static getPath(id: string): Promise<ArchiveItem[]> {
        const path = new Array<ArchiveItem>();
        const initialLocation = id;
        let locationRef = ref(firebaseStorage, initialLocation) as StorageReference | null;
        while (locationRef?.name !== ROOT_FOLDER_NAME) {
            if (locationRef) {
                path.unshift(this.makeArchiveItemWithoutIndexing(locationRef));
                locationRef = locationRef.parent;
            } else {
                return Promise.resolve([]);
            }
        }
        if (locationRef) {
            path.unshift(this.makeArchiveItemWithoutIndexing(locationRef));
        }
        return Promise.resolve(path);
    }

    static indexRecords(): Promise<Array<RecordWrapper>> {
        return this.getArchiveRootFolder()
            .then(item => this.findRecordsInFolder(item.id))
            .then(ids => this.indexRecordsByRecordIds(ids));
    }

    private static findRecordsInFolder(id: string | string[]): Promise<Array<string>> {
        return this.getArchiveItems(id, false).then(items => {
            const folders = items.filter(i => i.isFolder).map(i => i.id);
            if (folders.length > 0) {
                return this.findRecordsInFolder(folders).then(results => {
                    const result = new Array<string>();
                    result.push(...items.filter(i => !i.isFolder).map(i => i.id));
                    result.push(...results);
                    return result;
                });
            } else {
                return Promise.resolve(items.filter(i => !i.isFolder).map(i => i.id));
            }
        });
    }

    static getRecord(id: string): Promise<TpRecord | TudRecord | LeebRecord | UciRecord | UtRecord | MfRecord | DpmRecord> {
        const recordRef = ref(firebaseStorage, `${id}/${RECORD_DATA_FILE_NAME}`)
        return getDownloadURL(recordRef)
            .then(url => fetch(url))
            .then(response => {
                if (response.ok) {
                    return response.text().then(json => {
                        const baseRecord = JSON.parse(json.replaceAll("NaN", "0")) as BaseRecord;
                        switch (baseRecord.deviceType) {
                            case DeviceType.TUD2:
                            case DeviceType.TUD3:
                                return baseRecord as TudRecord;
                            case DeviceType.LEEB:
                                return baseRecord as LeebRecord;
                            case DeviceType.UCI:
                                const uciRecord = baseRecord as UciRecord;
                                if (isValidUciRecord(uciRecord)) {
                                    return uciRecord;
                                } else {
                                    throw new UciRecordValidationError();
                                }
                            case DeviceType.TP1M:
                                return baseRecord as TpRecord;
                            case DeviceType.UT1M:
                            case DeviceType.UT1M_IP:
                            case DeviceType.UT1M_CT:
                                return baseRecord as UtRecord;
                            case DeviceType.MF1M:
                                return baseRecord as MfRecord;
                            case DeviceType.DPM:
                                return baseRecord as DpmRecord;
                        }
                        throw new ResourceNotFoundError();
                    });
                }
                throw new ResourceNotFoundError();
            });
    }

    static getMediaRecordUrl(recordId: string, name: string): Promise<string> {
        const recordRef = ref(firebaseStorage, `${recordId}/${name}`);
        return getDownloadURL(recordRef)
    }

    static getMediaFile(id: string, name: string): Promise<string> {
        const recordRef = ref(firebaseStorage, `${id}/${name}`)
        return getDownloadURL(recordRef)
            .then(url => fetch(url))
            .then(response => {
                if (response.ok) {
                    return response.blob();
                }
                throw new ResourceNotFoundError();
            }).then(blob => {
                return URL.createObjectURL(blob)
            })
    }

    static shareRecord(recordId: string, attempt?: number): Promise<boolean> {
        return Promise.resolve(true);
    }

    private static makeArchiveItemWithoutIndexing(file: StorageReference): ArchiveItem {
        let isFolder = !file.name.endsWith(RECORD_FOLDER_NAME_SUFFIX);
        const name = isFolder ? file.name : file.name.substr(0, file.name.length - RECORD_FOLDER_NAME_SUFFIX.length);
        return {
            id: file.fullPath,
            name: name,
            isFolder: isFolder,
            lastChanged: DEFAULT_DATE
        } as ArchiveItem;
    }

    private static sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

}