import {useTranslation} from "react-i18next";
import {Button, Dropdown, DropdownButton, Form, ProgressBar} from "react-bootstrap";
import "./AwpFwLoader.css"
import {useAwpFwSerialDevice} from "../../serial/SerialPortHook";
import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {AwpFwSerialDevice} from "../../serial/AwpFwSerialDevice";
import {CONFIG, GSP, GSPS, NVP, NVT, PICS, ZIP} from "../../models/AwpFwFileType";
import {FwSelectDialog} from "./FwSelectDialog";
import {ZipFileSelectDialog} from "./ZipFileSelectDialog";
import {AwpDeviceInfo} from "../../models/AwpDeviceInfo";
import {AwpFwInfo} from "../../models/AwpFwInfo";
import {parseGspInfo} from "../../models/AwpGspFwInfo";
import {formatAwpFwDeviceName} from "../../helpers/AwpFwFormatHelper";
import {FwAttentionDialog} from "./FwAttentionDialog";
import {FwSearchDialog} from "./FwSearchDialog";
import {RequestAssistDialog} from "./RequestAssistDialog";
import {CancelAssistDialog} from "./CancelAssistDialog";
import mqtt from "mqtt";
import {logError, logger} from "../../helpers/LogHelper";
import {VerticalDivider} from "../Divider/VerticalDivider";
import {
    MQTT_BROKER_PASSWORD,
    MQTT_BROKER_URL,
    MQTT_BROKER_USERNAME,
    MQTT_PING_SEND_INTERVAL_MS,
    MQTT_PING_TIMEOUT_MS,
    MQTT_TOPIC_CLIENT,
    MQTT_TOPIC_SUPPORT
} from "../../AppSettings";
import {
    formatMqttState,
    STATE_AWAITING_SUPPORT,
    STATE_CONNECTING,
    STATE_ERROR,
    STATE_SUPPORT_DISCONNECTED,
    STATE_SUPPORT_ONLINE
} from "../../mqtt/MqttState";
import {
    MqttTextMessage,
    parseMqttMessage,
    sendAutoDetectStateMessage,
    sendChatMessage,
    sendClientFwData,
    sendClientStateMessage,
    sendDeviceInfoMessage,
    sendFwDataReceiveConfirmationMessage,
    sendFwDataRepeatRequestMessage,
    sendFwWriteProgressMessage,
    sendLowBaudRateStateMessage,
    sendPingMessage,
    sendPortEnumerationData,
    sendPortStateMessage,
    sendSupportDisconnectedMessage,
    sendTerminalMessage
} from "../../mqtt/MqttHelper";
import {
    MQTT_MESSAGE_AUTODETECT_STATE,
    MQTT_MESSAGE_CHAT,
    MQTT_MESSAGE_CONNECT_COM_PORT,
    MQTT_MESSAGE_DISCONNECT_COM_PORT,
    MQTT_MESSAGE_ENUMERATE_COM_PORTS,
    MQTT_MESSAGE_FW_DATA_BINARY,
    MQTT_MESSAGE_FW_DATA_DONE,
    MQTT_MESSAGE_FW_DATA_INFO,
    MQTT_MESSAGE_LOW_BAUD_RATE_STATE,
    MQTT_MESSAGE_START_WRITE_FW,
    MQTT_MESSAGE_STOP_WRITE_FW,
    MQTT_MESSAGE_SUPPORT_CONNECTED,
    MQTT_MESSAGE_SUPPORT_DISCONNECTED,
    MQTT_MESSAGE_TERMINAL
} from "../../mqtt/MqttMessage";
import {
    checkFwCompatibility,
    CONNECTION_STATE_AUTO_DETECTION,
    CONNECTION_STATE_CONNECTED,
    CONNECTION_STATE_CONNECTING,
    CONNECTION_STATE_NOT_CONNECTED,
    DeviceReadyConfirmationData,
    formatConnectionState,
    FwLoaderClientFirmwareData,
    FwLoaderFirmwareData,
    FwLoaderFirmwareSubFileData,
    FwLoaderRemoteFirmwareInfo,
    FwLoaderState,
    FwLoaderStatusMessage,
    FwWriteRequest
} from "./AwpFwLoaderCommon";
import {FwRemoteWriteConfirmationDialog} from "./FwRemoteWriteConfirmationDialog";
import {HelpButton} from "../HelpButton/HelpButton";
import {DeviceReadyConfirmationDialog} from "./DeviceReadyConfirmationDialog";
import JSZip from "jszip";
import {delay} from "../../helpers/Utils";
import {formatServiceMode, getServiceMode, SERVICE_MODE_DISABLED} from "../../helpers/ServiceModeHelper";
import {ActivationCodeDialog} from "../ActivationCodeDialog/ActivationCodeDialog";
import {SerialPortOpenErrorDialog} from "../SerialPortOpenErrorDialog/SerialPortOpenErrorDialog";
import {useParams} from "react-router-dom";
import {FwPrepareDialog} from "./FwPrepareDialog";

interface PathParams {
    fw?: string;
}

interface RemoteFwDataChunk {
    index: number;
    data: Buffer;
}

function browseZipFile(zipFile: ArrayBuffer): Promise<Array<string> | null> {
    return new Promise<Array<string> | null>(async (resolve) => {
        try {
            const zip = new JSZip();
            const zipData = await zip.loadAsync(zipFile);
            const fileNames: string[] = [];
            zipData.forEach((relativePath, file) => {
                if (!relativePath.endsWith("/")) {
                    fileNames.push(relativePath);
                }
            });
            resolve(fileNames);
        } catch {
            resolve(null);
        }
    });
}

function extractFile(zipFile: ArrayBuffer, fileName: string): Promise<ArrayBuffer | null> {
    return new Promise<ArrayBuffer | null>(async (resolve) => {
        try {
            const zip = new JSZip();
            const zipData = await zip.loadAsync(zipFile);
            const fileData = await zipData.files[fileName].async("arraybuffer");
            resolve(fileData);
        } catch {
            resolve(null);
        }
    });
}

export function AwpFwLoader() {
    const {t} = useTranslation();
    const path = useParams<keyof PathParams>() as PathParams;
    const [assistantCode, setAssistantCode] = useState(undefined as string | undefined);
    const [assistantState, setAssistantState] = useState(undefined as number | undefined);
    const [mqttClient, setMqttClient] = useState(undefined as mqtt.MqttClient | undefined);
    const [status, setStatus] = useState({text: ""} as FwLoaderStatusMessage)
    const [progress, setProgress] = useState(undefined as number | undefined);
    const [showSerialPortOpenErrorDialog, setShowSerialPortOpenErrorDialog] = useState(false);
    const [showFwSelectDialog, setShowFwSelectDialog] = useState(0);
    const [showFindFwDialog, setShowFindFwDialog] = useState(0);
    const [showFwPrepareDialog, setShowFwPrepareDialog] = useState(0);
    const [deviceReadyConfirmationDialogData, setDeviceReadyConfirmationDialogData] = useState(undefined as DeviceReadyConfirmationData | undefined);
    const [showZipFileSelectDialog, setShowZipFileSelectDialog] = useState(0);
    const [attentionDialogMessage, setAttentionDialogMessage] = useState(undefined as string | undefined);
    const [showRequestAssistantDialog, setShowRequestAssistantDialog] = useState(false);
    const [showCancelAssistantDialog, setShowCancelAssistantDialog] = useState(false);
    const [showFwRemoteWriteConfirmationDialog, setShowFwRemoteWriteConfirmationDialog] = useState(undefined as FwWriteRequest | undefined)
    const [showActivationCodeDialog, setShowActivationCodeDialog] = useState(0);
    const [autoDetect, setAutoDetect] = useState(true);
    const [slowDevice, setSlowDevice] = useState(false);
    const baudRate = useMemo(() => {
        return slowDevice ? AwpFwSerialDevice.speedOldLoader : AwpFwSerialDevice.speed921600;
    }, [slowDevice]);
    const [reconnectToken, setReconnectToken] = useState(0);
    const device = useAwpFwSerialDevice(baudRate, reconnectToken);
    const [serialDevice, setSerialDevice] = useState(null as AwpFwSerialDevice | null | undefined);
    const [autoDetectCancel, setAutoDetectCancel] = useState<(() => void) | undefined>(undefined);
    const [deviceInfo, setDeviceInfo] = useState(undefined as AwpDeviceInfo | null | undefined);
    const [remoteFwInfo, setRemoteFwInfo] = useState(undefined as AwpFwInfo | undefined);
    const [remoteFwFileName, setRemoteFwFileName] = useState(undefined as string | undefined);
    const [remoteFwFileType, setRemoteFwFileType] = useState(undefined as number | undefined);
    const [remoteFwData, setRemoteFwData] = useState(undefined as Array<Buffer | undefined> | undefined);
    const [remoteFwDataTransferState, setRemoteFwTransferState] = useState(false);
    const [clientState, setClientState] = useState(undefined as FwLoaderState | undefined);
    const [terminalString, setTerminalString] = useState(undefined as string | undefined);
    const [chatString, setChatString] = useState(undefined as string | undefined);
    const [portEnumerationData, setPortEnumerationData] = useState(undefined as Array<SerialPortInfo> | undefined);
    const [portConnectionRequest, setPortConnectionRequest] = useState(undefined as SerialPortInfo | undefined);
    const [portDisconnectionRequest, setPortDisconnectionRequest] = useState(false);
    const [checkRemoteFwDataRequest, setCheckRemoteDataRequest] = useState(false);
    const [receiveRemoteFwDataChunkRequest, setReceiveRemoteFwDataChunkRequest] = useState(undefined as RemoteFwDataChunk | undefined);
    const [writeFwRequest, setWriteFwRequest] = useState(undefined as FwWriteRequest | undefined);
    const [stopWriteFwRequest, setStopWriteFwRequest] = useState(false);
    useEffect(() => {
        if (path.fw) {
            toggleFwPrepareDialog();
        }
    }, [path.fw]);
    const serviceMode = useMemo(() => {
        return getServiceMode();
    }, [])
    const portState = useMemo(() => {
        let state;
        if (serialDevice) {
            if (autoDetect && deviceInfo === undefined) {
                state = CONNECTION_STATE_AUTO_DETECTION;
            } else {
                state = CONNECTION_STATE_CONNECTED;
            }
        } else {
            if (serialDevice === null) {
                state = CONNECTION_STATE_NOT_CONNECTED;
            } else {
                state = CONNECTION_STATE_CONNECTING;
            }
        }
        if (mqttClient && assistantCode) {
            sendPortStateMessage(mqttClient, assistantCode, MQTT_TOPIC_CLIENT, state);
        }
        return state;
    }, [serialDevice, mqttClient, assistantCode, autoDetect, deviceInfo]);
    const [fwData, setFwData] = useState(undefined as FwLoaderFirmwareData | undefined);
    const fwName = useMemo(() => {
        return fwData?.name ?? t("loader_fw_not_selected");
    }, [fwData?.name, t]);
    const terminalInputRef = useRef<HTMLInputElement>(null);
    const sendTerminalString = (data?: string) => {
        let text = data;
        if (!text && terminalInputRef.current) {
            text = data ?? terminalInputRef.current.value;
            terminalInputRef.current.value = "";
        }
        if (text && text.length > 0 && serialDevice) {
            const encoder = new TextEncoder();
            serialDevice.writeText(encoder, text + "\r\n", baudRate);
        }
    };
    const terminalOutputRef = useRef<HTMLTextAreaElement>(null);
    const [isTerminalOutputOnBottom, setTerminalOutputOnBottom] = useState(true);
    const terminalOutputOnScroll = (e: any) => {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 10) {
            setTerminalOutputOnBottom(true);
        } else {
            setTerminalOutputOnBottom(false);
        }
    };
    const [terminalText, setTerminalText] = useState("");
    useEffect(() => {
        if (terminalOutputRef.current) {
            if (isTerminalOutputOnBottom) {
                terminalOutputRef.current.scrollTop = terminalOutputRef.current.scrollHeight;
            }
        }
    }, [terminalText]);
    const chatInputRef = useRef<HTMLInputElement>(null);
    const sendChatString = () => {
        if (chatInputRef.current) {
            const text = chatInputRef.current.value;
            if (text && text.length > 0) {
                if (mqttClient && assistantCode) {
                    sendChatMessage(mqttClient, assistantCode, MQTT_TOPIC_CLIENT, text + "\n");
                    setChatText(prevState => prevState + ">>> " + text + "\n");
                }
                chatInputRef.current.value = "";
            }
        }
    };
    const chatOutputRef = useRef<HTMLTextAreaElement>(null);
    const [isChatOutputOnBottom, setChatOutputOnBottom] = useState(true);
    const chatOutputOnScroll = (e: any) => {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 10) {
            setChatOutputOnBottom(true);
        } else {
            setChatOutputOnBottom(false);
        }
    };
    const [chatText, setChatText] = useState("");
    useEffect(() => {
        if (chatOutputRef.current) {
            if (isChatOutputOnBottom) {
                chatOutputRef.current.scrollTop = chatOutputRef.current.scrollHeight;
            }
        }
    }, [chatText]);
    const releaseConnection = () => {
        if (autoDetectCancel) {
            autoDetectCancel();
            setAutoDetectCancel(undefined);
        }
        setSerialDevice(null);
        setReconnectToken(t => t + 1);
    }
    const disconnectListener = useCallback(() => {
        if (serialDevice && serialDevice.executeDisconnectCallback()) {
            if (serialDevice) {
                serialDevice.removeDisconnectListener(disconnectListener);
                serialDevice.setExternalListener(undefined, undefined);
            }
            releaseConnection();
        }
    }, [serialDevice]);
    useEffect(() => {
        if (serialDevice) {
            serialDevice.addDisconnectListener(disconnectListener);
        }
    }, [serialDevice, disconnectListener]);
    useEffect(() => {
        serialDevice?.setExternalListener(text => {
                setTerminalText(prevText => {
                    if (mqttClient && assistantCode) {
                        sendTerminalMessage(mqttClient, assistantCode, MQTT_TOPIC_CLIENT, text);
                    }
                    return prevText + text;
                });
            },
            text => setTerminalText(prevText => {
                let newText = "";
                if (prevText.length > 0) {
                    newText += "\r\n";
                }
                newText = newText + ">>> " + text + "\r\n";
                if (mqttClient && assistantCode) {
                    sendTerminalMessage(mqttClient, assistantCode, MQTT_TOPIC_CLIENT, newText);
                }
                return prevText + newText;
            }));
    }, [assistantCode, mqttClient, serialDevice]);
    const updateDeviceInfo = (info: AwpDeviceInfo | undefined | null) => {
        setDeviceInfo(info);
        if (mqttClient && assistantCode) {
            sendDeviceInfoMessage(mqttClient, assistantCode, MQTT_TOPIC_CLIENT, info);
        }
    };
    const connect = async (portInfo: SerialPortInfo | undefined = undefined) => {
        if (!serialDevice && device) {
            setSerialDevice(undefined);
            updateDeviceInfo(undefined);
            const result = await device.open(portInfo).catch((e) => {
                logger.log(`Serial port open error: ${e}`);
                return false;
            });
            if (result) {
                if (autoDetect) {
                    const [cancellationToken, promise] = device.requestDeviceData(3);
                    setAutoDetectCancel(() => cancellationToken);
                    promise.then((res) => {
                        if (res === null) {
                            updateDeviceInfo(null);
                        } else {
                            const [id, type, hw, sw, bootloader, slow] = res;
                            updateDeviceInfo({
                                displayId: id,
                                deviceTypeId: type,
                                hwVersion: hw,
                                swVersion: sw,
                                bootloader: bootloader
                            } as AwpDeviceInfo);
                            if (slow !== undefined) {
                                toggleSlowDevice(slow);
                            }
                        }
                    });
                }
                setSerialDevice(device);
            } else {
                releaseConnection();
                setShowSerialPortOpenErrorDialog(true);
            }
        }
    };
    const disconnect = () => {
        if (serialDevice) {
            serialDevice.close();
            releaseConnection();
        }
    };
    const refreshDeviceInfo = () => {
        if (device) {
            updateDeviceInfo(undefined);
            device.waitForData(["Start", "Init", "BOOT"], 60000, 500).then(async result => {
                if (result) {
                    await delay(5000);
                    const [, promise] = device.requestDeviceData(10, slowDevice);
                    const res = await promise;
                    if (res === null) {
                        updateDeviceInfo(null);
                    } else {
                        const [id, type, hw, sw, bootloader, slow] = res;
                        updateDeviceInfo({
                            displayId: id,
                            deviceTypeId: type,
                            hwVersion: hw,
                            swVersion: sw,
                            bootloader: bootloader
                        } as AwpDeviceInfo);
                    }
                }
            })
        }
    };
    const toggleConnection = async () => {
        if (serialDevice) {
            disconnect();
        } else {
            await connect();
        }
    };
    const toggleAutoDetect = (state: boolean) => {
        if (mqttClient && assistantCode) {
            sendAutoDetectStateMessage(mqttClient, assistantCode, MQTT_TOPIC_CLIENT, state);
        }
        setAutoDetect(state);
    };
    const toggleSlowDevice = (state: boolean) => {
        if (mqttClient && assistantCode) {
            sendLowBaudRateStateMessage(mqttClient, assistantCode, MQTT_TOPIC_CLIENT, state);
        }
        setSlowDevice(state)
    };
    const handleFwData = (fwInfo: AwpFwInfo | undefined, name: string, type: number, content: ArrayBuffer) => {
        if (type === ZIP) {
            const promise = new Promise(async () => {
                const fileNames = await browseZipFile(content);
                const subFiles = new Array<FwLoaderFirmwareSubFileData>();
                if (fileNames !== null) {
                    for (let fileName of fileNames) {
                        let fileType;
                        if (fileName.endsWith(".gsp")) {
                            fileType = GSP;
                        } else if (fileName.endsWith(".gsps")) {
                            fileType = GSPS;
                        } else if (fileName.endsWith(".nvt")) {
                            fileType = NVT;
                        } else if (fileName.endsWith(".bin")) {
                            fileType = PICS;
                        } else if (fileName.endsWith(".nvp")) {
                            fileType = NVP;
                        } else if (fileName.endsWith(".ncf")) {
                            fileType = CONFIG;
                        }
                        if (fileType) {
                            const fileContent = await extractFile(content, fileName);
                            if (fileContent) {
                                subFiles.push({
                                    name: fileName,
                                    type: fileType,
                                    content: fileContent
                                })
                            }
                        }
                    }
                }
                setFwData({
                    info: fwInfo,
                    name: name,
                    type: type,
                    content: content,
                    subFiles: subFiles
                });
                if (mqttClient && assistantCode) {
                    const clientFwData = {
                        info: fwInfo,
                        name: name,
                        type: type,
                        subFiles: subFiles
                    }
                    sendClientFwData(mqttClient, assistantCode, MQTT_TOPIC_CLIENT, clientFwData);
                }
            });
            promise.then();
        } else {
            setFwData({
                info: fwInfo,
                name: name,
                type: type,
                content: content,
                subFiles: undefined
            });
            if (mqttClient && assistantCode) {
                const clientFwData = {
                    info: fwInfo,
                    name: name,
                    type: type,
                    subFiles: undefined
                }
                sendClientFwData(mqttClient, assistantCode, MQTT_TOPIC_CLIENT, clientFwData);
            }
        }
    };
    const selectLocalFile = async () => {
        const pickerOptions = {
            types: [
                {
                    description: 'Novotest New FW',
                    accept: {
                        'application/nvt': ['.gsp', '.gsps']
                    }
                },
                {
                    description: 'Novotest FW',
                    accept: {
                        'application/nvt': ['.nvt']
                    }
                },
                {
                    description: 'Novotest PICS',
                    accept: {
                        'application/nvt': ['.bin']
                    }
                },
                {
                    description: 'Novotest Full',
                    accept: {
                        'application/nvt': ['.nvp']
                    }
                },
                {
                    description: 'Novotest Config',
                    accept: {
                        'application/nvt': ['.ncf']
                    }
                }
            ],
            excludeAcceptAllOption: true,
            multiple: false
        };
        const [fileHandle] = await window.showOpenFilePicker(pickerOptions);
        let fileType;
        if (fileHandle.name.endsWith(".gsp")) {
            fileType = GSP;
        } else if (fileHandle.name.endsWith(".gsps")) {
            fileType = GSPS;
        } else if (fileHandle.name.endsWith(".nvt")) {
            fileType = NVT;
        } else if (fileHandle.name.endsWith(".bin")) {
            fileType = PICS;
        } else if (fileHandle.name.endsWith(".nvp")) {
            fileType = NVP;
        } else if (fileHandle.name.endsWith(".ncf")) {
            fileType = CONFIG;
        } else {
            throw new Error("Wrong file type");
        }
        const file = await fileHandle.getFile();
        const content = await file.arrayBuffer();
        let info;
        if (fileType === GSP || fileType === GSPS) {
            info = parseGspInfo(content)
        }
        handleFwData(info, fileHandle.name, fileType, content);
    };
    const onCloudFwReady = (fwInfo: AwpFwInfo, name: string, type: number, content: ArrayBuffer) => {
        handleFwData(fwInfo, name, type, content);
    };
    const onZipFwReady = (name: string, type: number, content: ArrayBuffer) => {
        writeFw(type, content, true);
    };
    const updateProgress = (progress: number | undefined) => {
        setProgress(progress);
        if (mqttClient && assistantCode) {
            sendFwWriteProgressMessage(mqttClient, assistantCode, MQTT_TOPIC_CLIENT, progress);
        }
    }
    const handleProgressChange = (progress: number) => {
        if (fwData?.content) {
            updateProgress(progress * 100 / fwData.content.byteLength);
        }
    }
    const onUploadComplete = () => {
        setStatus({text: t("loader_fw_upload_complete")});
        updateProgress(100);
    }
    const onUploadError = () => {
        setStatus({text: t("loader_fw_upload_error"), isError: true});
        updateProgress(Math.min(-0.01, -1 * (progress ?? 0)));
    }
    const fwWriteComplete = progress !== undefined && progress >= 100;
    useEffect(() => {
        if (fwWriteComplete) {
            refreshDeviceInfo();
        }
    }, [fwWriteComplete]);
    const writeFw = (type?: number, content?: ArrayBuffer, skipCheck: boolean = false, deviceReady: boolean = false) => {
        if (progress !== undefined && (progress >= 100 || progress < 0)) {
            setStatus({text: "", isError: false});
            updateProgress(undefined);
            return;
        }
        const dataType = type ?? fwData?.type;
        const dataContent = content ?? fwData?.content;
        if (serialDevice && dataType && dataContent) {
            let writeAllowed;
            if (skipCheck) {
                writeAllowed = true;
            } else {
                const fwCompatibility = checkFwCompatibility(deviceInfo, fwData);
                if (fwCompatibility !== undefined) {
                    if (fwCompatibility) {
                        writeAllowed = true;
                    } else {
                        setAttentionDialogMessage(t("loader_fw_not_compatible"));
                        writeAllowed = false;
                    }
                } else {
                    setAttentionDialogMessage(t("loader_cant_check_fw_compatibility"));
                    writeAllowed = false;
                }
            }
            if (writeAllowed) {
                switch (dataType) {
                    case GSP:
                        serialDevice.sendGsp(dataContent, AwpFwSerialDevice.speed921600, handleProgressChange).then(() => onUploadComplete()).catch(() => onUploadError());
                        break;
                    case GSPS:
                        serialDevice.sendGsp(dataContent, AwpFwSerialDevice.speedOldLoader, handleProgressChange).then(() => onUploadComplete()).catch(() => onUploadError());
                        break;
                    case NVT:
                        if (deviceReady) {
                            serialDevice.sendNvt(dataContent, handleProgressChange).then(() => onUploadComplete()).catch(() => onUploadError());
                        } else {
                            showDeviceReadyConfirmationDialog(dataType, dataContent);
                            return;
                        }
                        break;
                    case PICS:
                        serialDevice.sendPics(dataContent, handleProgressChange).then(() => onUploadComplete()).catch(() => onUploadError());
                        break;
                    case NVP:
                        serialDevice.sendNvp(dataContent, handleProgressChange).then(() => onUploadComplete()).catch(() => onUploadError());
                        break;
                    case CONFIG:
                        serialDevice.sendConfig(dataContent, handleProgressChange).then(() => onUploadComplete()).catch(() => onUploadError());
                        break;
                    case ZIP:
                        toggleZipFileSelectDialog();
                        return;
                    default:
                        onUploadError();
                        return;
                }
                updateProgress(0);
                setStatus({text: t("assist_fw_copying")});
            }
        }
    }
    const canSendChat = useMemo(() => {
        return mqttClient && assistantCode;
    }, [mqttClient, assistantCode]);
    const toggleFwSelectDialog = () => {
        setShowFwSelectDialog(v => v + 1);
    };
    const toggleActivationCodeDialog = () => {
        setShowActivationCodeDialog(v => v + 1);
    }
    const sendActivationCode = (code: string) => {
        sendTerminalString(`ACTIVATION  ${code}`);
    }
    const toggleFwPrepareDialog = () => {
        setShowFwPrepareDialog(v => v + 1);
    };
    const toggleFwSearchDialog = () => {
        setShowFindFwDialog(v => v + 1);
    };
    const toggleZipFileSelectDialog = () => {
        setShowZipFileSelectDialog(v => v + 1);
    };
    const showDeviceReadyConfirmationDialog = (type: number, content: ArrayBuffer) => {
        switch (type) {
            case  NVT:
                setDeviceReadyConfirmationDialogData({
                    instructions: t("loader_nvt_fw_install_instruction"),
                    type: type,
                    content: content
                });
                break;
        }
    };
    const deviceReadyConfirmationHandle = (isConfirmed: boolean, type?: number, content?: ArrayBuffer) => {
        setDeviceReadyConfirmationDialogData(undefined);
        if (isConfirmed && type && content) {
            writeFw(type, content, true, true);
        } else {
            updateProgress(undefined);
        }
    };
    const fwWriteConfirmationHandle = (isConfirmed: boolean) => {
        setAttentionDialogMessage(undefined);
        if (isConfirmed) {
            writeFw(fwData?.type, fwData?.content, true);
        }
    };
    const displayDeviceData = useMemo(() => {
        if (deviceInfo && deviceInfo.deviceTypeId) {
            let result = formatAwpFwDeviceName(t, deviceInfo.deviceTypeId);
            if (result) {
                if (deviceInfo.hwVersion) {
                    result += ` v.${deviceInfo.hwVersion}`;
                }
                if (deviceInfo.swVersion) {
                    result += `, ${t("loader_firmware", {version: deviceInfo.swVersion})}`;
                }
                return result;
            }
        }
        return undefined;
    }, [deviceInfo, t]);
    const assistRequestHandler = () => {
        if (assistantCode) {
            setShowCancelAssistantDialog(true);
        } else {
            setShowRequestAssistantDialog(true);
        }
    };
    const assistRequestDialogHandler = (requestCode?: string) => {
        if (requestCode) {
            setAssistantCode(requestCode);
        }
        setShowRequestAssistantDialog(false);
    };
    const assistCancelDialogHandler = (confirmed: boolean) => {
        if (confirmed) {
            setAssistantCode(undefined);
        }
        setShowCancelAssistantDialog(false);
    }
    const checkRemoteFwData = () => {
        if (remoteFwData && remoteFwFileName && remoteFwFileType) {
            let size = 0;
            for (let i = 0; i < remoteFwData.length; i++) {
                const chunk = remoteFwData[i];
                if (chunk) {
                    size += chunk.length;
                } else {
                    if (mqttClient && assistantCode) {
                        sendFwDataRepeatRequestMessage(mqttClient, assistantCode, MQTT_TOPIC_CLIENT, i);
                    }
                    return;
                }
            }
            if (mqttClient && assistantCode) {
                sendFwDataReceiveConfirmationMessage(mqttClient, assistantCode, MQTT_TOPIC_CLIENT);
            }
            const content = new Uint8Array(size);
            let position = 0;
            for (let i = 0; i < remoteFwData.length; i++) {
                const chunk = remoteFwData[i];
                if (chunk) {
                    content.set(chunk, position);
                    position += chunk.length;
                }
            }
            handleFwData(remoteFwInfo, remoteFwFileName, remoteFwFileType, content.buffer);
        }
    }
    const mqttMessageHandler = (mqttMessage: MqttTextMessage) => {
        switch (mqttMessage.type) {
            case MQTT_MESSAGE_SUPPORT_CONNECTED:
                let clientFwData;
                if (fwData) {
                    clientFwData = {
                        info: fwData.info,
                        name: fwData.name,
                        type: fwData.type,
                        subFiles: fwData.subFiles?.map(f => {
                            return {
                                name: f.name,
                                type: f.type
                            }
                        })
                    } as FwLoaderClientFirmwareData
                }
                const state: FwLoaderState = {
                    autodetect: autoDetect,
                    slowDevice: slowDevice,
                    deviceInfo: deviceInfo,
                    portState: portState,
                    progress: progress,
                    fwData: clientFwData,
                    terminalText: terminalText,
                    chatText: chatText
                }
                setClientState(state);
                break;
            case MQTT_MESSAGE_SUPPORT_DISCONNECTED:
                setAssistantState(STATE_SUPPORT_DISCONNECTED);
                break;
            case MQTT_MESSAGE_TERMINAL:
                setTerminalString(mqttMessage.text);
                break;
            case MQTT_MESSAGE_CHAT:
                setChatString(mqttMessage.text);
                break;
            case MQTT_MESSAGE_AUTODETECT_STATE:
                toggleAutoDetect(mqttMessage.value as boolean);
                break;
            case MQTT_MESSAGE_LOW_BAUD_RATE_STATE:
                toggleSlowDevice(mqttMessage.value as boolean);
                break;
            case MQTT_MESSAGE_FW_DATA_INFO:
                const info = mqttMessage.value as FwLoaderRemoteFirmwareInfo;
                setRemoteFwInfo(info.info);
                setRemoteFwFileName(info.name);
                setRemoteFwFileType(info.type);
                setRemoteFwData(new Array<Buffer | undefined>(info.chunks));
                setRemoteFwTransferState(false);
                break;
            case MQTT_MESSAGE_FW_DATA_BINARY:
                setReceiveRemoteFwDataChunkRequest({index: Number.parseInt(mqttMessage.text), data: mqttMessage.value});
                break;
            case MQTT_MESSAGE_FW_DATA_DONE:
                setRemoteFwTransferState(true);
                setCheckRemoteDataRequest(true);
                break;
            case MQTT_MESSAGE_START_WRITE_FW:
                setWriteFwRequest({subFileName: mqttMessage.value as string | undefined});
                break;
            case MQTT_MESSAGE_STOP_WRITE_FW:
                setStopWriteFwRequest(true);
                break;
            case MQTT_MESSAGE_ENUMERATE_COM_PORTS:
                navigator.serial.getPorts().then(ports => {
                    const portsData = ports.map(p => p.getInfo());
                    setPortEnumerationData(portsData);
                });
                break;
            case MQTT_MESSAGE_CONNECT_COM_PORT:
                const portInfo = mqttMessage.value as SerialPortInfo;
                setPortConnectionRequest(portInfo);
                break;
            case MQTT_MESSAGE_DISCONNECT_COM_PORT:
                setPortDisconnectionRequest(true);
                break;
        }
    };
    useEffect(() => {
        if (clientState) {
            if (mqttClient && assistantCode) {
                sendClientStateMessage(mqttClient, assistantCode, MQTT_TOPIC_CLIENT, clientState);
            }
            setClientState(undefined);
        }
    }, [clientState]);
    useEffect(() => {
        if (terminalString) {
            sendTerminalString(terminalString);
            setTerminalString(undefined);
        }
    }, [terminalString]);
    useEffect(() => {
        if (chatString) {
            setChatText(prevState => prevState + "<<< " + chatString);
            setChatString(undefined);
        }
    }, [chatString]);
    useEffect(() => {
        if (portEnumerationData) {
            if (mqttClient && assistantCode) {
                sendPortEnumerationData(mqttClient, assistantCode, MQTT_TOPIC_CLIENT, portEnumerationData);
            }
            setPortEnumerationData(undefined);
        }
    }, [portEnumerationData]);
    useEffect(() => {
        if (portConnectionRequest) {
            connect(portConnectionRequest);
            setPortConnectionRequest(undefined);
        }
    }, [portConnectionRequest]);
    useEffect(() => {
        if (portDisconnectionRequest) {
            disconnect();
            setPortDisconnectionRequest(false);
        }
    }, [portDisconnectionRequest]);
    useEffect(() => {
        if (receiveRemoteFwDataChunkRequest) {
            if (remoteFwData) {
                remoteFwData[receiveRemoteFwDataChunkRequest.index] = receiveRemoteFwDataChunkRequest.data;
                if (remoteFwDataTransferState) {
                    checkRemoteFwData();
                }
            }
            setReceiveRemoteFwDataChunkRequest(undefined);
        }
    }, [receiveRemoteFwDataChunkRequest]);
    useEffect(() => {
        if (checkRemoteFwDataRequest) {
            checkRemoteFwData();
            setCheckRemoteDataRequest(false);
        }
    }, [checkRemoteFwDataRequest]);
    const remoteFwWriteConfirmationHandle = (request?: FwWriteRequest) => {
        setShowFwRemoteWriteConfirmationDialog(undefined);
        if (fwData && request) {
            if (fwData.subFiles && request.subFileName) {
                const subFile = fwData.subFiles.find(sf => sf.name === request.subFileName);
                if (subFile) {
                    writeFw(subFile.type, subFile.content, true);
                    return;
                }
            }
            writeFw(undefined, undefined, true);
        } else {
            updateProgress(undefined);
        }
    }
    useEffect(() => {
        if (writeFwRequest) {
            setShowFwRemoteWriteConfirmationDialog(writeFwRequest);
            setWriteFwRequest(undefined);
        }
    }, [writeFwRequest]);
    useEffect(() => {
        if (stopWriteFwRequest) {
            setStatus({text: "", isError: false});
            updateProgress(undefined);
            setStopWriteFwRequest(false);
            if (progress !== undefined && progress >= 100) {
                disconnect();
            }
        }
    }, [stopWriteFwRequest]);
    useEffect(() => {
        let client: mqtt.MqttClient;
        let listenInterval: NodeJS.Timeout | undefined;
        let pingInterval: NodeJS.Timeout | undefined;
        if (assistantCode) {
            const restartTimeout = () => {
                if (listenInterval) {
                    clearTimeout(listenInterval);
                }
                listenInterval = setTimeout(() => {
                    setAssistantState(s => s === STATE_SUPPORT_ONLINE ? STATE_AWAITING_SUPPORT : s);
                }, MQTT_PING_TIMEOUT_MS);
            }
            setAssistantState(STATE_CONNECTING);
            client = mqtt.connect(MQTT_BROKER_URL, {
                username: MQTT_BROKER_USERNAME,
                password: MQTT_BROKER_PASSWORD,
                clean: true
            });
            client.on('connect', () => {
                setAssistantState(STATE_AWAITING_SUPPORT);
                setMqttClient(client);
            });
            client.on('error', (error: Error) => {
                logError("Mqtt error", error);
                setAssistantState(STATE_ERROR);
                if (listenInterval) {
                    clearTimeout(listenInterval);
                    listenInterval = undefined;
                }
                if (pingInterval) {
                    clearInterval(pingInterval);
                    pingInterval = undefined;
                }
            });
            client.on('message', (topic: string, message: Buffer) => {
                setAssistantState(STATE_SUPPORT_ONLINE);
                restartTimeout();
                const mqttMessage = parseMqttMessage(message);
                if (mqttMessage) {
                    mqttMessageHandler(mqttMessage);
                }
            });
            client.subscribe(`${assistantCode}/${MQTT_TOPIC_SUPPORT}`, {qos: 2});
            pingInterval = setInterval(() => sendPingMessage(client, assistantCode, MQTT_TOPIC_CLIENT), MQTT_PING_SEND_INTERVAL_MS);
        }
        return () => {
            if (pingInterval) {
                clearInterval(pingInterval);
            }
            if (listenInterval) {
                clearTimeout(listenInterval);
            }
            if (client) {
                sendSupportDisconnectedMessage(client, assistantCode!, MQTT_TOPIC_CLIENT, () => client.end(true));
            }
            setAssistantState(undefined);
            setMqttClient(undefined);
        }
    }, [assistantCode]);
    const handleTerminalInputKeyDown = (key: React.KeyboardEvent<HTMLInputElement>) => {
        if (key.key === 'Enter') {
            sendTerminalString();
        }
    };
    const handleChatInputKeyDown = (key: React.KeyboardEvent<HTMLInputElement>) => {
        if (key.key === 'Enter') {
            sendChatString();
        }
    };
    const isDeviceInfoVisible = useMemo(() => {
        return serialDevice && autoDetect && deviceInfo !== undefined;
    }, [serialDevice, autoDetect, deviceInfo]);
    const isConnectButtonDisabled = useMemo(() => {
        return portState === CONNECTION_STATE_CONNECTING || progress !== undefined;
    }, [portState, progress]);
    const isFwSelectButtonDisabled = useMemo(() => {
        return progress !== undefined;
    }, [progress]);
    const isTerminalDisabled = useMemo(() => {
        return !(serialDevice !== undefined && serialDevice !== null && progress === undefined);
    }, [serialDevice, progress]);
    const isAssistButtonDisabled = useMemo(() => {
        return progress !== undefined;
    }, [progress]);
    const isWriteFwButtonDisabled = useMemo(() => {
        if (progress !== undefined && (progress >= 100 || progress < 0)) {
            return false;
        }
        if (serialDevice) {
            if (fwData?.content) {
                if (progress === undefined || progress >= 100 || progress < 0) {
                    return false;
                }
            }
        }
        return true;
    }, [serialDevice, fwData?.content, progress]);
    return (
        <div className="container-grow d-flex flex-column m-4">
            <div className="container-grow d-flex flex-column">
                <div className="container-grow d-flex flex-column">
                    <div className="container-grow d-flex flex-row">
                        <div className="flex-grow-1 d-flex align-self-stretch">
                            <div className="w-100 d-flex flex-column">
                                <div className="d-flex flex-row justify-content-between">
                                    <h5 className="mx-2">{serviceMode === SERVICE_MODE_DISABLED ? t("loader") : `${t("loader")} (${formatServiceMode(t, serviceMode)})`}</h5>
                                    <Button className={`mx-2 ${assistantCode ? "d-none" : ""}`}
                                            disabled={isAssistButtonDisabled}
                                            onClick={assistRequestHandler}>{t("loader_fw_request_support")}</Button>
                                </div>
                                <table className="loader-controls-table w-100">
                                    <tbody>
                                    {progress === undefined &&
                                        <Fragment>
                                            <tr>
                                                <td width="120px" className="px-2">
                                                    <Button className="btn btn-primary btn-sm w-100"
                                                            onClick={toggleConnection}
                                                            disabled={isConnectButtonDisabled}>{t(serialDevice ? "loader_disconnect" : "loader_connect")}</Button>
                                                </td>
                                                <td colSpan={2} className="px-2">
                                                    <Form.Control size="sm" type="text" className="w-100 text-center"
                                                                  readOnly
                                                                  disabled
                                                                  value={formatConnectionState(t, portState)}/>
                                                </td>
                                            </tr>
                                            {!isDeviceInfoVisible &&
                                                <Fragment>
                                                    <tr>
                                                        <td colSpan={3} className="px-2">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <Form.Check type={"checkbox"}
                                                                            label={t("loader_low_speed")}
                                                                            disabled={portState !== CONNECTION_STATE_NOT_CONNECTED || autoDetect}
                                                                            checked={slowDevice}
                                                                            onChange={() => toggleSlowDevice(!slowDevice)}/>
                                                                {!(portState !== CONNECTION_STATE_NOT_CONNECTED || autoDetect) &&
                                                                    <HelpButton
                                                                        text={t('loader_low_speed_description')}/>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3} className="px-2">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <Form.Check type={"checkbox"}
                                                                            label={t("loader_autodetect")}
                                                                            checked={autoDetect}
                                                                            disabled={serialDevice !== null}
                                                                            onChange={() => toggleAutoDetect(!autoDetect)}/>
                                                                {!(serialDevice !== null) &&
                                                                    <HelpButton
                                                                        text={t('loader_autodetect_description')}/>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            }
                                            {isDeviceInfoVisible &&
                                                <Fragment>
                                                    <tr style={{height: 50}}>
                                                        <td colSpan={3} className="px-2">
                                                            <div className={"d-flex flex-column align-items-center"}>
                                                                {(deviceInfo === null || displayDeviceData === undefined) &&
                                                                    <Form.Label>{t("loader_autodetect_failed")}</Form.Label>}
                                                                {deviceInfo && displayDeviceData &&
                                                                    <Form.Label>{displayDeviceData}</Form.Label>}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{height: 50}}/>
                                                </Fragment>
                                            }
                                            <tr>
                                                <td width="120px" className="px-2">
                                                    <DropdownButton size="sm" className="w-100"
                                                                    title={t("loader_select_fw")}
                                                                    disabled={isFwSelectButtonDisabled}>
                                                        <Dropdown.Item
                                                            onClick={selectLocalFile}>{t("loader_select_file")}</Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={toggleFwSelectDialog}>{t("loader_select_from_cloud")}</Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={toggleFwSearchDialog}
                                                            disabled={deviceInfo === undefined}>{t("loader_search_fw")}</Dropdown.Item>
                                                    </DropdownButton>
                                                </td>
                                                <td colSpan={2} className="px-2">
                                                    <Form.Control size="sm" type="text" className="w-100 text-center"
                                                                  readOnly
                                                                  disabled
                                                                  value={fwName}/>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    }
                                    {progress !== undefined &&
                                        <Fragment>
                                            <tr style={{height: 50}}>
                                                <td colSpan={3} rowSpan={4} className="px-2">
                                                    <Form.Label className={"status"}
                                                                dangerouslySetInnerHTML={{__html: `<br/><strong>${t("awp_fw_upload_warning_1")}<br/>${t("awp_fw_upload_warning_2")}<br/>${t("awp_fw_upload_warning_3")}</strong>`}}/>
                                                </td>
                                            </tr>
                                            <tr style={{height: 50}}/>
                                            <tr style={{height: 50}}/>
                                            <tr style={{height: 50}}/>
                                        </Fragment>
                                    }
                                    <tr>
                                        <td colSpan={3} className="px-2">
                                            <Form.Label className={`status ${status.isError ? "status-error" : ""}`}
                                                        dangerouslySetInnerHTML={{__html: status.text}}/>
                                        </td>
                                    </tr>
                                    {(progress !== undefined && (progress >= 100 || progress < 0)) &&
                                        <tr>
                                            <td colSpan={3} className="px-2">
                                                <div className="d-flex justify-content-center">
                                                    <Button className="btn btn-primary btn-sm"
                                                            disabled={isWriteFwButtonDisabled}
                                                            onClick={() => writeFw()}>{t("continue")}</Button>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    {(progress === undefined || (progress < 100 && progress >= 0)) &&
                                        <tr>
                                            <td colSpan={2} className="px-2">
                                                <ProgressBar className="w-100" min={0} max={100}
                                                             now={Math.abs(progress ?? 0)}/>
                                            </td>
                                            <td width="120px" className="px-2">
                                                <Button className="btn btn-primary btn-sm w-100"
                                                        disabled={isWriteFwButtonDisabled}
                                                        onClick={() => writeFw()}>{t("loader_fw_write")}</Button>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                                <div className="container-grow d-flex flex-row">
                                    <div className="container-grow d-flex flex-column">
                                        <h5 className="mx-2">{t("terminal")}</h5>
                                        <div className="d-flex container-grow">
                                            <div className="d-flex flex-grow-1 px-2">
                                                <Form.Control ref={terminalOutputRef} as="textarea" size="sm"
                                                              className="flex-grow-1 output-text-area"
                                                              onScroll={terminalOutputOnScroll}
                                                              readOnly
                                                              disabled value={terminalText}/>
                                            </div>
                                            <table className="loader-controls-table">
                                                <tbody>
                                                <tr>
                                                    <td className="px-2">
                                                        <Form.Control ref={terminalInputRef} size="sm" type="text"
                                                                      className="w-100"
                                                                      disabled={isTerminalDisabled}
                                                                      onKeyDown={handleTerminalInputKeyDown}/>
                                                    </td>
                                                    <td width="120px" className="px-2">
                                                        <Button className="btn btn-primary btn-sm w-100"
                                                                disabled={isTerminalDisabled}
                                                                onClick={() => sendTerminalString(undefined)}>{t("terminal_send")}</Button>
                                                    </td>
                                                    <td width="120px" className="px-2">
                                                        <DropdownButton size="sm" className="w-100"
                                                                        title={t("loader_addition_actions")}
                                                                        disabled={isTerminalDisabled}>
                                                            <Dropdown.Item
                                                                onClick={toggleActivationCodeDialog}>{t("loader_activation_code")}</Dropdown.Item>
                                                        </DropdownButton>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={assistantCode ? "d-flex flex-row side-panel" : "d-none"}>
                                <VerticalDivider className="mx-2"/>
                                <div className="flex-grow-1 d-flex flex-column align-self-stretch">
                                    <h5 className="mx-2 text-center">{t("loader_remote_assistance")}</h5>
                                    <table className="loader-controls-table w-100">
                                        <tbody>
                                        <tr>
                                            <td><Button className="mx-2 w-100" disabled={isAssistButtonDisabled}
                                                        onClick={assistRequestHandler}>{assistantCode ? t("loader_fw_disconnect_support") : t("loader_fw_request_support")}</Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Form.Label
                                                    className={"w-100 text-center"}>{t("loader_assistance_request_code")}</Form.Label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Form.Label
                                                    className={"w-100 text-center fw-bold"}>{assistantCode}</Form.Label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Form.Label
                                                    className={"w-100 text-center"}>{t("loader_assistance_state")}</Form.Label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Form.Label
                                                    className={"w-100 text-center fw-bold"}>{formatMqttState(t, assistantState)}</Form.Label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td/>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="d-flex container-grow">
                                        <h5 className="mx-2">{t("chat")}</h5>
                                        <div className="d-flex flex-grow-1 px-2">
                                            <Form.Control ref={chatOutputRef} as="textarea" size="sm"
                                                          className="flex-grow-1 output-text-area"
                                                          onScroll={chatOutputOnScroll}
                                                          readOnly
                                                          disabled value={chatText}/>
                                        </div>
                                        <table className="loader-controls-table">
                                            <tbody>
                                            <tr>
                                                <td className="px-2">
                                                    <Form.Control ref={chatInputRef} size="sm" type="text"
                                                                  className="w-100"
                                                                  disabled={!canSendChat}
                                                                  onKeyDown={handleChatInputKeyDown}/>
                                                </td>
                                                <td width="120px" className="px-2">
                                                    <Button className="btn btn-primary btn-sm w-100"
                                                            disabled={!canSendChat}
                                                            onClick={sendChatString}>{t("terminal_send")}</Button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SerialPortOpenErrorDialog show={showSerialPortOpenErrorDialog}
                                           closeHandler={() => setShowSerialPortOpenErrorDialog(false)}/>
                <FwSelectDialog show={showFwSelectDialog} closeHandler={toggleFwSelectDialog}
                                fwSelectionHandler={onCloudFwReady} deviceType={deviceInfo?.deviceTypeId}
                                serviceMode={serviceMode}/>
                <FwSearchDialog show={showFindFwDialog} closeHandler={toggleFwSearchDialog} deviceInfo={deviceInfo!}
                                fwSelectionHandler={onCloudFwReady} serviceMode={serviceMode}/>
                <ActivationCodeDialog show={showActivationCodeDialog} closeHandler={toggleActivationCodeDialog}
                                      codeHandler={sendActivationCode}/>
                <DeviceReadyConfirmationDialog data={deviceReadyConfirmationDialogData}
                                               closeHandler={deviceReadyConfirmationHandle}/>
                <ZipFileSelectDialog zipFile={fwData?.subFiles} show={showZipFileSelectDialog}
                                     closeHandler={toggleZipFileSelectDialog}
                                     zipFileSelectionHandler={onZipFwReady}/>
                <FwAttentionDialog message={attentionDialogMessage} closeHandler={fwWriteConfirmationHandle}/>
                <RequestAssistDialog show={showRequestAssistantDialog}
                                     closeHandler={assistRequestDialogHandler}/>
                <CancelAssistDialog show={showCancelAssistantDialog} closeHandler={assistCancelDialogHandler}/>
                <FwRemoteWriteConfirmationDialog request={showFwRemoteWriteConfirmationDialog}
                                                 closeHandler={remoteFwWriteConfirmationHandle}/>
                <FwPrepareDialog show={showFwPrepareDialog} closeHandler={toggleFwPrepareDialog}
                                 fwSelectionHandler={onCloudFwReady} fwId={path.fw!}/>
            </div>
        </div>
    );
}
