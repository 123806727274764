import './DistanceFilterSwitch.css';
import {useTranslation} from "react-i18next";
import {Dropdown, DropdownButton, FormCheck} from "react-bootstrap";
import {useState} from "react";
import IconDistanceSvg from "../Icons/IconDistanceSvg";

export const DISTANCE_ALL = "dist_all";
export const DISTANCE_50KM = "dist_50km";
export const DISTANCE_100KM = "dist_100km";
export const DISTANCE_250KM = "dist_250km";

export const DEFAULT_DISTANCE = DISTANCE_ALL;

export function getMaxDistance(distance : string){
    switch (distance){
        case DISTANCE_50KM:
            return 50_000;
        case DISTANCE_100KM:
            return 100_000;
        case DISTANCE_250KM:
            return 250_000;
        default:
            return Number.MAX_SAFE_INTEGER;
    }
}

const options = [DISTANCE_ALL, DISTANCE_50KM, DISTANCE_100KM, DISTANCE_250KM];

interface Props{
    activeValue: string;
    activeValueChangeListener: (newValue: string) => void;
    minimized?: boolean;
}

export function DistanceFilterSwitch(props: Props){
    const {t} = useTranslation();
    const [updateCounter, setUpdateCounter] = useState(0);
    return (
        <DropdownButton title={props.minimized ? <IconDistanceSvg/> : t('executors_distance')} className="no-select">
            {options.map(o =>
                <Dropdown.ItemText key={`it-${o}`}>
                    <FormCheck key={`fc-${o}-${updateCounter}`} label={t(o)}
                               defaultChecked={o === props.activeValue}
                               onChange={() => {
                                   props.activeValueChangeListener(o);
                                   setUpdateCounter(updateCounter + 1);
                               }}/>
                </Dropdown.ItemText>
            )}
        </DropdownButton>
    );
}