import * as React from "react"

function ErrorSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M8.05 21H2.5A2.502 2.502 0 010 18.5v-16C0 1.121 1.121 0 2.5 0h12C15.879 0 17 1.121 17 2.5v7.03a.5.5 0 01-1 0V2.5c0-.827-.673-1.5-1.5-1.5h-12C1.673 1 1 1.673 1 2.5v16c0 .827.673 1.5 1.5 1.5h5.55a.5.5 0 010 1z" fill="currentColor"/>
            <path d="M13.5 9h-10a.5.5 0 010-1h10a.5.5 0 010 1zM9.5 13h-6a.5.5 0 010-1h6a.5.5 0 010 1zM8.5 5h-5a.5.5 0 010-1h5a.5.5 0 010 1zM22.5 24h-11c-.827 0-1.5-.673-1.5-1.5 0-.294.081-.569.235-.799l5.488-8.981c.259-.441.75-.72 1.277-.72s1.018.279 1.281.728l5.495 8.992c.143.211.224.486.224.78 0 .827-.673 1.5-1.5 1.5zM17 13a.484.484 0 00-.418.233l-5.505 9.008A.444.444 0 0011 22.5c0 .275.225.5.5.5h11c.275 0 .5-.225.5-.5a.423.423 0 00-.065-.24l-5.512-9.019A.488.488 0 0017 13z" fill="currentColor"/>
            <path d="M17 20a.5.5 0 01-.5-.5v-4a.5.5 0 011 0v4a.5.5 0 01-.5.5z" fill="currentColor"/>
            <circle cx={17} cy={21.5} r={0.5} />
        </svg>
    )
}

export default ErrorSvg
