import ErrorSvg from "./ErrorSvg";
import "./Error.css";
import {useTranslation} from "react-i18next";
import {logError, logger} from "../../helpers/LogHelper";

interface Props{
    error? : Error;
    errorMessage? : string;
    retryText? : string;
    retryClickHandler? : () => void;
}

export function Error(props: Props){
    const {t} = useTranslation();
    if (props.error && process.env.NODE_ENV === 'development'){
        logError("Error", props.error);
    }
    return (
        <div className="container-grow d-flex flex-column justify-content-center align-items-center">
            <div className="error-screen-icon">
                <ErrorSvg/>
            </div>
            <span className="mt-4 error-screen">{props.errorMessage ?? t('error_message')}</span>
            {(props.error && process.env.NODE_ENV === 'development') && <span className="mt-4 error-screen-stack">{props.error.stack}</span>}
            {props.retryClickHandler && <span className="mt-4 error-screen-link" onClick={props.retryClickHandler}>{props.retryText ?? t('retry')}</span>}
        </div>
    );
}