import {AwpSerialDevice} from "../serial/AwpSerialDevice";
import {parseString, parseStrings} from "../helpers/BinaryParseHelper";
import {encodeBase64} from "../helpers/Utils";
import moment from "moment/moment";
import {AWP_RECORD_TIMESTAMP_FORMAT} from "../helpers/AwpHelper";
import {AwpDirectoryHandle} from "../helpers/AwpDirectoryHandle";
import {AwpKrcSeries, parseKrcSeries} from "./AwpKrcSeries";

export interface AwpKrcRecord {
    name: string;
    date?: Date;
    series: Array<AwpKrcSeries>;
    deviceSerial?: string;
    probeName?: string;
    probeType?: number;
    probeSerial?: string;
    photo?: string;
    operator?: string;
    object?: string;
    conclusion?: string;
}

export function readAwpKrcRecord(fsHandle: AwpDirectoryHandle, recordName: string): Promise<AwpKrcRecord | null> {
    return new Promise<AwpKrcRecord | null>(async resolve => {
        let name = "";
        let date : Date | undefined = new Date();
        let series = new Array<AwpKrcSeries>();
        let photo;
        let operator;
        let object;
        let conclusion;
        const recordHandle = await fsHandle.getDirectoryHandle(recordName).catch(() => null);
        if (recordHandle) {
            const nameHandle = await recordHandle.getFileHandle(AwpSerialDevice.nameFile).catch(() => null);
            if (nameHandle) {
                const buffer = await nameHandle.getData().catch(() => null);
                if (buffer) {
                    name = parseString(buffer);
                    const lastModified = await nameHandle.lastModified();
                    date = new Date(lastModified);
                }
            } else {
                resolve(null);
                return;
            }
            const dateHandle = await recordHandle.getFileHandle(AwpSerialDevice.dateFile).catch(() => null);
            if (dateHandle) {
                const buffer = await dateHandle.getData().catch(() => null);
                if (buffer) {
                    date = moment(parseString(buffer), AWP_RECORD_TIMESTAMP_FORMAT).toDate();
                }
            }
            const photoFile = await recordHandle.getFileHandle(AwpSerialDevice.photoFile).catch(() => null);
            if (photoFile) {
                const buffer = await photoFile.getData().catch(() => null);
                if (buffer) {
                    photo = "data:image/bmp;base64," + encodeBase64(new Uint8Array(buffer));
                }
            }
            const infoFile = await recordHandle.getFileHandle(AwpSerialDevice.infoFile).catch(() => null);
            if (infoFile) {
                const info = await infoFile.getData().catch(() => null);
                if (info) {
                    const parts = parseStrings(info);
                    if (parts.length >= 1) {
                        operator = parts[0];
                    }
                    if (parts.length >= 2) {
                        object = parts[1];
                    }
                }
            }
            const conclusionFile = await recordHandle.getFileHandle(AwpSerialDevice.conclusionFile).catch(() => null);
            if (conclusionFile) {
                const info = await conclusionFile.getData().catch(() => null);
                if (info) {
                    conclusion = parseString(info);
                }
            }
            for (let i = 1; i <= 100; i++) {
                const seriesFile = await recordHandle.getFileHandle(AwpSerialDevice.getSeriesFileName(i)).catch(() => null);
                if (!seriesFile) {
                    if (i === 1){
                        resolve(null);
                        return;
                    }
                    break;
                }
                const buffer = await seriesFile.getData().catch(() => null);
                if (buffer) {
                    const krcSeries = parseKrcSeries(buffer);
                    if (krcSeries) {
                        series.push(krcSeries);
                    } else {
                        resolve(null);
                        return;
                    }
                } else {
                    break;
                }
            }
            let deviceSerial;
            let probeType;
            let probeSerial;
            if (series.length > 0) {
                deviceSerial = series[0].systemSet.serial;
                if ((deviceSerial.length === 10 || deviceSerial.length === 11) && deviceSerial.match(/\d*/gm)) {
                    deviceSerial = `${deviceSerial.substring(0, 3)}.${deviceSerial.substring(3, 6)}.${deviceSerial.substring(6)}`;
                }
                probeType = series[0].probeParam.typeProbe;
                probeSerial = series[0].probeParam.serial;
            }
            resolve({
                name: name,
                date: date,
                deviceSerial: deviceSerial,
                probeType: probeType,
                probeSerial: probeSerial,
                series: series,
                photo: photo,
                operator: operator,
                object: object,
                conclusion: conclusion
            });
        }
    }).catch(() => {
        return null;
    });
}
