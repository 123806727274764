export const IMAGE_TYPE = "image/jpeg";
export const IMAGE_QUALITY = 0.92;

export interface ImageData{
    width: number;
    height: number;
    data: string;
}

export function getImageDataFromUrl(url : string, flipX?: boolean, flipY?: boolean) : Promise<ImageData> {
    return new Promise (function (resolved, rejected) {
        let i = new Image()
        i.onload = function(){
            const canvas = document.createElement("canvas");
            canvas.width = i.width;
            canvas.height = i.height;
            const context = canvas.getContext("2d");
            if (flipX) {
                context?.scale(-1, 1);
                context?.translate(canvas.width, 0);
            }
            if (flipY) {
                context?.scale(1, -1);
                context?.translate(0, -canvas.height);
            }
            context?.drawImage(i, 0, 0);
            const dataURL = canvas.toDataURL(IMAGE_TYPE, IMAGE_QUALITY);
            resolved({
                width : i.width,
                height: i.height,
                data: dataURL
            } as ImageData)
        };
        i.onerror = function (){
            rejected();
        }
        i.crossOrigin = "anonymous";
        i.src = url;
    });
}

export function getImageDataFromBase64(data: string) : Promise<ImageData> {
    return new Promise (function (resolved, rejected) {
        let i = new Image()
        i.onload = function(){
            resolved({
                width : i.width,
                height: i.height,
                data: data
            } as ImageData)
        };
        i.onerror = function (){
            rejected();
        }
        i.src = data
    });
}