import {AwpDirectoryHandle} from "../helpers/AwpDirectoryHandle";

export abstract class AwpDevice{

    static readonly nameFile = "NAME.TXT";
    static readonly dateFile = "DATE.TXT";
    static readonly seriesFile = "SER#XX#.BIN";
    static readonly configFile = "CONFIG.BIN";
    static readonly probeFile = "PROBE.BIN";
    static readonly curveFile = "CURVE.BIN";
    static readonly photoFile = "FOTO.BMP";
    static readonly sygnalFile = "SYGNAL.BMP";
    static readonly iconFile = "ICON.BMP";
    static readonly infoFile = "RESUME.TXT";
    static readonly conclusionFile = "RESUME2.TXT";

    protected isAborted: boolean;

    protected constructor() {
        this.isAborted = false;
    }

    abstract readRecords(fsHandle: AwpDirectoryHandle, from: Date, to: Date, move: boolean, progressCallback?: (progress: number) => void, confirmationCallback?: (count: number) => Promise<boolean>, instructionCallback? :() => Promise<void>): Promise<boolean>;

    abort() {
        this.isAborted = true;
    }
}