import {useTranslation} from "react-i18next";
import {AwpSeries} from "../../helpers/AwpHelper";
import {formatNumber} from "../../helpers/FormatHelper";
import {AwpUd2303Series} from "../../models/AwpUd2303Series";
import {getUd2303TransducerInfo} from "../../helpers/AwpUd2303FormatHelper";

interface Props {
    data: AwpSeries;
}

export function AwpUd2303TransducerInfo(props: Props) {
    const {t} = useTranslation();
    const data = props.data as AwpUd2303Series;
    const transducerInfo = getUd2303TransducerInfo(t, data);
    return (
        <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-stretch">
            <table className="table-measurement-info">
                <tbody>
                <tr>
                    <th>{t('frequency_mhz')}</th>
                    <td>{transducerInfo.freq}</td>
                </tr>
                {data.probe.type !== 0 && data.probe.type !== 1 &&
                    <tr>
                        <th>{t('input_angle')}</th>
                        <td>{transducerInfo.angle}</td>
                    </tr>
                }
                <tr>
                    <th>{t('delay_in_prism_us')}</th>
                    <td>{transducerInfo.delayUs}</td>
                </tr>
                <tr>
                    <th>{t('arrow_mm')}</th>
                    <td>{transducerInfo.vector}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}