import {AwpSeries} from "../../helpers/AwpHelper";
import {AwpUt3EmaSeries} from "../../models/AwpUt3EmaSeries";
import {useTranslation} from "react-i18next";
import {getUt3EmaResult} from "../../helpers/AwpUt3EmaFormatHelper";

interface Props {
    data: AwpSeries;
}

export function AwpUt3EmaResultInfo(props: Props) {
    const {t} = useTranslation();
    const data = props.data as AwpUt3EmaSeries;
    const result = getUt3EmaResult(t, data);
    return <h5 className="mt-2">
        <strong>
            {result.display}
        </strong>
    </h5>
}