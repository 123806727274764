import './NoPhotoPlaceholder.css';
import ImagePlaceholderSvg from "./ImagePlaceholderSvg";

interface Props{
    text: string;
}

export function NoPhotoPlaceholder(props : Props) {
    return (
        <div className="container-grow d-flex flex-column justify-content-center align-items-center">
            <div className="no-photo-placeholder-icon">
                <ImagePlaceholderSvg/>
            </div>
            <span className="mt-4 no-photo-placeholder">{props.text}</span>
        </div>
    );
}