export const HRC = "HRC";
export const HB = "HB";
export const HV= "HV";
export const HRB = "HRB";
export const HS = "HS";
export const USER1 = "USER1";
export const USER2 = "USER2";
export const USER3 = "USER3";
export const MPA_T = "MPA_T";
export const MPA_Y = "MPA_Y";

export function getMeasurementError(uciScale: string){
    switch (uciScale){
        case HRC:
            return 4;
        case HB:
            return 20;
        case HV:
            return 20;
        case HRB:
            return 4;
        case HS:
            return 10;
        case USER1:
            return 10;
        case USER2:
            return 10;
        case USER3:
            return 10;
        case MPA_T:
            return 10;
        case MPA_Y:
            return 10;
        default:
            return 10;
    }
}

