import {useTranslation} from "react-i18next";
import {Fragment, useEffect, useState} from "react";
import {Button, Modal, Table} from "react-bootstrap";
import {Loader} from "../Loader/Loader";
import {FwLoaderFirmwareSubFileData} from "./AwpFwLoaderCommon";
import {delay} from "../../helpers/Utils";

interface Props {
    show: number;
    closeHandler: () => void;
    zipFileSelectionHandler: (fileName: string, fileType: number, content: ArrayBuffer) => void;
    zipFile: Array<FwLoaderFirmwareSubFileData> | undefined;
}

interface Message {
    text: string;
    isLoader: boolean;
}

export function ZipFileSelectDialog(props: Props) {
    const {t} = useTranslation();
    const [message, setMessage] = useState(undefined as Message | undefined)
    const [files, setFiles] = useState(undefined as Array<string> | undefined | null);
    useEffect(() => {
        setMessage({text: t("zip_reading_file"), isLoader: true});
        delay(2000).then(() => {
            if (props.zipFile?.length !== 0) {
                setMessage(undefined);
            } else {
                setMessage({text: t("zip_reading_file_error"), isLoader: false});
            }
            setFiles(props.zipFile?.map(zf => zf.name));
        });
    }, [props.zipFile, props.show, t]);
    const selectFile = (fileName: string) => {
        setMessage({text: t("zip_extracting_file"), isLoader: true});
        delay(2000).then(() => {
            const fileData = props?.zipFile?.find(zf => zf.name === fileName);
            if (fileData) {
                props.zipFileSelectionHandler(fileName, fileData.type, fileData.content);
                close();
            } else {
                setMessage({text: t("zip_extracting_file_error"), isLoader: false});
            }
        });
    };
    const close = () => {
        props.closeHandler();
    }
    return (
        <Modal show={props.show % 2 === 1} size={"xl"} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{t("zip_select_file")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message &&
                    <Fragment>
                        {message.isLoader && <Loader message={message.text}/>}
                        {!message.isLoader && <div>{message.text}</div>}
                    </Fragment>
                }
                {!message && files &&
                    <Fragment>
                        <Table striped bordered className="fw-table mt-4 mb-0">
                            <thead>
                            <tr>
                                <th>{t("zip_file_name")}</th>
                                <th/>
                                {files.length > 7 && <th style={{width: "15px"}}/>}
                            </tr>
                            </thead>
                        </Table>
                        <div style={files.length > 7 ? {
                            height: "400px",
                            overflowY: "scroll"
                        } : {}}>
                            <Table striped bordered className="fw-table mb-0">
                                <tbody>
                                {files.map(f => {
                                        const displayName = f.substr(f.lastIndexOf("/") + 1);
                                        return <tr key={`{f-${f}`}>
                                            <td>{displayName}</td>
                                            <td><Button className="btn btn-primary btn-sm"
                                                        onClick={() => selectFile(f)}>{t("select")}</Button></td>
                                        </tr>
                                    }
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </Fragment>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>{t("cancel")}</Button>
            </Modal.Footer>
        </Modal>
    );
}