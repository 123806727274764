import {useTranslation} from "react-i18next";
import {useLocale} from "../../hooks/LocaleHook";
import {ExecutorDataWithDistance} from "../../models/ExecutorDataWithDistance";
import {formatDateTime, formatDeviceName} from "../../helpers/FormatHelper";
import IconLocationSvg from "../Icons/IconLocationSvg";
import './ExecutorsListItem.css';
import React, {useMemo} from "react";

interface Props {
    item: ExecutorDataWithDistance;
    clickListener: (id: string) => void;
    locationClickListener: (lat: number, lng: number) => void;
}

export function ExecutorsListItem(props: Props) {
    const {t} = useTranslation();
    const locale = useLocale();
    const devices = useMemo(() => {
        const arr = new Array<string>();
        props.item.executor.devices.forEach((v, k) => {
            if (v){
                arr.push(k);
            }
        });
        return arr;
    }, [props.item]);
    const handleLocationClick = (e : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.item.executor.locationLat && props.item.executor.locationLng) {
            props.locationClickListener(props.item.executor.locationLat, props.item.executor.locationLng)
        }
        e.stopPropagation();
    };
    return (
        <div className="executors-list-item" key={props.item.executor.phone}
             onClick={() => props.clickListener(props.item.executor.phone)}>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="flex-grow-1 mx-1" style={{maxWidth: "calc(100% - 60px)"}}>
                    <div className="my-1 font-weight-bold text-center">{props.item.executor.name}</div>
                    {props.item.executor.phone &&
                    <div>{`${t("executors_phone")}: ${props.item.executor.phone}`}</div>
                    }
                    {props.item.executor.email &&
                    <div>{`${t("executors_email")}: ${props.item.executor.email}`}</div>
                    }
                    <div>{t("executors_available_devices")}</div>
                    {devices.map(d => <div key={`k-${d}`}>{`- ${formatDeviceName(t, d)}`}</div>)}
                    {props.item.executor.comments &&
                    <div
                        className="single-line-text">{`${t("executors_about_me")}: ${props.item.executor.comments}`}</div>
                    }
                    {props.item.executor.timestamp &&
                    <div>{`${t("executors_last_update")}: ${formatDateTime(locale, new Date(props.item.executor.timestamp))}`}</div>
                    }
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center executors-list-item-icon" onClick={handleLocationClick}>
                    <IconLocationSvg/>
                    {props.item.distance &&
                    <span>{`${(props.item.distance / 1000).toFixed(1)} ${t("km")}`}</span>
                    }
                </div>
            </div>
        </div>
    );
}