import {Button, Modal} from "react-bootstrap";

interface Props{
    show: boolean;
    title: string;
    body: string;
    buttonText: string;
    closeHandler: () => void;
    buttonAction?: () => void;
}

export function ModalSingleButton(props : Props) {
    const ok = () => {
        props.closeHandler();
        if (props.buttonAction) {
            props.buttonAction();
        }
    }
    return (<Modal
        show={props.show}
        onHide={props.closeHandler}
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.body}</Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={() => ok()}>{props.buttonText}</Button>
        </Modal.Footer>
    </Modal>);
}