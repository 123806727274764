import {formatDateTime} from "../../helpers/FormatHelper";
import React, {Fragment} from "react";
import {useLocale} from "../../hooks/LocaleHook";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import IconFolderSvg from "../Icons/IconFolderSvg";
import IconRecordSvg from "../Icons/IconRecordSvg";

interface Props {
    item: Array<string | Date | undefined>;
    isFolder: boolean;
    isHighlighted: boolean;
    itemHighlightListener?: (fileName: string, ctrl : boolean, shift : boolean) => void;
    itemSelectionListener?: (fileName: string) => void;
    itemContextClickListener? : (fileName: string, x: number, y: number) => void;
}

export function AwpRecordListItem(props: Props) {
    const locale = useLocale();
    const count = props.item.length - 1;
    const fileName = props.item[count] as string;
    const clickHandler = (e : React.MouseEvent) => {
        e.stopPropagation();
        if (props.itemHighlightListener) {
            props.itemHighlightListener(fileName, e.ctrlKey, e.shiftKey);
        }
    };
    const doubleClickHandler = (e : React.MouseEvent) => {
        e.stopPropagation();
        if (props.itemSelectionListener) {
            props.itemSelectionListener(fileName);
        }
    };
    const contextMenuHandler = (event : any) => {
        event.preventDefault();
        if (props.itemContextClickListener) {
            props.itemContextClickListener(fileName, event.clientX, event.clientY);
        }
    };
    return (
        <Fragment>
            <tr className={`no-select ${props.isHighlighted ? "record-item-active" : ""}`} onClick={clickHandler}
                onDoubleClick={doubleClickHandler} onContextMenu={contextMenuHandler}>
                {props.item.slice(0, count).map((item, idx) => (
                    <td key={`i-${props.item[count]}-${idx}`} className={"py-2 record-item-data"}>
                        <div className="record-item-data">
                            {idx === 0 && <span className="item-type-icon">{props.isFolder ? <IconFolderSvg/>  : <IconRecordSvg/>}</span>}
                            {(props.isFolder && idx > 0) ? "" : (item instanceof Date ? formatDateTime(locale, item as Date) : item ?? "----")}
                        </div>
                    </td>
                ))}
            </tr>
            <tr key={`d-${props.item[count]}`}>
                <td colSpan={count}><HorizontalDivider/></td>
            </tr>
        </Fragment>
    );
}