import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";

interface Props{
    onRetry: () => void;
}

export function AwpFwUploadFailed(props: Props){
    const {t} = useTranslation();
    const onRetry = () => {
        props.onRetry();
    }
    return (
        <div
            className="container-grow d-flex flex-column m-4 justify-content-center align-items-center fw-instruction-text">
            <div className="my-2">{t("awp_fw_upload_failed")}</div>
            <Button variant="primary" className="my-2"
                    onClick={onRetry}>{t("retry")}</Button>
        </div>
    );
}