export const NO_PROBE_VALUE = 0;
export const F0_3 = 105;
export const F0_5 = 106;
export const F2 = 107;
export const F5 = 108;
export const NF0_5 = 109;
export const NF2 = 110;
export const M12 = 111;
export const M30 = 112;
export const M60 = 113;
export const DSH1 = 116;
export const DTVR = 114;
export const DT = 120;