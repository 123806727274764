import {parseFloat, parseInt16, parseInt32, parseString, parseUint32, parseUint8} from "../helpers/BinaryParseHelper";

export interface AwpUt2aSeries {
    adc: ADC;
    asd: ASD;
    strobe: Strobe;
    screen: Screen;
    systemSet: Sys;
    probes: Probes;
}

const CONFIG_SIZE = 5489;
const NEW_CONFIG_SIZE = 5508;
const PROBE_SIZE = 1924;

const SERIAL_SIZE = 11;
const NEW_SERIAL_SIZE = 16;
const PARAM_STROBE_SIZE = 26;

const ADC_SIZE = 132;
const ASD_SIZE = 4214;
const STROBE_SIZE = 238;
const SCREEN_SIZE = 61;

const ASD_OFFSET = ADC_SIZE;
const STROBE_OFFSET = ASD_OFFSET + ASD_SIZE;
const SCREEN_OFFSET = STROBE_OFFSET + STROBE_SIZE;
const SYS_OFFSET = SCREEN_OFFSET + SCREEN_SIZE + 3;

const PARAM_PROBE_SIZE = 96;

interface ADC {
    mmDelay: Array<number>;
    mmScan: Array<number>;
    modePIC2: number;
    rfHw: number;
    gain: number;
    speed: number;
    etalonMm: number;
    startCalibr: number;
    maxMeasAGC: number;
    enableADC: number;
    gainAGC: number;
    maxLevelAGC: number;
    minLevelAGC: number;
    maxGainAGC: number;
    minGainAGC: number;
    agcOnOff: number;
    corrMm: number;
    modeRISE: number;
    startRemote: number;
    nc4: number;
}

interface ASD {
    maxBSCAN: number;
    minBSCAN: number;
    sygnall: number;
    maxCONT: number;
    minCONT: number;
    nominalCONT: number;
    bscan: Array<number>;
    headBSCAN: number;
    tZerro2: Array<number>;
    nZerro2: Array<number>;
    acZerro2: Array<number>;
    tZerro: number;
    nZerro1: number;
    acZerro: number;
    tRassing: number;
    nRassing: number;
    acRassing: number;
    tPicPic: Array<number>;
    nPicPic: Array<number>;
    acPicPic: Array<number>;
    tPicPicT: Array<number>;
    nPicPicT: number;
    acPicPicT: Array<number>;
    tAkf: number;
    nAkf: number;
    acAkf: number;
    tAvto: number;
    nAvto: number;
    acAvto: number;
    metod: number;
    nc: Array<number>;
}

interface Strobe {
    paramStrobe: Array<ParamStrobe>;
    select: number;
}

interface ParamStrobe {
    x: Array<number>;
    y: Array<number>;
    len: Array<number>;
    en: Array<number>;
}

interface Screen {
    filling: number;
    envelope: number;
    view: number;
    typeFilterMeasure: number;
    sourceWork: number;
    globalSource: number;
    sourceBSCAN: number;
    sourceCONTROL: number;
    nc6: number;
    nc7: number;
    nc8: number;
    nc9: number;
    mmInchUs: number;
    midle: number;
    orientation: number;
    enableView: number;
}

interface Sys {
    serial: string;
}

interface ParamProbe {
    Name: string;
    Type: number;
    Dead_Time: number;
    Freq: number;
    N_Pulses: number;
    Ampl_V: number;
    Gain: number;
    AGC_ON_OFF: number;
    Porog: number
    Gate: number;
    V: number;
    K_V: number;
    Delay: number;
    Type_Wave: number;
    Measure_Level: number;
    K_Gain: number;
    Edit: number;
}

interface Probes {
    UsedProbe: number;
    Param: Array<ParamProbe>;
}

function parseAdc(initialBuffer: ArrayBuffer, baseOffset: number): ADC {
    let buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const arraySize = 9;
    const mmDelay = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const mmScan = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const modePIC2 = parseInt32(buffer, offset);
    offset += 4;
    const rfHw = parseInt32(buffer, offset);
    offset += 4;
    const gain = parseUint32(buffer, offset);
    offset += 4;
    const speed = parseInt32(buffer, offset);
    offset += 4;
    const etalonMm = parseInt32(buffer, offset);
    offset += 4;
    const startCalibr = parseUint8(buffer, offset);
    offset += 1;
    buffer = buffer.slice(offset);
    offset = 0;
    const maxMeasAGC = parseInt32(buffer, offset);
    offset += 4;
    const enableADC = parseUint8(buffer, offset);
    offset += 1;
    buffer = buffer.slice(offset);
    offset = 0;
    const gainAGC = parseInt32(buffer, offset);
    offset += 4;
    const maxLevelAGC = parseInt32(buffer, offset);
    offset += 4;
    const minLevelAGC = parseInt32(buffer, offset);
    offset += 4;
    const maxGainAGC = parseInt32(buffer, offset);
    offset += 4;
    const minGainAGC = parseInt32(buffer, offset);
    offset += 4;
    const agcOnOff = parseUint8(buffer, offset);
    offset += 1;
    buffer = buffer.slice(offset);
    offset = 0;
    const corrMm = parseInt32(buffer, offset);
    offset += 4;
    const modeRISE = parseInt32(buffer, offset);
    offset += 4;
    const startRemote = parseUint8(buffer, offset);
    offset += 1;
    buffer = buffer.slice(offset);
    offset = 0;
    const nc4 = parseInt32(buffer, offset);
    return {
        mmDelay: Array.from(mmDelay),
        mmScan: Array.from(mmScan),
        modePIC2: modePIC2,
        rfHw: rfHw,
        gain: gain,
        speed: speed,
        etalonMm: etalonMm,
        startCalibr: startCalibr,
        maxMeasAGC: maxMeasAGC,
        enableADC: enableADC,
        gainAGC: gainAGC,
        maxLevelAGC: maxLevelAGC,
        minLevelAGC: minLevelAGC,
        maxGainAGC: maxGainAGC,
        minGainAGC: minGainAGC,
        agcOnOff: agcOnOff,
        corrMm: corrMm,
        modeRISE: modeRISE,
        startRemote: startRemote,
        nc4: nc4
    }
}

function parseAsd(initialBuffer: ArrayBuffer, baseOffset: number): ASD {
    const arraySize = 2;
    let buffer = initialBuffer.slice(baseOffset + 12);
    let offset = 0;
    const maxBSCAN = parseInt32(buffer, offset);
    offset += 4;
    const minBSCAN = parseInt32(buffer, offset);
    offset += 4;
    const sygnall = parseInt32(buffer, offset);
    offset += 4;
    const maxCONT = parseInt32(buffer, offset);
    offset += 4;
    const minCONT = parseInt32(buffer, offset);
    offset += 4;
    const nominalCONT = parseInt32(buffer, offset);
    offset += 4;
    const bscan = new Float32Array(buffer, offset, 1000);
    offset += 4 * 1000;
    const headBSCAN = parseInt16(buffer, offset);
    offset += 2;
    buffer = buffer.slice(offset);
    offset = 0;
    const tZerro2 = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const nZerro2 = new Int16Array(buffer, offset, arraySize);
    offset += 2 * arraySize;
    const acZerro2 = new Uint32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    buffer = buffer.slice(offset);
    offset = 0;
    const tZerro = parseFloat(buffer, offset);
    offset += 4;
    const nZerro1 = parseInt16(buffer, offset);
    offset += 2;
    buffer = buffer.slice(offset);
    offset = 0;
    const acZerro = parseUint32(buffer, offset);
    offset += 4;
    buffer = buffer.slice(offset);
    offset = 0;
    const tRassing = parseFloat(buffer, offset);
    offset += 4;
    const nRassing = parseInt16(buffer, offset);
    offset += 2;
    buffer = buffer.slice(offset);
    offset = 0;
    const acRassing = parseUint32(buffer, offset);
    offset += 4;
    buffer = buffer.slice(offset);
    offset = 0;
    const tPicPic = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const nPicPic = new Int16Array(buffer, offset, arraySize);
    offset += 2 * arraySize;
    const acPicPic = new Uint8Array(buffer, offset, arraySize);
    offset += arraySize;
    buffer = buffer.slice(offset);
    offset = 0;
    const tPicPicT = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const nPicPicT = parseInt16(buffer, offset);
    offset += 2;
    const acPicPicT = new Uint8Array(buffer, offset, arraySize);
    offset += arraySize;
    buffer = buffer.slice(offset);
    offset = 0;
    const tAkf = parseFloat(buffer, offset);
    offset += 4;
    const nAkf = parseInt16(buffer, offset);
    offset += 2;
    const acAkf = parseUint8(buffer, offset);
    offset += 1;
    buffer = buffer.slice(offset);
    offset = 0;
    const tAvto = parseFloat(buffer, offset);
    offset += 4;
    const nAvto = parseInt16(buffer, offset);
    offset += 2;
    const acAvto = parseUint8(buffer, offset);
    offset += 1;
    buffer = buffer.slice(offset);
    offset = 0;
    const metod = parseInt32(buffer, offset);
    offset += 4;
    const nc = new Int32Array(buffer, offset, 5);
    return {
        maxBSCAN: maxBSCAN,
        minBSCAN: minBSCAN,
        sygnall: sygnall,
        maxCONT: maxCONT,
        minCONT: minCONT,
        nominalCONT: nominalCONT,
        bscan: Array.from(bscan),
        headBSCAN: headBSCAN,
        tZerro2: Array.from(tZerro2),
        nZerro2: Array.from(nZerro2),
        acZerro2: Array.from(acZerro2),
        tZerro: tZerro,
        nZerro1: nZerro1,
        acZerro: acZerro,
        tRassing: tRassing,
        nRassing: nRassing,
        acRassing: acRassing,
        tPicPic: Array.from(tPicPic),
        nPicPic: Array.from(nPicPic),
        acPicPic: Array.from(acPicPic),
        tPicPicT: Array.from(tPicPicT),
        nPicPicT: nPicPicT,
        acPicPicT: Array.from(acPicPicT),
        tAkf: tAkf,
        nAkf: nAkf,
        acAkf: acAkf,
        tAvto: tAvto,
        nAvto: nAvto,
        acAvto: acAvto,
        metod: metod,
        nc: Array.from(nc),
    }
}

function parseStrobe(initialBuffer: ArrayBuffer, baseOffset: number): Strobe {
    let buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const paramStrobe = new Array<ParamStrobe>();
    for (let i = 0; i < 9; i++) {
        paramStrobe.push(parseParamStrobe(buffer, offset));
        offset += PARAM_STROBE_SIZE;
    }
    buffer = buffer.slice(offset);
    offset = 0;
    const select = parseInt32(buffer, offset);
    return {
        paramStrobe: paramStrobe,
        select: select
    }
}

function parseParamStrobe(initialBuffer: ArrayBuffer, baseOffset: number): ParamStrobe {
    const arraySize = 2;
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const x = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const y = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const len = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const en = new Uint8Array(buffer, offset, arraySize);
    return {
        x: Array.from(x),
        y: Array.from(y),
        len: Array.from(len),
        en: Array.from(en)
    }
}

function parseScreen(initialBuffer: ArrayBuffer, baseOffset: number): Screen {
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const filling = parseInt32(buffer, offset);
    offset += 4;
    const envelope = parseInt32(buffer, offset);
    offset += 4;
    const view = parseInt32(buffer, offset);
    offset += 4;
    const typeFilterMeasure = parseInt32(buffer, offset);
    offset += 4;
    const sourceWork = parseInt32(buffer, offset);
    offset += 4;
    const globalSource = parseInt32(buffer, offset);
    offset += 4;
    const sourceBSCAN = parseInt32(buffer, offset);
    offset += 4;
    const sourceCONTROL = parseInt32(buffer, offset);
    offset += 4;
    const nc6 = parseInt32(buffer, offset);
    offset += 4;
    const nc7 = parseInt32(buffer, offset);
    offset += 4;
    const nc8 = parseInt32(buffer, offset);
    offset += 4;
    const nc9 = parseInt32(buffer, offset);
    offset += 4;
    const mmInchUs = parseInt32(buffer, offset);
    offset += 4;
    const midle = parseInt32(buffer, offset);
    offset += 4;
    const orientation = parseInt32(buffer, offset);
    offset += 4;
    const enableView = parseUint8(buffer, offset)
    return {
        filling: filling,
        envelope: envelope,
        view: view,
        typeFilterMeasure: typeFilterMeasure,
        sourceWork: sourceWork,
        globalSource: globalSource,
        sourceBSCAN: sourceBSCAN,
        sourceCONTROL: sourceCONTROL,
        nc6: nc6,
        nc7: nc7,
        nc8: nc8,
        nc9: nc9,
        mmInchUs: mmInchUs,
        midle: midle,
        orientation: orientation,
        enableView: enableView
    }
}

function parseSys(buffer: ArrayBuffer, baseOffset: number, isNew : boolean): Sys {
    const serial = parseString(buffer, baseOffset, isNew ? NEW_SERIAL_SIZE : SERIAL_SIZE);
    return {
        serial: serial
    }
}

function parseProbes(initialBuffer: ArrayBuffer, baseOffset: number): Probes {
    let buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const UsedProbe = parseInt32(buffer, offset);
    offset += 4;
    const Param = new Array<ParamProbe>();
    for (let i = 0; i < 20; i++) {
        Param.push(parseParamProbe(buffer, offset));
        offset += PARAM_PROBE_SIZE;
    }
    return {
        UsedProbe: UsedProbe,
        Param: Param
    }
}

function parseParamProbe(initialBuffer: ArrayBuffer, baseOffset: number): ParamProbe {
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const Name = parseString(buffer, offset, 32);
    offset += 32;
    const Type = parseInt32(buffer, offset);
    offset += 4;
    const Dead_Time = parseInt32(buffer, offset);
    offset += 4;
    const Freq = parseInt32(buffer, offset);
    offset += 4;
    const N_Pulses = parseInt32(buffer, offset);
    offset += 4;
    const Ampl_V = parseInt32(buffer, offset);
    offset += 4;
    const Gain = parseInt32(buffer, offset);
    offset += 4;
    const AGC_ON_OFF = parseInt32(buffer, offset);
    offset += 4;
    const Porog = parseInt32(buffer, offset);
    offset += 4;
    const Gate = parseInt32(buffer, offset);
    offset += 4;
    const V = parseInt32(buffer, offset);
    offset += 4;
    const K_V = parseInt32(buffer, offset);
    offset += 4;
    const Delay = parseInt32(buffer, offset);
    offset += 4;
    const Type_Wave = parseInt32(buffer, offset);
    offset += 4;
    const Measure_Level = parseInt32(buffer, offset);
    offset += 4;
    const K_Gain = parseInt32(buffer, offset);
    offset += 4;
    const Edit = parseInt32(buffer, offset);
    return {
        Name: Name,
        Type: Type,
        Dead_Time: Dead_Time,
        Freq: Freq,
        N_Pulses: N_Pulses,
        Ampl_V: Ampl_V,
        Gain: Gain,
        AGC_ON_OFF: AGC_ON_OFF,
        Porog: Porog,
        Gate: Gate,
        V: V,
        K_V: K_V,
        Delay: Delay,
        Type_Wave: Type_Wave,
        Measure_Level: Measure_Level,
        K_Gain: K_Gain,
        Edit: Edit
    }
}

export function parseAwpUt2aSeries(configBuffer: ArrayBuffer, probesBuffer : ArrayBuffer): AwpUt2aSeries | null {
    if ((configBuffer.byteLength !== CONFIG_SIZE && configBuffer.byteLength !== NEW_CONFIG_SIZE)  || probesBuffer.byteLength !== PROBE_SIZE){
        return null;
    }
    const adc = parseAdc(configBuffer, 0);
    const asd = parseAsd(configBuffer, ASD_OFFSET);
    const strobe = parseStrobe(configBuffer, STROBE_OFFSET);
    const screen = parseScreen(configBuffer, SCREEN_OFFSET);
    const systemSet = parseSys(configBuffer, SYS_OFFSET, configBuffer.byteLength === NEW_CONFIG_SIZE);
    const probes = parseProbes(probesBuffer, 0);
    return {
        adc: adc,
        asd: asd,
        strobe: strobe,
        screen: screen,
        systemSet: systemSet,
        probes: probes
    }
}