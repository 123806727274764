import {useTranslation} from "react-i18next";
import {MeasurementInfo} from "./MeasurementInfo";
import {LeebMeasurement} from "../../models/LeebMeasurement";
import {formatLeebScaleName, formatLeebScaleValue} from "../../helpers/FormatHelper";

interface Props{
    measurement : LeebMeasurement;
    detailsClickListener: (measurement : LeebMeasurement) => void;
}

export function LeebMeasurementWithoutPhoto(props : Props){
    const {t} = useTranslation();
    const avg = `${formatLeebScaleValue(props.measurement.leebScale, props.measurement.middle)} ${formatLeebScaleName(t, props.measurement.leebScale)}`;
    const min = formatLeebScaleValue(props.measurement.leebScale, props.measurement.min);
    const max = formatLeebScaleValue(props.measurement.leebScale, props.measurement.max);
    const count = props.measurement.n.toFixed(0);
    const detailsClickHandler = () => {
        props.detailsClickListener(props.measurement);
    };
    return (
        <MeasurementInfo avg={avg} min={min} max={max} count={count} detailsClickListener={detailsClickHandler}/>
    );
}