import {parseString} from "../helpers/BinaryParseHelper";
import {parseIpsmScale, parseIpsmValue} from "../helpers/AwpIpsmFormatHelper";

export interface AwpIpsmMeasurement {
    value: number;
    scale: number;
}

export interface AwpIpsmSeries {
    deviceSerial: string;
    values: Array<AwpIpsmMeasurement>;
}

export function parseIpsmSeries(buffer: ArrayBuffer): AwpIpsmSeries[] | null {
    const data = parseString(buffer);
    return parseIpsmSeriesFromString(data);
}

export function parseIpsmSeriesFromString(data: string): AwpIpsmSeries[] | null {
    const strings = data.split("\r\n");
    if ((!(strings[0] === "DEVICE IPSM") || !(strings[strings.length - 1] === "END")) && (!(strings[0] === "Џђ€ЃЋђ €Џ‘Њ") || !(strings[strings.length - 1] === "ЉЋЌ…–"))) {
        return null;
    }
    const deviceSerial = strings[1].replaceAll(/\D/gm, "");
    const groups = new Array<Array<string>>();
    for (let i = 2; i < strings.length - 1; i++) {
        const parts = strings[i].split(/\s+/);
        if (parts.length > 1) {
            const groupIndex = Number(parts[1].substring(1));
            while (groups.length <= groupIndex) {
                groups.push([]);
            }
            if (parts.length > 2) {
                groups[groupIndex].push(parts[2]);
            }
        }
    }
    const series = new Array<AwpIpsmSeries>();
    for (const group of groups) {
        if (group.length > 0) {
            const values: Array<AwpIpsmMeasurement> = group.map(v => {
                return {
                    value: parseIpsmValue(v),
                    scale: parseIpsmScale(v)
                } as AwpIpsmMeasurement;
            });
            series.push({
                deviceSerial: deviceSerial,
                values: values
            });
        }
    }
    return series;
}
