import {useTranslation} from "react-i18next";
import {formatNumber} from "../../helpers/FormatHelper";
import './AwpSeriesInfo.css';
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA,
    AwpSeries,
    formatAwpValue
} from "../../helpers/AwpHelper";
import {AwpTudSeries, prepareAwpTudSeriesInfoData} from "../../models/AwpTudSeries";
import {AwpTpSeries, prepareAwpTpSeriesInfoData} from "../../models/AwpTpSeries";
import {AwpKrcSeries, prepareAwpKrcSeriesInfoData} from "../../models/AwpKrcSeries";

interface AwpSeriesInfoData {
    count: number;
    max: number;
    min: number;
    deviation: number;
    avg: number;
    standardDeviation: number;
    meanSquareDeviation: number;
    variationCoefficient: number;
}

interface Props {
    deviceType: number;
    data: AwpSeries;
}

export function AwpMeasurementSeriesInfo(props: Props) {
    const {t} = useTranslation();
    const data = prepareAwpSeriesInfoData(props.deviceType, props.data);
    return <table className="table-measurement-info">
        {data &&
            <tbody>
            <tr>
                <th>{t('maximum')}</th>
                <td>{formatAwpValue(props.deviceType, data.max, props.data)}</td>
            </tr>
            <tr>
                <th>{t('minimum')}</th>
                <td>{formatAwpValue(props.deviceType, data.min, props.data)}</td>
            </tr>
            <tr>
                <th>{t('deviation')}</th>
                <td>{formatAwpValue(props.deviceType, data.deviation, props.data)}</td>
            </tr>
            <tr>
                <th>{t('average')}</th>
                <td>{formatAwpValue(props.deviceType, data.avg, props.data)}</td>
            </tr>
            <tr>
                <th>{t('standard_deviation')}</th>
                <td>{formatNumber(data.standardDeviation, 1)} %</td>
            </tr>
            <tr>
                <th>{t('mean_square_deviation')}</th>
                <td>{formatNumber(data.meanSquareDeviation, 1)}</td>
            </tr>
            <tr>
                <th>{t('variation_coefficient')}</th>
                <td>{formatNumber(data.variationCoefficient, 1)} %</td>
            </tr>
            <tr>
                <th>{t('homogeneity_of_series')}</th>
                <td>{data.variationCoefficient < 33 ? t('yes') : t('no')}</td>
            </tr>
            <tr>
                <th>{t('number_of_measurements')}</th>
                <td>{data.count}</td>
            </tr>
            </tbody>
        }
    </table>
}

export function prepareAwpSeriesInfoData(deviceType: number, series: AwpSeries) : AwpSeriesInfoData | undefined {
    switch (deviceType) {
        case AWP_DEVICE_TUD:
            return prepareAwpTudSeriesInfoData(series as AwpTudSeries);
        case AWP_DEVICE_KRC:
            return prepareAwpKrcSeriesInfoData(series as AwpKrcSeries);
        case AWP_DEVICE_TP:
            return prepareAwpTpSeriesInfoData(series as AwpTpSeries);
        case AWP_DEVICE_UT:
        case AWP_DEVICE_MF:
        case AWP_DEVICE_UD2301:
        case AWP_DEVICE_UD2303:
        case AWP_DEVICE_UD3701:
        case AWP_DEVICE_UT3EMA:
        case AWP_DEVICE_UT2A:
        case AWP_DEVICE_IPSM:
            throw new Error("Wrong device");
    }
    return undefined;
}
