import {Dropdown, DropdownButton, FormCheck} from "react-bootstrap";
import {formatDeviceName} from "../../helpers/FormatHelper";
import './DeviceFilterSwitcher.css';
import {useTranslation} from "react-i18next";
import IconDeviceSvg from "../Icons/IconDeviceSvg";

interface Props {
    filters: Map<string, boolean>;
    filtersChangeListener: (newFilters: Map<string, boolean>) => void;
    minimized?: boolean;
}

export function DeviceFilterSwitcher(props: Props) {
    const {t} = useTranslation();
    return (
        <DropdownButton title={props.minimized ? <IconDeviceSvg/> : t('devices')} className="no-select">
            {Array.from(props.filters.entries()).map(([k, v]) => (
                <Dropdown.ItemText key={`it-${k}`}>
                    <FormCheck key={`fc-${k}`} label={formatDeviceName(t, k)}
                               defaultChecked={v}
                               onChange={() => {
                                   const result = new Map<string, boolean>();
                                   props.filters.forEach((nv, nk) => {
                                       result.set(nk, k === nk ? !nv : nv)
                                   })
                                   props.filtersChangeListener(result);
                               }
                               }/>
                </Dropdown.ItemText>
            ))}
        </DropdownButton>
    );
}