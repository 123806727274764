import {useTranslation} from "react-i18next";
import {AwpSeries} from "../../helpers/AwpHelper";
import {AwpUd2301Series} from "../../models/AwpUd2301Series";
import {formatNumber} from "../../helpers/FormatHelper";
import {DATA_PLACEHOLDER} from "../../AppSettings";
import './AwpSeriesInfo.css';
import {getUd2301Result} from "../../helpers/AwpUd2301FormatHelper";

interface Props {
    data: AwpSeries;
}

export function AwpUd2301ResultInfo(props: Props) {
    const {t} = useTranslation();
    const data = props.data as AwpUd2301Series;
    const result = getUd2301Result(t, data);
    return (
        <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-stretch">
            <table className="table-data">
                <thead>
                <tr>
                    <th>{t('parameter')}</th>
                    <th>{t('awp_gate_a')}</th>
                    <th>{t('awp_gate_b')}</th>
                    <th>{t('awp_gate_a_gate_b')}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>{t('awp_t_us')}</th>
                    <td>{result.tA}</td>
                    <td>{result.tB}</td>
                    <td>{result.tAB}</td>
                </tr>
                <tr>
                    <th>{t('awp_a_db')}</th>
                    <td>{result.aA}</td>
                    <td>{result.aB}</td>
                    <td>{result.aAB}</td>
                </tr>
                <tr>
                    <th>{t('awp_xb_mm')}</th>
                    <td>{result.xbA}</td>
                    <td>{result.xbB}</td>
                    <td/>
                </tr>
                <tr>
                    <th>{t('awp_xip_mm')}</th>
                    <td>{result.xipA}</td>
                    <td>{result.xipB}</td>
                    <td/>
                </tr>
                <tr>
                    <th>{t('awp_y_mm')}</th>
                    <td>{result.yA}</td>
                    <td>{result.yB}</td>
                    <td>{result.yAB}</td>
                </tr>
                <tr>
                    <th>{t('awp_s_mm2')}</th>
                    <td>{result.sA}</td>
                    <td>{result.sB}</td>
                    <td/>
                </tr>
                <tr>
                    <th>{t('awp_l_mm')}</th>
                    <td>{result.lA}</td>
                    <td>{result.lB}</td>
                    <td>{result.lAB}</td>
                </tr>
                <tr>
                    <th>{t('awp_da_db')}</th>
                    <td>{result.daA}</td>
                    <td>{result.daB}</td>
                    <td>{result.daAB}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}