import './NotFound.css';
import {useTranslation} from "react-i18next";

export function NotFoundSmall(){
    const {t} = useTranslation();
    return (
        <div className="container-grow d-flex flex-column justify-content-center align-items-center p-2 no-select">
            <span className="not-found">{t('resource_is_missing')}</span>
        </div>
    );
}