import './ToolBarButton.css';
import {OverlayTrigger, Popover} from "react-bootstrap";
import {OverlayTriggerType} from "react-bootstrap/OverlayTrigger";

interface Props {
    icon: JSX.Element;
    activated?: boolean;
    disabled?: boolean;
    clickHandler: () => void;
    popoverText?: string;
}

export function ToolBarButton(props: Props) {
    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <span style={{display:"block", textAlign: "center"}} dangerouslySetInnerHTML={{__html: props.popoverText ?? ""}}/>
            </Popover.Body>
        </Popover>
    );
    const triggers : OverlayTriggerType[] = props.popoverText ? ["hover","focus"] : [];
    return (
        <OverlayTrigger trigger={triggers} placement="bottom" overlay={popover}>
            <div
                className={`d-flex justify-content-center align-items-center toolbar-button-container ${props.disabled ? "toolbar-button-container-disabled" : ""} ${props.activated ? "toolbar-button-container-active" : ""}`}
                onClick={props.clickHandler}>
                {props.icon}
            </div>
        </OverlayTrigger>
    );
}