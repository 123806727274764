import {Carousel, Modal} from "react-bootstrap";
import {useState} from "react";
import './MeasurementDetails.css';
import {Loader} from "../Loader/Loader";
import {useTranslation} from "react-i18next";
import {DpmMeasurement} from "../../models/DpmMeasurement";
import {MeasurementsDpmChart} from "./MeasurementsDpmChart";

interface Props {
    measurement: DpmMeasurement | null;
    closeHandler: () => void;
    modalContainer: HTMLDivElement | undefined;
}

export function DpmMeasurementDetails(props: Props) {
    const {t} = useTranslation();
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };
    return (
        <Modal show={props.measurement !== null} size="lg" centered onHide={props.closeHandler}
               container={props.modalContainer}>
            <Modal.Header closeButton>{t('measurements')}</Modal.Header>
            <Modal.Body>
                {props.measurement ?
                    <Carousel activeIndex={index} onSelect={handleSelect} controls interval={null}>
                        <Carousel.Item>
                            <MeasurementsDpmChart classNames="measurement-details-container" data={props.measurement}/>
                        </Carousel.Item>
                    </Carousel> : <Loader/>
                }
            </Modal.Body>
        </Modal>
    );
}