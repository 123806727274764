import {useTranslation} from "react-i18next";
import {Button, Form, Modal} from "react-bootstrap";
import IconPasteSvg from "../Icons/IconPasteSvg";
import {useRef, useState} from "react";

interface Props {
    show: boolean;
    closeHandler: (code?: string) => void;
}

export function StartAssistDialog(props: Props) {
    const {t} = useTranslation();
    const ref = useRef<HTMLInputElement>(null);
    const [isCodeValid, setCodeValid] =useState(false);
    const codeChangeHandler = () => {
        if (ref.current){
            setCodeValid(ref.current.value.length === 8);
            return;
        }
        setCodeValid(false);
    };
    const pasteButtonClickHandler = () => {
        navigator.clipboard.readText().then((text) => {
            if (ref.current){
                ref.current.value = text;
                codeChangeHandler();
            }
        });
    };
    return (
        <Modal show={props.show} onHide={() => props.closeHandler()}>
            <Modal.Header closeButton>
                <Modal.Title>{t("loader_remote_assistance")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>{t("loader_remote_assistance_connect_prompt")}</div>
                <div className="d-flex align-self-stretch flex-row justify-content-between align-items-center my-4">
                    <Form.Control type="text" className="flex-grow fw-bold text-center"
                                  ref={ref} onChange={codeChangeHandler}/>
                    <Button className="ms-4" variant="outline-primary"
                            onClick={pasteButtonClickHandler}><IconPasteSvg/></Button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="mx-2"
                        onClick={() => props.closeHandler()}>{t("cancel")}</Button>
                <Button variant="primary" className="ms-2" disabled={!isCodeValid}
                        onClick={() => props.closeHandler(ref.current?.value)}>{t("ok")}</Button>
            </Modal.Footer>
        </Modal>
    );
}