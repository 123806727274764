import './PeriodFilterSwitch.css';
import {useTranslation} from "react-i18next";
import {Dropdown, DropdownButton, FormCheck} from "react-bootstrap";
import {useState} from "react";
import IconHistorySvg from "../Icons/IconHistorySvg";

interface Props{
    activeValue: string;
    activeValueChangeListener: (newValue: string) => void;
    minimized?: boolean;
}

export const PERIOD_ALL = "period_all";
export const PERIOD_7_DAYS = "period_7_days";
export const PERIOD_14_DAYS = "period_14_days";
export const PERIOD_30_DAYS = "period_30_days";
export const PERIOD_90_DAYS = "period_90_days";

export const DEFAULT_PERIOD = PERIOD_ALL;

const options = [PERIOD_ALL, PERIOD_7_DAYS, PERIOD_14_DAYS, PERIOD_30_DAYS, PERIOD_90_DAYS];
// const options = [PERIOD_7_DAYS, PERIOD_14_DAYS, PERIOD_30_DAYS];

const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

export function getMaxTimestamp(period: string){
    const now = new Date().getTime();
    switch (period){
        case PERIOD_7_DAYS:
            return now - 7 * MILLISECONDS_PER_DAY;
        case PERIOD_14_DAYS:
            return now - 14 * MILLISECONDS_PER_DAY;
        case PERIOD_30_DAYS:
            return now - 30 * MILLISECONDS_PER_DAY;
        case PERIOD_90_DAYS:
            return now - 90 * MILLISECONDS_PER_DAY;
        default:
            return 0;
    }
}

export function PeriodFilterSwitch(props: Props){
    const {t} = useTranslation();
    const [updateCounter, setUpdateCounter] = useState(0);
    return (
        <DropdownButton title={props.minimized ? <IconHistorySvg/> : t('executors_period')} className="no-select">
            {options.map(o =>
                <Dropdown.ItemText key={`it-${o}`}>
                    <FormCheck key={`fc-${o}-${updateCounter}`} label={t(o)}
                               defaultChecked={o === props.activeValue}
                               onChange={() => {
                                   props.activeValueChangeListener(o);
                                   setUpdateCounter(updateCounter + 1);
                               }}/>
                </Dropdown.ItemText>
            )}
        </DropdownButton>
    );
}