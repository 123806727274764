import {MeasurementInfo} from "./MeasurementInfo";
import {formatMfScaleName, formatMfScaleValue} from "../../helpers/FormatHelper";
import {useTranslation} from "react-i18next";
import {MfMeasurement} from "../../models/MfMeasurement";

interface Props{
    measurement : MfMeasurement;
    detailsClickListener: (measurement : MfMeasurement) => void;
}

export function MfMeasurementWithoutPhoto(props : Props){
    const {t} = useTranslation();
    const measurements = props.measurement.measurements;
    let min = Number.MAX_VALUE;
    let max = -Number.MAX_VALUE;
    let sum = 0;
    for (let item of measurements){
        min = Math.min(min, item);
        max = Math.max(max, item);
        sum += item;
    }
    let avg = sum / measurements.length;
    const avgString = `${formatMfScaleValue(props.measurement.mfScale, avg)} ${formatMfScaleName(t, props.measurement.mfScale)}`;
    const minString = measurements.length > 1 ? formatMfScaleValue(props.measurement.mfScale, min) : undefined;
    const maxString = measurements.length > 1 ? formatMfScaleValue(props.measurement.mfScale, max) : undefined;
    const detailsClickHandler = () => {
        props.detailsClickListener(props.measurement);
    };
    return (
        <MeasurementInfo avg={avgString} min={minString} max={maxString} detailsClickListener={measurements.length > 1 ? detailsClickHandler : undefined}/>
    );
}