import {TpMeasurement} from "../models/TpMeasurement";
import {TudMeasurement} from "../models/TudMeasurement";
import {UtMeasurement} from "../models/UtMeasurement";
import {
    CsvSeries,
    makeAwpIpsmSeries,
    makeAwpMfSeries,
    makeAwpTpSeries,
    makeAwpTudKrcCsvSeries,
    makeAwpUd2301Series,
    makeAwpUd2303Series,
    makeAwpUd3701Series,
    makeAwpUt2aSeries,
    makeAwpUt3EmaSeries,
    makeAwpUtSeries,
    makeDpmCsvSeries,
    makeLeebCsvSeries,
    makeMfCsvSeries,
    makeTpCsvSeries,
    makeTpDewPointCsvSeries,
    makeTpSurfaceTemperatureCsvSeries,
    makeTudCsvSeries,
    makeUciCsvSeries,
    makeUtCsvSeries
} from "./CsvSeries";
import {UciMeasurement} from "../models/UciMeasurement";
import {AwpTudSeries} from "../models/AwpTudSeries";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA,
    AwpRecord
} from "../helpers/AwpHelper";
import {AwpTpSeries} from "../models/AwpTpSeries";
import {AwpUtSeries} from "../models/AwpUtSeries";
import {AwpMfSeries} from "../models/AwpMfSeries";
import {AwpUd2303Series} from "../models/AwpUd2303Series";
import {AwpUd2301Series} from "../models/AwpUd2301Series";
import {AwpUd3701Series} from "../models/AwpUd3701Series";
import {AwpUt3EmaSeries} from "../models/AwpUt3EmaSeries";
import {AwpUt2aSeries} from "../models/AwpUt2aSeries";
import {MfMeasurement} from "../models/MfMeasurement";
import {AwpIpsmSeries} from "../models/AwpIpsmSeries";
import {TpSurfaceTemperatureMeasurement} from "../models/TpSurfaceTemperatureMeasurement";
import {TpDewPointMeasurement} from "../models/TpDewPointMeasurement";
import {LeebMeasurement} from "../models/LeebMeasurement";
import {DpmMeasurement} from "../models/DpmMeasurement";

const DELIMITER = ",";
const STRING_DELIMITER = ",\n";

export function createTpCsv(name: string, measurements: TpMeasurement[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        const title = `Measurement series #${i + 1}`;
        csvSeries.push(makeTpCsvSeries(title, measurements[i]));
    }
    createCsv(name, csvSeries);
}

export function createTpSurfaceTemperatureCsv(name: string, measurements: TpSurfaceTemperatureMeasurement[]) {
    const csvSeries = new Array<CsvSeries>();
    const title = `Measurements`;
    csvSeries.push(makeTpSurfaceTemperatureCsvSeries(title, measurements));
    createCsv(name, csvSeries);
}

export function createTpDewPointCsv(name: string, measurements: TpDewPointMeasurement[]) {
    const csvSeries = new Array<CsvSeries>();
    const title = `Measurements`;
    csvSeries.push(makeTpDewPointCsvSeries(title, measurements));
    createCsv(name, csvSeries);
}

export function createTudCsv(name: string, measurements: TudMeasurement[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        const title = `Measurement series #${i + 1}`;
        csvSeries.push(makeTudCsvSeries(title, measurements[i]));
    }
    createCsv(name, csvSeries);
}

export function createLeebCsv(name: string, measurements: LeebMeasurement[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        const title = `Measurement series #${i + 1}`;
        csvSeries.push(makeLeebCsvSeries(title, measurements[i]));
    }
    createCsv(name, csvSeries);
}

export function createUciCsv(name: string, measurements: UciMeasurement[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        const title = `Measurement series #${i + 1}`;
        csvSeries.push(makeUciCsvSeries(title, measurements[i]));
    }
    createCsv(name, csvSeries);
}

export function createUtCsv(name: string, measurements: UtMeasurement[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        const title = `Measurement series #${i + 1}`;
        csvSeries.push(makeUtCsvSeries(title, measurements[i]));
    }
    createCsv(name, csvSeries);
}

export function createMfCsv(name: string, measurements: MfMeasurement[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        const title = `Measurement series #${i + 1}`;
        csvSeries.push(makeMfCsvSeries(title, measurements[i]));
    }
    createCsv(name, csvSeries);
}

export function createDpmCsv(name: string, measurements: DpmMeasurement[]) {
    const csvSeries = new Array<CsvSeries>();
    const title = "Measuring";
    csvSeries.push(makeDpmCsvSeries(title, measurements));
    createCsv(name, csvSeries);
}

export function createAwpCsv(deviceType: number, name: string, record: AwpRecord){
    switch (deviceType){
        case AWP_DEVICE_TUD:
        case AWP_DEVICE_KRC:
            createAwpTudKrcCsv(name, record.series as AwpTudSeries[]);
            break;
        case AWP_DEVICE_TP:
            createAwpTpCsv(name, record.series as AwpTpSeries[]);
            break;
        case AWP_DEVICE_UT:
            createAwpUtCsv(name, record.series as AwpUtSeries[]);
            break;
        case AWP_DEVICE_MF:
            createAwpMfCsv(name, record.series as AwpMfSeries[]);
            break;
        case AWP_DEVICE_UD2301:
            createAwpUd2301Csv(name, record.series as AwpUd2301Series[]);
            break;
        case AWP_DEVICE_UD2303:
            createAwpUd2303Csv(name, record.series as AwpUd2303Series[]);
            break;
        case AWP_DEVICE_UD3701:
            createAwpUd3701Csv(name, record.series as AwpUd3701Series[]);
            break;
        case AWP_DEVICE_UT3EMA:
            createAwpUt3EmaCsv(name, record.series as AwpUt3EmaSeries[]);
            break;
        case AWP_DEVICE_UT2A:
            createAwpUt2aCsv(name, record.series as AwpUt2aSeries[]);
            break;
        case AWP_DEVICE_IPSM:
            createAwpIpsmCsv(name, record.series as AwpIpsmSeries[]);
            break;
    }

}

function createAwpTudKrcCsv(name: string, measurements: AwpTudSeries[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        const title = `Measurement series #${i + 1}`;
        csvSeries.push(makeAwpTudKrcCsvSeries(title, measurements[i]));
    }
    createCsv(name, csvSeries);
}

function createAwpTpCsv(name: string, measurements: AwpTpSeries[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        const title = `Measurement series #${i + 1}`;
        csvSeries.push(makeAwpTpSeries(title, measurements[i]));
    }
    createCsv(name, csvSeries);
}

function createAwpUtCsv(name: string, measurements: AwpUtSeries[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        const title = `Measurement series #${i + 1}`;
        csvSeries.push(makeAwpUtSeries(title, measurements[i]));
    }
    createCsv(name, csvSeries);
}

function createAwpMfCsv(name: string, measurements: AwpMfSeries[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        const title = `Measurement series #${i + 1}`;
        csvSeries.push(makeAwpMfSeries(title, measurements[i]));
    }
    createCsv(name, csvSeries);
}

function createAwpUd2301Csv(name: string, measurements: AwpUd2301Series[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        csvSeries.push(...makeAwpUd2301Series(measurements[i]));
    }
    createCsv(name, csvSeries);
}

function createAwpUd2303Csv(name: string, measurements: AwpUd2303Series[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        csvSeries.push(...makeAwpUd2303Series(measurements[i]));
    }
    createCsv(name, csvSeries);
}

function createAwpUd3701Csv(name: string, measurements: AwpUd3701Series[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        csvSeries.push(...makeAwpUd3701Series(measurements[i]));
    }
    createCsv(name, csvSeries);
}

function createAwpUt3EmaCsv(name: string, measurements: AwpUt3EmaSeries[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        csvSeries.push(...makeAwpUt3EmaSeries(measurements[i]));
    }
    createCsv(name, csvSeries);
}

function createAwpUt2aCsv(name: string, measurements: AwpUt2aSeries[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        csvSeries.push(...makeAwpUt2aSeries(measurements[i]));
    }
    createCsv(name, csvSeries);
}

function createAwpIpsmCsv(name: string, measurements: AwpIpsmSeries[]) {
    const csvSeries = new Array<CsvSeries>();
    for (let i = 0; i < measurements.length; i++) {
        const title = `Measurement group #${i + 1}`;
        csvSeries.push(makeAwpIpsmSeries(title, measurements[i]));
    }
    createCsv(name, csvSeries);
}

function createCsv(name: string, csvSeries: CsvSeries[]){
    let content = "\"sep=,\"\n";
    let isFirst = true;
    for (const series of csvSeries) {
        if (isFirst){
            isFirst = false;
        } else {
            content += STRING_DELIMITER;
        }
        content += series.title;
        content += STRING_DELIMITER;
        content += series.columnTitles.join(DELIMITER);
        content += STRING_DELIMITER;
        for (const columns of series.columnData) {
            content += columns.join(DELIMITER);
            content += STRING_DELIMITER;
        }
    }
    const a = document.createElement("a");
    const file = new Blob([content], {type: "text/plain"});
    a.href = URL.createObjectURL(file);
    a.download = `${name}.csv`;
    a.click();
    a.remove();
}