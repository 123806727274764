import {useTranslation} from "react-i18next";
import {Button, Modal} from "react-bootstrap";
import {FwWriteRequest} from "./AwpFwLoaderCommon";

interface Props {
    request?: FwWriteRequest;
    closeHandler: (request?: FwWriteRequest) => void;
}

export function FwRemoteWriteConfirmationDialog(props: Props) {
    const {t} = useTranslation();
    return (
        <Modal show={props.request !== undefined} onHide={() => props.closeHandler()}>
            <Modal.Header closeButton>
                <Modal.Title>{t("awp_fw_upload_warning_1")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>{t("assist_fw_write_request")}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.closeHandler()}>{t("reject")}</Button>
                <Button variant="primary"
                        onClick={() => props.closeHandler(props.request)}>{t("confirm")}</Button>
            </Modal.Footer>
        </Modal>
    );
}