import './VideoNotSupported.css';
import {useTranslation} from "react-i18next";
import {buildListPath} from "../../routes";
import IconDownloadSvg from "../Icons/IconDownloadSvg";

interface Props{
    downloadClickHandler : () => void;
}

export function VideoNotSupported(props: Props){
    const {t} = useTranslation();
    return (
        <div className="container-grow d-flex flex-column justify-content-center align-items-center p-2 no-select">
            <span className="not-supported no-select">{t('video_not_supported')}</span>
            <span className="mt-1 not-supported-link" onClick={props.downloadClickHandler}><IconDownloadSvg/> {t('download_video')}</span>
        </div>
    );
}