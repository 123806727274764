import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {Button, Modal} from "react-bootstrap";
import mqtt from "mqtt";
import {Loader} from "../Loader/Loader";
import {sendEnumeratePortsCommand} from "../../mqtt/MqttHelper";
import {MQTT_TOPIC_SUPPORT} from "../../AppSettings";

interface Props {
    show: number;
    mqttClient?: mqtt.MqttClient;
    code?: string;
    devices?: SerialPortInfo[];
    closeHandler: (device?: SerialPortInfo) => void;
}

export function AssistConnectDialog(props: Props) {
    const {t} = useTranslation();
    useEffect(() => {
        if (props.show % 2 !== 0) {
            if (props.mqttClient && props.code) {
                sendEnumeratePortsCommand(props.mqttClient, props.code, MQTT_TOPIC_SUPPORT);
            }
        }
    }, [props.show, props.mqttClient, props.code]);
    return (
        <Modal show={props.show % 2 !== 0} onHide={() => props.closeHandler()}>
            <Modal.Header closeButton>
                <Modal.Title>{t("loader_remote_assistance_select_device")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.devices === undefined &&
                    <Loader message={t("loader_remote_assistance_search_for_devices")}/>
                }
                {props.devices && props.devices.length === 0 &&
                    <div>{t("loader_remote_assistance_no_available_devices")}</div>
                }
                {props.devices && props.devices.length > 0 &&
                    <table>
                        <tbody>
                        {props.devices.map((d,i) =>
                            <tr key={`dev-${i}`}>
                                <td width="100%">COM Port [PID: 0x{d.usbProductId?.toString(16)?.toUpperCase() ?? "-"}, VID: 0x{d.usbVendorId?.toString(16)?.toUpperCase() ?? "-"}]</td>
                                <td>
                                    <Button size="sm" onClick={() => props.closeHandler(d)}>{t("loader_connect")}</Button>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="mx-2"
                        onClick={() => props.closeHandler()}>{t("cancel")}</Button>
            </Modal.Footer>
        </Modal>
    );
}