import {useTranslation} from "react-i18next";
import './AwpSeriesInfo.css';
import {AwpIpsmSeries} from "../../models/AwpIpsmSeries";
import {formatAwpIpsmScaleName, formatAwpIpsmValue} from "../../helpers/AwpIpsmFormatHelper";

interface Props {
    data: AwpIpsmSeries;
}

export function AwpIpsmSeriesInfo(props: Props) {
    const {t} = useTranslation();
    return <table className="table-ipsm-measurement-info">
        <tbody>
        <tr>
            <th>{t('measurement')}</th>
            <th>{t('value')}</th>
        </tr>
        {props.data.values.map((measurement, i) =>
            <tr key={`ipsm-row-${i}`}>
                <td>{i+1}</td>
                <td>{formatAwpIpsmValue(measurement.value, measurement.scale)} {formatAwpIpsmScaleName(t, measurement.scale)}</td>
            </tr>
        )}
        </tbody>
    </table>
}

