import {AwpUt3EmaSeries} from "../models/AwpUt3EmaSeries";
import {TFunction} from "i18next";
import {DATA_PLACEHOLDER} from "../AppSettings";
import {formatNumber} from "./FormatHelper";

export interface Ut3EmaDeviceInfo{
    workMode : string;
    measMode : string;
    gain  :string;
    middle : string;
    durationTitle : string;
    duration : string;
    delayTitle: string;
    delay: string;
    velocityTitle: string;
    velocity: string;
    durationDelayUnits: string;
    velocityUnits: string;
}

export interface Ut3EmaResult{
    value?: string;
    units: string;
    display : string;
}

export interface Ut3EmaStrobeInfo{
    startA : string;
    startB: string;
    widthA: string;
    widthB: string;
    thresholdA : string;
    thresholdB: string;
    levelA: string;
    levelB: string;
}

export interface Ut3EmaTransducerInfo{
    name : string;
    type: string;
    freq: string;
    delay: string;
    amplV: string;
    nPulses: string;
}

export function getUt3EmaDeviceInfo(t: TFunction<"translation">, data: AwpUt3EmaSeries) : Ut3EmaDeviceInfo{
    const [workMode, measMode] = formatUt3EmaProbeInfo(t, data);
    let sweepDuration = data.adc.mmScan[data.screen.Global_Source] / 10;
    let sweepDelay = data.adc.mmDelay[data.screen.Global_Source] / 10;
    let formatNumbers = 1;
    if (data.screen.mm_uS){
        sweepDuration /= 25.4;
        sweepDelay /= 25.4;
        formatNumbers= 3;
    }
    return  {
        workMode : workMode !== "" ? workMode : DATA_PLACEHOLDER,
        measMode : measMode !== "" ? measMode : DATA_PLACEHOLDER,
        gain  :data.adc.agcOnOff > 0 ? "AGC" : formatNumber(data.adc.gain / 10, 1, 1),
        middle : formatNumber(Math.pow(2, data.screen.Midle), 0, 0),
        durationTitle : `${t('awp_sweep_duration')}, ${t(data.screen.mm_uS ? 'in' : 'mm')}`,
        duration : formatNumber(sweepDuration, formatNumbers, formatNumbers),
        delayTitle: `${t('awp_sweep_delay')}, ${t(data.screen.mm_uS ? 'in' : 'mm')}`,
        delay: formatNumber(sweepDelay, formatNumbers, formatNumbers),
        velocityTitle: `${t('velocity')}, ${t(data.screen.mm_uS ? 'scale_inpus' : 'scale_mps')}`,
        velocity: data.screen.mm_uS ? formatNumber(data.adc.speed / 1000, 3, 3) : formatNumber(data.adc.speed, 0, 0),
        durationDelayUnits: t(data.screen.mm_uS ? 'in' : 'mm'),
        velocityUnits: t(data.screen.mm_uS ? 'scale_inpus' : 'scale_mps')
    }
}

export function getUt3EmaResult(t: TFunction<"translation">, data: AwpUt3EmaSeries) : Ut3EmaResult{
    let ready = false;
    let value : number | undefined;
    let time;
    let source;
    switch (data.screen.View) {
        case 0:
            source = data.screen.Global_Source;
            break;
        case 1:
            source = data.screen.Source_Work;
            break;
        case 2:
            source = data.screen.Source_BSCAN;
            break;
        case 3:
            source = data.screen.Source_CONTROL;
            break;
    }
    switch (source) {
        case 1:
            ready = data.asd.AC_AKF > 0;
            time = data.asd.T_AKF;
            value = calcDist(time, data);
            break;
        case 0:
            [ready, value] = checkRaceAKF(data);
            break;
        case 5:
            ready = ((data.asd.AC_ZERRO2[0] === 1) && (data.asd.AC_ZERRO2[1] === 1));
            time = Math.abs(data.asd.T_ZERRO2[0] - data.asd.T_ZERRO2[1]);
            value = calcDist(time, data);
            break;
        case 3:
            ready = (data.asd.AC_ZERRO1 === 1);
            time = data.asd.T_ZERRO1 - data.probes.probes[data.probes.usedProbe].delay;
            value = calcDist(time, data);
            break;
        case 4:
            if (data.adc.modePIC2) {
                ready = ((data.asd.AC_PIC_PIC[0] > 0 && data.asd.AC_PIC_PIC[1] > 0));
                time = Math.abs(data.asd.T_PIC_PIC[0] - data.asd.T_PIC_PIC[1]);
            } else {
                ready = ((data.asd.AC_PIC_PIC_T[0] > 0 && data.asd.AC_PIC_PIC_T[1] > 0));
                time = Math.abs(data.asd.T_PIC_PIC_T[0] - data.asd.T_PIC_PIC_T[1]);
            }
            value = calcDist(time, data);
            break;
        case 2: {
            ready = data.asd.AC_RASSING > 0;
            time = data.asd.T_RASSING - data.probes.probes[data.probes.usedProbe].delay;
            value = calcDist(time, data);
            break;
        }
    }

    const formatDigits = data.screen.mm_uS ? 3 : 2;

    if (ready && value) {
        if (data.screen.mm_uS) {
            value /= 25.4;
        }
    } else {
        value = undefined;
    }
    return {
        value: value !== undefined ? formatNumber(value, formatDigits, formatDigits) : undefined,
        units: t(data.screen.mm_uS ? 'in' : 'mm'),
        display: value !== undefined ? `${formatNumber(value, formatDigits, formatDigits)} ${t(data.screen.mm_uS ? 'in' : 'mm')}` : "----"
    }
}

export function getUt3EmaTransducerInfo(t: TFunction<"translation">, data: AwpUt3EmaSeries) : Ut3EmaTransducerInfo{
    const usedProbe = data.probes.usedProbe;
    let sensorType = DATA_PLACEHOLDER;
    switch (data.probes.probes[usedProbe].type) {
        case 1:
            sensorType = "SINGLE";
            break;
        case 0:
            sensorType = "DUAL";
            break;
        case 2:
            sensorType = "EMAT";
            break;
    }
    return  {
        name : data.probes.probes[usedProbe].name,
        type: sensorType,
        freq: formatNumber(data.probes.probes[usedProbe].freq / 100, 2, 2),
        delay: formatNumber(data.probes.probes[usedProbe].delay / 40, 3, 1),
        amplV: formatNumber(data.probes.probes[usedProbe].amplV, 0, 0),
        nPulses: formatNumber(data.probes.probes[usedProbe].nPulses, 0, 0)
    }
}

export function getUt3EmaStrobeInfo(t: TFunction<"translation">, data: AwpUt3EmaSeries) : Ut3EmaStrobeInfo{
    let strobeIdx = data.strobe.select;
    let startA : string | undefined = formatNumber(data.strobe.paramStrobe[strobeIdx].x[0] / 10, 1, 1);
    let startB : string | undefined = formatNumber(data.strobe.paramStrobe[strobeIdx].x[1] / 10, 1, 1);
    let widthA : string | undefined = formatNumber(data.strobe.paramStrobe[strobeIdx].len[0] / 10, 1, 1);
    let widthB : string | undefined = formatNumber(data.strobe.paramStrobe[strobeIdx].len[1] / 10, 1, 1);
    let levelA : string | undefined = formatNumber(data.strobe.paramStrobe[strobeIdx].y[0], 1, 1);
    let levelB : string | undefined = formatNumber(data.strobe.paramStrobe[strobeIdx].y[1], 1, 1);
    let thresholdA : string | undefined = formatNumber(data.strobe.paramStrobe[strobeIdx].y[0], 1, 1);
    let thresholdB : string | undefined = formatNumber(data.strobe.paramStrobe[strobeIdx].y[1], 1, 1);
    switch (data.screen.View) {
        case 0:
            levelA = levelB = undefined;
            break;
        case 1:
            switch (data.screen.Source_Work) {
                case 1:
                    levelA = levelB = undefined;
                    break;
                case 2:
                    break;
                case 3:
                    widthA = widthB = undefined;
                    levelA = levelB = undefined;
                    break;
                case 4:
                    levelA = levelB = undefined;
                    break;
                case 5:
                    levelA = levelB = undefined;
                    break;
            }
            break;
        case 2:
            switch (data.screen.Source_BSCAN) {
                case 0:
                    levelA = levelB = undefined;
                    break;
                case 1:
                    levelA = levelB = undefined;
                    break;
                case 2:
                    break;
                case 3:
                    widthA = widthB = undefined;
                    levelA = levelB = undefined;
                    break;
                case 4:
                    levelA = levelB = undefined;
                    break;
                case 5:
                    levelA = levelB = undefined;
                    break;
            }
            break;
        case 3:
            startA = startB = undefined;
            widthA = widthB = undefined;
            levelA = levelB = undefined;
            thresholdA = thresholdB = undefined;
            break;
    }
    return {
        startA : startA ?? DATA_PLACEHOLDER,
        startB: startB ?? DATA_PLACEHOLDER,
        widthA: widthA ?? DATA_PLACEHOLDER,
        widthB: widthB ?? DATA_PLACEHOLDER,
        thresholdA : thresholdA ?? DATA_PLACEHOLDER,
        thresholdB: thresholdB ?? DATA_PLACEHOLDER,
        levelA: levelA ?? DATA_PLACEHOLDER,
        levelB: levelB ?? DATA_PLACEHOLDER
    }
}

export function formatUt3EmaProbeInfo(t: TFunction<"translation">, data: AwpUt3EmaSeries){
    let workMode = "";
    let measMode = "";
    switch (data.screen.View) {
        case 0:
            workMode = t('mode_auto');
            measMode = `${t("mode_acf")} + ${t("mode_rising")}`;
            break;
        case 1:
            workMode = t("mode_manual");
            switch (data.screen.Source_Work) {
                case 1:
                    measMode = t("mode_acf");
                    break;
                case 4:
                    measMode = t("mode_peak_peak");
                    break;
                case 2:
                    measMode = t("mode_rising");
                    break;
                case 3:
                    measMode = t("mode_echo");
                    break;
                case 5:
                    measMode = t("mode_echo_echo");
                    break;
            }
            break;
        case 2:
            workMode = t("mode_b_scan");
            switch (data.screen.Source_BSCAN) {
                case 0:
                    measMode = t('mode_auto');
                    break;
                case 1:
                    measMode = t("mode_acf");
                    break;
                case 4:
                    measMode = t("mode_peak_peak");
                    break;
                case 2:
                    measMode = t("mode_rising");
                    break;
                case 3:
                    measMode = t("mode_echo");
                    break;
                case 5:
                    measMode = t("mode_echo_echo");
                    break;
            }
            break;
        case 3:
            workMode = t("mode_control");
            switch (data.screen.Source_CONTROL) {
                case 0:
                    measMode = t('mode_auto');
                    break;
                case 1:
                    measMode = t("mode_acf");
                    break;
                case 4:
                    measMode = t("mode_peak_peak");
                    break;
                case 2:
                    measMode = t("mode_rising");
                    break;
                case 3:
                    measMode = t("mode_echo");
                    break;
                case 5:
                    measMode = t("mode_echo_echo");
                    break;
            }
            break;
    }
    return [workMode, measMode];
}

function calcTime(time: number) {
    return time / 40;
}

function calcDist(time: number, data: AwpUt3EmaSeries) {
    let dist = calcTime(time * data.adc.speed) / 2000;
    if (data.screen.mm_uS){
        dist *= 25.4;
    }
    return dist;
}

function checkRaceAKF(data: AwpUt3EmaSeries) : [boolean, number] {
    let ready = false;
    let depth = 0;
    if (data.asd.AC_RASSING) {
        let hA = 0;
        let hR = calcDist(data.asd.T_RASSING - data.probes.probes[data.probes.usedProbe].delay, data)
        if (data.asd.AC_AKF) {
            hA = calcDist(data.asd.T_AKF, data);
        }
        if ((hA > 10.5) || (!data.asd.AC_AKF)) {
            depth = hR;
        } else {
            depth = hA;
        }
        ready = true;
    }
    return [ready, depth];
}