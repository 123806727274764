import {TFunction} from "i18next";
import {AwpUt2aSeries} from "../models/AwpUt2aSeries";
import {formatNumber} from "./FormatHelper";
import {DATA_PLACEHOLDER} from "../AppSettings";

const SCALE_DELAY_PROBE = 5.0;
const PEP_RS = 0;

const ADC_SAMPLE_RATE = 40;
const N_INTERPOLATION = 5;

export interface Ut2ADeviceInfo{
    workMode : string;
    measMode : string;
    gain: string;
    middle: string;
    scanningTitle: string;
    scanning: string;
    delayTitle :string;
    delay: string;
    velocityTitle: string;
    velocity : string;
    scanningAndDelayUnits : string;
    velocityUnits: string;
}

export interface Ut2ATransducerInfo{
    name : string;
    type : string;
    freq : string;
    delay : string;
    amplV  :string;
    nPulses : string;
}

export interface Ut2AResult{
    value? : string;
    units: string;
    display : string;
}

interface Polinom {
    Max_Thinc: number;
    K: Array<number>;
    V_Factor_Thinc: number;
    User_K_V: Array<number>;
}

export function getUt2ADeviceInfo(t: TFunction<"translation">, data : AwpUt2aSeries) : Ut2ADeviceInfo{
    const [workMode, measMode] = formatAwpUt2AProbeInfo(t, data);
    let scanning = data.adc.mmScan[data.screen.globalSource] / 10;
    let delay = data.adc.mmDelay[data.screen.globalSource] / 10;
    let formatDigits = 1;
    if (data.screen.mmInchUs){
        scanning /= 25.4;
        delay /= 25.4;
        formatDigits = 3;
    }
    return {
        workMode : workMode,
        measMode : measMode,
        gain: formatNumber(data.adc.gain / 10, 1, 1),
        middle: data.screen.midle !== 0 ? formatNumber(Math.pow(2, data.screen.midle), 0, 0) : t('awp_off'),
        scanningTitle: t(data.screen.mmInchUs ? 'range_inch' : 'range_mm'),
        scanning: formatNumber(scanning, formatDigits, formatDigits),
        delayTitle :t(data.screen.mmInchUs ? 'delay_inch' : 'delay_mm'),
        delay: formatNumber(delay, formatDigits, formatDigits),
        velocityTitle: `${t('velocity')}, ${t(data.screen.mmInchUs ? 'scale_inpus' : 'scale_mps')}`,
        velocity : data.screen.mmInchUs ? formatNumber(data.adc.speed / 1000, 3, 3) : formatNumber(data.adc.speed, 0, 0),
        scanningAndDelayUnits : t(data.screen.mmInchUs ? 'in' : 'mm'),
        velocityUnits: t(data.screen.mmInchUs ? 'scale_inpus' : 'scale_mps')
    };
}

export function getUt2ATransducerInfo(t: TFunction<"translation">, data : AwpUt2aSeries) : Ut2ATransducerInfo{
    const paramProbe = data.probes.Param[data.probes.UsedProbe];
    let sensorType = DATA_PLACEHOLDER;
    switch (paramProbe.Type) {
        case 1:
            sensorType = t("ut2a_probe_type_single");
            break;
        case 0:
            sensorType = t("ut2a_probe_type_dual");
            break;
        case 2:
            sensorType = t("ut2a_probe_type_emat");
            break;
    }
    return {
        name : paramProbe.Name,
        type : sensorType,
        freq : formatNumber(paramProbe.Freq / 100, 2, 1),
        delay : formatNumber(paramProbe.Delay / 1000, 3, 1),
        amplV  : formatNumber(paramProbe.Ampl_V, 0, 0),
        nPulses : formatNumber(paramProbe.N_Pulses, 0, 0)
    }
}

export function getUt2AResult(t: TFunction<"translation">, data : AwpUt2aSeries) : Ut2AResult{
    let time;
    let value;
    let sourceWork;
    switch (data.screen.view) {
        case 4:
            sourceWork = data.screen.sourceWork;
            break;
        case 0:
            sourceWork = data.screen.sourceWork;
            break;
        case 1:
            sourceWork = data.screen.sourceBSCAN;
            break;
        case 2:
            sourceWork = data.screen.sourceCONTROL;
            break;
        case 3:
            sourceWork = data.screen.sourceWork;
            break;
    }
    if (sourceWork !== undefined) {
        switch (sourceWork) {
            case 1: {
                if (data.asd.acZerro2[0] !== 0 && data.asd.acZerro2[1] !== 0) {
                    time = Math.abs(data.asd.tZerro2[0] - data.asd.tZerro2[1]);
                }
                break;
            }
            case 2: {
                if (data.adc.modePIC2) {
                    if (data.asd.acPicPic[0] !== 0 || data.asd.acPicPic[1] !== 0) {
                        time = Math.abs(data.asd.tPicPic[1] - data.asd.tPicPic[0]);
                    }
                } else {
                    if (data.asd.acPicPicT[0] !== 0 && data.asd.acPicPicT[1] !== 0) {
                        time = Math.abs(data.asd.tPicPicT[1] - data.asd.tPicPicT[0]);
                    }
                }
                break;
            }
            case 0: {
                let t;
                if (data.asd.acRassing !== 0) {
                    let delta = (data.asd.tZerro - data.asd.tRassing);
                    switch (data.adc.modeRISE) {
                        case 0:
                            t = data.asd.tRassing + delta;
                            break;
                        case 1:
                            t = data.asd.tZerro;
                            break;
                    }
                }
                if (t !== undefined) {
                    time = t - data.probes.Param[data.probes.UsedProbe].Delay / SCALE_DELAY_PROBE;
                }
                break;
            }
        }
        if (time) {
            value = calcDistance(time, data);
            if (data.screen.mmInchUs) {
                value /= 25.4;
            }
        }
    }
    const formatDigits = data.screen.mmInchUs ? 3 : 2;
    return {
        value: value !== undefined ? formatNumber(value, formatDigits, formatDigits) : undefined,
        units: t(data.screen.mmInchUs ? "in" : "mm"),
        display: value !== undefined ? `${formatNumber(value, formatDigits, formatDigits)} ${t(data.screen.mmInchUs ? "in" : "mm")}` : "----"
    }
}

export  function formatAwpUt2AProbeInfo(t: TFunction<"translation">, data : AwpUt2aSeries){
    let workMode = "";
    let measMode = "";
    let sourceWork = -1;
    switch (data.screen.view){
        case 4:
            workMode = t('mode_auto');
            sourceWork = data.screen.sourceWork;
            break;
        case 0:
            workMode = t("mode_measurement");
            sourceWork = data.screen.sourceWork;
            break;
        case 1:
            workMode = t("mode_b_scan");
            sourceWork = data.screen.sourceBSCAN;
            break;
        case 2:
            workMode = t("mode_control");
            sourceWork = data.screen.sourceCONTROL;
            break;
        case 3:
            workMode = t("mode_calibr");
            sourceWork = data.screen.sourceWork;
            break;
    }
    switch (sourceWork){
        case 0:
            measMode = t("mode_echo");
            break;
        case 1:
            measMode = t("mode_echo_echo");
            break;
        case 2:
            measMode = t("mode_peak_peak");
            break;
    }
    return [workMode, measMode];
}

const V_Polynom: Polinom[] = [
    {
        Max_Thinc: 50.0,
        K: [
            4.18680283931E-001,
            3.41494096599E-001,
            1.04126283854E-001,
            -7.24412124995E-003,
            2.53079588952E-004,
            -4.29831561470E-006,
            2.81076823770E-008,
            0,
        ],
        V_Factor_Thinc: 6.0,
        User_K_V: [9.99031310256E-002, -9.46722064089E-002, 2.48599761271E-002, 0, 0]
    },
    {
        Max_Thinc: 30.0,
        K: [
            -5.0475764471400E-01,
            8.0767246567900E-01,
            4.5497326138900E-02,
            -2.9675474019500E-03,
            -6.3705554621900E-06,
            8.2659345828700E-06,
            -3.2228311008400E-07,
            3.8705972913900E-09,
        ],
        V_Factor_Thinc: 2.0,
        User_K_V: [9.99031310256E-002, -9.46722064089E-002, 2.48599761271E-002, 0, 0]
    },
    {
        Max_Thinc: 20.0,
        K: [
            -3.81662921675E-01,
            9.03330092735E-01,
            3.46114952852E-02,
            -4.43549773521E-03,
            3.04065302797E-04,
            -1.15446149142E-05,
            2.25810313627E-07,
            -1.76034994118E-09,
        ],
        V_Factor_Thinc: 2.2,
        User_K_V: [9.99031310256E-002, -9.46722064089E-002, 2.48599761271E-002, 0, 0]
    },
    {
        Max_Thinc: 10.0,
        K: [
            -4.182545879480E-01,
            1.249885534310E+00,
            -1.060299735280E-01,
            2.309242876650E-02,
            -2.459618729010E-03,
            1.222731380850E-04,
            -2.260030395650E-06,
            0,
        ],
        V_Factor_Thinc: 2.0,
        User_K_V: [9.99031310256E-002, -9.46722064089E-002, 2.48599761271E-002, 0, 0]
    },
    {
        Max_Thinc: 20.0,
        K: [
            -2.9195315395500000E-01,
            1.0183864867100000E+00,
            -3.6923264446100000E-04,
            2.5786812893100000E-06,
            0,
            0,
            0,
            0,
        ],
        V_Factor_Thinc: 2.0,
        User_K_V: [9.99031310256E-002, -9.46722064089E-002, 2.48599761271E-002, 0, 0]
    },
];

function calcUs(time: number) {
    return time / (ADC_SAMPLE_RATE * N_INTERPOLATION);
}

function calcVPolinom(Data: number, K: Array<number>) {
    let Out = K[0];
    let X = Data;
    let XX = X;
    for (let i = 1; i < 8; ++i) {
        Out += K[i] * XX;
        XX = XX * X;
    }
    return Out;
}

function calcUserVPolinom(Data: number, K: Array<number>) {
    let Out = K[0];
    let X = Data;
    let XX = X;
    for (let i = 1; i < 5; ++i) {
        Out += K[i] * XX;
        XX = XX * X;
    }
    return Out;
}

function vCorr(th: number, data: AwpUt2aSeries) {
    let h;
    if (th < V_Polynom[data.probes.Param[data.probes.UsedProbe].V].Max_Thinc) {
        h = calcVPolinom(th, V_Polynom[data.probes.Param[data.probes.UsedProbe].V].K);
        if (h < V_Polynom[data.probes.Param[data.probes.UsedProbe].V].V_Factor_Thinc) {
            let delta = calcUserVPolinom(h, V_Polynom[data.probes.Param[data.probes.UsedProbe].V].User_K_V);
            h = h + (data.probes.Param[data.probes.UsedProbe].K_V / 100.0) * delta;
        }
    } else {
        h = th;
    }

    return h;
}

function vCorr2(th: number, data: AwpUt2aSeries) {
    let h;
    let a = 8.23960664483E-002;
    let b = 9.99923901953E-001;
    if (th < V_Polynom[data.probes.Param[data.probes.UsedProbe].V].Max_Thinc) {
        h = a + b * th;
    } else {
        h = th;
    }
    if (h < 0.1) {
        h = 0.0;
    }
    return (h);
}

function calcDistance(time: number, data: AwpUt2aSeries) {
    time = calcUs(time);
    let distance = (time * data.adc.speed) / 2000;
    if (data.screen.mmInchUs){
        distance *= 25.4;
    }
    if (data.probes.Param[data.probes.UsedProbe].Type === PEP_RS) {
        switch (data.screen.view) {
            case 3:
                distance = vCorr(distance, data);
                break;
            case 0:
                switch (data.screen.sourceWork) {
                    case 1:
                    case 2:
                        distance = vCorr2(distance, data);
                        break;
                    default :
                        distance = vCorr(distance, data);
                        break;
                }
                break;
            case 1:
                switch (data.screen.sourceBSCAN) {
                    case 1:
                    case 2:
                        distance = vCorr2(distance, data);
                        break;
                    default :
                        distance = vCorr(distance, data);
                        break;
                }
                break;
            case 2:
                switch (data.screen.sourceCONTROL) {
                    case 1:
                    case 2:
                        distance = vCorr2(distance, data);
                        break;
                    default :
                        distance = vCorr(distance, data);
                        break;
                }
                break;
        }
    }
    return distance;
}