import {SerialDevice} from "./SerialDevice";
import moment from "moment/moment";
import {delay} from "../helpers/Utils";
import {formatNumber} from "../helpers/FormatHelper";
import {AwpDevice} from "./AwpDevice";
import {AwpDirectoryHandle} from "../helpers/AwpDirectoryHandle";
import {logInfo} from "../helpers/LogHelper";

const ACK = Uint8Array.of(0x06);
const EOT = Uint8Array.of(0x04);

const REPLACE_PATTERN = "#XX#";

const DATA_TIMEOUT = 3000;
const HEADER_TIMEOUT = 4000;
const FILE_TIMEOUT = 10000;

export class AwpSerialDevice extends AwpDevice {

    static readonly defaultBaudRate = 921600;
    static readonly defaultFilters: SerialPortFilter[] = [
        {
            usbVendorId: 0x10C4,
            usbProductId: 0xEA60
        }
    ];
    //ut1m, ud2301, ud2303, ut3ema
    static readonly advancedFilters: SerialPortFilter[] = [
        {
            usbVendorId: 0x10C4,
            usbProductId: 0xEA60
        },
        {
            usbVendorId: 0x0403,
            usbProductId: 0x6001
        },
        {
            usbVendorId: 0x0403,
            usbProductId: 0x0000
        }
    ];

    protected readonly port: SerialDevice;
    private readonly smallReadDelays: boolean;

    constructor(port: SerialDevice, smallReadDelays: boolean = false) {
        super();
        this.port = port;
        this.smallReadDelays = smallReadDelays;
    }

    static getSeriesFileName(index: number) {
        return this.seriesFile.replace(REPLACE_PATTERN, formatNumber(index, 0, 0, 2));
    }

    readRecords(fsHandle: AwpDirectoryHandle, from: Date, to: Date, move: boolean, progressCallback?: (progress: number) => void, confirmationCallback?: (count: number) => Promise<boolean>): Promise<boolean> {
        this.isAborted = false;
        let isFirstAttempt = true;
        return this.port.open().then((isOpen) => {
            if (!isOpen) {
                return false;
            }
            const command = `${move ? "move" : "copy"} ${moment(from).format("DDMMYY")} 0000 ${moment(to).format("DDMMYY")} 2359\r\n`;
            const encoder = new TextEncoder();

            let openFiles = 0;
            return new Promise<void>(async (resolve, reject) => {
                await this.port.writeTextAsync(encoder, command);
                let isAlive = true;
                const decoder = new TextDecoder("windows-1251");
                let localBuffer = "";
                let fileBuffer = new Array<Uint8Array>();
                let fileSize = 0;
                let filePos = 0;
                let isNewRecord = true;
                let folderName: string | undefined;
                let fileName: string | undefined;
                let recordDate: Date | undefined;
                let stage = 0;
                let count = 0;
                let progress = 0;
                let timerId: NodeJS.Timeout | undefined;
                const success = () => {
                    resetTimer();
                    if (isAlive) {
                        resolve();
                    }
                    isAlive = false;
                };
                const fail = (reason: any) => {
                    resetTimer();
                    if (isAlive) {
                        reject(reason);
                    }
                    isAlive = false;
                }
                const setTimer = (timeout: number) => {
                    resetTimer();
                    timerId = setTimeout(() => fail("Timeout"), timeout);
                }
                const resetTimer = () => {
                    if (timerId) {
                        clearTimeout(timerId);
                        timerId = undefined;
                    }
                }
                setTimer(DATA_TIMEOUT);
                let stopped = false;
                this.port.listen(async data => {
                    if (stopped) {
                        return;
                    }
                    if (data) {
                        if (stage < 2) {
                            const decodedText = decoder.decode(data);
                            logInfo("Text data received", decodedText);
                            localBuffer += decodedText;
                        } else {
                            logInfo(`Binary data received (${data.length} bytes)`);
                            fileBuffer.push(data);
                            filePos += data.length;
                            logInfo(`${filePos} of ${fileSize} bytes received`);
                            if (filePos === fileSize) {
                                resetTimer();
                                stage = 1;
                                const writeBuffer = fileBuffer;
                                openFiles++;
                                new Promise(async () => {
                                    const localFolderName = folderName;
                                    const localFileName = fileName;
                                    const localRecordDate = recordDate;
                                    if (localFolderName && localFileName && localRecordDate) {
                                        const handle = fsHandle.getFileSystemHandle();
                                        if (handle) {
                                            const folderHandle = await handle.getDirectoryHandle(localFolderName, {create: true});
                                            if (isNewRecord) {
                                                const dateFileHandle = await folderHandle.getFileHandle(AwpSerialDevice.dateFile, {create: true});
                                                const dateFile = await dateFileHandle.createWritable();
                                                const dateFileWriter = dateFile.getWriter();
                                                await dateFileWriter.write(encoder.encode(moment(localRecordDate).format("DD.MM.YYYY HH.mm")));
                                                dateFileWriter.releaseLock();
                                                await dateFile.close();
                                            }
                                            const fileHandle = await folderHandle.getFileHandle(localFileName, {create: true});
                                            const file = await fileHandle.createWritable();
                                            const fileWriter = file.getWriter();
                                            for (const chunk of writeBuffer) {
                                                await fileWriter.write(chunk);
                                            }
                                            fileWriter.releaseLock();
                                            await file.close();
                                        }
                                    }
                                    openFiles--;
                                });
                                fileBuffer = new Array<Uint8Array>();
                                await delay(this.smallReadDelays ? 20 : 500);
                                if (this.isAborted) {
                                    await this.port.writeBinaryAsync(EOT);
                                } else {
                                    await this.port.writeBinaryAsync(ACK);
                                    await delay(this.smallReadDelays ? 10 : 200);
                                    setTimer(HEADER_TIMEOUT);
                                }
                            }
                        }
                        if (stage === 0 && (localBuffer.length > 30)) {
                            const recordsRegEx = /.*RECORDS (\d{4}).*/gm;
                            const errorRegEx = /.*Error Command.*/gm;
                            const recordsMatch = recordsRegEx.exec(localBuffer);
                            if (recordsMatch) {
                                resetTimer();
                                localBuffer = "";
                                count = Number(recordsMatch[1]);
                                const shouldContinue = confirmationCallback ? await confirmationCallback(count) : true;
                                if (shouldContinue) {
                                    stage = 1
                                    await delay(500);
                                    await this.port.writeBinaryAsync(ACK);
                                    setTimer(HEADER_TIMEOUT);
                                } else {
                                    await this.port.writeBinaryAsync(EOT);
                                    success();
                                }
                            } else if (errorRegEx.test(localBuffer)) {
                                if (isFirstAttempt) {
                                    resetTimer();
                                    isFirstAttempt = false;
                                    await delay(500);
                                    localBuffer = "";
                                    await this.port.writeTextAsync(encoder, command);
                                    setTimer(DATA_TIMEOUT);
                                } else {
                                    fail("Error");
                                }
                            }
                        }
                        if (stage === 1) {
                            if (localBuffer.length > 0 && localBuffer[0] === '\0') {
                                resetTimer();
                                success();
                            } else if (localBuffer.length === 128) {
                                resetTimer();
                                const parts = localBuffer.split(":");
                                fileSize = Number(parts[0]);
                                isNewRecord = false;
                                if (folderName !== parts[1]) {
                                    if (folderName) {
                                        progress++;
                                        if (progressCallback) {
                                            progressCallback(progress / count * 100);
                                        }
                                    }
                                    isNewRecord = true;
                                    folderName = parts[1];
                                }
                                fileName = parts[2];
                                const dd = Number(parts[3]);
                                const mm = Number(parts[4]);
                                const yy = Number(parts[5]);
                                const HH = Number(parts[6]);
                                const MM = Number(parts[7]);
                                try {
                                    recordDate = new Date(yy, mm - 1, dd, HH, MM);
                                } catch {
                                    recordDate = new Date();
                                }
                                localBuffer = "";
                                stage = 2
                                filePos = 0;
                                await this.port.writeBinaryAsync(ACK);
                                if (!this.smallReadDelays) {
                                    await delay(200);
                                }
                                setTimer(FILE_TIMEOUT);
                            }
                        }
                    } else {
                        fail("Data read error");
                    }
                });
            }).then(async () => {
                this.port.close();
                do {
                    await delay(500);
                } while (openFiles > 0);
                return true;
            }).catch(reason => {
                this.port.close();
                throw new Error(reason);
            });
        });
    }
}
