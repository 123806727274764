import EmptyFolderSvg from "./EmptyFolderSvg";
import './ArchiveListPlaceholder.css';
import {useTranslation} from "react-i18next";
import {DEMO_APP_URL} from "../../AppSettings";

export function EmptyArchivePlaceholder() {
    const {t} = useTranslation();
    return (
        <div className="container-grow d-flex flex-column justify-content-center align-items-center">
            <div className="placeholder-icon">
                <EmptyFolderSvg/>
            </div>
            <span className="mt-4 placeholder-text text-center">{t("empty_archive")}</span>
            <span className="mt-4 placeholder-text text-center">{t("empty_archive_sync_app")}</span>
            <span className="mt-4 placeholder-text text-center">{t("empty_archive_explore_demo")}</span>
            <span className="mt-2 explore-demo-link" onClick={() => window.open(DEMO_APP_URL, "_blank")}>{t('explore')}</span>
        </div>
    );
}