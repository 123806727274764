import {useTranslation} from "react-i18next";
import {Button, Modal} from "react-bootstrap";

interface Props{
    show : boolean;
    closeHandler: () => void;
}

export function GoogleSignInNotAvailableDialog(props : Props) {
    const {t} = useTranslation();
    return (
        <Modal show={props.show} size="lg" centered onHide={props.closeHandler}>
            <Modal.Header><Modal.Title className="m-auto">{t("google_sign_in_not_available_title")}</Modal.Title></Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column align-items-center my-2">
                    <div>{t("google_sign_in_not_available_check_browser")}</div>
                    <div className="fst-italic">- {t("google_sign_in_not_available_enable_cookies")}</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => props.closeHandler()}>{t('ok')}</Button>
            </Modal.Footer>
        </Modal>
    );
}