import {Rect} from "./Rect";
import {IMAGE_QUALITY, IMAGE_TYPE, ImageData} from "./ImageData";
import {backgroundPlugin, MM_TO_PX_FACTOR} from "./PdfChartPlugins";
import {Chart} from "chart.js";
import {DpmMeasurement} from "../models/DpmMeasurement";
import {DpmChartData} from "../components/MeasurementDetails/MeasurementsDpmChart";
import {TFunction} from "i18next";

export function buildDpmChart(t: TFunction<"translation">, bounds: Rect, measurement: DpmMeasurement): ImageData {
    const canvas = document.getElementById("pdf-canvas") as HTMLCanvasElement;
    if (canvas) {
        canvas.setAttribute("width", `${bounds.width * MM_TO_PX_FACTOR / 1.25}px`);
        canvas.setAttribute("height", `${bounds.height * MM_TO_PX_FACTOR / 1.25}px`);
        const context = canvas.getContext('2d');
        if (context) {
            const tempPoints = new Array<number>();
            tempPoints.push(...measurement.measurements.map(m => m.tempSurface));
            tempPoints.push(...measurement.measurements.map(m => m.tempAir));
            tempPoints.push(...measurement.measurements.map(m => m.devPoint));
            const minTemp = Math.min(...tempPoints);
            const maxTemp = Math.max(...tempPoints);
            const tempMeasurementError = 1;
            const tempDelta = Math.max(1.5 * (maxTemp - minTemp), tempMeasurementError * 10);
            const tempMiddle = minTemp + (maxTemp - minTemp) / 2;
            const texmAxisMin = Math.floor(tempMiddle - tempDelta / 2);
            const tempAxisMax = texmAxisMin + Math.ceil(tempDelta / 10) * 10;
            const humidityAxisMin = 0;
            const humidityAxisMax = 100;
            const divisions = 11;
            const maxX = 180;
            const ticksX = 11;
            const data = measurement.measurements.map((m, i) => {
                return {
                    x: i,
                    humidity: m.humidity,
                    tempSurface: m.tempSurface,
                    tempAir: m.tempAir,
                    dewPoint: m.devPoint
                } as DpmChartData
            });
            const chartData = {
                datasets: [{
                    label: `${t("air_humidity_short")} %`,
                    backgroundColor: '#27769d',
                    borderColor: '#27769d',
                    showLine: true,
                    borderWidth: 1,
                    data: data.map(d => [d.x, d.humidity]),
                    yAxisID: 'yAxis2',
                }, {
                    label: `${t("surface_temperature_short")} ⁰C`,
                    backgroundColor: '#F43249',
                    borderColor: '#F43249',
                    showLine: true,
                    borderWidth: 1,
                    data: data.map(d => [d.x, d.tempSurface]),
                    yAxisID: 'yAxis',
                }, {
                    label: `${t("air_temperature_short")} ⁰C`,
                    backgroundColor: '#629c44',
                    borderColor: '#629c44',
                    showLine: true,
                    borderWidth: 1,
                    data: data.map(d => [d.x, d.tempAir]),
                    yAxisID: 'yAxis',
                }, {
                    label: `${t("dew_point_temperature_short")} ⁰C`,
                    backgroundColor: '#262626',
                    borderColor: '#262626',
                    showLine: true,
                    borderWidth: 1,
                    data: data.map(d => [d.x, d.dewPoint]),
                    yAxisID: 'yAxis',
                }]
            };
            const chart = new Chart(context, {
                type: "scatter",
                data: chartData,
                plugins: [backgroundPlugin],
                options: {
                    animation: false,
                    scales: {
                        xAxis: {
                            min: 0,
                            max: maxX,
                            ticks: {
                                count: ticksX
                            },
                            grid: {
                                display: false,
                                borderColor: "#666666"
                            }
                        },
                        yAxis: {
                            min: texmAxisMin,
                            max: tempAxisMax,
                            ticks: {
                                count: divisions,
                                callback: v => Number(v).toFixed(0)
                            },
                            grid: {
                                tickLength: 0,
                                borderColor: "#666666"
                            },
                            position: 'left'
                        },
                        yAxis2: {
                            min: humidityAxisMin,
                            max: humidityAxisMax,
                            ticks: {
                                count: divisions,
                                callback: v => Number(v).toFixed(0)
                            },
                            grid: {
                                tickLength: 0,
                                borderColor: "#666666"
                            },
                            position: 'right'
                        }
                    },
                    elements: {
                        point: {
                            radius: 0,
                            pointStyle: "circle"
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                filter: (label) => {
                                    return label.text !== "-";
                                },
                                font: {
                                    size: 9
                                },
                                boxWidth: 6,
                                boxHeight: 6
                            }
                        }
                    }
                }
            });
            const imageData = {
                width: bounds.width,
                height: bounds.height,
                data: chart.toBase64Image(IMAGE_TYPE, IMAGE_QUALITY)
            };
            chart.destroy();
            return imageData;
        }
    }
    return {
        width: 0,
        height: 0,
        data: ""
    };
}