import {Button, ButtonGroup} from "react-bootstrap";
import * as ExplorerMode from "../../models/ExplorerMode";
import IconListSvg from "../Icons/IconListSvg";
import IconMapSvg from "../Icons/IconMapSvg";
import IconCalendarSvg from "../Icons/IconCalendarSvg";
import {isMobile} from "react-device-detect";

interface Props {
    mode: number;
    modeChangeListener: (mode: number) => void;
}

export function ArchiveModeSwitcher(props: Props) {
    const mobileClass = isMobile ? "mobile" : "";
    return (
        <ButtonGroup className="my-4">
            <Button variant={props.mode === ExplorerMode.LIST ? "primary" : "outline-primary"} className={mobileClass}
                    onClick={() => props.modeChangeListener(ExplorerMode.LIST)}><IconListSvg/></Button>
            <Button variant={props.mode === ExplorerMode.MAP ? "primary" : "outline-primary"} className={mobileClass}
                    onClick={() => props.modeChangeListener(ExplorerMode.MAP)}><IconMapSvg/></Button>
            <Button variant={props.mode === ExplorerMode.CALENDAR ? "primary" : "outline-primary"} className={mobileClass}
                    onClick={() => props.modeChangeListener(ExplorerMode.CALENDAR)}><IconCalendarSvg/></Button>
        </ButtonGroup>
    );
}