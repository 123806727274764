import {AwpFileHandle} from "./AwpFileHandle";
import {getDownloadURL, StorageReference} from "firebase/storage";
import {ResourceNotFoundError} from "../errors/GoogleDriveErrors";

const DEFAULT_DATE = new Date(Date.UTC(2021, 9, 1, 12, 0, 0)).getDate();

export class AwpGoogleCloudFileHandle implements AwpFileHandle{

    private readonly ref: StorageReference;

    constructor(ref: StorageReference) {
        this.ref = ref;
    }

    name(): string {
        return this.ref.name;
    }

    getData(): Promise<ArrayBuffer> {
        return getDownloadURL(this.ref)
            .then(url => fetch(url))
            .then(response => {
                if (response.ok) {
                    return response.arrayBuffer();
                }
                throw new ResourceNotFoundError();
            });
    }

    lastModified(): Promise<number> {
        return Promise.resolve(DEFAULT_DATE);
    }

}