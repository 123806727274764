import {Carousel, Modal} from "react-bootstrap";
import {useState} from "react";
import './MeasurementDetails.css';
import {LineChartData, MeasurementsLineChart} from "./MeasurementsLineChart";
import {formatMfScaleName, formatMfScaleValue, formatUtScaleName, formatUtScaleValue} from "../../helpers/FormatHelper";
import {Loader} from "../Loader/Loader";
import {AreaChartData, MeasurementsAreaChart} from "./MeasurementsAreaChart";
import {useTranslation} from "react-i18next";
import {MeasurementsTable} from "./MeasurementsTable";
import {MfMeasurement} from "../../models/MfMeasurement";
import { getMeasurementError } from "../../models/MfScale";

interface Props {
    measurement: MfMeasurement | null;
    closeHandler: () => void;
    modalContainer: HTMLDivElement | undefined;
}

export function MfMeasurementDetails(props: Props) {
    const {t} = useTranslation();
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };
    const getAreaChartData = () => {
        let i = 0;
        const data = new Array<AreaChartData>();
        let measurement = props.measurement;
        if (measurement) {
            for (const item of measurement.measurements) {
                data.push({
                    x: i++,
                    y: [item, item]
                });
            }
        }
        return data;
    };
    const getTableData = () => {
        const data = new Array<number>();
        let measurement = props.measurement;
        if (measurement) {
            for (const item of measurement.measurements) {
                data.push(item)
            }
        }
        return data;
    };
    return (
        <Modal show={props.measurement !== null} size="lg" centered onHide={props.closeHandler} container={props.modalContainer}>
            <Modal.Header closeButton>{t('measurements')}</Modal.Header>
            <Modal.Body>
                {props.measurement ?
                    <Carousel activeIndex={index} onSelect={handleSelect} controls interval={null}>
                        <Carousel.Item>
                            <MeasurementsAreaChart classNames="measurement-details-container" data={getAreaChartData()}
                                                   measurementError={getMeasurementError(props.measurement.mfScale)}
                                                   name={formatMfScaleName(t, props.measurement.mfScale)}
                                                   symmetric={true}
                                                   valueFormatter={v => `${formatMfScaleValue(props.measurement?.mfScale ?? "", v)} ${formatMfScaleName(t, props.measurement?.mfScale ?? "")}`}/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <MeasurementsTable data={getTableData()} hideCount={true}
                                               formatter={v => formatMfScaleValue(props.measurement?.mfScale ?? "", v)}/>
                        </Carousel.Item>
                    </Carousel> : <Loader/>
                }
            </Modal.Body>
        </Modal>
    );
}