import {Button, Form, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useRef} from "react";

interface Props{
    show: boolean;
    title: string;
    recordName: string;
    positiveButtonText: string;
    negativeButtonText: string;
    closeHandler: () => void;
    positiveButtonAction?: (title : string) => void;
    negativeButtonAction?: () => void;
}

export function ModalRecordName(props : Props) {
    const {t} = useTranslation();
    const recordNameRef = useRef<HTMLInputElement>(null);
    const cancel = () => {
        props.closeHandler();
        if (props.negativeButtonAction) {
            props.negativeButtonAction();
        }
    };
    const ok = () => {
        props.closeHandler();
        if (props.positiveButtonAction) {
            props.positiveButtonAction(recordNameRef?.current?.value ?? "");
        }
    }
    return (<Modal
        show={props.show}
        onHide={props.closeHandler}
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-flex flex-column align-items-stretch">
                <Form.Label>{t('record_name')}</Form.Label>
                <Form.Control ref={recordNameRef} type="text" defaultValue={props.recordName}/>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => cancel()}>{props.negativeButtonText}</Button>
            <Button variant="primary" onClick={() => ok()}>{props.positiveButtonText}</Button>
        </Modal.Footer>
    </Modal>);
}