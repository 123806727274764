import {Carousel, Modal} from "react-bootstrap";
import {useState} from "react";
import {UtMeasurement} from "../../models/UtMeasurement";
import './MeasurementDetails.css';
import {LineChartData, MeasurementsLineChart} from "./MeasurementsLineChart";
import {formatUtScaleName, formatUtScaleValue} from "../../helpers/FormatHelper";
import {convertValue, getMeasurementError} from "../../models/UtScale";
import {Loader} from "../Loader/Loader";
import {AreaChartData, MeasurementsAreaChart} from "./MeasurementsAreaChart";
import {useTranslation} from "react-i18next";
import {MeasurementsTable} from "./MeasurementsTable";

interface Props {
    measurement: UtMeasurement | null;
    showAverage: boolean;
    closeHandler: () => void;
    modalContainer: HTMLDivElement | undefined;
}

export function UtMeasurementDetails(props: Props) {
    const {t} = useTranslation();
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };
    const getLinesChartData = () => {
        const data = new Array<LineChartData>();
        let measurement = props.measurement;
        if (measurement) {
            for (let i = 0; i < measurement.measurementContacts.length; i++) {
                const c = measurement.measurementContacts[i];
                let value;
                if (props.showAverage) {
                    let sum = 0;
                    for (const rawMeasurement of c.rawMeasurements) {
                        sum += rawMeasurement.thickness;
                    }
                    value = c.rawMeasurements.length > 0 ? sum / c.rawMeasurements.length : 0;
                } else {
                    value = c.rawMeasurements[c.rawMeasurements.length - 1].thickness;
                }
                data.push({
                    x: i + 1,
                    y: convertValue(measurement.utScale, value)
                });
            }
        }
        return data;
    };
    const getAreaChartData = () => {
        let i = 0;
        const data = new Array<AreaChartData>();
        let measurement = props.measurement;
        if (measurement) {
            for (const contact of measurement.measurementContacts) {
                for (const item of contact.rawMeasurements) {
                    data.push({
                        x: i++,
                        y: [0, convertValue(measurement.utScale, item.thickness)]
                    });
                }
            }
        }
        return data;
    };
    const getTableData = () => {
        const data = new Array<number>();
        let measurement = props.measurement;
        if (measurement) {
            for (const contact of measurement.measurementContacts) {
                if (contact.rawMeasurements.length > 0) {
                    if (props.showAverage) {
                        let sum = 0;
                        for (const rawMeasurement of contact.rawMeasurements) {
                            sum += rawMeasurement.thickness;
                        }
                        const avg = sum / contact.rawMeasurements.length;
                        data.push(convertValue(measurement.utScale, avg))
                    } else {
                        data.push(convertValue(measurement.utScale, contact.rawMeasurements[contact.rawMeasurements.length -1].thickness));
                    }
                }
            }
        }
        return data;
    };
    return (
        <Modal show={props.measurement !== null} size="lg" centered onHide={props.closeHandler}
               container={props.modalContainer}>
            <Modal.Header closeButton>{t('measurements')}</Modal.Header>
            <Modal.Body>
                {props.measurement ?
                    <Carousel activeIndex={index} onSelect={handleSelect} controls interval={null}>
                        <Carousel.Item>
                            <MeasurementsLineChart classNames="measurement-details-container" data={getLinesChartData()}
                                                   measurementError={getMeasurementError(props.measurement.utScale)}
                                                   name={formatUtScaleName(t, props.measurement.utScale)}
                                                   valueFormatter={v => `${formatUtScaleValue(v, props.measurement?.discreteness ?? 0)} ${formatUtScaleName(t, props.measurement?.utScale ?? "")}`}/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <MeasurementsAreaChart classNames="measurement-details-container" data={getAreaChartData()}
                                                   measurementError={getMeasurementError(props.measurement.utScale)}
                                                   name={formatUtScaleName(t, props.measurement.utScale)}
                                                   valueFormatter={v => `${formatUtScaleValue(v, props.measurement?.discreteness ?? 0)} ${formatUtScaleName(t, props.measurement?.utScale ?? "")}`}/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <MeasurementsTable data={getTableData()}
                                               formatter={v => formatUtScaleValue(v, props.measurement?.discreteness ?? 0)}/>
                        </Carousel.Item>
                    </Carousel> : <Loader/>
                }
            </Modal.Body>
        </Modal>
    );
}