import {parseFloat, parseInt32, parseString, parseUint32, parseUint8} from "../helpers/BinaryParseHelper";

const CONFIG_SIZE = 2825;
const CURVE_SIZE = 131072;

const SERIAL_SIZE = 16;
const SENSOR_SIZE = 44;
const DAC_SIZE = 148;
const ADC_SIZE = 196;
const ASD_SIZE = 86;
const STROBE_SIZE = 68;
const SCREEN_SIZE = 208;
const SYS_SIZE = 551;
const INFO_BLOCK_SIZE = 320;
const ARD_SIZE = 44;

const DAC_OFFSET = SENSOR_SIZE;
const ADC_OFFSET = DAC_OFFSET + DAC_SIZE;
const ASD_OFFSET = ADC_OFFSET + ADC_SIZE;
const STROBE_OFFSET = ASD_OFFSET + ASD_SIZE;
const SCREEN_OFFSET = STROBE_OFFSET + STROBE_SIZE;
const SYS_OFFSET = SCREEN_OFFSET + SCREEN_SIZE;
const INFO_BLOCK_OFFSET = SYS_OFFSET + SYS_SIZE;
const ARD_OFFSET = INFO_BLOCK_OFFSET + INFO_BLOCK_SIZE;
const KBD_INC_DEC_OFFSET = ARD_OFFSET + ARD_SIZE;

export interface AwpUd3701Series {
    probe: Sensor;
    dac: DAC;
    adc: ADC;
    asd: ASD;
    strobe: Strobe;
    screen: Screen;
    systemSet: Sys;
    infoBlock: InfoBlock;
    ard: ARD;
    kdbIncDec: KbdIncDec;
    signal: Signal
}

export function parseAwpUd3701Series(configBuffer: ArrayBuffer, signalBuffer: ArrayBuffer): AwpUd3701Series | null {
    if (configBuffer.byteLength !== CONFIG_SIZE && signalBuffer.byteLength !== CURVE_SIZE){
        return null;
    }
    const probe = parseProbe(configBuffer, 0);
    const dac = parseDac(configBuffer, DAC_OFFSET);
    const adc = parseAdc(configBuffer, ADC_OFFSET);
    const asd = parseAsd(configBuffer, ASD_OFFSET);
    const strobe = parseStrobe(configBuffer, STROBE_OFFSET);
    const screen = parseScreen(configBuffer, SCREEN_OFFSET);
    const systemSet = parseSys(configBuffer, SYS_OFFSET);
    const infoBlock = parseInfoBlock(configBuffer, INFO_BLOCK_OFFSET);
    const ard = parseArd(configBuffer, ARD_OFFSET);
    const kbdIncDec = parseKbdIncDec(configBuffer, KBD_INC_DEC_OFFSET);
    const signal = parseSignal(signalBuffer);
    return {
        probe: probe,
        dac: dac,
        adc: adc,
        asd: asd,
        strobe: strobe,
        screen: screen,
        systemSet: systemSet,
        infoBlock: infoBlock,
        ard: ard,
        kdbIncDec: kbdIncDec,
        signal: signal
    }
}

interface Sensor {
    type: number;
    delay: number;
    angle: number;
    vector: number;
    freq: number;
    diamSyzeA: number;
    syzeB: number;
    sqCirc: number;
    refH: number;
    metod: number;
    startC: number;
}

interface DAC {
    enable: number;
    refLevel: number;
    refS: number;
    pointDAC: number;
    timeDAC: Array<number>;
    levelDAC: Array<number>;
    dacClear: number;
}

interface ADC {
    mmDelay: number;
    mmScan: number;
    pulseP: number;
    pulseN: number;
    pulsePause: number;
    pulseAmplitude: number;
    mode: number;
    otsechka: number;
    referens: number;
    fat: number;
    timeTVG: Array<number>;
    deltaTVG: Array<number>;
    pointTvg: number;
    tvgOnOff: number;
    tvgClear: number;
    gain: number;
    speed: number;
    filterOnOff: number;
    filterFreq: number;
}

interface ASD {
    tFront: Array<number>;
    tPic: Array<number>;
    t: Array<number>;
    aCod: Array<number>;
    adB: Array<number>;
    y: Array<number>;
    yab: number;
    x: Array<number>;
    xip: Array<number>;
    s: Array<number>;
    flag: Array<number>;
    dACod: Array<number>;
    dAdB: Array<number>;
}

interface Strobe {
    x: Array<number>;
    y: Array<number>;
    ySearch: Array<number>;
    yContr: Array<number>;
    len: Array<number>;
    mode: Array<number>;
    select: number;
    sygnal: Array<number>;
    frontPic: Array<number>;
}

interface Screen {
    greed: number;
    envelope: number;
    view: number;
    info: Array<number>;
    mmuS: number;
    midle: number;
    orientation: number;
    nParam: number;
    nameStart: Array<number>;
    nameStop: Array<number>;
}

interface Sys {
    serial: string;
    pallete: number;
}

interface InfoBlock {
    x: Array<number>;
    y: Array<number>;
    frame: Array<number>;
    value: Array<number>;
}

interface ARD {
    ardOnOff: number;
    refLevel: number;
    dBrack: number;
    dContr: number;
    dSearch: number;
    dTune: number;
    callDistans: number;
    nearZone: number;
    dBrackD: number;
    dContrD: number;
}

interface KbdIncDec {
    x: number;
    y: number;
    win: number;
    inc: number;
    dec: number;
}

interface Signal {
    data: Array<number>;
}

function parseProbe(buffer: ArrayBuffer, baseOffset: number): Sensor {
    let offset = baseOffset;
    const type = parseInt32(buffer, offset);
    offset += 4;
    const delay = parseInt32(buffer, offset);
    offset += 4;
    const angle = parseInt32(buffer, offset);
    offset += 4;
    const vector = parseInt32(buffer, offset);
    offset += 4;
    const freq = parseInt32(buffer, offset);
    offset += 4;
    const diamSyzeA = parseInt32(buffer, offset);
    offset += 4;
    const syzeB = parseInt32(buffer, offset);
    offset += 4;
    const sqCirc = parseInt32(buffer, offset);
    offset += 4;
    const refH = parseInt32(buffer, offset);
    offset += 4;
    const metod = parseInt32(buffer, offset);
    offset += 4;
    const startC = parseInt32(buffer, offset);
    return {
        type: type,
        delay: delay,
        angle: angle,
        vector: vector,
        freq: freq,
        diamSyzeA: diamSyzeA,
        syzeB: syzeB,
        sqCirc: sqCirc,
        refH: refH,
        metod: metod,
        startC: startC,
    }
}

function parseDac(buffer: ArrayBuffer, baseOffset: number): DAC {
    let offset = baseOffset;
    const enable = parseInt32(buffer, offset);
    offset += 4;
    const refLevel = parseInt32(buffer, offset);
    offset += 4;
    const refS = parseInt32(buffer, offset);
    offset += 4;
    const pointDAC = parseInt32(buffer, offset);
    offset += 4;
    const arraySize = 16;
    const timeDAC = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const levelDAC = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const dacClear = parseInt32(buffer, offset);
    return {
        enable: enable,
        refLevel: refLevel,
        refS: refS,
        pointDAC: pointDAC,
        timeDAC: Array.from(timeDAC),
        levelDAC: Array.from(levelDAC),
        dacClear: dacClear
    }
}

function parseAdc(buffer: ArrayBuffer, baseOffset: number): ADC {
    let offset = baseOffset;
    const mmDelay = parseInt32(buffer, offset);
    offset += 4;
    const mmScan = parseInt32(buffer, offset);
    offset += 4;
    const pulseP = parseInt32(buffer, offset);
    offset += 4;
    const pulseN = parseInt32(buffer, offset);
    offset += 4;
    const pulsePause = parseInt32(buffer, offset);
    offset += 4;
    const pulseAmplitude = parseInt32(buffer, offset);
    offset += 4;
    const mode = parseInt32(buffer, offset);
    offset += 4;
    const otsechka = parseInt32(buffer, offset);
    offset += 4;
    const referens = parseInt32(buffer, offset);
    offset += 4;
    const fat = parseInt32(buffer, offset);
    offset += 4;
    const arraySize = 16;
    const timeTVG = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const deltaTVG = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const pointTvg = parseInt32(buffer, offset);
    offset += 4;
    const tvgOnOff = parseInt32(buffer, offset);
    offset += 4;
    const tvgClear = parseInt32(buffer, offset);
    offset += 4;
    const gain = parseUint32(buffer, offset);
    offset += 4;
    const speed = parseUint32(buffer, offset);
    offset += 4;
    const filterOnOff = parseUint32(buffer, offset);
    offset += 4;
    const filterFreq = parseUint32(buffer, offset);
    return {
        mmDelay: mmDelay,
        mmScan: mmScan,
        pulseP: pulseP,
        pulseN: pulseN,
        pulsePause: pulsePause,
        pulseAmplitude: pulseAmplitude,
        mode: mode,
        otsechka: otsechka,
        referens: referens,
        fat: fat,
        timeTVG: Array.from(timeTVG),
        deltaTVG: Array.from(deltaTVG),
        pointTvg: pointTvg,
        tvgOnOff: tvgOnOff,
        tvgClear: tvgClear,
        gain: gain,
        speed: speed,
        filterOnOff: filterOnOff,
        filterFreq: filterFreq
    }
}

function parseAsd(initialBuffer: ArrayBuffer, baseOffset: number): ASD {
    let buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const arraySize = 2;
    const tFront = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const tPic = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const t = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const aCod = new Int16Array(buffer, offset, arraySize);
    offset += 2 * arraySize;
    const adB = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const y = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const yab = parseFloat(buffer, offset);
    offset += 4;
    const x = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const xip = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const s = new Float32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const flag = new Uint8Array(buffer, offset, arraySize);
    offset += arraySize;
    const daCod = new Int16Array(buffer, offset, arraySize);
    offset += 2 * arraySize;
    buffer = buffer.slice(offset);
    offset = 0;
    const daDb = new Float32Array(buffer, offset, arraySize);
    return {
        tFront: Array.from(tFront),
        tPic: Array.from(tPic),
        t: Array.from(t),
        aCod: Array.from(aCod),
        adB: Array.from(adB),
        y: Array.from(y),
        yab: yab,
        x: Array.from(x),
        xip: Array.from(xip),
        s: Array.from(s),
        flag: Array.from(flag),
        dACod: Array.from(daCod),
        dAdB: Array.from(daDb),
    }
}

function parseStrobe(initialBuffer: ArrayBuffer, baseOffset: number): Strobe {
    const arraySize = 2;
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const x = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const y = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const ySearch = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const yContr = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const len = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const mode = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const select = parseInt32(buffer, offset);
    offset += 4;
    const sygnal = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const frontPic = new Int32Array(buffer, offset, arraySize);
    return {
        x: Array.from(x),
        y: Array.from(y),
        ySearch: Array.from(ySearch),
        yContr: Array.from(yContr),
        len: Array.from(len),
        mode: Array.from(mode),
        select: select,
        sygnal: Array.from(sygnal),
        frontPic: Array.from(frontPic),
    }
}

function parseScreen(initialBuffer: ArrayBuffer, baseOffset: number): Screen {
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const greed = parseInt32(buffer, offset);
    offset += 4;
    const envelope = parseInt32(buffer, offset);
    offset += 4;
    const view = parseInt32(buffer, offset);
    offset += 4;
    const arraySize = 20;
    const info = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const mmuS = parseInt32(buffer, offset);
    offset += 4;
    const midle = parseInt32(buffer, offset);
    offset += 4;
    const orientation = parseInt32(buffer, offset);
    offset += 4;
    const nParam = parseInt32(buffer, offset);
    offset += 4;
    const newArraySize = 50;
    const nameStart = new Uint8Array(buffer, offset, arraySize);
    offset += newArraySize;
    const nameStop = new Uint8Array(buffer, offset, arraySize);
    return {
        greed: greed,
        envelope: envelope,
        view: view,
        info: Array.from(info),
        mmuS: mmuS,
        midle: midle,
        orientation: orientation,
        nParam: nParam,
        nameStart: Array.from(nameStart),
        nameStop: Array.from(nameStop)
    }
}

function parseSys(buffer: ArrayBuffer, baseOffset: number): Sys {
    const serial = parseString(buffer, baseOffset, SERIAL_SIZE);
    const pallete = parseUint32(buffer.slice(baseOffset + serial.length + 1), 0);
    return {
        serial: serial,
        pallete: pallete
    }
}

function parseInfoBlock(initialBuffer: ArrayBuffer, baseOffset: number): InfoBlock {
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const arraySize = 20;
    const x = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const y = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const frame = new Int32Array(buffer, offset, arraySize);
    offset += 4 * arraySize;
    const value = new Int32Array(buffer, offset, arraySize);
    return {
        x: Array.from(x),
        y: Array.from(y),
        frame: Array.from(frame),
        value: Array.from(value),
    }
}

function parseArd(initialBuffer: ArrayBuffer, baseOffset: number): ARD {
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const ardOnOff = parseInt32(buffer, offset);
    offset += 4;
    const refLevel = parseInt32(buffer, offset);
    offset += 4;
    const dBrack = parseInt32(buffer, offset);
    offset += 4;
    const dContr = parseInt32(buffer, offset);
    offset += 4;
    const dSearch = parseInt32(buffer, offset);
    offset += 4;
    const dTune = parseInt32(buffer, offset);
    offset += 4;
    const callDistans = parseInt32(buffer, offset);
    offset += 4;
    const nearZone = parseFloat(buffer, offset);
    offset += 4;
    const dBrackD = parseFloat(buffer, offset);
    offset += 4;
    const dContrD = parseFloat(buffer, offset);
    return {
        ardOnOff: ardOnOff,
        refLevel: refLevel,
        dBrack: dBrack,
        dContr: dContr,
        dSearch: dSearch,
        dTune: dTune,
        callDistans: callDistans,
        nearZone: nearZone,
        dBrackD: dBrackD,
        dContrD: dContrD
    }
}

function parseKbdIncDec(initialBuffer: ArrayBuffer, baseOffset: number): KbdIncDec {
    const buffer = initialBuffer.slice(baseOffset);
    let offset = 0;
    const x = parseInt32(buffer, offset);
    offset += 4;
    const y = parseInt32(buffer, offset);
    offset += 4;
    const win = parseInt32(buffer, offset);
    offset += 4;
    const inc = parseInt32(buffer, offset);
    offset += 4;
    const dec = parseInt32(buffer, offset);
    return {
        x: x,
        y: y,
        win: win,
        inc: inc,
        dec: dec
    }
}

function parseSignal(buffer: ArrayBuffer): Signal {
    const data = new Int16Array(buffer);
    return {
        data: Array.from(data)
    };
}