import {DeviceFilterSwitcher} from "../DeviceFilterSwitcher/DeviceFilterSwitcher";
import {defaultDeviceFilter, setDeviceFilterState} from "../../models/DeviceFilter";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import {Loader} from "../Loader/Loader";
import {Error} from "../Error/Error";
import {GoogleMap, InfoWindow, LoadScript, Marker, MarkerClusterer} from "@react-google-maps/api";
import {API_KEY, EXECUTORS_REFERENCE} from "../../AppSettings";
import {Fragment, useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {usePersistentState} from "../../hooks/PersistentStateHook";
import {EXECUTORS_DEVICE_FILTER, EXECUTORS_DISTANCE_FILTER, EXECUTORS_PERIOD_FILTER} from "../../persistence";
import {ExecutorDataWithDistance} from "../../models/ExecutorDataWithDistance";
import {ExecutorsInfoWindowList} from "./ExecutorsInfoWindowList";
import {off, onValue, ref} from "firebase/database";
import {firebaseDb} from "../../index";
import {ExecutorData} from "../../models/ExecutorData";
import {GeoLocation} from "../../models/GeoLocation";
import {DEFAULT_PERIOD, getMaxTimestamp, PeriodFilterSwitch} from "../PeriodFilterSwitch/PeriodFilterSwitch";
import {DEFAULT_DISTANCE, DistanceFilterSwitch, getMaxDistance} from "../DistanceFilterSwitch/DistanceFilterSwitch";
import './Executors.css';
import {ExecutorsListItem} from "./ExecutorsListItem";
import {ExecutorsNotFoundPlaceholder} from "../ExecutorsNotFoundPlaceholder/ExecutorsNotFoundPlaceholder";
import {ExecutorDetailsDialog} from "../ExecutorDetailsDialog/ExecutorDetailsDialog";
import {Alert, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import IconMyLocationSvg from "../Icons/IconMyLocationSvg";
import {useIsFullExecutorsLayout} from "../../hooks/ScreenSizeHooks";
import {ExecutorsModeSwitcher} from "../ExecutorsModeSwitcher/ExecutorsModeSwitcher";
import * as ExecutorsMode from "../../models/ExecutorsMode";
import {useAnalytics} from "../../hooks/AnalyticsHook";
import {EXECUTORS_PAGE_NAME} from "../../routes";
import {DPM, LEEB, MF1M, TP1M, TUD2, TUD3, UCI, UT1M, UT1M_IP} from "../../models/DeviceType";

function computeDistanceBetween(lat1: number, lon1: number, lat2: number, lon2: number) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    } else {
        const radlat1 = Math.PI * lat1 / 180;
        const radlat2 = Math.PI * lat2 / 180;
        const theta = lon1 - lon2;
        const radtheta = Math.PI * theta / 180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1609.344
        return dist;
    }
}

export function Executors() {
    useAnalytics(EXECUTORS_PAGE_NAME);
    const {t} = useTranslation();
    const isFullLayout = useIsFullExecutorsLayout();
    const mapContainer = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState(null as google.maps.Map | null);
    const [isBoundsSet, setBoundsSet] = useState(false);
    const [center, setCenter] = useState({lat: 0, lng: 0});
    const [zoom, setZoom] = useState(2);
    const history = useNavigate();
    const location = useLocation();
    const [userLocation, setUserLocation] = useState(null as GeoLocation | null);
    const [rawItems, setRawItems] = useState(null as Array<ExecutorData> | null);
    const [activeItem, setActiveItem] = useState(null as ExecutorDataWithDistance[] | null);
    const [selectedItem, setSelectedItem] = useState(undefined as ExecutorData | undefined);
    const [error, setError] = useState(null as Error | null);
    const [deviceFilter, setDeviceFilter] = usePersistentState(EXECUTORS_DEVICE_FILTER, defaultDeviceFilter);
    const [periodFilter, setPeriodFilter] = usePersistentState(EXECUTORS_PERIOD_FILTER, DEFAULT_PERIOD);
    const [distanceFilter, setDistanceFilter] = usePersistentState(EXECUTORS_DISTANCE_FILTER, DEFAULT_DISTANCE);
    const [filter, setFilter] = useState(new Map<string, boolean>());
    const [showLinkToast, setShowLinkToast] = useState(false);
    const [copyToastTimerId, setCopyToastTimerId] = useState(null as NodeJS.Timeout | null);
    const [smallLayoutMode, setSmallLayoutMode] = useState(ExecutorsMode.MAP);
    const hideCopyToast = () => {
        if (copyToastTimerId) {
            clearTimeout(copyToastTimerId);
            setCopyToastTimerId(null);
        }
        setShowLinkToast(false);
    }
    const openCopyToast = () => {
        if (copyToastTimerId) {
            clearTimeout(copyToastTimerId);
            setCopyToastTimerId(null);
        }
        setShowLinkToast(true);
        const id = setTimeout(hideCopyToast, 2000);
        setCopyToastTimerId(id);
    };
    useEffect(() => {
        const filters = new Map<string, boolean>([[TUD2, false], [TUD3, false], [LEEB, false], [UCI, false], [TP1M, false], [UT1M, false], [UT1M_IP, false], [MF1M, false], [DPM, false]]);
        deviceFilter.filters.forEach(i => {
            if (filters.has(i.k)) {
                filters.set(i.k, i.v);
            }
        });
        setFilter(filters);
    }, []);
    useEffect(() => {
        const executorsRef = ref(firebaseDb, EXECUTORS_REFERENCE);
        onValue(executorsRef, snapshot => {
            setError(null);
            const executors = new Array<ExecutorData>();
            snapshot.forEach(child => {
                const val = child.val() as ExecutorData;
                if (val.enabled) {
                    const map = new Map<string, boolean>();
                    for (let k of Object.entries(val.devices)) {
                        map.set(k[0], k[1]);
                    }
                    val.devices = map;
                    executors.push(val);
                }
            });
            setRawItems(executors);
        }, error => setError(error))
        return () => {
            off(executorsRef);
        }
    }, []);
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            if (position) {
                setUserLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    dateTime: position.timestamp
                });
            }
        });
    }, []);
    const items = useMemo(() => {
        return rawItems?.map(i => {
            let distance = undefined;
            if (userLocation && i.locationLat && i.locationLng) {
                distance = computeDistanceBetween(userLocation.latitude, userLocation.longitude, i.locationLat, i.locationLng);
            }
            return {executor: i, distance: distance} as ExecutorDataWithDistance;
        });
    }, [rawItems, userLocation]);
    const displayItems = useMemo(() => {
        const deviceItems = items?.filter(i => {
            let result = false;
            i.executor.devices.forEach((v, k) => {
                if (v && filter.get(k)) {
                    result = true;
                }
            });
            return result;
        });
        const maxTimestamp = getMaxTimestamp(periodFilter);
        const periodItems = deviceItems?.filter(i => i.executor.timestamp > maxTimestamp);
        const maxDistance = getMaxDistance(distanceFilter);
        const distanceItems = userLocation ? periodItems?.filter(i => i.distance && i.distance < maxDistance) : periodItems;
        distanceItems?.sort((i1, i2) => (i1.distance ?? Number.MAX_SAFE_INTEGER) > (i2.distance ?? Number.MAX_SAFE_INTEGER) ? 1 : -1);
        return distanceItems
    }, [items, filter, periodFilter, distanceFilter]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!isBoundsSet && map && displayItems) {
            if (displayItems.length > 1) {
                const bounds = new google.maps.LatLngBounds();
                displayItems.forEach(i => {
                    if (i.executor.locationLat && i.executor.locationLng) {
                        bounds.extend({lat: i.executor.locationLat, lng: i.executor.locationLng});
                    }
                });
                map.fitBounds(bounds);
            } else {
                if (displayItems.length === 1) {
                    if (displayItems[0].executor.locationLat && displayItems[0].executor.locationLng) {
                        setCenter({
                            lat: displayItems[0].executor.locationLat,
                            lng: displayItems[0].executor.locationLng
                        });
                    }
                    setZoom(14);
                }
            }
            setBoundsSet(true);
        }
    }, [map, displayItems]);
    const saveMapState = () => {
        const c = map?.getCenter();
        if (c) {
            setCenter({lat: c.lat(), lng: c.lng()})
        }
        const z = map?.getZoom();
        if (z) {
            setZoom(z);
        }
    };
    const locationClickHandler = (lat: number, lng: number, zoom?: number) => {
        setCenter({lat: lat, lng: lng});
        setZoom(zoom ?? 16);
        setSmallLayoutMode(ExecutorsMode.MAP);
    };
    const myLocationClickHandler = (e: any) => {
        if (userLocation) {
            locationClickHandler(userLocation.latitude, userLocation.longitude, 12);
            e.currentTarget.blur();
        }
    }
    const copyButtonClickHandler = (data?: string) => {
        if (data) {
            navigator.clipboard.writeText(data).then(() => {
                openCopyToast()
            });
        }
    };
    return (
        <div className="container-grow">
            {(items && displayItems && !error) &&
                <div className="d-flex flex-row  justify-content-between py-4">
                    <div className="d-flex flex-row align-items-center">
                        <DeviceFilterSwitcher filters={filter}
                                              minimized={!isFullLayout}
                                              filtersChangeListener={newFilters => {
                                                  setFilter(newFilters);
                                                  newFilters.forEach((v, k) => {
                                                      setDeviceFilterState(deviceFilter, k, v);
                                                  })
                                                  setDeviceFilter(deviceFilter);
                                              }}/>
                        <div className={`${isFullLayout ? "mx-4" : "mx-1"}`}/>
                        <PeriodFilterSwitch activeValue={periodFilter} activeValueChangeListener={setPeriodFilter}
                                            minimized={!isFullLayout}/>
                        {userLocation &&
                            <Fragment>
                                <div className={`${isFullLayout ? "mx-4" : "mx-1"}`}/>
                                <DistanceFilterSwitch activeValue={distanceFilter}
                                                      activeValueChangeListener={setDistanceFilter}
                                                      minimized={!isFullLayout}/>
                            </Fragment>
                        }
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        {userLocation && (isFullLayout || smallLayoutMode === ExecutorsMode.MAP) &&
                            <Button variant="primary" onClick={myLocationClickHandler}><IconMyLocationSvg/></Button>
                        }
                        {!isFullLayout &&
                            <Fragment>
                                <div className="mx-1"/>
                                <ExecutorsModeSwitcher mode={smallLayoutMode} modeChangeListener={setSmallLayoutMode}/>
                            </Fragment>
                        }
                    </div>
                </div>
            }
            <HorizontalDivider/>
            {(!error && (!items || !displayItems)) && <Loader/>}
            {(error) && <Error error={error} retryClickHandler={() => history(location.pathname, {replace: true})}/>}
            {(items && displayItems && !error) &&
                <div className="d-flex flex-grow-1 flex-row align-items-stretch mt-4">
                    <div
                        className={`list-container ${!isFullLayout ? 'small-screen' : 'me-4'} ${!isFullLayout && smallLayoutMode === ExecutorsMode.MAP ? "d-none" : ""}`}>
                        {displayItems.length > 0 && displayItems.filter(i => i.executor.locationLat && i.executor.locationLng)
                            .flatMap((item, index) => {
                                const elements = [];
                                if (index > 0) {
                                    elements.push(<HorizontalDivider key={`ld-${index}`}/>);
                                }
                                elements.push(<ExecutorsListItem key={`l-${item.executor.phone}`} item={item}
                                                                 clickListener={() => setSelectedItem(item.executor)}
                                                                 locationClickListener={locationClickHandler}/>);
                                return elements;
                            })}
                        {displayItems.length === 0 && <ExecutorsNotFoundPlaceholder/>}
                    </div>
                    <div className="container-grow" ref={mapContainer}>
                        <LoadScript googleMapsApiKey={API_KEY}>
                            <GoogleMap
                                mapContainerClassName="container-executors-map"
                                options={{
                                    streetViewControl: false
                                }}
                                center={center}
                                zoom={zoom}
                                onLoad={setMap}
                                onZoomChanged={saveMapState}>
                                <MarkerClusterer averageCenter
                                                 enableRetinaIcons
                                                 onClick={(cluster) => {
                                                     setActiveItem(cluster.getMarkers().flatMap(m => items.filter(i => i.executor.phone === m.getTitle())));
                                                 }}
                                                 gridSize={20}
                                                 zoomOnClick={false}
                                                 styles={[
                                                     {
                                                         height: 53,
                                                         url: "images/cluster_icon.png",
                                                         width: 53,
                                                         textColor: "#ffffff"
                                                     }]}>
                                    {(c) => <div>{
                                        displayItems?.filter(i => i.executor.locationLat && i.executor.locationLng)
                                            .map(i => (
                                                <Marker key={i.executor.phone}
                                                        title={i.executor.phone}
                                                        position={{
                                                            lat: i.executor.locationLat!,
                                                            lng: i.executor.locationLng!
                                                        }}
                                                        clusterer={c}
                                                        onClick={() => {
                                                            saveMapState();
                                                            setActiveItem([i]);
                                                        }}/>
                                            ))}
                                    </div>}
                                </MarkerClusterer>
                                {activeItem &&
                                    <InfoWindow options={{disableAutoPan: true}}
                                                onCloseClick={() => {
                                                    saveMapState();
                                                    setActiveItem(null);
                                                }}
                                                position={{
                                                    lat: activeItem[0].executor.locationLat!,
                                                    lng: activeItem[0].executor.locationLng!
                                                }}>
                                        <ExecutorsInfoWindowList items={activeItem} itemClickListener={item => {
                                            setSelectedItem(item)
                                        }}/>
                                    </InfoWindow>}
                            </GoogleMap>
                        </LoadScript>
                    </div>
                </div>
            }
            <ExecutorDetailsDialog closeHandler={() => setSelectedItem(undefined)} executor={selectedItem}
                                   dataCopyHandler={copyButtonClickHandler}/>
            <Alert show={showLinkToast} variant={"success"} className="toast-container-executors"
                   onClose={() => hideCopyToast()}
                   dismissible>
                <span
                    className="d-flex justify-content-center align-items-center toast-text-executors">{t("data_copied")}</span>
            </Alert>
        </div>
    );
}