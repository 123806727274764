import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";

interface Props {
    onFinish: () => void;
}

export function AwpFwUploadComplete(props: Props) {
    const {t} = useTranslation();
    const onFinish = () => {
        props.onFinish();
    };
    return (
        <div
            className="container-grow d-flex flex-column m-4 justify-content-center align-items-center fw-instruction-text">
            <div className="my-2">{t("awp_fw_upload_complete")}</div>
            <Button variant="primary" className="my-2"
                    onClick={onFinish}>{t("continue")}</Button>
        </div>
    );
}