import {useTranslation} from "react-i18next";
import IconGoogleSvg from "./GoogleIcon";
import {Button} from "react-bootstrap";
import "./GoogleSignInButton.css";

interface Props{
    className?: string;
    clickHandler: () => void;
}

export function GoogleSignInButton(props: Props){
    const {t} = useTranslation();
    return (
        <Button className={`google-sign-in-button ${props.className}`} variant="primary" onClick={props.clickHandler}><IconGoogleSvg className="google-sign-in-button-icon"/>{t("sign_in_with_google")}</Button>
    );
}