import {AwpSerialDevice} from "../serial/AwpSerialDevice";
import {parseString, parseStrings} from "../helpers/BinaryParseHelper";
import {AwpTpSeries, parseTpSeries} from "./AwpTpSeries";
import {AwpDirectoryHandle} from "../helpers/AwpDirectoryHandle";

export interface AwpTpRecord {
    name: string;
    date?: Date;
    series: Array<AwpTpSeries>;
    deviceSerial?: string;
    probeName?: string;
    probeType?: number;
    probeSerial?: string;
    photo?: string;
    operator?: string;
    object?: string;
    conclusion?: string;
}

export function readAwpTpRecord(fsHandle: AwpDirectoryHandle, recordName: string): Promise<AwpTpRecord | null> {
    return new Promise<AwpTpRecord | null>(async resolve => {
        let name = "";
        let series = new Array<AwpTpSeries>();
        let operator;
        let object;
        let conclusion;
        const recordHandle = await fsHandle.getDirectoryHandle(recordName).catch(() => null);
        if (recordHandle) {
            const nameHandle = await recordHandle.getFileHandle(AwpSerialDevice.nameFile).catch(() => null);
            if (nameHandle) {
                const buffer = await nameHandle.getData().catch(() => null);
                if (buffer) {
                    name = parseString(buffer);
                }
            } else {
                resolve(null);
                return;
            }
            const infoFile = await recordHandle.getFileHandle(AwpSerialDevice.infoFile).catch(() => null);
            if (infoFile) {
                const info = await infoFile.getData().catch(() => null);
                if (info) {
                    const parts = parseStrings(info);
                    if (parts.length >= 1) {
                        operator = parts[0];
                    }
                    if (parts.length >= 2) {
                        object = parts[1];
                    }
                }
            }
            const conclusionFile = await recordHandle.getFileHandle(AwpSerialDevice.conclusionFile).catch(() => null);
            if (conclusionFile) {
                const info = await conclusionFile.getData().catch(() => null);
                if (info) {
                    conclusion = parseString(info);
                }
            }
            for (let i = 1; i <= 100; i++) {
                const seriesFile = await recordHandle.getFileHandle(AwpSerialDevice.getSeriesFileName(i)).catch(() => null);
                if (!seriesFile) {
                    if (i === 1){
                        resolve(null);
                        return;
                    }
                    break;
                }
                const buffer = await seriesFile.getData().catch(() => null);
                if (buffer) {
                    const tpSeries = parseTpSeries(buffer);
                    if (tpSeries) {
                        series.push(tpSeries);
                    } else {
                        resolve(null);
                        return;
                    }
                } else {
                    break;
                }
            }
            let deviceSerial;
            let probeName;
            let probeSerial;
            if (series.length > 0) {
                deviceSerial = series[0].deviceSerial;
                if ((deviceSerial.length === 10 || deviceSerial.length === 11) && deviceSerial.match(/\d*/gm)) {
                    deviceSerial = `${deviceSerial.substring(0, 3)}.${deviceSerial.substring(3, 6)}.${deviceSerial.substring(6)}`;
                }
                if (deviceSerial.length === 14 && deviceSerial.match(/\d*/gm)) {
                    deviceSerial = `${deviceSerial.substring(0, 2)}.${deviceSerial.substring(2, 6)}.${deviceSerial.substring(6, 10)}.${deviceSerial.substring(10)}`;
                }
                probeName = series[0].probeName;
                probeSerial = series[0].probeSerial;
            }
            resolve({
                name: name,
                date: undefined,
                deviceSerial: deviceSerial,
                probeName: probeName,
                probeSerial: probeSerial,
                series: series,
                photo: undefined,
                operator: operator,
                object: object,
                conclusion: conclusion
            });
        }
    }).catch(() => {
        return null;
    });
}
