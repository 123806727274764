import './ExecutorsInfoWindowList.css';
import {ExecutorDataWithDistance} from "../../models/ExecutorDataWithDistance";
import {ExecutorsInfoWindowListItem} from "./ExecutorsInfoWindowListItem";
import {ExecutorData} from "../../models/ExecutorData";

interface Props{
    items : ExecutorDataWithDistance[];
    itemClickListener: (item: ExecutorData) => void;
}

export function ExecutorsInfoWindowList(props : Props){
    return (
        <div className="executors-info-window-list no-select">
            {props.items.map(i => <ExecutorsInfoWindowListItem key={i.executor.phone} item={i} clickListener={() => props.itemClickListener(i.executor)} />)}
        </div>
    );
}