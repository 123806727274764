export interface LanguageInfo{
    shortName: string;
    fullName: string;
    code: string
}

export const Languages : Array<LanguageInfo> = [
    {
        shortName: "En",
        fullName: "English",
        code: "en"
    },
    {
        shortName: "Ru",
        fullName: "Русский",
        code: "ru"
    },
    {
        shortName: "Ua",
        fullName: "Українська",
        code: "uk"
    }
]
