import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Fragment, useEffect, useRef, useState} from "react";
import {ArchiveItem} from "../../models/ArchiveItem";
import {
    deleteItems,
    getArchiveItems,
    getArchiveRootFolder,
    getPath,
    getRecord
} from "../../helpers/GoogleStorageApiHelper";
import {Loader} from "../Loader/Loader";
import {Error} from "../Error/Error";
import {Breadcrumbs} from "./Breadcrumbs";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import {ArchiveList} from "./ArchiveList";
import {ArchiveModeSwitcher} from "../ArchiveModeSwitcher/ArchiveModeSwitcher";
import {LIST} from "../../models/ExplorerMode";
import {getArchiveItemName} from "../../helpers/ArchiveFormatHelper";
import {ArchiveListPlaceholder} from "./ArchiveListPlaceholder";
import {buildListPath, buildRecordPath, LIST_PAGE_NAME, unescapePath} from "../../routes";
import {useAbortStatus} from "../../hooks/AbortHook";
import {LIST_LOCATION, SHOW_PREVIEW} from "../../persistence";
import {saveValue} from "../../helpers/PersistenceHelper";
import {ResourceNotFoundError} from "../../errors/GoogleDriveErrors";
import {NotFound} from "../NotFound/NotFound";
import {useTranslation} from "react-i18next";
import {useAnalytics} from "../../hooks/AnalyticsHook";
import {EmptyArchivePlaceholder} from "./EmptyArchivePlaceholder";
import {ToolBarButton} from "../ToolBarButton/ToolBarButton";
import IconTrashSvg from "../Icons/IconTrashSvg";
import IconInfoSvg from "../Icons/IconInfoSvg";
import {RecordPreview} from "./RecordPreview";
import {ControlledMenu, MenuItem, useMenuState} from "@szhsin/react-menu";
import {FetchData} from "../../models/FetchData";
import {IS_DEMO_APP, MIN_SCREEN_WIDTH_TO_SHOW_RECORD_PREVIEW} from "../../AppSettings";
import {NovotestLabRecord} from "../../models/NovotestLabRecord";
import {ModalTwoButtons} from "../ModalTwoButtons/ModalTwoButtons";
import {useUpdateToken} from "../../hooks/UpdateTokenHook";
import {usePersistentState} from "../../hooks/PersistentStateHook";
import {isMobile} from "react-device-detect";
import {useScreenSize} from "../../hooks/ScreenSizeHooks";
import {useGoogleApi} from "../../google_api/GoogleApiProvider";

interface Props {
    modeChangeListener: (mode: number) => void;
}


export function ListExplorer(props: Props) {
    useAnalytics(LIST_PAGE_NAME);
    const {t} = useTranslation();
    const isAborted = useAbortStatus();
    const drive = useGoogleApi().drive;
    const history = useNavigate();
    const location = useLocation();
    const {id} = useParams();
    const locationId = id ? unescapePath(id) : id;
    const [updateToken, refreshToken] = useUpdateToken();
    const firstSelectedItemId = useRef(null as string | null);
    const sortedIds = useRef(null as Array<string> | null);
    const [error, setError] = useState(null as Error | null);
    const [path, setPath] = useState(new Array<ArchiveItem>());
    const [items, setItems] = useState(null as Array<ArchiveItem> | null);
    const [highlightedItems, setHighlightedItems] = useState(() => new Array<string>());
    const singleHighlightedItem = (highlightedItems.length === 1 && (items?.find(i => i.id === highlightedItems[0])?.isFolder === false)) ? highlightedItems[0] : undefined;
    const [activeRecord, setActiveRecord] = useState(FetchData.init<NovotestLabRecord>());
    const {toggleMenu, ...menuProps} = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0});
    const [contextItemsId, setContextItemsId] = useState(null as Array<string> | null);
    const [showPreview, setShowPreview] = usePersistentState(SHOW_PREVIEW, !isMobile || window.innerWidth > window.innerHeight);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const currentLocation = path.length > 0 ? path[path.length - 1] : null;
    const [width] = useScreenSize();
    const isPreviewAvailable = width > MIN_SCREEN_WIDTH_TO_SHOW_RECORD_PREVIEW;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setPath([]);
        if (drive) {
            (locationId ? getPath(drive, locationId) : getArchiveRootFolder(drive).then(root => [root])).then(path => {
                if (!isAborted) {
                    if (path.length > 0) {
                        setPath(path);
                        let id = path[path.length - 1].id;
                        saveValue(LIST_LOCATION, id);
                    } else {
                        history(buildListPath());
                    }
                }
            }).catch((e) => {
                if (!isAborted) {
                    setError(e);
                }
            });
        }
    }, [drive, id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (drive) {
            const id = currentLocation?.id;
            if (id) {
                setItems(null);
                getArchiveItems(drive, id, true).then(items => {
                    if (!isAborted) {
                        setItems(path.length > 1 ? items : items.filter(i => {
                            const itemName = getArchiveItemName(t, 1, i.name);
                            if (itemName) {
                                i.name = itemName;
                            }
                            return itemName != null;
                        }));
                        setError(null);
                    }
                }).catch((e) => {
                    if (!isAborted) {
                        setError(e);
                    }
                });
            }
        }
    }, [currentLocation?.id, drive, t, updateToken]);
    useEffect(() => {
        if (drive && singleHighlightedItem) {
            setActiveRecord(FetchData.loading<NovotestLabRecord>());
            getRecord(drive, singleHighlightedItem, !IS_DEMO_APP).then(record => {
                if (!isAborted) {
                    setActiveRecord(record ? FetchData.value(record) : FetchData.error<NovotestLabRecord>());
                }
            }).catch((e) => {
                if (!isAborted) {
                    setActiveRecord(FetchData.error<NovotestLabRecord>());
                }
            });
        } else {
            setActiveRecord(FetchData.init<NovotestLabRecord>());
        }
    }, [drive, singleHighlightedItem]);
    useEffect(() => {
        const keyboardListener = (ev: KeyboardEvent) => {
            if ((ev.key.toLowerCase() === 'a' || ev.key.toLowerCase() === 'ф') && ev.ctrlKey) {
                setHighlightedItems(sortedIds.current ?? []);
                ev.preventDefault();
            } else if (ev.key === 'Delete') {
                deleteHighlightedRecord();
                ev.preventDefault();
            }
        };
        window.addEventListener('keydown', keyboardListener);
        return () => {
            window.removeEventListener('keydown', keyboardListener);
        }
    }, [highlightedItems])
    const showItem = (fileNames: Array<string>) => {
        if (fileNames.length > 0) {
            const archiveItem = items?.find(item => item.id === fileNames[0]);
            if (archiveItem) {
                setHighlightedItems(new Array<string>());
                if (archiveItem.isFolder) {
                    history(buildListPath(archiveItem.id));
                } else {
                    history(buildRecordPath(archiveItem.id));
                }
            }
        }
    };
    const showItemNewTab = (fileNames: Array<string>) => {
        if (fileNames.length > 0) {
            const archiveItem = items?.find(item => item.id === fileNames[0]);
            if (archiveItem) {
                if (archiveItem.isFolder) {
                    window.open(buildListPath(archiveItem.id), '_blank');
                } else {
                    window.open(buildRecordPath(archiveItem.id), '_blank');
                }
            }
        }
    };
    const contextMenuHandler = (id: string, x: number, y: number) => {
        setAnchorPoint({x: x, y: y});
        if (highlightedItems.includes(id)) {
            const records = new Array<string>();
            records.push(id);
            records.push(...highlightedItems.filter(i => i !== id));
            setContextItemsId(records);
        } else {
            setContextItemsId([id]);
        }
        toggleMenu(true);
    };
    const handleItemClick = (id?: string, ctrl?: boolean, shift?: boolean) => {
        if (id) {
            if (path.length > 1 && ctrl) {
                if (highlightedItems.includes(id)) {
                    setHighlightedItems(highlightedItems.filter(i => i !== id));
                } else {
                    highlightedItems.push(id)
                    setHighlightedItems(new Array(...highlightedItems));
                }
                firstSelectedItemId.current = id;
            } else if (path.length > 1 && shift) {
                if (sortedIds.current) {
                    const items = [];
                    let started = false;
                    if (firstSelectedItemId.current === null) {
                        started = true;
                    }
                    for (const item of sortedIds.current) {
                        if (!started) {
                            if (item === firstSelectedItemId.current || item === id) {
                                started = true;
                            }
                        }
                        if (started) {
                            items.push(item);
                        }
                        if (items.includes(id) && (!firstSelectedItemId.current || items.includes(firstSelectedItemId.current))) {
                            break;
                        }
                    }
                    setHighlightedItems(items);
                }
            } else {
                setHighlightedItems(new Array<string>(...[id]))
                firstSelectedItemId.current = id;
            }
        } else {
            setHighlightedItems(new Array<string>());
            firstSelectedItemId.current = null;
        }
    }
    const openContextRecord = () => {
        if (contextItemsId) {
            showItem(contextItemsId);
        }
    };
    const openContextRecordNewTab = () => {
        if (contextItemsId) {
            showItemNewTab(contextItemsId);
        }
    };
    const deleteContextRecord = () => {
        if (path.length > 1 && contextItemsId) {
            setShowDeleteConfirmModal(true);
        }
    };
    const deleteHighlightedRecord = () => {
        if (path.length > 1 && highlightedItems.length > 0) {
            setContextItemsId(highlightedItems);
            setShowDeleteConfirmModal(true);
        }
    }
    const onDeleteContextRecordsConfirm = () => {
        if (contextItemsId && path.length > 1) {
            deleteItems(drive, contextItemsId).then(result => {
                if (contextItemsId && contextItemsId.length > 1) {
                    setHighlightedItems(new Array<string>());
                }
                setContextItemsId(null);
                refreshToken();
            })
        }
    };
    return (
        <div className="container-grow">
            <div className="d-flex flex-row align-items-center justify-content-between">
                {(drive && path.length > 0 && items && !error) &&
                    <Breadcrumbs path={path}
                                 locationChangeListener={locationId => history(buildListPath(locationId))}/>
                }
                <div/>
                <ArchiveModeSwitcher mode={LIST} modeChangeListener={props.modeChangeListener}/>
            </div>
            <HorizontalDivider className="mb-1"/>
            {(!error && (!drive || path.length === 0 || !items)) && <Loader/>}
            {error && ((error instanceof ResourceNotFoundError) ? <NotFound/> :
                <Error error={error} retryClickHandler={() => history(location.pathname, {replace: true})}/>)}
            {(drive && path.length > 0 && items && !error) &&
                (items.length > 0 ?
                    <Fragment>
                        <div className="d-flex flex-row justify-content-end">
                            {path.length > 1 &&
                                <ToolBarButton icon={<IconTrashSvg/>} disabled={highlightedItems.length === 0}
                                               clickHandler={deleteHighlightedRecord}
                                               popoverText={t('record_list_instruction_2')}/>
                            }
                            {isPreviewAvailable &&
                                <ToolBarButton icon={<IconInfoSvg/>} activated={showPreview}
                                               clickHandler={() => setShowPreview(!showPreview)}/>

                            }
                        </div>
                        {(path.length > 1 || isPreviewAvailable) &&
                            <HorizontalDivider className="my-1"/>
                        }
                        <div className="container-grow container-records flex-row">
                            <div
                                className="d-flex flex-grow-1 flex-column align-self-stretch m-2 overflow-y-auto"
                                onClick={() => handleItemClick()}>
                                <ArchiveList items={items}
                                             highlightedItems={highlightedItems}
                                             itemHighlightListener={handleItemClick}
                                             itemDoubleClickListener={(id) => showItem([id])}
                                             itemContextClickListener={contextMenuHandler}
                                             sortedIdsRef={sortedIds}/>
                            </div>
                            {isPreviewAvailable &&
                                <RecordPreview drive={drive} recordId={singleHighlightedItem} record={activeRecord}
                                               showPreview={showPreview}
                                               count={highlightedItems.length} onClose={() => setShowPreview(false)}
                                               onDetailsClick={() => showItem(highlightedItems)}/>
                            }
                        </div>
                    </Fragment> :
                    (path.length > 1 ? <ArchiveListPlaceholder/> : <EmptyArchivePlaceholder/>))}
            <ModalTwoButtons show={showDeleteConfirmModal} title={t("delete_confirmation_title")}
                             body={(contextItemsId?.length ?? 0) > 1 ? t("delete_multiple_elements_confirmation_message") : t("delete_single_element_confirmation_message")}
                             negativeButtonText={t("no")}
                             positiveButtonText={t("yes")}
                             closeHandler={() => setShowDeleteConfirmModal(false)}
                             positiveButtonAction={onDeleteContextRecordsConfirm}/>
            <ControlledMenu {...menuProps} anchorPoint={anchorPoint}
                            onClose={() => toggleMenu(false)} menuClassName={"context-menu"}>
                {contextItemsId && contextItemsId.length === 1 &&
                    <MenuItem onClick={openContextRecord}>{t("open")}</MenuItem>
                }
                {contextItemsId && contextItemsId.length === 1 &&
                    <MenuItem onClick={openContextRecordNewTab}>{t("open_new_tab")}</MenuItem>
                }
                {!IS_DEMO_APP && path.length > 1 &&
                    <MenuItem onClick={deleteContextRecord}>{t("delete")}</MenuItem>
                }
            </ControlledMenu>
        </div>
    );
}
