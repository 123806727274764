import {parseInt32, parseString, parseUint8} from "../helpers/BinaryParseHelper";
import {AreaChartData} from "../components/MeasurementDetails/MeasurementsAreaChart";

const SERIES_SIZE = 2153;
const NEW_SERIES_SIZE = 123;

const NAME_SIZE = 21;
const PROBE_NAME_SIZE = 21;
const PROBE_SERIAL_SIZE = 11;

const DEVICE_SERIAL_SIZE = 11;
const NEW_SERIAL_NUMBER_SIZE = 30;

const DIR_NAME_SIZE = 15;
const STRENGTH_SIZE = 4;

export interface AwpMfSeries{
    name: string;
    probeName: string;
    probeSerial: string;
    deviceSerial: string;
    dirName: string;
    probeType: number;
    strength: Array<number>;
    scale: number;
    mode: number;
    head: number;
    graph: Array<number>;
}

export function parseMfSeries(buffer: ArrayBuffer) : AwpMfSeries | null{
    if (buffer.byteLength === SERIES_SIZE){
        let offset = 0;
        const name = parseString(buffer, offset, NAME_SIZE);
        offset += NAME_SIZE;
        const probeName = parseString(buffer, offset, PROBE_NAME_SIZE);
        offset += PROBE_NAME_SIZE;
        const probeSerial = parseString(buffer, offset, PROBE_SERIAL_SIZE);
        offset += PROBE_SERIAL_SIZE;
        const deviceSerial = parseString(buffer, offset, DEVICE_SERIAL_SIZE);
        offset += DEVICE_SERIAL_SIZE;
        const dirName = parseString(buffer, offset, DIR_NAME_SIZE);
        offset += DIR_NAME_SIZE;
        const probeType = parseUint8(buffer, offset);
        offset += 1;
        const strength = new Float32Array(buffer.slice(offset), 0, STRENGTH_SIZE);
        offset += STRENGTH_SIZE * 4;
        const scale = parseInt32(buffer, offset);
        offset += 4;
        const mode = parseInt32(buffer, offset);
        offset += 4;
        const head = parseUint8(buffer, offset);
        offset += 1;
        const graph = new Float32Array(buffer.slice(offset), 0, 512);
        return {
            name: name,
            probeName: probeName,
            probeSerial: probeSerial,
            deviceSerial: deviceSerial,
            dirName: dirName,
            probeType: probeType,
            strength: Array.from(strength),
            scale: scale,
            mode: mode,
            head: head,
            graph: Array.from(graph)
        };
    }
    if (buffer.byteLength === NEW_SERIES_SIZE){
        let offset = 0;
        const name = parseString(buffer, offset, NAME_SIZE);
        offset += NAME_SIZE;
        const probeName = parseString(buffer, offset, PROBE_NAME_SIZE);
        offset += PROBE_NAME_SIZE;
        const probeSerial = parseString(buffer, offset, PROBE_SERIAL_SIZE);
        offset += PROBE_SERIAL_SIZE;
        const deviceSerial = parseString(buffer, offset, NEW_SERIAL_NUMBER_SIZE);
        offset += NEW_SERIAL_NUMBER_SIZE;
        const dirName = parseString(buffer, offset, DIR_NAME_SIZE);
        offset += DIR_NAME_SIZE;
        const probeType = parseUint8(buffer, offset);
        offset += 1;
        const strength = new Float32Array(buffer.slice(offset), 0, STRENGTH_SIZE);
        offset += STRENGTH_SIZE * 4;
        const scale = parseInt32(buffer.slice(offset), 0);
        offset += 4;
        const mode = parseInt32(buffer.slice(offset), 0);
        const head = 0;
        const graph = new Array<number>(512).fill(0);
        return {
            name: name,
            probeName: probeName,
            probeSerial: probeSerial,
            deviceSerial: deviceSerial,
            dirName: dirName,
            probeType: probeType,
            strength: Array.from(strength),
            scale: scale,
            mode: mode,
            head: head,
            graph: Array.from(graph)
        };
    }
    return null;

}

export function getAwpMfMeasurementError(scale: number){
    switch (scale){
        case 0:
            return 0.05;
        case 1:
            return 0.001;
        default:
            return 0.05;
    }
}

export function prepareAwpMfAreaChartData(series: AwpMfSeries) {
    let i = 0;
    const data = new Array<AreaChartData>();
    for (let j = 0; j < 128; j++) {
        const max = series.graph[i]
        const min = series.graph[256 + i];
        data.push({
            x: i++,
            y: [min, max]
        });
    }
    return data;
}