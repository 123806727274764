import {AwpLocalDirectoryHandle} from "../helpers/AwpLocalDirectoryHandle";
import {AwpDirectoryHandle} from "../helpers/AwpDirectoryHandle";
import {StorageReference} from "firebase/storage";
import {AwpGoogleCloudDirectoryHandle} from "../helpers/AwpGoogleCloudDirectoryHandle";

export interface FileData {
    data: ArrayBuffer;
    timestamp: Date;
}

export interface ArchiveFileSystemHandle {
    handle?: AwpDirectoryHandle;
    isGranted?: boolean;
    isExist?: boolean;
}

export function createLocalArchiveFileSystemHandle(handle?: FileSystemDirectoryHandle, rootHandleName? : string, isGranted?: boolean, isExist?: boolean): ArchiveFileSystemHandle {
    return {
        handle: handle ? new AwpLocalDirectoryHandle(handle, rootHandleName) : undefined,
        isGranted: isGranted,
        isExist: isExist
    };
}

export function createDemoArchiveFileSystemHandle(handle?: StorageReference): ArchiveFileSystemHandle {
    return {
        handle: handle ? new AwpGoogleCloudDirectoryHandle(handle) : undefined,
        isGranted: true,
        isExist: true
    };
}

export function openFileHandle(f: AwpDirectoryHandle, name: string): Promise<FileData> {
    return f.getFileHandle(name).then(e => {
        return e!.getData().then(arr => {
            return e!.lastModified().then(lastModified => {
                return {
                    data: arr,
                    timestamp: new Date(lastModified)
                };
            });
        });
    });
}