import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {useEffect, useMemo, useRef, useState} from "react";

const DATA_COLOR="#9e1b1b";

export interface LineChartData {
    x: number;
    y: number;
}

interface Props {
    data: LineChartData[];
    measurementError: number;
    name: string;
    valueFormatter: (v : number) => string;
    classNames?: string;
}

interface AxisProperties {
    minY: number;
    maxY: number;
    ticksY: number;
    fractionDigits: number;
    maxX: number;
    ticksX: number;
}

function getRounder(min: number, max: number) {
    let delta = max - min;
    let rounder = 0.00001;
    while (delta > rounder * 10) {
        rounder *= 10;
    }
    return Math.min(rounder, 100);
}

function roundMin(min: number, rounder: number) {
    let value;
    if (rounder < 1) {
        value = min;
    } else {
        value = Math.floor(min);
    }
    return Math.floor(value / rounder) * rounder;
}

function getDividerX(count : number){
    let divider = 1;
    while (count / divider > 10){
        divider++;
    }
    return divider;
}

function calculateAxisX(count : number) : [max : number, ticks : number] {
    if (count <= 10){
        return [10, 11];
    }
    const divider = getDividerX(count);
    const max = count % divider === 0 ? count : (Math.floor(count / divider) + 1) * divider;
    return [max, max / divider + 1];
}

function roundMax(max: number, rounder: number) {
    let value;
    if (rounder < 1) {
        value = max;
    } else {
        value = Math.ceil(max) - 1;
    }
    return (Math.floor(value / rounder) + 1) * rounder;
}

export function MeasurementsLineChart(props: Props) {
    const container = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const checkSize = () => {
        const w = container.current?.clientWidth ?? 0;
        const h = container.current?.clientHeight ?? 0;
        if (w !== width) {
            setWidth(w);
        }
        if (h !== height) {
            setHeight(h);
        }
    };
    useEffect(() => {
        checkSize();
        window.addEventListener("resize", checkSize);
        return () => {
            window.removeEventListener("resize", checkSize);
        }
    });
    const chartProps = useMemo(() => {
        const points = props.data.map(d => d.y);
        const minY = Math.min(...points);
        const maxY = Math.max(...points);
        const delta = maxY - minY;
        let axisMin = minY;
        let axisMax = maxY;
        let minDelta = props.measurementError * 10;
        if (delta < minDelta) {
            let middle = axisMin + (axisMax - axisMin) / 2;
            axisMin = minY < 0 ? middle - minDelta / 2 : Math.max(0, middle - minDelta / 2);
            axisMax = axisMin + minDelta;
        }
        let rounder = getRounder(axisMin, axisMax);
        let min = roundMin(axisMin, rounder);
        let max = roundMax(axisMax, rounder);
        let divisions = Math.min(12, Math.round((max - min) / rounder) + 1);
        while (divisions < 4) {
            rounder /= 2;
            min = roundMin(axisMin, rounder);
            max = roundMax(axisMax, rounder);
            divisions = Math.min(12, Math.round((max - min) / rounder) + 1);
        }
        const log = Math.log10(rounder) - 1;
        const fractionDigits = log >= 0 ? 0 : Math.abs(Math.floor(log));
        const [maxX, ticksX] = calculateAxisX(props.data.length);
        return {
            minY: Number(min.toFixed(fractionDigits)),
            maxY: Number(max.toFixed(fractionDigits)),
            ticksY: divisions,
            fractionDigits: fractionDigits,
            maxX : maxX,
            ticksX: ticksX
        } as AxisProperties;
    }, [props]);
    const yAxisChars = Math.max(chartProps.minY.toFixed(chartProps.fractionDigits).length, chartProps.maxY.toFixed(chartProps.fractionDigits).length);
    return (
        <div className={`d-flex flex-column ${props.classNames}`} ref={container}>
            <LineChart width={width} height={height} data={props.data}>
                <CartesianGrid vertical={false}/>
                <XAxis dataKey={"x"} domain={[0, chartProps.maxX]} type={"number"} tickCount={chartProps.ticksX}
                       interval={0} padding={{left: 0, right: 8}}/>
                <YAxis domain={[chartProps.minY, chartProps.maxY]} tickCount={chartProps.ticksY} tickFormatter={(v : number) => v.toFixed(chartProps.fractionDigits)} width={yAxisChars * 12}/>
                <Legend verticalAlign={"top"} height={36}/>
                <Tooltip formatter={(v : any) => [props.valueFormatter(v)]}/>
                <Line dataKey={"y"} name={props.name} stroke={DATA_COLOR}/>
            </LineChart>
        </div>
    );
}