import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";

interface Props {
    onBackButtonClick: () => void;
}

export function AwpFwConnectionLost(props: Props){
    const {t} = useTranslation();
    return (<div className="container-grow">
        <div
            className="d-flex flex-grow-1 justify-content-center flex-column align-items-center my-4 fw-simple-instruction-text awp-fw-buttons-100">
            <span>{t("awp_fw_connection_lost_message")}</span>
            <span>{t("awp_fw_contact_manufacturer")}</span>
            <div className="my-2"/>
            <Button onClick={props.onBackButtonClick}>{t("ok")}</Button>
        </div>
    </div>);
}