export const LIST_LOCATION = "list_location";
export const EXPLORER_SORT = "explorer_key";
export const EXPLORER_DEVICE_FILTER = "explorer_filter";
export const CALENDAR_DATE = "calendar_date";

export const SHOW_PREVIEW = "show_preview";

export const EXECUTORS_DEVICE_FILTER = "executors_device_filter";
export const EXECUTORS_PERIOD_FILTER = "executors_period_filter";
export const EXECUTORS_DISTANCE_FILTER = "executors_distance_filter";

export const AWP_LIST_SORT_ORDER = "awp_list_sort_order";

export const USER_DATA = "gud";