import './ViewContainer.css';

interface Props {
    title?: string;
    className?: string;
    children: JSX.Element | JSX.Element[];
    action: string;
    onActionClick: () => void;
}

export function ViewContainerWithAction(props: Props) {
    return (
        <div className={`d-flex flex-grow-1 flex-column my-2 ${props.className ?? ""}`}>
            {props.title && <span className="align-self-start mb-2 view-title">{props.title}</span>}
            <div className="view-container flex-grow-1 p-2">
                {props.children}
            </div>
            <span className="align-self-end mt-2 view-action" onClick={props.onActionClick}>
                    {props.action.toUpperCase()}
            </span>
        </div>
    );
}