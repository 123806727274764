import {AwpSeries} from "../../helpers/AwpHelper";
import {useTranslation} from "react-i18next";
import {formatAwpMfScaleName, formatAwpMfValue} from "../../helpers/AwpMfFormatHelper";
import {AwpMfSeries} from "../../models/AwpMfSeries";
import './AwpSeriesInfo.css';

interface Props{
    data: AwpSeries;
}

export function AwpMfMeasuredInfo(props: Props){
    const {t} = useTranslation();
    const data = props.data as AwpMfSeries;
    return (
        <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
            <table className="table-mf-measurement-info">
                <tbody>
                <tr>
                    <th>{formatAwpMfValue(data.strength[data.scale], data.scale)}</th>
                    <td>{formatAwpMfScaleName(t, data.scale)}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}