import {BaseRecord} from "./BaseRecord";
import {MediaRecord} from "./MediaRecord";
import {UciMeasurement} from "./UciMeasurement";

export interface UciRecord extends BaseRecord {
    measurements?: UciMeasurement[];
    notes?: string;
    photo?: MediaRecord;
    audio?: MediaRecord[];
    video?: MediaRecord[];
}

export function isValidUciRecord(uciRecord: UciRecord){
    return uciRecord.measurements?.every(m => {
        return m.count !== undefined && m.avg !== undefined && m.max !== undefined && m.min !== undefined && m.uciScale !== undefined && m.uciMaterial !== undefined && m.data !== undefined;
    });
}