import {TFunction} from "i18next";
import {formatNumber} from "./FormatHelper";
import i18next from "i18next";

const G = 0;
const A_CM = 1;
const MT = 2;
const CODE = 3;

export function formatAwpMfScaleName(t: TFunction<"translation">, scale: number) {
    switch (scale) {
        case G:
            return t('scale_g');
        case A_CM:
            return t('scale_a_cm');
        case MT:
            return t('scale_mt');
        case CODE:
            return t('scale_cod');
    }
    return "";
}

export function formatEnAwpMfScaleName(scale: number) {
    const t = i18next.getFixedT("en");
    return formatAwpMfScaleName(t, scale);
}

export function formatAwpMfValue(value: number, scale: number){
    switch (scale){
        case G:
            return formatNumber(value, 1, 1);
        case A_CM:
            return formatNumber(value, 1, 1);
        case MT:
            return formatNumber(value, 2, 2);
        case CODE:
            return formatNumber(value, 0, 0);
    }
    return formatNumber(value, 1, 1);
}