import languageEn from './locale/en/translate.json';
import languageRu from './locale/ru/translate.json';
import languageUk from './locale/uk/translate.json';

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        resources: {
            en: languageEn,
            uk: languageUk,
            ru: languageRu
        }
    });

export default i18n;
